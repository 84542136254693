import {useGetRoleQuery} from "../store/api/api";
import {roleById} from "./roles";

export const skillById = (industries,skillId) => {

    for (let i=0; i<industries.length; i++) {
        if (skillId === industries[i].skill_id) {
            return industries[i]
        }
    }

    return null

};


export const skillLabelById = (industries,skillId) => {

    let skill = skillById(industries,skillId)
    if (skill) {
        return skill.sublabel ? skill.sublabel : skill.label
    }

    return 'label'


};

