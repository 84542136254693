import React from 'react'

export const MiniLogo = () => {
  return (
    <svg width="37" height="33.4" viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg" className='mini-logo'>
      <path d="M30.4373 0.481934C26.1309 0.481934 0.499512 0.653695 0.499512 0.481934C0.499512 0.481934 0.499512 22.3742 0.499512 25.2908C0.499512 28.5423 4.10804 29.1907 4.43694 29.2433C4.46502 29.5096 4.96037 33.5181 9.45666 33.5181C11.6961 33.5181 37.5 33.5181 37.5 33.5181C37.5 33.5181 37.5 11.7303 37.5 7.68726C37.5 3.05569 34.7438 0.481934 30.4373 0.481934ZM8.92588 23.2876H6.43574C6.43574 23.2876 6.43574 14.7708 6.43574 13.3694C6.43574 10.4587 8.92588 10.4587 8.92588 10.4587V23.2883V23.2876ZM29.4346 23.2876V10.4587H18.2714C18.4305 10.4621 20.7529 10.5586 20.7529 13.3694V23.2876H18.2627V10.4587H8.95462C8.95462 10.4587 8.95462 7.97886 11.8773 7.97886H15.1656C17.379 7.97886 17.9158 9.40022 18.0468 10.0906C18.1772 9.40022 18.7146 7.97886 20.928 7.97886H26.5708C29.4934 7.97886 29.4934 10.4587 29.4934 10.4587H29.4433C29.6024 10.4621 31.9241 10.5586 31.9241 13.3694C31.9241 14.7708 31.9241 23.2876 31.9241 23.2876H29.4339H29.4346Z" fill="url(#paint0_linear_4668_31877)" />
      <defs>
        <linearGradient id="paint0_linear_4668_31877" x1="18.9997" y1="1.31146" x2="18.9997" y2="34.3476" gradientUnits="userSpaceOnUse">
          <stop offset="0.22" className='first-color'/>
          <stop offset="0.5" className='sec-color'/>
          <stop offset="0.84" className='third-color'/>
        </linearGradient>
      </defs>
    </svg>


  )
}
