import logo from "../../../assets/img/initial-logo.svg";
import darklogo from "../../../assets/img/dark-initial-logo.svg";
import button from "../../../assets/img/initial-btn-2.svg";
import darkbutton from "../../../assets/img/dark-initial-btn-2.svg";
import buttonSym from "../../../assets/img/initial-btn-sym.svg";
import background from "../../../assets/img/Isolation_Mode.svg";
import mlogo from "../../../assets/img/m-logo-light.png";
import darkmlogo from "../../../assets/img/m-logo-dark.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {useGetIndustryQuery, useGetSkillQuery} from '../../../store/api/api';
import MultiselectTreeNode from "../common/multiselect-tree";
import MultiSelectTree from "../common/multiselect-tree";



export const ProjectLaunch = (props) => {
    const commonStore = useSelector(state => state.commonStore);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        if (commonStore.theme) {
            if (commonStore.theme === 'light') {
                document.body.classList.remove('dark')
            } else {
                document.body.classList.add(commonStore.theme);
            }
        }
    }, [commonStore.theme])

    function createNewProject() {
        dispatch({ type: 'project-edit/launch', payload: { "name": Date.now() + ((Math.random() * 100000).toFixed()) } });
        navigate("/project/start")
    }

    return (
        <div className="main-container project_initial">
            <div className="setup-container w-full overflow-auto mobile:overflow-hidden tablet:overflow-hidden min-h-[720px]">
                <div className="max-w-[1920px] w-full mx-auto h-full relative bg-white dark:bg-[#1D1D1D] flex-row flex mobile:flex-col tablet:flex-col tablet:justify-between mobile:gap-[19px]">
                    <div className="w-[46%] [@media(min-width:1920px)]:w-[676px] tablet:w-full h-full p-[88px] mobile:py-[64px] mobile:w-full mobile:px-[24px] mobile:h-auto">
                        <div className="relative w-full h-full flex items-center mobile:h-auto mobile:items-start tablet:h-auto tablet:items-start flex-wrap mobile:gap-[48px] tablet:gap-[48px]">
                            <div className="w-full absolute top-0 left-0 mobile:relative mobile:top-auto mobile:left-auto mobile:float-left mobile:flex mobile:justify-center tablet:relative tablet:top-auto tablet:left-auto tablet:float-left tablet:flex tablet:justify-center">
                                <img className="dark:hidden mobile:max-w-[138px]" alt="logo" src={logo} />
                                <img className="hidden dark:block mobile:max-w-[138px]" alt="logo" src={darklogo} />
                            </div>
                            <div className="flex-col justify-center items-start gap-12 flex self-center w-full">
                                <div className="flex-col justify-center items-start gap-6 w-[500px] flex mobile:gap-[24px] tablet:gap[24px] tablet:w-full mobile:w-full">
                                    <div className="w-full text-stone-950 text-[63px] font-semibold leading-[91.5%] tracking-[-1.89px] mobile:text-[48px] mobile:text-center tablet:text-[48px] tablet:text-center dark:text-[#fff]">Weaponize creativity</div>
                                    <div className="w-full text-neutral-800 text-[20px] font-normal leading-[130%] tablet:text-[16px] tablet:text-center mobile:text-[16px] mobile:text-center dark:text-[#A3A3A3]">This is mission control. The place where we get all the right inputs to so we can align the creative superstars to crush up your business problem. </div>
                                </div>
                                <div className="w-full relative mobile:flex mobile:justify-center tablet:justify-center tablet:flex">
                                    <button className="w-[229.3px] h-[76.02px] " onClick={createNewProject} >
                                        <div className="w-full h-full justify-center items-center gap-[7.88px] flex">
                                            <div className="absolute top-[0] left-[0] mobile:left-[calc(50%-115px)] tablet:left-[calc(50%-115px)]"><img alt="icon-bg" src={button} className="dark:hidden" /><img alt="icon-bg" src={darkbutton} className="hidden dark:block" /></div>
                                            <div className="w-[9.14px] h-[22.05px] relative">
                                                <img alt="icon" src={buttonSym} />
                                            </div>
                                            <div className="text-center text-black text-[16.5px] font-bold dark:text-[#fff]">Launch now</div>
                                        </div>
                                    </button>

                                    <div className="w-[421px]  shadow-xl">
                                         {/*<div className="field-group w-[100%] mt-[40px] custom-select" tabIndex="0">*/}
                                         {/*    <label className="text-[14px] top-0 text-[#a3a3a3] primary z-0 duration-300 origin-0 mt-[-26px] select-label">Industries</label>*/}
                                         {/*    <input readOnly="" type="text" name="primaryindustry" className="input-fld !relative !z-1"/>*/}
                                         {/*    <span className="absolute right-[4px] bottom-[8px]">*/}
                                         {/*        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" className="dropdown-icon">*/}
                                         {/*           <path fill-rule="evenodd" clip-rule="evenodd" d="M5.70711 6.24422C5.31658 6.63474 4.68342 6.63474 4.29289 6.24422L0.292892 2.24422C-0.0976325 1.85369 -0.0976325 1.22053 0.292892 0.830002C0.683416 0.439478 1.31658 0.439478 1.70711 0.830002L5 4.1229L8.29289 0.830002C8.68342 0.439478 9.31658 0.439478 9.70711 0.830002C10.0976 1.22053 10.0976 1.85369 9.70711 2.24422L5.70711 6.24422Z" fill="#4C4C4C"></path>*/}
                                         {/*        </svg>*/}
                                         {/*    </span>*/}
                                         {/*    <span className="absolute bottom-[8px] right-[1px]">*/}
                                         {/*        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="tick-icon dark:hidden">*/}
                                         {/*        <path d="M7.99651 15.5C12.1179 15.5 15.5 12.1248 15.5 8.00349C15.5 3.88215 12.1179 0.5 7.99651 0.5C3.87517 0.5 0.5 3.88215 0.5 8.00349C0.5 12.1248 3.87517 15.5 7.99651 15.5ZM7.2364 11.546C6.88773 11.546 6.60879 11.3856 6.37169 11.0997L4.79568 9.24477C4.60042 9.02162 4.53766 8.84031 4.53766 8.61018C4.53766 8.11506 4.94212 7.73152 5.42329 7.73152C5.70223 7.73152 5.91841 7.8431 6.11367 8.07322L7.22245 9.39121L9.80265 5.31869C10.0188 4.98396 10.2629 4.80962 10.5837 4.80962C11.0649 4.80962 11.4972 5.16527 11.4972 5.65342C11.4972 5.8417 11.4344 6.05091 11.295 6.24616L8.07322 11.0788C7.87796 11.3717 7.57113 11.546 7.2364 11.546Z" fill="#1D1D1D"></path>*/}
                                         {/*        </svg>*/}
                                         {/*        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="tick-icon hidden dark:block"><path d="M7.99651 15.6011C12.1179 15.6011 15.5 12.2259 15.5 8.10456C15.5 3.98322 12.1179 0.601074 7.99651 0.601074C3.87517 0.601074 0.5 3.98322 0.5 8.10456C0.5 12.2259 3.87517 15.6011 7.99651 15.6011ZM7.2364 11.6471C6.88773 11.6471 6.60879 11.4867 6.37169 11.2008L4.79568 9.34584C4.60042 9.12269 4.53766 8.94138 4.53766 8.71126C4.53766 8.21614 4.94212 7.83259 5.42329 7.83259C5.70223 7.83259 5.91841 7.94417 6.11367 8.1743L7.22245 9.49229L9.80265 5.41976C10.0188 5.08503 10.2629 4.9107 10.5837 4.9107C11.0649 4.9107 11.4972 5.26635 11.4972 5.75449C11.4972 5.94278 11.4344 6.15198 11.295 6.34724L8.07322 11.1799C7.87796 11.4728 7.57113 11.6471 7.2364 11.6471Z" fill="white"></path>*/}
                                         {/*        </svg>*/}
                                         {/*    </span>*/}
                                         {/*    <div className="inds-primary-multi-select-backdrop multi-select-backdrop " style="display: block;"></div>*/}
                                         {/*</div>*/}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[54%] [@media(min-width:1920px)]:flex-grow h-full mobile:w-full mobile:h-auto tablet:w-full tablet:h-auto" >
                        <div className="relative float-left w-full h-full  bg-cover  flex items-center mobile:h-[400px] mobile:items-start tablet:h-[400px] tablet:items-start !dark:bg-none" style = {{ background: `url(${commonStore.theme === 'light' ? background : ''}) no-repeat top right`}} >
                            <div className="pl-[10%] pr-[22%] mobile:px-[20%] tablet:px-[30%] mobile:m-auto tablet:m-auto">
                                <span>
                                    <img alt="mlogo" src={mlogo} className="dark:hidden block mobile:dark:hidden tablet:dark:hidden"/>
                                    <img alt="mlogo" src={darkmlogo} className="dark:block hidden mobile:dark:block tablet:dark:block"/>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}