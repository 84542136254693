import Session from 'supertokens-web-js/recipe/session';
import logo from "../../../assets/img/initial-logo.svg";
import orangeIcon from "../../../assets/img/arrow-orange-icon.svg";
import mlogo from "../../../assets/img/logo-mini-color.svg";
import minilogo from '../../../assets/img/logo-mini-color.svg';
import { useEffect, useState } from "react";
import { ProjectSetupTemplate } from "../common/project-setup-template";
import { ProjectSetupFooter } from "../common/project-setup-footer";
import darklogo from "../../../assets/img/dark-initial-logo.svg";
import darkminilogo from '../../../assets/img/dark-logo-mini.svg';
import { EditIcon } from "../common/icons/edit-icon";
import { GetProject } from "../../service/project-setup-api";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import common from "../../../utils/common";
import nav from "../../../assets/img/nav-arrow.png";
import rendertoast from "../../../utils/rendertoast";

export const ProjectSummary = (props) => {
    const [showSummaryInfo,setShowSummaryInfo] = useState(false);
    const [showScopeInfo,setShowScopeInfo] = useState(false);
    const [projectSummary, setProjectSummary] = useState({});
    const [estimatedScope, setEstimatedScope] = useState();
    const [selectedSkills, setSelectedSkills] = useState()
    const [showLoginConfirmation , setLoginConfirmation ] = useState(false);
    const projectSetupStore = useSelector(state => state.projectSetupStore);
    const selectStore = useSelector(state => state.selectStore);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchParam = new URLSearchParams(location.search);
    const id =  searchParam.size && location.search && location.search !== "" ? common.encryptdecrypt("decrypt", location.search.slice(1))  : null;
    useEffect(() => {
        if((projectSetupStore.project_id && projectSetupStore.project_id !== "") || id) {
            var projectId = id && id !== "" ? id : projectSetupStore.project_id ? projectSetupStore.project_id : null;
            if(projectId) {
                GetProject(dispatch, projectId).then((res) => {
                    if(res && res.data && res.data.projects && res.data.projects.length) {
                        var projectSum = res.data.projects[0];
                        var estimated = projectSum.estimated_project_scope && projectSum.estimated_project_scope.length ? projectSum.estimated_project_scope[0] : null
                        setProjectSummary(projectSum)
                        setEstimatedScope(estimated)
                    }
                })
            }
        }
        if(projectSetupStore.page2 && projectSetupStore.page2.skills && projectSetupStore.page2.skills.length) {
            var content = projectSetupStore.page2.skills[0].split('-');
            if(content && content.length) {
                if(content[0] === 'bigidea') {
                    setSelectedSkills('Big Idea')
                } else if(content[0] === 'experiential') {
                    setSelectedSkills('Experiential & Activation')
                } else if(content[0] === 'metaverse') {
                    setSelectedSkills('Gaming, AI & New Realities')
                } else if(content[0] === 'strategybrand') {
                    setSelectedSkills('Strategy')
                } else if(content[0] === 'social') {
                    setSelectedSkills('Social & Creators')
                } else if(content[0] === 'brand') {
                    setSelectedSkills('Content & Entertainment')
                }
            }
        }
    },[projectSetupStore])


    function toggleElement(e, type) {
        let boolVal;
        if (e.currentTarget.classList.contains('active')) {
            e.currentTarget.classList.remove('active');
            boolVal = false;
        } else {
            e.currentTarget.classList.add('active');
            boolVal = true;
        }
        if (type === 'summary') {
            setShowSummaryInfo(boolVal);
        } else if (type === 'scope') {
            setShowScopeInfo(boolVal);
        } else {
            setShowSummaryInfo(false);
            setShowScopeInfo(false);
        }
    }

    function getWeeks(d) {
        if (d==1) {
            return '1 week'
        }

        return d + ' weeks'
    }

    function getDiver() {
        if(projectSummary.diversity.length) {
            var diversity = ""
            projectSummary.diversity.forEach((data) => {
                if(diversity !== "") {diversity += ', '}
                diversity += data.label
            })
            return diversity
        } else {
            return ''
        }
    }

    function getSkil() {
        if(projectSummary.skills.length) {
            var skils = ""
            projectSummary.skills.forEach((data) => {
                if(skils !== "") {skils += ', '}
                var val = data.sublabel ? data.sublabel : data.label
                skils += val
            })
            return skils
        } else {
            return ''
        }
    }

    function getIndu() {
        if(projectSummary.industries.length) {
            var indus = ""
            projectSummary.industries.forEach((data) => {
                if(indus !== "") {indus += ', '}
                var val = data.sublabel ? data.sublabel : data.label
                indus += val
            })
            return indus
        } else {
            return ''
        }
    }

    function getInspiration() {
        if(projectSummary.inspiration.length) {
            var brands = ""
            projectSummary.inspiration.forEach((data) => {
                if(brands !== "") {brands += ', '}
                var val = data.sublabel ? data.sublabel : data.label
                brands += val
            })
            return brands
        } else {
            return ''
        }
    }

    function getMarkets() {
        if(projectSummary.markets.length) {
            var markets = ""
            projectSummary.markets.forEach((data) => {
                if(markets !== "") {markets += ', '}
                var val = data.sublabel ? data.sublabel : data.label
                markets += val
            })
            return markets
        } else {
            return 'Global'
        }
    }
      
    function getBrandVoice() {
        const selecttones = [...selectStore.tone]
        if(projectSummary.tone_of_voice.length) {
            var brandVoice = "";
            var uniqueVal = []
            projectSummary.tone_of_voice.forEach((data) => {
                const index = selecttones.findIndex(val => val.tone_of_voice_id === data.tone_of_voice_id)
                if(index !== -1 && !uniqueVal.includes(data.tone_of_voice_id)){
                    uniqueVal.push(data.tone_of_voice_id);
                    if(brandVoice !== "") {brandVoice += ', '}
                    var val = selecttones[index].sublabel ? selecttones[index].sublabel : selecttones[index].label
                    brandVoice += val
                }
            })
            return brandVoice
        } else {
            return ''
        }
    }
    

    function getAdditionalReq() {
        var addReq = ''
        addReq += projectSummary && projectSummary.addreq_need_account_lead ? ', Account Lead' : '';
        addReq += projectSummary && projectSummary.addreq_need_strategic_planner ? ', Strategic Planner' : '';
        addReq += projectSummary && projectSummary.addreq_need_famous ? ', Famous' : '';
        addReq += projectSummary && projectSummary.addreq_need_production ? ', Production' : '';
        addReq += projectSummary && projectSummary.addreq_need_media_planning ? ', Media Planning' : ''
        addReq += projectSummary && projectSummary.addreq_need_media_buying ? ', Media Buying' : '';
        addReq += projectSummary && projectSummary.addreq_need_ongoing_services ? ', Ongoing Services' : ''
        return addReq.replace(/^,|,$/g,'');
    }

    async function showTeams() {
        var _id = projectSummary && projectSummary.project_id ? projectSummary.project_id : id ? common.encryptdecrypt("decrypt",id) : null;
        if(_id) {
            if (await Session.doesSessionExist()) {
                if(projectSetupStore.teams_platform === 'agency') {
                    navigate('/project-teams/agency/'+ common.encryptdecrypt('encrypt', _id));
                } else {
                    navigate('/project-teams/talent/'+ common.encryptdecrypt('encrypt', _id));
                }
            }else{
                if(projectSetupStore.teams_platform === 'agency') {
                    sessionStorage.nav_show_team = '/project-teams/agency/'+ common.encryptdecrypt('encrypt', _id);
                } else {
                    sessionStorage.nav_show_team = '/project-teams/talent/'+ common.encryptdecrypt('encrypt', _id);
                }
                setLoginConfirmation(true)
            }

        } else {
            rendertoast.showToast({ "type": "e", "msg": "Something went wrong" })
        }
        
    }
      

    function SummaryText(){
        return(
            <div className="flex flex-col justify-between bg-[#F5F5F5] dark:bg-[#161616] gap-[64px] project-summary">
                <div className="flex flex-col gap-[88px] mobile:gap-[48px] tablet:gap-[48px]">
                    <div className="flex flex-row gap-[16px] mobile:h-[64px] tablet:py-[5px] tablet:px-[24px] tablet:bg-white tablet:dark:bg-[#0E0E0E]">
                        <img alt="mlogo" className="tablet:p-[10px] [@media(min-width:768px)]:hidden dark:hidden" src={minilogo} width="48px" /><img alt="mlogo" className="tablet:p-[10px] mobile:hidden dark:hidden" src={mlogo} width="56px" height="50px" /><img alt="logo" className="mobile:hidden dark:hidden" src={logo} width="182px" />
                        <img alt="mlogo" className="tablet:p-[10px] hidden mobile:dark:block" src={darkminilogo} width="48px" /><img alt="mlogo" className="tablet:p-[10px] mobile:dark:hidden dark:block hidden" src={darkminilogo} width="56px" height="50px" /><img alt="logo" className="mobile:hidden dark:block hidden" src={darklogo} width="182px" />
                    </div>
                    <div className="flex flex-col text-[#1D1D1D] dark:text-[#fff] tablet:px-[24px]">
                        <nav className="flex flex-col items-start gap-[6px] pb-[16px] " aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                <li className="inline-flex items-center">
                                    <div onClick={() => props.prevStep("startingpoint")} className="inline-flex cursor-pointer items-center text-[14px] font-medium dark:text-[#fff] text-[#0e0e0e]  hover:!text-[#F05831]  capitalize">StartingPoint</div>
                                </li>
                                <img alt='navigation' src={nav}/>
                                <li>
                                    <div onClick={() => props.prevStep("skills")} className="ml-1 inline-flex cursor-pointer items-center text-[14px] font-medium dark:text-[#fff] text-[#0e0e0e]  hover:!text-[#F05831] capitalize">Skills</div>
                                </li>
                                <img alt='navigation' src={nav}/>
                                <li>
                                <div className="ml-1 inline-flex cursor-pointer text-[14px] font-semibold text-[#0e0e0e] hover:!text-[#F05831] block dark:text-[#FFF] capitalize whitespace-nowrap">{selectedSkills && selectedSkills !== "" ?selectedSkills + ' - ' : ''}Pinpoints</div>
                                </li>
                                <img alt='navigation' src={nav}/>
                                <li>
                                    <div className="ml-1 inline-flex cursor-pointer text-[#0e0e0e]  hover:!text-[#F05831] text-[14px] font-semibold block dark:text-[#FFF] capitalize">Summary</div>
                                </li>
                            </ol>
                        </nav>
                        <hr className="border-solid border-[#d9d9d9] dark:border-[#A3A3A3]"/>
                        <p className="archivo text-[40px] font-extralight leading-[120%] pt-[24px] tracking-[-1.2px] mobile:text-[24px] mobile:leading-[120%] mobile:tracking-[-0.72px] dark:text-[#fff]"><span className="archivo w-full float-left font-extrabold text-[40px] mobile:text-[24px] mobile:w-auto mobile:float-none">Almost there. </span>Review your summary and estimated scope.</p>
                    </div>
                </div>
            </div>
        )
    }
    return(
        <div className="page-section summary-container">
            <ProjectSetupTemplate>
                <SummaryText></SummaryText>
                <div className="h-full flex flex-wrap gap-[64px] tablet:gap-[48px]">
                    <div className="w-full">
                        <div className="w-full grid grid-cols-[repeat(auto-fill,_minmax(350px,_100%))] gap-[12px] child:flex child:min-h-[347px] child:bg-white child:mobile:p-[24px] tablet:child:min-h-[auto] tablet:grid-cols-[repeat(auto-fill,_minmax(350px,_100%))] mobile:min-h-[auto] mobile:!grid-cols-none dark:child:bg-[#0E0E0E] dark:text-[#fff]">
                            <div className={`flex flex-wrap items-center px-[48px] bg-white cursor-pointer toggle `}>
                                <div className="w-full flex flex-row justify-between items-center py-[32px] mobile:py-[0] group" onClick={(e) => toggleElement(e, 'summary')}>
                                        <span className="archivo w-full flex items-center mobile:justify-between"><span className={`mobile:flex mobile:items-center text-[24px] leading-[100%] tracking[-0.72px] mobile:gap-[16px] mobile:text-[18px] mobile:leading-[110%] mobile:tracking-[-0.54px]`}><img alt="icon" src={orangeIcon} className="mb-[8px] group-hover:translate-x-[12px] mobile:mb-[0] mobile:float-left"/>Your project setup summary</span><button className="hidden px-[4px] py-[3.5px] shrink-0 mobile:block"></button></span>
                                        <span className="expand relative">

                                            <div className="dd-down w-[88px] mobile:w-[32px]">
                                                <svg className="dark:child:fill-[#fff]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 89" fill="none">
                                                    <path d="M44 56.9634L22 34.9634L27.1333 29.8301L44 46.6967L60.8667 29.8301L66 34.9634L44 56.9634Z" fill="#1D1D1D"/>
                                                </svg>
                                            </div>

                                            <div className="dd-up hidden w-[88px] mobile:w-[32px]">
                                                <svg className="dark:child:fill-[#fff]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 33" fill="none">
                                                    <path d="M16 12.356L24 20.356L22.1333 22.2227L16 16.0893L9.86667 22.2227L8 20.356L16 12.356Z" fill="#1D1D1D"/>
                                                </svg>
                                            </div>

                                        </span>
                                </div>
                                {showSummaryInfo && <div className="w-full flex flex-col gap-[48px] bg-white z-[1] py-[24px] child:border-b-[1px] child:border-[#D9D9D9] child:border-solid last:child:border-none mobile:pb-[0] dark:bg-[#0E0E0E]">
                                    <div className="w-full border-box">
                                        <div className="w-full flex flex-wrap items-center tablet2:gap-[8px] mobile:gap-[10px] mobile:flex-nowrap">
                                            <span className="w-[40%] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full">Project Start-point</span>
                                            <span className="w-[60%] text-[32px] font-bold leading-[91.5%] tracking-[-0.96px] tablet2:w-full mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px] capitalize">{projectSummary && projectSummary.starting_point ? projectSummary.starting_point.replace('_', ' ') : ''}</span>
                                        </div>
                                        <div className="float-right py-[16px] px-[16px]">
                                            <button><EditIcon/></button>
                                        </div>
                                    </div>
                                    <div className="w-full border-box">
                                        <div className="w-full flex flex-wrap items-center tablet2:gap-[8px] mobile:gap-[10px] mobile:flex-nowrap">
                                            <span className="w-[40%] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full">Skills & Expertise</span>
                                            <span className="w-[60%] text-[32px] font-bold leading-[91.5%] tracking-[-0.96px] tablet2:w-full mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px]">{projectSummary && projectSummary.skills ? getSkil() : ''}</span>
                                        </div>
                                        <div className="float-right py-[16px] px-[16px]">
                                            <button><EditIcon/></button>
                                        </div>
                                    </div>
                                    <div className="w-full border-box">
                                        <div className="w-full flex flex-wrap items-center tablet2:gap-[8px] mobile:gap-[10px] mobile:flex-nowrap">
                                            <span className="w-[40%] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full">Diversity Prioritization</span>
                                            <span className="w-[60%] text-[32px] font-bold leading-[91.5%] tracking-[-0.96px] tablet2:w-full mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px]">{projectSummary && projectSummary.diversity ? getDiver() : ''}</span>
                                        </div>
                                        <div className="float-right py-[16px] px-[16px]">
                                            <button><EditIcon/></button>
                                        </div>
                                    </div>
                                    <div className="w-full border-box">
                                        <div className="w-full flex flex-wrap items-center tablet2:gap-[8px] mobile:gap-[10px] mobile:flex-nowrap">
                                            <span className="w-[40%] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full">Industry</span>
                                            <span className="w-[60%] text-[32px] font-bold leading-[91.5%] tracking-[-0.96px] tablet2:w-full mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px]">{projectSummary && projectSummary.industries ? getIndu() : ''}</span>
                                        </div>
                                        <div className="float-right py-[16px] px-[16px]">
                                            <button><EditIcon/></button>
                                        </div>
                                    </div>
                                    <div className="w-full border-box">
                                        <div className="w-full flex flex-wrap items-center tablet2:gap-[8px] mobile:gap-[10px] mobile:flex-nowrap">
                                            <span className="w-[40%] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full">Brands that inspire</span>
                                            <span className="w-[60%] text-[32px] font-bold leading-[91.5%] tracking-[-0.96px] tablet2:w-full mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px]">{projectSummary && projectSummary.inspiration ? getInspiration() : ''}</span>
                                        </div>
                                        <div className="float-right py-[16px] px-[16px]">
                                            <button><EditIcon/></button>
                                        </div>
                                    </div>
                                    <div className="w-full border-box">
                                        <div className="w-full flex flex-wrap items-center tablet2:gap-[8px] mobile:gap-[10px] mobile:flex-nowrap">
                                            <span className="w-[40%] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full">Target Geography</span>
                                            <span className="w-[60%] text-[32px] font-bold leading-[91.5%] tracking-[-0.96px] tablet2:w-full mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px]">{projectSummary && projectSummary.markets ? getMarkets() : 'Global'}</span>
                                        </div>
                                        <div className="float-right py-[16px] px-[16px]">
                                            <button><EditIcon/></button>
                                        </div>
                                    </div>
                                    <div className="w-full border-box">
                                        <div className="w-full flex flex-wrap items-center tablet2:gap-[8px] mobile:gap-[10px] mobile:flex-nowrap">
                                            <span className="w-[40%] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full">Brand Voice</span>
                                            <span className="w-[60%] text-[32px] font-bold leading-[91.5%] tracking-[-0.96px] tablet2:w-full mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px]">{projectSummary && projectSummary.tone_of_voice ? getBrandVoice() : ''}</span>
                                        </div>
                                        <div className="float-right py-[16px] px-[16px]">
                                            <button><EditIcon/></button>
                                        </div>
                                    </div>
                                    <div className="w-full border-box">
                                        <div className="w-full flex flex-wrap items-center tablet2:gap-[8px] mobile:gap-[10px] mobile:flex-nowrap">
                                            <span className="w-[40%] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full">Budget Target</span>
                                            <span className="w-[60%] text-[32px] font-bold leading-[91.5%] tracking-[-0.96px] tablet2:w-full mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px]">{projectSummary && projectSummary.budget ? '$' + projectSummary.budget.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : ''}</span>
                                        </div>
                                        <div className="float-right py-[16px] px-[16px]">
                                            <button><EditIcon/></button>
                                        </div>
                                    </div>
                                    <div className="w-full border-box">
                                        <div className="w-full flex flex-wrap items-center tablet2:gap-[8px] mobile:gap-[10px] mobile:flex-nowrap">
                                            <span className="w-[40%] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full">Other requirements</span>
                                            <span className="w-[60%] text-[32px] font-bold leading-[91.5%] tracking-[-0.96px] tablet2:w-full mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px]">{projectSummary && getAdditionalReq()}</span>
                                        </div>
                                        <div className="float-right py-[16px] px-[16px]">
                                            <button><EditIcon/></button>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            <div className={`flex flex-wrap items-center px-[48px] bg-white cursor-pointer toggle group`}>
                                <div className="w-full flex flex-row justify-between items-center py-[32px] mobile:py-[0]" onClick={(e) => toggleElement(e, 'scope')} >
                                    <span className="archivo w-full flex items-center mobile:justify-between"><span className={`mobile:flex mobile:items-center text-[24px] leading-[100%] tracking[-0.72px] mobile:gap-[16px] mobile:text-[18px] mobile:leading-[110%] mobile:tracking-[-0.54px]`}><img alt="icon" src={orangeIcon} className="mb-[8px] group-hover:translate-x-[12px] mobile:mb-[0] mobile:float-left "/>Estimated project scope</span><button className="hidden px-[4px] py-[3.5px] shrink-0 mobile:block"></button></span>
                                    <span className="expand relative">

                                        <div className="dd-down w-[88px] mobile:w-[32px]">
                                            <svg className="dark:child:fill-[#fff]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 89" fill="none">
                                                <path d="M44 56.9634L22 34.9634L27.1333 29.8301L44 46.6967L60.8667 29.8301L66 34.9634L44 56.9634Z" fill="#1D1D1D"/>
                                            </svg>
                                        </div>

                                        <div className="dd-up hidden w-[88px] mobile:w-[32px]">
                                            <svg className="dark:child:fill-[#fff]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 33" fill="none">
                                                <path d="M16 12.356L24 20.356L22.1333 22.2227L16 16.0893L9.86667 22.2227L8 20.356L16 12.356Z" fill="#1D1D1D"/>
                                            </svg>
                                        </div>

                                    </span>
                                </div>
                                
                                {showScopeInfo && <div className="w-full flex flex-wrap pt-[24px] pb-[48px] bg-white gap-[40px] dark:bg-[#0E0E0E]">
                                    <div className="w-full bg-[#f5f5f5] dark:bg-[#262626]">
                                        <div className="flex flex-wrap p-[32px] gap-[48px] mobile:px-[16px] mobile:pt-[16px]">
                                            <div className="w-full flex justify-between items-center gap-[10px]">
                                                <span className="min-w-[130px] mobile:min-w-[100px] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full float-left"><span className="float-left">Team onboarding&nbsp;</span><span className="float-left">(Meet, SOW, Sign, Brief)</span></span>
                                                <span className="min-w-[100px] text-right text-[24px] font-bold leading-[91.5%] tracking-[-0.72px] tablet2:w-full mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px]">{estimatedScope && estimatedScope.team_onboarding_min ? getWeeks(estimatedScope.team_onboarding_min) : ''}{estimatedScope && estimatedScope.team_onboarding_max && estimatedScope.team_onboarding_max>estimatedScope.team_onboarding_min? ' - ' + getWeeks(estimatedScope.team_onboarding_max) : ''}</span>
                                            </div>
                                            <div className="w-full flex justify-between items-center gap-[10px]">
                                                <span className="min-w-[130px] mobile:min-w-[100px] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full">Creative work</span>
                                                <span className="min-w-[100px] text-right text-[24px] font-bold leading-[91.5%] tracking-[-0.72px] tablet2:w-full mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px]">{estimatedScope && estimatedScope.creative_work_min ? getWeeks(estimatedScope.creative_work_min) : ''}{estimatedScope && estimatedScope.creative_work_max && estimatedScope.creative_work_max>estimatedScope.creative_work_min? ' - ' + getWeeks(estimatedScope.creative_work_max) : ''}</span>
                                            </div>
                                            <div className="w-full flex justify-between items-center gap-[10px]">
                                                <span className="min-w-[130px] mobile:min-w-[100px] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full">Presentation and feedback</span>
                                                <span className="min-w-[100px] text-right text-[24px] font-bold leading-[91.5%] tracking-[-0.72px] tablet2:w-full mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px]">{estimatedScope  ? getWeeks(estimatedScope.presentation_min) : ''}{estimatedScope && estimatedScope.presentation_max && estimatedScope.presentation_max>estimatedScope.presentation_min? ' - ' + getWeeks(estimatedScope.presentation_max) : ''}</span>
                                            </div>
                                            <div className="w-full flex justify-between items-center gap-[10px]">
                                                <span className="min-w-[130px] mobile:min-w-[100px] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full">Revision</span>
                                                <span className="min-w-[100px] text-right text-[24px] font-bold leading-[91.5%] tracking-[-0.72px] tablet2:w-full mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px]">{estimatedScope && estimatedScope.revision_min ? getWeeks(estimatedScope.revision_min) : ''}{estimatedScope && estimatedScope.revision_max && estimatedScope.revision_max>estimatedScope.revision_min? ' - ' + getWeeks(estimatedScope.revision_max) : ''}</span>
                                            </div>
                                        </div>
                                        <hr className="border-[#A3A3A3] border-solid border-b mx-[32px] mobile:mx-[16px]"/>
                                        <div className="flex flex-wrap p-[32px] mobile:px-[16px] pb-[16px]">
                                            <div className="w-full flex justify-between items-center gap-[10px]">
                                                <span className="min-w-[130px] mobile:min-w-[100px] text-[16px] font-bold leading-[130%] tracking-[-0.48px] tablet2:w-full mobile:text-[14px] mobile:font-normal mobile:leading-[130%] mobile:tracking-[-0.42px]">Project duration</span>
                                                <span className="min-w-[100px] text-right text-[40px] font-bold leading-[91.5%] tracking-[-1.2px] tablet2:w-full tablet2:text-[16px] tablet2:leading-none tablet2:tracking-[-0.48px] mobile:text-[16px] mobile:leading-none mobile:tracking-[-0.48px]">{estimatedScope && estimatedScope.total_project_duration_min ? getWeeks(estimatedScope.total_project_duration_min) : ''}{estimatedScope && estimatedScope.total_project_duration_max && estimatedScope.total_project_duration_max>estimatedScope.total_project_duration_min? ' - ' + getWeeks(estimatedScope.total_project_duration_max) : ''} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full flex justify-between items-center gap-[10px]">
                                        <span className="min-w-[130px] mobile:min-w-[100px] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full">Estimated team size</span>
                                        <span className="min-w-[100px] text-right text-[48px] font-bold leading-[91.5%] tracking-[-1.44px] tablet2:w-full tablet2:text-[16px] tablet2:leading-[130%] tablet2:tracking-[-0.42px] mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px]">{estimatedScope && estimatedScope.team_size_min ? estimatedScope.team_size_min : ''}{estimatedScope && estimatedScope.team_size_max && estimatedScope.team_size_max>estimatedScope.team_size_min? ' - ' + estimatedScope.team_size_max : ''}</span>
                                    </div>
                                    {/*<div className="w-full flex justify-between items-center gap-[10px]">*/}
                                    {/*    <span className="min-w-[130px] mobile:min-w-[100px] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full">Number of days paid - estimated</span>*/}
                                    {/*    <span className="min-w-[100px] text-right text-[48px] font-bold leading-[91.5%] tracking-[-1.44px] tablet2:w-full tablet2:text-[16px] tablet2:leading-[130%] tablet2:tracking-[-0.42px] mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px]">{estimatedScope && estimatedScope.work_days_min ? estimatedScope.work_days_min : ''}{estimatedScope && estimatedScope.work_days_max && estimatedScope.work_days_max>estimatedScope.work_days_min? ' - ' + estimatedScope.work_days_max : ''}</span>*/}
                                    {/*</div>*/}
                                    <div className="w-full flex justify-between items-center gap-[10px]">
                                        <span className="min-w-[130px] mobile:min-w-[100px] text-[14px] leading-[130%] tracking-[-0.42px] tablet2:w-full">Estimated budget range</span>
                                        <span className="min-w-[100px] text-right text-[48px] font-bold leading-[91.5%] tracking-[-1.44px] tablet2:w-full tablet2:text-[16px] tablet2:leading-[130%] tablet2:tracking-[-0.42px] mobile:text-[16px] mobile:leading-[130%] mobile:tracking-[-0.42px]">{estimatedScope && estimatedScope.budget_min ? '$' + estimatedScope.budget_min.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : ''}{estimatedScope && estimatedScope.budget_max && estimatedScope.budget_max > estimatedScope.budget_min ? ' - ' + '$' +estimatedScope.budget_max.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : ''}</span>
                                    </div>
                                </div>}
                            </div>
                        </div>

                        <div className="w-full flex gap-[12px]">
                            <button onClick={props.prevStep} type="button" className="w-full flex group items-center bg-[#fff] p-[24px] group mt-[12px] dark:bg-[#0E0E0E] ">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="back-arrow group-hover:-translate-x-[12px]">
                                    <rect width="24" height="24" transform="matrix(-1 0 0 1 24 0)" fill="transparent"/>
                                    <path d="M15.4898 7.18733L9.44658 11.9992C9.44658 11.9992 7.11559 10.1432 9.57661 8.18361C10.3379 7.57744 13.4377 5.10925 15.552 3.42578L15.4898 7.18733Z" fill="#0E0E0E"/>
                                    <path d="M15.4896 16.8118L9.44634 12C9.44634 12 7.11536 13.856 9.57637 15.8156C10.3377 16.4217 13.4375 18.8899 15.5518 20.5734L15.4896 16.8118Z" fill="#0E0E0E"/>
                                </svg>
                                <span className="text-[24px] leading-[100%] tracking-[-0.72px] uppercase dark:text-[#fff] ">Back</span>
                            </button>
                            <button type="button" className="w-full flex items-center justify-end bg-[#F05831] hover:bg-[#f17e61] disabled:bg-[#dc5836] group disabled:opacity-[35%] p-[24px] mt-[12px]" onClick={showTeams}>
                                <span className="text-[24px] leading-[100%] tracking-[-0.72px] uppercase text-white">Show teams</span> 
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="transition-all group-hover:translate-x-[12px]" xmlns="http://www.w3.org/2000/svg">
                                <g id="Frame 6938">
                                <rect width="24" height="24" fill="transparent"/>
                                <g id="Group 8090">
                                <path id="Vector" d="M8.51043 7.18733L14.5537 11.9992C14.5537 11.9992 16.8846 10.1432 14.4236 8.18361C13.6623 7.57744 10.5625 5.10925 8.44824 3.42578L8.51043 7.18733Z" fill="#FFF"/>
                                <path id="Vector_2" d="M8.51042 16.8118L14.5537 12C14.5537 12 16.8846 13.856 14.4236 15.8156C13.6623 16.4217 10.5625 18.8899 8.44824 20.5734L8.51042 16.8118Z" fill="#FFF"/>
                                </g>
                                </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <ProjectSetupFooter activepage="Summary" activepersentage="62%" className="mb-[64px]"/>
                </div>
            </ProjectSetupTemplate>
        </div>
    )
}