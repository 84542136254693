import React, { useEffect } from 'react';
import nextArrow from '../../../assets/img/Arrowwhite.png';
import darknextArrow from '../../../assets/img/ArrowDark.png';
import { Cancel } from '../common/icons/cancel';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

export const FinalQuestionpopup = (props) => {
    const projectSetupStore = useSelector(state => state.projectSetupStore);
    const dispatch = useDispatch();
    const defaultobj =  {
        addreq_need_account_lead: false,
        addreq_need_strategic_planner: false,
        addreq_need_famous: false,
        addreq_need_production: false,
        addreq_need_media_planning: false,
        addreq_need_media_buying: false,
        addreq_need_ongoing_services: false
    }
    const { register, getValues, watch, setValue } = useForm({
        mode: "all",
        reValidateMode: "onChange", 
        criteriaMode: "all",
        defaultValues:defaultobj
    });

    const values = [
        {label: 'I need someone to help manage and run the project with all its aspects (Client/Account lead)', name:'addreq_need_account_lead'},
        {label: 'I need someone to help me write the strategy/creative brief for the creative team (Strategic/Creative Planner)', name:'addreq_need_strategic_planner'},
        {label: 'I would love a team with famous and/or award-winning work to show for', name:'addreq_need_famous'},
        {label: 'I might need Production (If and when the creative is approved)', name:'addreq_need_production'},
        {label: 'I might need Media Planning (If and when the creative is approved)', name:'addreq_need_media_planning'},
        {label: 'I might need Media Buying (If and when the creative is approved)', name:'addreq_need_media_buying'},
        {label: 'I might need Ongoing creative/studio/media/production services for the life of this campaign', name:'addreq_need_ongoing_services'}
    ]

    useEffect(() => {
        Object.entries(projectSetupStore.page4).forEach(val => {
            if (val[1] && (val[1] !== "" || (Array.isArray(val[1]) && val[1].length))) {
                setValue(val[0], val[1]);
            }
        })
    }, [])

    function submitHandler() {
        var val = getValues();
        dispatch({ type: "project_setup/insertValue", payload: {"id":val,"name":"page4"} });
        props.saveData(val);
        document.body.classList.remove('overflow-hidden');
        props.nextStep();
    }

    function onInputChange(e, fieldname) {
        setValue(fieldname, e.target.checked)
    }

    function onLabelClick(e, fieldname) {
        if(e) {
            e.preventDefault()
            e.stopPropagation();
        }
        var curr = getValues(fieldname);
        setValue(fieldname, !curr);
        var ele = e.target.children;
        if(ele && ele.length) {
            if(curr)
                ele[0].classList.add('checked')
            else ele[0].classList.remove('checked')
        }
    }

    function skipHandler(){
        props.saveData(defaultobj);
        props.nextStep()
    }


    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 overlay-background bg-opacity-75 backdrop-blur-sm transition-opacity"></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full justify-center px-[32px] sm:items-center sm:p-0 final-questions mobile:m-auto mobile:items-center mobile:my-0"  >
                    <div className="relative w-[481.34px] m-auto transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[481.34px] dark:bg-[#0E0E0E] mobile:h-[90%] mobile:m-auto">
                        <div className="sm:items-start w-full flex">
                            <div className="sm:text-left mobile:p-[0px] p-[32px] w-full">
                                <div className='flex justify-between box-border border-b border-solid border-[#d9d9d9] pb-[16px] mobile:p-[16px]'>
                                    <div className='flex-col gap-1'>
                                        <p className='text-[#1D1D1D] text-[20px] leading-[26px] tracking-[-0.6px] font-bold dark:text-[#fff]'>Final questions (optional)</p>
                                        <p className='mt-[4px] text-[#a3a3a3] text-[14px] leading-[130%] tracking-[-0.42px] dark:text-[#808080]'>Additional requirements to take into consideration:</p>
                                    </div>
                                    <div className="absolute right-0 xs:mt-[-10px] pr-7 sm:block mt-[4px]">
                                        <button type="button" onClick={props.close} className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none pr-[7px]"><Cancel/></button>
                                    </div>
                                </div>
                                <div className='flex flex-col mt-[24px]'>
                                    <div className='input-fld final-quest-popup mobile:max-h-[340px] overflow-y-auto overflow-x-hidden'>
                                        {values.map((data, i) => (
                                            <div className='final-popup mobile:px-[24px] input-fld mb-0 group child-hover:child:text-[#1D1D1D] dark:child-hover:child:text-[#FFF]' key={i} >
                                                <div className='flex items-center justify-between gap-[8px] mobile:py-[6px] py-[16px] border-y-[10px] border border-white dark:border-black pr-[10px] transition-all hover:bg-[#f5f5f5] dark:hover:bg-[#4C4C4C] hover:text-[#4C4C4C] dark:hover:text-[#FFF] hover:font-bold rounded-[8px]  hover:p-[10px] md:h-[73px] dark:text-[#A3A3A3] text-[#A3A3A3] text-[14px]' onClick={e => onLabelClick(e, data.name)}>{data.label}
                                                <label className={`${watch(data.name) ? 'checked' : '' } control control--checkbox text-[#a3a3a3] group-hover:text-[#1d1d1d] mr-[10px] peer-checked:text-[#1d1d1d] text-[14px]`} >
                                                    <input type="checkbox" id={0} className='h-[24px] peer w-[24px] shrink-0' {...register(data.name)} onChange={e => onInputChange(e, data.name)}/>
                                                    <div className="control__indicator"></div>
                                                </label>
                                            </div>
                                            <hr className={`${i >= values.length-1 ? 'hidden': ''} box-border border-solid border-[#ececec] dark:border-[#A3A3A3]`}/>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='flex justify-between items-center mobile:mt-0 mt-[32px] mobile:flex-col-reverse mobile:gap-[16px] mobile:p-[24px]'>
                                    <button className='w-[67px] h-[36px] border-[1px] border-[#1D1D1D] text-[#1D1D1D] font-bold rounded-full px-[16px] py-[8px] text-[14px] leading-[130%] tracking-[-0.42px] mobile:w-full mobile:h-[40px] dark:bg-[#0E0E0E] dark:text-[#fff] border-solid dark:border-[#fff]' onClick={props.close}>Back</button>
                                    <div className='mobile:w-full flex gap-[10px]'>
                                        <button onClick={skipHandler} className='w-[67px] hidden h-[36px] border-[1px] border-[#1D1D1D] text-[#1D1D1D] font-bold rounded-full px-[16px] py-[8px] text-[14px] leading-[130%] tracking-[-0.42px] mobile:w-full mobile:h-[40px] dark:bg-[#0E0E0E] dark:text-[#fff] border-solid dark:border-[#fff]'>Skip</button>
                                        <button onClick={() => submitHandler()} className='bg-[#1D1D1D] text-white min-w-[226px] h-[34px] px-[16px] py-[8px] font-bold rounded-full flex flex-row justify-center items-center gap-[10px] text-[14px] leading-[130%] tracking-[-0.42px] mb-[1px] mobile:w-full mobile:h-[40px] dark:bg-[#fff] dark:text-[#0E0E0E]'>Save and go to summary<img alt='next' src={nextArrow} className='dark:hidden' /><img alt='next' src={darknextArrow} className='hidden dark:block' /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
