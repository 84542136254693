import React, { useEffect, useState, useRef } from 'react'
import Select from "react-select";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useForm, Controller } from "react-hook-form";
import { GetCompanies } from '../../service/commonapi';
import { useSelector } from 'react-redux';
import FormFieldError from '../../../utils/error-message';
import FormValidationIcon from '../../../utils/form-validation-icon';
import rendertoast from '../../../utils/rendertoast';
import { Cancel } from '../common/icons/cancel';
import { Dropdown } from '../common/icons/dropdown';
import common from '../../../utils/common';

export const ExperiencePopup = (props) => {
    const [role, setRole] = useState();
    const [company, setCompany] = useState();
    const [startedMonth, setStartedMonth] = useState();
    const [startedYear, setStartedyear] = useState();
    const [endMonth, setEndMonth] = useState();
    const [endYear, setEndyear] = useState();
    const [yearOptions, setYearOptions] = useState([]);
    const [endYearOptions, setEndYearOptions] = useState([]);
    const [monthOptions, setMonthOptions] = useState([]);
    const [endMonthOptions, setEndMonthOptions] = useState([]);
    const [roleLabel, setRoleLabel] = useState(false);
    const [companyLabel, setCompanyLabel] = useState(false);
    const [startedAtMonthLabel, setStartedAtMonthLabel] = useState(false);
    const [startedAtYearLabel, setStartedAtYearLabel] = useState(false);
    const [endMonthLabel, setEndMonthLabel] = useState(false);
    const [endYearLabel, setEndYearLabel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const selectStore = useSelector(state => state.selectStore);
    const [options, setOptions] = useState([]);
    const dailogRef = useRef();
    const [checked, setChecked] = useState(false);
    const { register, unregister, trigger, clearErrors, formState: { errors, touchedFields, dirtyFields }, setValue, getValues, control } = useForm({
        mode: "all",
        reValidateMode: "onChange", criteriaMode: "all", shouldUnregister: true
    });
    
    useEffect(() => {
        getCompany();
        if (!props.value) {
            reset();
        }
        getmonthYearList();
        if (props.value && props.value.data) {
            setChecked(props.value.data.is_current)
            if(props.value.data.is_current) {
                unregister(["end_year", "end_month"])
            } else {
                register('end_year', { required: 'End year is required' })
                register('end_month', { required: 'End month is required' });
            }
            var data = props.value.data;
            Object.entries(data).forEach(val => {
                if (val[1] && (val[1] !== "" || (Array.isArray(val[1]) && val[1].length))) {
                    setValue(val[0], val[1], { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                }
            })
            var storeData = data;
            Object.entries(storeData).forEach((awd) => {
                if (awd[0] === 'is_current' ) {
                    setValue('is_current', awd[1] ? true:false, { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                    clearEndDate(awd[1])
                    if(awd[1]) {unregisterEndDate()} else {registerEndDate()}
                }
                if ((awd[0] === 'started_at' || awd[0] === 'start_at') && awd[1] !== "") {
                    if (!isNaN(new Date(awd[1]).getTime())) {
                        awd[1] = new Date(awd[1])
                        var date = awd[1];
                        let month = awd[1].toLocaleString('en-us', { month: 'short' });
                        let year = '' + date.getFullYear();
                        if (year) {
                            setStartedyear({ value: year, label: year })
                            setValue('started_year', year, { shouldTouch: true, shouldValidate: true, shouldDirty: true })
                            var first = new Date(`01 Jan ${year}`).getFullYear();
                            var second = new Date().getFullYear();
                            var end = [];
                            for (let i = first; i <= second; i++) end.push({ value: i.toString(), label: i.toString() })
                            setEndYearOptions(end);
                            if (first === second) {
                                // getEndMonth();
                            }
                        }
                        if (month) {
                            setStartedMonth({ value: month.toLowerCase(), label: awd[1].toLocaleString('en-us', { month: 'long' }) })
                            setValue('started_month', month, { shouldTouch: true, shouldValidate: true, shouldDirty: true })
                        }
                    }
                }
                if ((awd[0] === 'ended_at' || awd[0] === 'end_at') && awd[1] !== "" && !getValues("is_current")) {
                    if (!isNaN(new Date(awd[1]).getTime())) {
                        awd[1] = new Date(awd[1])
                        var date1 = awd[1];
                        let month = awd[1].toLocaleString('en-us', { month: 'short' });
                        let year = '' + date1.getFullYear();
                        if (year) {
                            setEndyear({ value: year, label: year })
                            setValue('end_year', year, { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                        }
                        if (month) {
                            setEndMonth({ value: month.toLowerCase(), label: awd[1].toLocaleString('en-us', { month: 'long' }) })
                            setValue('end_month', month, { shouldTouch: true, shouldValidate: true, shouldDirty: true })
                        }
                    }
                }
                if (awd[0] === 'roles_id' && awd[1] !== "") {
                    let index = selectStore.role.findIndex(x => x.role_id === awd[1]);
                    let role = selectStore.role[index];
                    setRole(role);
                    setValue('roles_id', role.role_id, { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                    setRoleLabel(true);
                }
              
                if (awd[0] === 'company_id' && awd[1] !== "") {
                    setCompany(storeData['company']);
                }
            })
        }
    }, [])

    /**UNREGISTER END DATE FIELDS WHEN IS CURRENT IS CHECKED */
    function unregisterEndDate() {
        clearErrors("end_year");
        clearErrors("end_month");
        unregister("end_year");
        unregister("end_month")
    }

    /**RE-REGISTER END DATE FIELDS WHEN IS CURRENT IS UNCHECKED */
    function registerEndDate() {
        register('end_year', { required: 'End year is required' })
        register('end_month', { required: 'End month is required' });
    }

    /* RESET FIELD */
    function reset() {
        try {
            setStartedMonth();
            setStartedyear();
            setEndyear();
            setEndMonth();
            setRole();
            setCompany();
            setValue("roles_id", "");
            setValue("company_id", "");
            setValue("started_year", "");
            setValue("started_month", "");
            setValue("end_year", "");
            setValue("end_month", "");
            setValue("is_current", false);
        } catch (err) {
            console.log(err);
        }
    }

    /* MONTH & YEAR LIST */
    function getmonthYearList() {
        var getCurrentYear = new Date().getFullYear();
        var listOfYears = Array.from({ length: 50 }, (_, i) => getCurrentYear - i);
        listOfYears = listOfYears.map((val) => ({ value: val.toString(), label: val.toString() }));
        setYearOptions(listOfYears);
        const month = [{ label: 'January', value: 'jan' }, { label: 'February', value: 'feb' }, { label: 'March', value: 'mar' }, { label: 'April', value: 'apr' }, { label: 'May', value: 'may' }, { label: 'June', value: 'june' }, { label: 'July', value: 'july' }, { label: 'August', value: 'aug' }, { label: 'September', value: 'sept' }, { label: 'October', value: 'oct' }, { label: 'November', value: 'nov' }, { label: 'December', value: 'dec' }]
        setMonthOptions(month);
        setEndMonthOptions(month);
    }
    /* GET COMPANIES */
    function getCompany() {
        try {
            if (options && options.length === 0) {
                setIsLoading(true)
                GetCompanies("").then((res) => {
                    if (res) {
                        setIsLoading(false)
                        const options = res.map(i => ({
                            label: i.label,
                            value: i.company_id,
                            company_id: i.company_id,
                        }));
                        setOptions(options);
                        return options;
                    } else {
                        setIsLoading(false)
                    }
                })
            }
        } catch (err) { console.log(err); }
    }

    /** ROLE ONCHANGE */
    function handleRoleOnChange(val) {
        try {
            if (val && Object.values(val).length) {
                setRoleLabel(true)
                setValue("roles_id", val !== "" ? val.role_id : "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setRole(val);
            } else {
                setRole();
                setValue("roles_id", "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setRoleLabel(false)
            }
        } catch (err) {
            console.log(err);
        }
    }

    /*COMPANY ONCHANGE SELECT */
    function handleCompanyOnChange(val) {
        try {
            if (val && Object.values(val).length) {
                setCompanyLabel(true)
                setValue("company_id", val !== "" ? val.company_id : "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setCompany(val);
            } else {
                setCompany();
                setValue("company_id", "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setCompanyLabel(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    /* START MONTH ONCHANGE */
    function handleStartedAtMonthOnChange(val) {
        try {
            if (val && Object.values(val).length) {
                setStartedAtMonthLabel(true)
                setValue("started_month", val !== "" ? val.value : "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setStartedMonth(val);
                if (startedYear && startedYear.value && startedYear.value !== "" && parseInt(startedYear.value) === new Date().getFullYear()) {
                    getEndMonth();
                }
            } else {
                setStartedAtMonthLabel(false)
                setValue("started_month", "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setStartedMonth();
            }
        } catch (err) { console.log(err); }
    }

    /* END MONTH ONCHANGE */
    function handleEndMonthOnChange(val) {
        try {
            if (val && Object.values(val).length) {
                setEndMonthLabel(true);
                setValue("end_month", val !== "" ? val.value : "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setEndMonth(val);
            } else {
                setEndMonthLabel(true);
                setValue("end_month", "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setEndMonth();
            }
        } catch (err) { console.log(err) }
    }

    /*GET END MONTH */
    function getEndMonth() {
        try {
            setEndMonth();
            setValue('end_month', "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
            let month = [...monthOptions];
            const index = month.findIndex(val => val.value === getValues('started_month'));
            let endMonth = month.filter((value, i) => { if (i >= index) { return value } else { return [] } });
            setEndMonthOptions(endMonth);
        } catch (err) { console.log(err) }
    }

    /* START YEAR ONCHANGE */
    function handleStartedAtYearOnChange(val) {
        try {
            if (val && Object.values(val).length) {
                if(getValues('is_current')) {
                    unregisterEndDate();
                }
                setStartedAtYearLabel(true);
                setValue("started_year", val !== "" ? val.value : "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setValue("end_year", "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setStartedyear(val);
                setEndyear();
                var first = new Date(`01 Jan ${val.value}`).getFullYear();
                var second = new Date().getFullYear();
                var end = [];
                for (let i = first; i <= second; i++) end.push({ value: i.toString(), label: i.toString() })
                setEndYearOptions(end);
                if (first === second) {
                    getEndMonth();
                }
                if (endYear && endYear.value && parseInt(val.value) <= parseInt(endYear.value)) {
                    setValue('end_year', "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                    setEndyear();
                }
                
            } else {
                setStartedAtYearLabel(false)
                setValue("started_year", "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setStartedyear();
            }
        } catch (err) { console.log(err); }
    }

    /* END YEAR ONCHANGE*/
    function handleEndYearOnChange(val) {
        try {
            if (val && Object.values(val).length) {
                setEndYearLabel(true);
                setValue("end_year", val !== "" ? val.value : "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setEndyear(val);
            } else {
                setEndYearLabel(false);
                setValue("end_year", "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
                setEndyear();
            }
        } catch (err) { console.log(err); }
    }

    /** REACT SELECT ROLE ONBLUR */
    function handleRoleOnBlur(val) {
        setRoleLabel(role ? true : false)
    }

    /* SELECT COMPANY ONBLUR */
    function handleCompanyOnBlur(val) {
        setCompanyLabel(company ? true : false)
    }

    /* SELECT START MONTH ONBLUR */
    function handleStartedAtMonthOnBlur(val) {
        setStartedAtMonthLabel(startedMonth ? true : false)
    }

    /* SELECT SELECT YEAR ONBLUR */
    function handleStartedAtYearOnBlur(val) {
        setStartedAtYearLabel(startedYear ? true : false)
    }

    /* SELECT END YEAR ONBLUR */
    function handleEndYearOnBlur(val) {
        setEndYearLabel(endYear ? true : false)
    }

    /* SELECT END MONTH ONBLUR */
    function handleEndMonthOnBlur(val) {
        setEndMonthLabel(endMonth ? true : false)
    }

    /**CHECK FORM VALIDITY -ISVALID WILL NOT WORK BECAUSE WE USE CUSTOM REGISTER FOR END DATE */
    function checkFormValidity() {
        var val = getValues();
        if(val.is_current) {
            return getValues('company_id') && getValues('roles_id') && getValues('roles_id') !== "" && getValues('started_month') && getValues('started_month') !== "" && getValues('started_year') && getValues('started_year') !== "" ? true : false
        } else {
            return getValues('company_id') && getValues('roles_id') && getValues('roles_id') !== "" && getValues('started_month') && getValues('started_month') !== "" && getValues('started_year') && getValues('started_year') !== "" && getValues('end_year') && getValues('end_year') !== "" && getValues('end_month') && getValues('end_month') !== "" ? true : false 
        }
    }

    /**SAVE EXPERIENCE */
    function saveExperience() {
        try {
            if(getValues('is_current') === true) {
                unregisterEndDate();
            }
            trigger();
            if (checkFormValidity()) {
                let experience = getValues();
                if (experience['start_at']) delete experience['start_at'];
                if (experience['end_at']) delete experience['end_at'];
                if (experience['company_id']) {
                    experience['company'] = company;
                }
                if (experience['is_current']) 
                {
                    experience['is_current'] = true;
                }
                else{
                    experience['is_current'] = false;
                }
                experience['ended_at'] = experience['end_year'] ? common.dateConverter(new Date(`${experience['end_year']}/${experience['end_month']}/01`)) : "";
                experience['ended_at'] = experience['ended_at'] !== "" ? common.dateConverter(experience['ended_at'].toString()) : "";
                experience['started_at'] = experience['started_year'] ? new Date(`${experience['started_year']}/${experience['started_month']}/01`) : ""; delete experience['end_month'];
                // experience['started_at'] =  experience['started_at'] ? experience['started_at'].toString(): "";
              
                experience["started_at"] =experience['started_at'] ? common.dateConverter(experience['started_at']) : "";
                // experience['started_at'] =  experience['started_at'] ? experience['started_at'].toUTCString(): "";
                delete experience['end_year'];
                delete experience['started_year'];
                delete experience['started_month'];
                const isEmpty = Object.values(experience).every(x => {
                    if (x === null || x === '' || x === undefined) {
                        return true
                    } else {
                        return false
                    }
                });
                if (experience['ended_at'] !== "" && (experience['started_at'] > experience['ended_at'])) {
                    rendertoast.showToast({ "type": "s", "msg": "End date cannot be greater than start date" });
                    return;
                }
                if (!isEmpty) {
                    props.save(experience, props.value && (props.value.index !== null && props.value.index !== "" && props.value.index !== undefined) ? props.value.index : null);
                }
                reset();
                props.close()
            }
        } catch (err) { console.log(err); }
    }

    /* HANDLE INPUT CHANGE EVENT */
    const handleInputChange = value => {
        if (value.length >= 3) {
            setIsLoading(true)
            GetCompanies(value).then((res) => {
                if (res) {
                    setIsLoading(false)
                    const options = res.map(i => ({
                        label: i.label,
                        value: i.company_id,
                        company_id: i.company_id,
                    }));
                    setOptions(options);
                    return options;
                } else {
                    setIsLoading(false)
                }
            })
        }
    };

    function currentStartYear(e) {
        setChecked(e.target.checked)
        if(e.target.checked) {
            clearEndDate(e.target.checked)
            unregisterEndDate();
        } else {
            registerEndDate();
        }
    }

    function clearEndDate(val) {
        if(val) {
            unregisterEndDate();
            setValue("end_year", "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
            setValue("end_month", "", { shouldTouch: true, shouldValidate: true, shouldDirty: true });
            setEndMonth();
            setEndyear();
        }
    }


    return (
        <div className="relative z-10 experience-popup" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 overlay-background bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full  justify-center p-4 sm:items-center sm:p-0" ref={dailogRef}>
                    <div className="relative w-[480px] m-auto transform overflow-hidden rounded-md bg-white p-[10px] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[500px] dark:bg-[#1D1D1D] dark:shadow-[0px_4px_21px_rgba(138, 138, 138, 0.2)]">
                        <div className="sm:items-start w-full flex">
                            <div className="m-[30px] sm:text-left w-full">
                                <div className="absolute right-[21px] xs:mt-[-10px] pr-4 sm:block mt-[4px]">
                                    <button type="button" onClick={props.close} className="rounded-md !bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none">
                                        <Cancel />
                                    </button>
                                </div>
                                <h2 className='text-[20px] font-bold'>Add experience</h2>
                                <hr className='mt-[10px]' />
                                <div className="field-group w-[100%] mt-[40px]">
                                    <label className={(roleLabel || getValues('roles_id ')) ? "input-lbl select-label" : "hidden"}>Role</label>
                                    <Controller
                                        name="roles_id"
                                        control={control}
                                        rules={{ required: 'Role is required' }}
                                        render={({ field }) => (
                                            <Select
                                                isClearable
                                                menuPortalTarget={dailogRef.current}
                                                menuPosition={'fixed'}
                                                value={role}
                                                getOptionLabel={e => e.sublabel ? e.label + ' - ' + e.sublabel : e.label}
                                                onChange={(e) => { handleRoleOnChange(e) }}
                                                onBlurName="onChange"
                                                onFocus={() => (setRoleLabel(true))}
                                                onBlur={(e) => {
                                                    handleRoleOnBlur(e)
                                                }}
                                                ref={field.ref}
                                                classNamePrefix={"react-select"}
                                                placeholder={<label className={(!roleLabel && !getValues("roles_id")) ? "input-lbl" : 'hidden'}>Role</label>}
                                                options={selectStore.role}
                                                formatOptionLabel={loc => (
                                                    <div className='single-select-default flex'><span className="txt"> { loc.sublabel ? loc.label + ' - ' + loc.sublabel : loc.label}</span></div>
                                                    // <div className="location-option flex" >
                                                    //     <div className="flag-div">
                                                    //         <img className="flagSVG" src={`https://static.moonshoot.inc/app/flags/4x3/${loc['location_id']}.svg`} alt="flag" />
                                                    //     </div>
                                                    //     <span className='loc-label'>{loc.label}</span>
                                                    // </div>
                                                )}
                                            />
                                        )}
                                    />
                                    <span className="absolute right-[4px] bottom-[8px]"><Dropdown /></span>
                                    <FormValidationIcon getval={getValues("roles_id")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"roles_id"}></FormValidationIcon>
                                </div>
                                <FormFieldError errors={errors} fieldname={"roles_id"}></FormFieldError>
                                <div className="field-group w-[100%] mt-[40px]">
                                    <label className={(companyLabel || getValues('company_id')) ? "input-lbl select-label" : "hidden"}>Company</label>
                                    <Controller
                                        name="company_id"
                                        control={control}
                                        rules={{ required: 'Company is required' }}
                                        render={() => (
                                            <Select
                                                isClearable
                                                menuPortalTarget={dailogRef.current}
                                                menuPosition={'fixed'}
                                                cacheOptions
                                                defaultOptions
                                                value={company}
                                                isLoading={isLoading}
                                                onChange={(e) => { handleCompanyOnChange(e) }}
                                                onBlurName="onChange"
                                                onFocus={() => {setCompanyLabel(true); getCompany()}}
                                                onBlur={(e) => {
                                                    handleCompanyOnBlur(e)
                                                }}
                                                onInputChange={handleInputChange}
                                                classNamePrefix={"react-select"}
                                                placeholder={<label className={(!companyLabel && !getValues("company_id")) ? "input-lbl" : 'hidden'}>Company</label>}
                                                options={options}
                                                formatOptionLabel={loc => (
                                                    <div className='single-select-default flex'><span className="txt"> { loc.label}</span></div>
                                                )}
                                                
                                            />
                                        )} />
                                        <span className="absolute right-[4px] bottom-[8px]"><Dropdown /></span>
                                        <FormValidationIcon getval={getValues("company_id")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"company_id"}></FormValidationIcon>
                                </div>
                                <FormFieldError errors={errors} fieldname={"company_id"}></FormFieldError>
                                <div className='input-fld relative mt-[40px]'>
                                    <input type="checkbox" className=" absolute w-[18px] h-[18px] text-blue-600 bg-gray-100 border-gray-300 rounded  dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 mt-1"  {...register("is_current")} checked={checked} onChange={(e) => { currentStartYear(e) }} />
                                    <label htmlFor="checkbox" className="ml-[24px] text-[16px]">I am currently working in this role</label>
                                </div>
                                <div className='relative w-[100%] mt-[20px]'>
                                    <p className='group-label'>Start Date</p>
                                    <div className='flex w-full justify-between'>
                                        <div className="field-group w-[48%]">
                                            <Controller
                                                name="started_month"
                                                control={control}
                                                rules={{ required: 'Started month is required' }}
                                                render={({ field }) => (
                                                    <Select
                                                        isClearable
                                                        menuPortalTarget={dailogRef.current}
                                                        menuPosition={'fixed'}
                                                        value={startedMonth}
                                                        onChange={(e) => { handleStartedAtMonthOnChange(e) }}
                                                        onBlurName="onChange"
                                                        onFocus={() => (setStartedAtMonthLabel(true))}
                                                        onBlur={(e) => {
                                                            handleStartedAtMonthOnBlur(e)
                                                        }}
                                                        ref={field.ref}
                                                        classNamePrefix={"react-select"}
                                                        placeholder={<label className={(!startedAtMonthLabel && !getValues("started_month")) ? "input-lbl" : 'hidden'}>Month</label>}
                                                        options={monthOptions}
                                                        formatOptionLabel={loc => (
                                                            <div className='single-select-default flex'><span className="txt"> { loc.label}</span></div>
                                                        )}
                                                    />
                                                )}
                                            />
                                            <FormValidationIcon getval={getValues("started_month")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"started_month"}></FormValidationIcon>
                                        </div>
                                        <div className="field-group w-[48%]">
                                            <Controller
                                                name="started_year"
                                                control={control}
                                                rules={{ required: 'Started year is required' }}
                                                render={({ field }) => (
                                                    <Select
                                                        isClearable
                                                        menuPortalTarget={dailogRef.current}
                                                        menuPosition={'fixed'}
                                                        value={startedYear}
                                                        onChange={(e) => { handleStartedAtYearOnChange(e) }}
                                                        onBlurName="onChange"
                                                        onFocus={() => (setStartedAtYearLabel(true))}
                                                        onBlur={(e) => {
                                                            handleStartedAtYearOnBlur(e)
                                                        }}
                                                        ref={field.ref}
                                                        classNamePrefix={"react-select"}
                                                        placeholder={<label className={(!startedAtYearLabel && !getValues("started_year")) ? "input-lbl" : 'hidden'}>Year</label>}
                                                        options={yearOptions}
                                                        formatOptionLabel={loc => (
                                                            <div className='single-select-default flex'><span className="txt"> { loc.label}</span></div>
                                                        )}
                                                    />
                                                )}
                                            />
                                            <FormValidationIcon getval={getValues("started_year")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"started_year"}></FormValidationIcon>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex w-full justify-between form-error'>
                                    <div className="relative w-[48%]"><FormFieldError errors={errors} fieldname={"started_month"}></FormFieldError></div>
                                    <div className="relative w-[48%]"><FormFieldError errors={errors} fieldname={"started_year"}></FormFieldError></div>
                                </div>
                                <div className='relative w-[100%] mt-[20px]'>
                                    <p className='group-label'>End Date</p>
                                    <div className='flex w-full justify-between'>
                                        <div className="field-group w-[48%]">
                                            <Controller
                                                name="end_month"
                                                control={control}
                                                rules={{ required: 'End month is required' }}
                                                render={({ field }) => (
                                                    <Select
                                                        isClearable
                                                        menuPortalTarget={dailogRef.current}
                                                        menuPosition={'fixed'}
                                                        value={endMonth}
                                                        isDisabled={checked}
                                                        onChange={(e) => { handleEndMonthOnChange(e) }}
                                                        onBlurName="onChange"
                                                        onFocus={() => (setEndMonthLabel(true))}
                                                        onBlur={(e) => {
                                                            handleEndMonthOnBlur(e)
                                                        }}
                                                        ref={field.ref}
                                                        classNamePrefix={"react-select"}
                                                        placeholder={<label className={(!endMonthLabel && !getValues("end_month")) ? "input-lbl" : 'hidden'}>Month</label>}
                                                        options={endMonthOptions}
                                                        formatOptionLabel={loc => (
                                                            <div className='single-select-default flex'><span className="txt"> { loc.label}</span></div>
                                                        )}
                                                    />
                                                )}
                                            />
                                            <FormValidationIcon getval={getValues("end_month")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"end_month"}></FormValidationIcon>
                                        </div>
                                        <div className="field-group w-[48%]">
                                            <Controller
                                                name="end_year"
                                                control={control}
                                                rules={{ required: 'End year is required' }}
                                                render={({ field }) => (
                                                    <Select
                                                        isClearable
                                                        menuPortalTarget={dailogRef.current}
                                                        menuPosition={'fixed'}
                                                        isDisabled={checked}
                                                        value={endYear}
                                                        onChange={(e) => { handleEndYearOnChange(e) }}
                                                        onBlurName="onChange"
                                                        onFocus={() => (setEndYearLabel(true))}
                                                        onBlur={(e) => {
                                                            handleEndYearOnBlur(e)
                                                        }}
                                                        ref={field.ref}
                                                        classNamePrefix={"react-select"}
                                                        placeholder={<label className={(!endYearLabel && !getValues("end_year")) ? "input-lbl" : 'hidden'}>Year</label>}
                                                        options={endYearOptions}
                                                        formatOptionLabel={loc => (
                                                            <div className='single-select-default flex'><span className="txt"> { loc.label}</span></div>
                                                        )}
                                                    />
                                                )}
                                            />
                                            <FormValidationIcon getval={getValues("end_year")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"end_year"}></FormValidationIcon>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex w-full justify-between form-error'>
                                    <div className="relative w-[48%]"><FormFieldError errors={errors} fieldname={"end_month"}></FormFieldError></div>
                                    <div className="relative w-[48%]"><FormFieldError errors={errors} fieldname={"end_year"}></FormFieldError></div>
                                </div>
                                <div className='flex justify-between mt-[35px] [@media(max-width:640px)]:flex-col-reverse [@media(max-width:640px)]:gap-[10px]'>
                                    <button className='w-[65px] h-[34px] rounded-border-button [@media(max-width:640px)]:w-full' onClick={props.close}>Back</button>
                                    <button className='w-[105px] disabled:opacity-50 h-[34px] rounded-fill-button dark:hover:!bg-[#4C4C4C] [@media(max-width:640px)]:w-full' onClick={saveExperience} >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
