import React from 'react';
import {ColoredProgressBar} from './colored-progress-bar'
export const ProjectSetupFooter = (props) => {

    return (
        <footer className="footer flex items-end">
            <div className='flex flex-col w-full'>
                <div className="w-full bg-[#F5F5F5] dark:bg-[#1d1d1d] flex">
                    <div className='w-full'>
                    <ColoredProgressBar list={[{"name":"Start Point","persentage":"0%"},{"name":"Name","persentage":"17%"},{"name":"Skills","persentage":"34%"} , {"name":"Pinpoints","persentage":"50%"},{"name":"Summary","persentage":"65%"} ,{"name":"Results","persentage":"82%"} ]} activeTab={props.activepage} activeTabPersentage = {props.activepersentage}/>
                    </div>
                </div>
            </div>
        </footer>
    )
}