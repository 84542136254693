import { useForm } from 'react-hook-form';
import FormValidationIcon from '../../../utils/form-validation-icon';
import FormSearchIcon from '../../../utils/form-search-icon';
import { SelectFormFieldError } from '../../../utils/error-message';
import { GetCompanies, AddCompany } from '../../service/commonapi';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import common from '../../../utils/common';
import { ProCancel } from '../common/icons/skills-cancel';
import loader from '../../../assets/icons/loader.svg'

export const BrandsPopup = (props) => {
    const projectSetupStore = useSelector(state => state.projectSetupStore);
    const selectStore = useSelector(state => state.selectStore);
    const [brands, setbrands] = useState([]);
    const [brandsIsValid, setBrandsIsvalid] = useState(true);
    const [brandsErrorMessage, setBrandsErrorMsg] = useState("");
    const [brandsDrop, setBrandsDrop] = useState([]);
    const [brandsTempDrop, setBrandsTempDrop] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [showAddloader, setShowAddloader] = useState(false);
    const [brandText, setBrandText] = useState("");
    const brandsRef = useRef();
    const brandsRefDrop = useRef();
    const [isOpen, setisOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=> {
        if(!props.open){
            const popup = document.querySelector(".brand-popup")
            if(popup){
                popup.classList.add("slide-right")
            }
            setTimeout(() => {
                setisOpen(false);
            },100)
        }else{
            setisOpen(true)
        }
    },[props.open])
    
        const { setValue, getValues } = useForm({
        mode: "all",
        reValidateMode: "onChange", criteriaMode: "all"
    });

    useEffect(() => {
        getCompany();
        window.addEventListener("scroll", resetDropdownPosition);
        return () => {
            window.removeEventListener("scroll", resetDropdownPosition);
        };
    }, [])

    /**Recalculate Position on scroll*/
    function resetDropdownPosition() {
        var brandsParent = brandsRef.current;
        if (brandsParent) {
            brandsParent = brandsParent.getBoundingClientRect();
            if (document.querySelector('.brands.demoFixed') && brandsParent.top) {
                document.querySelector('.brands.demoFixed').style.top = brandsParent.top + 30 + 'px';
            }
        }
    }

    const enableBrandsFirstLevel = () => {
        setBrandsDrop(brandsTempDrop);
        if (document.querySelectorAll('.brands .demoFixedSubLevel')) {
            [...document.querySelectorAll('.brands .demoFixedSubLevel')].forEach(div => {
                if (div.style.display !== 'none') {
                    div.style.display = 'none'
                }
            });
        }
        const mark = document.querySelector(".brands-label");
        if (mark) {
            mark.classList.remove("move-label");
        }
        const parent = brandsRef.current.getBoundingClientRect()
        document.querySelector('.brands.demoFixed').style.left = parent.left + 'px';
        document.querySelector('.brands.demoFixed').style.top = parent.top + 30 + 'px';
        document.querySelector('.brands.demoFixed').style.width = parent.width + 'px';
        document.querySelector('.react-select__menu.brands.demoFixed').style.display = 'block';
        document.querySelector('.brands-multi-select-backdrop').style.display = 'block';
    }

    const disableAllBrandsLevel = () => {
        if (document.querySelector('.brands.demoFixed').style) {
            document.querySelector('.brands.demoFixed').style.display = 'none';
            if (brands && !brands.length >= 3) {
                selectStore.brands.forEach((_val, _i) => {
                    document.querySelector('.react-select__menu.brands .demoFixedSubLevel.child_' + _i).style.display = 'none'
                })
            }
            document.querySelector('.brands-multi-select-backdrop').style.display = 'none';
        }
    }

    function brandsOnchange(val, i, e) {
        if (e) {
            e.preventDefault()
            e.stopPropagation();
        }
        var _val = JSON.parse(JSON.stringify(brandsDrop));
        var _brands = JSON.parse(JSON.stringify(brands));
        var _valueIndex = _brands.findIndex(value => value.company_id === val.company_id);
        if (_val[i]["isChecked"]) {
            _val[i]["isChecked"] = false;
            if (_valueIndex !== -1) {
                _brands.splice(_valueIndex, 1);
                setbrands(_brands);
            }
        } else {
            _val.forEach((val, j) => {
                if (i === j) {
                    _val[j]["isChecked"] = true;
                    _brands.push(_val[j])
                    setbrands(_brands);
                }
            })
        }
        setBrandsDrop(_val);
        setBrandsTempDrop(_val)
        BrandSelectValidation(_brands, 1, 'Brands', 'brands')
        // var _data = JSON.parse(JSON.stringify(projectSetupStore.page3));
        // let data = [];
        // _brands.forEach((_val) => {
        //     data.push(_val.company_id);
        // });
        // _data["inspiration"] = _brands;
        // console.log(_data)
        // dispatch({ type: "project_setup/insertValue", payload: { id: _data, name: "page3" } });
        // props.saveData({ "inspiration": data });
        // if(_brands && _brands.length >=3){
        //     disableAllBrandsLevel()
        //   }
    }
    function brandsOnAdd() {
        if (!showAddloader) {
            setShowAddloader(true)
            AddCompany(brandText).then((res) => {
                if (res) {
                    var event = { "target": { "value": brandText } }
                    onInputChange(event)
                    setShowAddloader(false)
                } else {
                    setShowAddloader(false)
                }
            })
        }
    }
    function onInputChange(event) {
        const _searchText = event.target.value;
        if (_searchText !== "" && _searchText.length >= 3) {
            setBrandText(_searchText);
            GetCompanies(_searchText).then((res) => {
                if (res) {
                    // setIsLoading(false)
                    var updatedVal = []
                    res.forEach((v) => {
                        var _val = {};
                        _val["label"] = v.label;
                        _val["company_id"] = v.company_id;
                        _val["isChecked"] = false;
                        _val["logo"] = v.logo
                        updatedVal.push(_val)
                    })
                    var brns = [...brands];
                    brns.forEach((val, i) => {
                        var ind = updatedVal.findIndex(v => v.company_id === val.company_id)
                        if (ind !== -1) {
                            updatedVal[ind]["isChecked"] = true
                        }
                    })
                    setBrandsDrop(updatedVal);
                    if (res.length <= 0) {
                        setShowAdd(true)
                    } else {
                        setShowAdd(false)
                    }
                    return updatedVal;
                }
            })
        } else if (!_searchText || _searchText.length === 0 || _searchText === "") {
            setBrandsDrop(brandsTempDrop)
        }
    }

    function brandsBlurHandler() {
        const mark = document.querySelector(".brands-label");
        if (mark) {
            mark.classList.add("move-label");
        }
        if (document.getElementsByName('brands')) {
            document.getElementsByName('brands').forEach(o => {
                if (o && o.value !== "") {
                    o.value = ""
                }
            })
        }
    }

    function getCompany() {
        try {
            GetCompanies("").then((res) => {
                if (res) {
                    var modifiedObj = res.map(obj => ({ ...obj, isChecked: false }))
                    if (projectSetupStore.page3.inspiration && Array.isArray(projectSetupStore.page3.inspiration) && projectSetupStore.page3.inspiration.length) {
                        var _brands = []
                        var branValues = JSON.parse(JSON.stringify(projectSetupStore.page3.inspiration))
                        branValues.forEach((_val, i) => {
                            var index = modifiedObj.findIndex(x => x.company_id === _val);
                            _brands.push(modifiedObj[index]);
                            if (index !== -1) {
                                modifiedObj[index]["isChecked"] = true;
                            }
                        })
                        if (_brands.length >= 1) {
                            setValue('brands', _brands, {
                                shouldTouch: true,
                                shouldValidate: true,
                                shouldDirty: true,
                            });
                            setbrands(_brands);
                        }
                    } else {
                        setValue('brands', _brands, {
                            shouldTouch: true,
                            shouldValidate: true,
                            shouldDirty: true,
                        });
                    }
                    setBrandsDrop(modifiedObj);
                    setBrandsTempDrop(modifiedObj)
                } else {
                    setBrandsDrop([]);
                    setBrandsTempDrop([])
                }
            })
        } catch (err) {
            console.log(err);
        }
    }
    function removeHandler(val, i) {
        const brandValue = [...brands];
        brandValue.splice(i, 1);
        setbrands(brandValue);
        var _val = JSON.parse(JSON.stringify(brandsDrop));
        var _valueIndex = _val.findIndex(value => value.company_id === val.company_id);
        if (_val[_valueIndex]["isChecked"]) {
            _val[_valueIndex]["isChecked"] = false;
        }
        setBrandsDrop(_val);
        setBrandsTempDrop(_val)
        BrandSelectValidation(brandValue, 1, 'Brands', 'brands')
    }

    function BrandSelectValidation(value, min, fieldname, type) {
        let message;
        switch (type) {
            case 'brands':
                if (!value.length) {
                    setBrandsIsvalid(false)
                    message = common.formValidation(fieldname, 'required')
                    setBrandsErrorMsg(message)
                } else if (value.length < min) {
                    setBrandsIsvalid(false)
                    message = common.formValidation(fieldname, 'minValue', min)
                    setBrandsErrorMsg(message)
                } else {
                    setBrandsIsvalid(true);
                    setBrandsErrorMsg()
                }
                break;
            default:
                break;
        }
    }
    function submitHandler() {
        try {
            if (brands.length >= 1) {
                var val = JSON.parse(JSON.stringify(brands));
                let data = [];
                val.forEach((_val) => {
                    data.push(_val.company_id);
                });
                var _data = JSON.parse(JSON.stringify(projectSetupStore.page3));
                _data["inspiration"] = data;
                dispatch({ type: 'project_setup/insertValue', payload: { "id": _data, "name": "page3" } });
                props.saveData({ "inspiration": data });
                props.close();
            } else {
                BrandSelectValidation(brands, 1, 'Brands', 'brands')
            }
        } catch (err) { console.log(err); }
    }

    if(isOpen){
    return (
        <div className="relative z-10 mobile:w-full" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 overlay-background bg-opacity-75 backdrop-blur-sm transition-opacity mobile:hidden"></div>
            <div className={`fixed brand-popup inset-0 z-10 overflow-y-auto h-full mobile:relative mobile:h-auto mobile:w-full ${props.open ? "slide-left" : ""}`}>
                <div className='absolute z-10 h-full p-[32px] top-0 right-0 bg-[#ffffff] w-[485px] shadow-md mobile:relative mobile:shadow-none mobile:w-full mobile:p-0 mobile:h-auto dark:bg-[#0E0E0E]'>
                    <div className="w-full flex flex-wrap">
                        <div className='flex mobile:hidden'>
                            <div className='flex flex-wrap gap-1 text-left'>
                                <p className='text-[#07091b] text-[20px] leading-[130%] tracking-[-0.6px] dark:text-[#fff]'>03. Brands that inspire you</p>
                                <p className='text-[#a3a3a3] text-[14px] leading-[130%] tracking-[-0.42px] dark:text-[#808080]'>Although your brand is unique, we can find talent whose creative work you find inspiring and relevant to your project.</p>
                            </div>
                            <div className="absolute right-0 xs:mt-[-10px] pr-7 sm:block mt-[4px]">
                                <button type="button" onClick={props.close} className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none pr-[7px]"><ProCancel /></button>
                            </div>
                        </div>
                        <div className='w-full flex flex-col mt-[24px] pt-[24px] box-border border-t border-solid border-[#d9d9d9] dark:border-[#A3A3A3]'>
                            <div className="field-group w-full mt-[40px] custom-select brands-placeholder">
                                <label className={"absolute move-label brands-label diversity-edge z-0 text-[14px] text-[#a3a3a3] z-1 origin-0 select-label"}>Brands</label>
                                <input autoComplete='off' type="text" ref={brandsRef} name="brands" onFocus={enableBrandsFirstLevel} onInputCapture={onInputChange} onBlur={brandsBlurHandler} className="input-fld !relative !z-1" />
                                {brands && brands.length < 1 && <FormSearchIcon />}
                                <FormValidationIcon isArray={true} twolevelselect={true} minlen={1} getval={brands}></FormValidationIcon>
                                <div className={'brands-multi-select-backdrop multi-select-backdrop'} onClick={disableAllBrandsLevel}></div>
                            </div>
                            <div ref={brandsRefDrop} className="multi-select react-select__menu brands demoFixed">
                                <div className="react-select__menu-list react-select__menu-list--is-multi single-level">
                                {JSON.parse(JSON.stringify(brandsDrop)).length <= 0 && !showAdd ?<div className="react-select__menu-notice react-select__menu-notice--no-options css-1wlit7h-NoOptionsMessage">Enter company name</div>:""}
                                    {showAdd && <div className={"react-select__option"}>
                                        <li className={"cursor-pointer"} onClick={(e) => { brandsOnAdd() }}>
                                            <span className="txt" >   <div className='flex items-center'>
                                                <span>{"Add " + brandText}</span>
                                            </div>
                                                {showAddloader ? <img src={loader} className={"w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-white"} alt="loading.." /> : <div role="button" className="react-select__multi-value__remove" onClick={(e) => { brandsOnAdd() }}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="9.14453" y="4" width="1.71429" height="12" fill="#1D1D1D" />
                                                        <rect x="4" y="9.14258" width="12" height="1.71429" fill="#1D1D1D" />
                                                    </svg>
                                                </div>}
                                            </span>

                                        </li>
                                    </div>}
                                    {JSON.parse(JSON.stringify(brandsDrop)).map((diver, i) =>
                                        <div className={"react-select__option"} key={i}  >
                                            <li className={"cursor-pointer"} onClick={(e) => { brandsOnchange(diver, i, e) }}>
                                                <span className="txt" >
                                                    <div className='flex items-center'>
                                                        {/* <div className="flag-div mr-[10px]">
                                                    <img className="flagSVG h-[20px] w-[20px]" src={diver.logo} alt="brand" />
                                                    </div> */}
                                                        <span>{diver.label}</span>
                                                    </div>
                                                    {/* <label className="ch-box">
                                                <input type="checkbox" onChange={() => { brandsOnchange(diver, i) }} readOnly checked={diver.isChecked} />
                                                </label> */}
                                                    <label className="control control--checkbox">
                                                        <input type="checkbox" onChange={() => { brandsOnchange(diver, i) }} readOnly checked={diver.isChecked} />
                                                        <div className="control__indicator"></div>
                                                    </label>
                                                </span>
                                            </li>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <SelectFormFieldError isValid={brandsIsValid} message={brandsErrorMessage}></SelectFormFieldError>
                            <div className={`${brands && brands.length ? '!mt-0 !mb-[24px] custom-multi-select' : ''}  `}>
                                {brands && brands.map((val, i) => {
                                    return (
                                        <div key={i} className="react-select__multi-value new">
                                            <div className="react-select__multi-value__label">
                                                <div className="brands-option flex m-auto">
                                                    {/* <div className="flag-div mr-[10px]">
                                            <img
                                            className="flagSVG h-[20px] w-[20px]"
                                            src={val.logo}
                                            alt="brand"
                                            />
                                        </div> */}
                                                    <span className="mar-label">{val.label}</span>
                                                </div>
                                            </div>
                                            <div
                                                role="button"
                                                onClick={() => removeHandler(val, i)}
                                                className="react-select__multi-value__remove"
                                                aria-label="Remove X"
                                            >
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.72184 15.2738C4.82196 15.3739 4.938 15.4399 5.06997 15.4717C5.20648 15.5036 5.33845 15.5036 5.46587 15.4717C5.59784 15.4399 5.7116 15.3762 5.80717 15.2806L10.0051 11.0827L14.1962 15.2806C14.2918 15.3762 14.4056 15.4399 14.5375 15.4717C14.6695 15.5036 14.8015 15.5036 14.9334 15.4717C15.0654 15.4399 15.1815 15.3739 15.2816 15.2738C15.3771 15.1782 15.4408 15.0644 15.4727 14.9325C15.5046 14.8005 15.5046 14.6708 15.4727 14.5434C15.4408 14.4114 15.3771 14.2954 15.2816 14.1953L11.0904 9.99733L15.2816 5.8062C15.3771 5.70609 15.4408 5.59232 15.4727 5.4649C15.5091 5.33294 15.5091 5.20097 15.4727 5.069C15.4408 4.93703 15.3771 4.82327 15.2816 4.7277C15.1769 4.62759 15.0586 4.5616 14.9266 4.52975C14.7992 4.4979 14.6695 4.4979 14.5375 4.52975C14.4056 4.55705 14.2918 4.62076 14.1962 4.72088L10.0051 8.91883L5.80717 4.72088C5.7116 4.62531 5.59784 4.5616 5.46587 4.52975C5.3339 4.4979 5.20193 4.4979 5.06997 4.52975C4.938 4.5616 4.82196 4.62759 4.72184 4.7277C4.62628 4.82327 4.56257 4.93703 4.53072 5.069C4.49886 5.20097 4.49886 5.33294 4.53072 5.4649C4.56257 5.59687 4.62628 5.70836 4.72184 5.79937L8.91297 9.99733L4.72184 14.2021C4.62628 14.2931 4.5603 14.4046 4.52389 14.5366C4.49204 14.6685 4.49204 14.8005 4.52389 14.9325C4.55575 15.0644 4.62173 15.1782 4.72184 15.2738Z" className="dark:fill-white" fill="#1D1D1D" />
                                                </svg>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='absolute bottom-[32px] mt-[10%] mobile:mt-[24px] mobile:relative mobile:bottom-auto'>
                        <button disabled={!brands.length} onClick={submitHandler} className='bg-c-black disabled:opacity-[0.3] text-white w-[421px] h-[45px] font-bold mobile:text-[12px] mobile:tracking-[-0.36px] text-[16px] leading-[130%] tracking-[-0.48px] rounded-full px-[24px] py-[12px] mobile:w-full dark:!bg-[#fff] dark:text-[#0E0E0E]'>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
    }
}