import play from "../../../assets/img/playbtn.png";
import bg2 from "../../../assets/images/video.png";
import bg1 from "../../../assets/images/Overlay.png";
import question from "../../../assets/img/questioncircle.png";
import { Cancel } from '../common/icons/cancel';

export const ShowExamplePopup = (props) => {

    return(
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 overlay-background bg-opacity-75 backdrop-blur-sm transition-opacity"></div>
            <div className="fixed inset-0 z-10 h-full">
                    <div className='absolute h-full flex flex-col items-end top-0 right-0 bg-[#ffffff] w-[727.59px] shadow-md mobile:w-full dark:bg-[#0E0E0E]'>
                    <div className='flex flex-col h-[calc(100%-122px)]'>
                        <div className='m-[32px] mb-[24px] flex flex-row justify-between box-border border-b border-solid border-[#d9d9d9] pb-[16px]'>
                            <div className='flex flex-row items-center gap-[48px] mobile:justify-between'>
                                <div className='flex flex-col gap-[4px]'>
                                    <p className='text-[#1D1D1D] text-[20px] leading-[130%] tracking-[-0.6px] mobile:w-full dark:text-[#fff]'><span className='text-[20px] font-bold leading-[130%] tracking-[-0.6px]'>{props.title}</span> example</p>
                                    <p className='text-[#a3a3a3] text-[16px] leading-[130%] tracking-[-0.48px] mobile:w-full dark:text-[#D9D9D9]'>Check all that apply</p>
                                </div>
                            </div>
                            <div className="absolute right-0 xs:mt-[-10px] pr-7 mt-[4px] mobile:hidden">
                                <button type="button" onClick={props.close} className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none pr-[7px]"><Cancel/></button>
                            </div>
                        </div>
                        <div className="h-[calc(100%-124px)] px-[32px] popup-max-height overflow-y-auto mb-[24px]">
                        <div className="video-con  relative flex items-center justify-center">
                            <video className='w-screen' controls poster={bg1}>
                                <source src='' type="video/mp4"></source> 
                            </video>
                            <button className='absolute focus:outline-none'>
                                <img alt='.' src={play}/>
                            </button>
                        </div>
                        
                        <div className="video-con pt-[24px] relative flex items-center justify-center">
                            <video className='w-screen' controls poster={bg2}>
                                <source src='' type="video/mp4"></source> 
                            </video>
                            <button className='absolute focus:outline-none'>
                                <img alt='.' src={play}/>
                            </button>
                        </div>
                        </div>
                    </div>
                    {/* <hr className='w-full border border-solid border-[#d9d9d9] mx-[32px]'/> */}
                    <div className='flex flex-col gap-[4px] mobile:mb-[24px]  border-t border-solid border-[#d9d9d9] mx-[32px] mb-[24px] pt-[32px]'>
                        <div className='flex flex-row gap-[4px]'>
                            <img alt='.' src={question} className='cursor-pointer'/>
                            <span className='text-[#808080] text-[14px] font-medium leading-[20px]'>Lorem ipsum</span>
                        </div>
                        <p className='text-[#808080] text-[14px] leading-[20px] tracking-[0.42px]'>The strategy experts we have on our platform can vary in expertise and skills. Your answers will help us match you with the best talent for your project.</p>
                    </div>
                    <hr className='w-[110px] border-[6.5px] border-solid border-[#A3A3A3] rounded-[6.5px] absolute bottom-[16px] left-[calc(50%-55px)] z-[10] hidden mobile:block'/>
                </div>
            </div>
        </div>
    )
}