import React from 'react';
import "react-circular-progressbar/dist/styles.css";
export const ProgressBar = (props) => {
    return (
        <>
        <div className='flex justify-end mt-[30px] mx-auto '>
            <div className="text-[#808080] text-[14px] leading-[18px]">{props.text}</div>
        </div>
        <div className="mx-auto my-[8px] h-[16px] overflow-hidden block tab-bar rounded-[24px]">
            <div className="overflow-hidden h-[16px] mb-4 text-xs flex rounded bg-[#B487FF] bar" active-tab="2"></div>
            <div className="active-tab h-[16px]" style={{ "--active-tab": props.activeTabPersentage }}>
                <svg width="374" height="16" viewBox="0 0 374 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_f_4256_44245)">
                        <circle cx="187.091" cy="8.00073" r="108.589" fill="url(#paint0_radial_4256_44245)" />
                    </g>
                    <defs>
                        <filter id="filter0_f_4256_44245" x="0.782661" y="-178.307" width="372.616" height="372.616"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="38.8596" result="effect1_foregroundBlur_4256_44245" />
                        </filter>
                        <radialGradient id="paint0_radial_4256_44245" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(187.091 8.00073) rotate(90) scale(108.589)">
                            <stop stopColor="#F05831" />
                            <stop offset="1" stopColor="#F05831" />
                        </radialGradient>
                    </defs>
                </svg>
            </div>
        </div>
        <div className='tab-name w-full mx-auto block'>
            <div className="text-[#808080] text-[14px] leading-[18px]" style={{ "--active-name": 0,width:'100%' }}>{props.activeTab}</div>
        </div>
            {/*<CircularProgressbar value={props.persentage} text={props.text} strokeWidth={10} styles={{ trail: {stroke: "#4C4C4C"}, path: { stroke: "#F5F5F5" }, text: {fill: "#F5F5F5"} }}/>*/}            
        </>
    )
}