import {  useNavigate } from "react-router-dom";
import { ProCancel } from "../common/icons/skills-cancel";
export const LoginConfirmationPopup = (props) => {
    const navigate = useNavigate();
    return (
        <div className="relative z-10 browse-talent-popup " aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 overlay-background bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className='flex min-h-full  justify-center p-4 sm:items-center sm:p-0'>
                <div className=" relative w-[360px] m-auto transform overflow-hidden rounded-md bg-white p-[15px] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[500px] dark:bg-[#1D1D1D] dark:shadow-[0px_4px_21px_rgba(138, 138, 138, 0.2)]">
                    <div className='h-[calc(100%-339px)] w-full flex flex-col gap-[24px]'>
                        <div className='flex justify-between box-border border-b border-solid border-[#d9d9d9] dark:border-[#A3A3A3] '>
                            <div className='flex-col gap-1'>
                                <p className='text-[#A3A3A3] text-[20px] leading-[130%] tracking-[-0.6px] font-bold mobile:pt-[16px] mobile:text-[14px] mobile:leading-[130%] mobile:tracking-[-0.42px] mobile:hidden dark:text-[#fff]'>Confirmation</p>
                                <p className='text-[#07091b] text-[16px] leading-[18.2px] w-[calc(100%-25px)] tracking-[-0.42px] mt-[4px] mobile:text-[14px] mobile:leading-[18.2px] mobile:tracking-[-0.42px] mobile:w-full mobile:mt-[0]'>Need to log-in to view the team</p>
                            </div>
                            <div className="absolute right-0 xs:mt-[-10px] pr-7 mt-[4px] mobile:hidden">
                                <button type="button" onClick={props.close} className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "><ProCancel /></button>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-between mt-[35px] [@media(max-width:640px)]:flex-col-reverse [@media(max-width:640px)]:gap-[10px]'>
                        <button className='w-[215px] h-[36px] rounded-border-button [@media(max-width:640px)]:w-full' onClick={props.close}>Cancel</button>
                        <button className={`w-[215px] h-[36px] rounded-fill-button [@media(max-width:640px)]:w-full dark:hover:!bg-[#4C4C4C] `} onClick={()=>{         navigate("/")}} >Ok</button>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}