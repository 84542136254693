import {useGetRoleQuery} from "../store/api/api";
import {roleById} from "./roles";

export const industryById = (industries,industryId) => {

    for (let i=0; i<industries.length; i++) {
        if (industryId === industries[i].industry_id) {
            return industries[i]
        }
    }

    return null

};


export const industryLabelById = (industries,industryId) => {

    let industry = industryById(industries,industryId)
    if (industry) {
        return industry.sublabel ? industry.sublabel : industry.label
    }

    return 'label'
};


export const industryTree = (industries) => {

    let retval = []
    // Top level
    for (let i=0;i<industries.length;i++) {
        if (!industries[i].sublabel) {
            retval.push({
                id: industries[i].industry_id,
                label: industries[i].label,
                subItems: [],
            })
        }
    }



    return retval
};
