import {useGetRoleQuery} from "../store/api/api";

export const roleById = (roles,roleId) => {

    for (let i=0; i<roles.length; i++) {
        if (roleId === roles[i].role_id) {
            return roles[i]
        }
    }

    return null

};


export const roleLabelById = (roles,roleId) => {

    let role = roleById(roles,roleId)
    if (role) {
        return role.label
    }

    return 'label'

};
