import logo from "../../../assets/img/initial-logo.svg";
import darklogo from "../../../assets/img/dark-initial-logo.svg";
import mlogo from "../../../assets/img/logo-mini-color.svg";
import minilogo from '../../../assets/img/logo-mini-color.svg';
import darkminilogo from '../../../assets/img/dark-logo-mini.svg';
// import greenIcon from "../../../assets/img/greenIcon.png";
import orangeIcon from "../../../assets/img/arrow-orange-icon.svg";
import layer from "../../../assets/img/layer.png";
import darklayer from "../../../assets/img/LayerDark.png";
import { ProjectSetupTemplate } from "../common/project-setup-template";
import { ProjectSetupFooter } from "../common/project-setup-footer"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BrowseTalentPopup } from "./browsetalent-popup";
import { RequestDemoPopup } from "./requestDemo-popup";
import { LockIcon} from "../common/icons/lock-icon.js"
import { WhyIcon } from "../common/icons/why-icon";
import { Tooltip as ReactTooltip } from "react-tooltip";

export const StartingPoint = (props) => {
    const projectSetupStore = useSelector(state => state.projectSetupStore);
    const [showBrowseTalent,setShowBrowseTalent] = useState(false);
    const [showGuideTour,setShowGuideTour] = useState(false);
    const dispatch = useDispatch();
    const [openMobileView,setMobileView] = useState(false);

    function browseTalent(){
        setShowBrowseTalent(true);
        // submitHandler('browse_talent');
    }

    function guideTour(){
        setShowGuideTour(true);
        // submitHandler('guided_tour');
    }

    useEffect(()=>{
        if(document.querySelector(".page-section")) {
            document.querySelector(".page-section").classList.add("skillpoint");
        }
        return () => {
            if(document.querySelector(".page-section")) { 
                document.querySelector(".page-section").classList.remove("skillpoint");
            }
        }
    },[])

    useEffect(() => {
        resizeListener()
        window.addEventListener('resize', resizeListener);
        return () => {
        window.removeEventListener('resize', resizeListener);
        }

    },[]);

    function resizeListener() { 
        if(window.innerWidth < 768) {
            setMobileView(true);
        } else {
            setMobileView(false);
            setShowBrowseTalent(false);
            setShowGuideTour(false);
        }
    }

    function submitHandler(value){
        var _data = JSON.parse(JSON.stringify(projectSetupStore.page1));
        switch (value){
            case 'find_team':
                if(value){
                    _data["starting_point"] = value;
                    dispatch({ type:"project_setup/insertValue", payload: {"id":_data,"name":"page1"}})
                    dispatch({ type:"project_setup/insertValue", payload: {"id":'agency',"name":"teams_platform"}})
                    props.saveData({ "starting_point" : value})
                    props.nextPage(value);
                }
                break;
            case 'add_talent':
                if(value){
                    _data["starting_point"] = value;
                    dispatch({ type:"project_setup/insertValue", payload: {"id":_data,"name":"page1"}})
                    dispatch({ type:"project_setup/insertValue", payload: {"id":'talent',"name":"teams_platform"}})
                    props.saveData({ "starting_point" : value})
                    props.nextPage(value);
                }
                break;
            case 'browse_talent':
                if(value){
                    _data["starting_point"] = value;
                    dispatch({ type:"project_setup/insertValue", payload: {"id":_data,"name":"page1"}})
                    props.saveData({ "starting_point" : value});
                    props.nextPage(value);
                }
                break;
            case 'guided_tour':
                if(value){
                    _data["starting_point"] = value;
                    dispatch({ type:"project_setup/insertValue", payload: {"id":_data,"name":"page1"}})
                    props.saveData({ "starting_point" : value})
                    props.nextPage(value);
                }
                break;
            default:
                break;
        }
        
    }

    function StartingPointText() {
        return (
            <div className="flex flex-col justify-between bg-[#F5F5F5] dark:bg-[#161616] footer-clr-change">
                <div className="flex flex-col gap-[88px] mobile:gap-[48px] tablet:gap-[48px]">
                    <div className="flex flex-row gap-[16px] mobile:h-[64px] tablet:py-[5px] tablet:px-[24px] tablet:bg-white tablet:dark:bg-[#0E0E0E]">    
                        <img alt="mlogo" className="tablet:p-[10px] [@media(min-width:768px)]:hidden dark:hidden" src={minilogo} width="48px" /><img alt="mlogo" className="tablet:p-[10px] mobile:hidden dark:hidden" src={mlogo} width="56px" height="50px" /><img alt="logo" className="mobile:hidden dark:hidden" src={logo} width="182px" />
                        <img alt="mlogo" className="tablet:p-[10px] hidden mobile:dark:block" src={darkminilogo} width="48px" /><img alt="mlogo" className="tablet:p-[10px] mobile:dark:hidden dark:block hidden" src={darkminilogo} width="56px" height="50px" /><img alt="logo" className="mobile:hidden dark:block hidden" src={darklogo} width="182px" />
                    </div>
                    <div className="flex flex-col gap-[24px] text-[#1D1D1D] tablet:px-[24px] dark:text-[#fff]">
                        <span className="flex flex-col  relative mobile:justify-between mobile:text-[14px] mobile:tracking-[-0.42px] ">
                            <span className="text-[14px] w-fit font-semibold tracking-[-0.54px] text-[#0e0e0e] dark:text-[#fff] hover:!text-[#F05831]">Start</span>
                            <hr className="border-solid mt-[16px] w-full border-[#d9d9d9] dark:border-[#A3A3A3]"></hr>
                            <div className="hidden mobile:block" data-tooltip-id='whyask'><WhyIcon/></div>
                            <ReactTooltip content='Aim high! What skills and expertise do you need (Remember, it is rocket science)' className='tooltip-top' id='whyask' variant='light' place="top"/>
                        </span>
                        <p className="[@media(width:1920px)]:w-[624px] archivo text-[40px] font-extralight leading-[120%] tracking-[-1.2px] mobile:text-[24px] mobile:leading-[120%] mobile:tracking-[-0.72px] dark:text-[#fff] height-ctrl2:text-[24px] height-ctrl2:tracking-[-0.72px]">Whatever kind of creative brilliance you're looking for,<span className="archivo font-bold text-[40px] mobile:text-[24px] leading-[120%] tracking-[-1.2px] mobile:leading-[120%] mobile:tracking-[-0.72px] height-ctrl2:text-[24px] height-ctrl2:tracking-[-0.72px]"> we can match it.</span> Whether you're ready to brief, or just browsing for brain power, go right ahead.</p>
                    </div>
                </div>
                <div className="w-full flex-col justify-start items-start gap-[24px] flex tablet:hidden">
                    <div className="w-full flex-col justify-start items-start gap-[24px] flex height-ctrl:hidden">
                        <div className="relative dark:hidden"><img alt="." src={layer} height="168px" width="236px" /></div>
                        <div className="relative hidden dark:block"><img alt="." src={darklayer} height="168px" width="236px" /></div>
                        <div className="self-stretch border-t-[1px] border-solid border-[#d9d9d9] dark:border-[#A3A3A3]"></div>
                    </div>
                    {/*<div className="flex-col justify-start items-start gap-4 flex w-[448px]">*/}
                    {/*    <div className="w-[341px] h-[18px] text-stone-900 text-[18px] font-bold uppercase leading-[100%] tracking-[0.9px] dark:text-[#fff]">Why we ask</div>*/}
                    {/*    <div className="self-stretch text-black text-[20px] font-normal leading-[130%] dark:text-[#fff]">Make us understand what makes you and your brand tick. The nuances. The tone. The texture. The practicalities and realities. And we’ll do the rest.</div>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }

    return (
        <div className="page-section">
            <ProjectSetupTemplate>
                <StartingPointText></StartingPointText>
                <div className="h-[calc(100%-101px)] flex flex-wrap gap-[50.71px] tablet:gap-[48px] tablet:h-[auto]">
                    <div className="w-full h-full overflow-auto grid grid-cols-[repeat(2,_minmax(350px,_1fr))] custom:grid-cols-[repeat(1,_minmax(350px,_1fr))] gap-[12px] child:flex child:items-center child:bg-white mobile:grid-cols-[repeat(auto-fill,_minmax(312px,_1fr))] child:mobile:p-[24px] child:mobile:h-[auto] dark:child:bg-[#0E0E0E] dark:text-[#fff]">
                        <div className="min-h-[396px] mobile:min-h-[auto]">
                            <button onClick={() => submitHandler('find_team')} className="w-full group mobile:self-center cursor-pointer py-[64px] pr-[64px] mobile:p-0">
                                <div className="flex flex-col gap-[8px] py-[24px] pl-[24px] text-left mobile:p-[0] mobile:gap-[16px] mobile:flex-row">
                                    <div className="mobile:self-center"><img alt="icon" src={orangeIcon} className="min-w-[19px] transition-all left-0 relative group-hover:left-3" /></div>
                                    <div className="mobile:flex mobile:flex-col">
                                        <b className="text-[24px] leading-[110%] tracking-[-0.72px] mobile:text-[18px] dark:text-white text-[#1d1d1d] mobile:tracking-[-0.54px]">Find an Independent Agency.</b>
                                        <span className="reveal-txt mobile:block hidden md:group-hover:block fady-in dark:text-white text-[14px] leading-[110%] tracking-[-0.72px] mobile:text-[14px] mobile:tracking-[-0.54px]">Fully outsource your project to a world-class agency that suits your project needs.</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className="min-h-[396px] mobile:min-h-[auto]">
                            <button onClick={() => submitHandler('add_talent')} className="w-full group mobile:self-center cursor-pointer py-[64px] relative pr-[64px] mobile:p-0">
                                <div className="flex flex-col gap-[8px] py-[24px] pl-[24px] text-left mobile:p-[0] mobile:gap-[16px] mobile:flex-row">
                                    <div className="flex justify-end absolute top-[-44px] right-[20px] mobile:top-[-15px] mobile:right-[-15px] group-hover:top-[-28px]"><LockIcon/></div>
                                    <div className="mobile:self-center"><img alt="icon" src={orangeIcon} className="min-w-[19px] transition-all left-0 relative group-hover:left-3" /></div>
                                    <div className="mobile:flex mobile:flex-col">
                                        <b className="text-[24px] leading-[110%] tracking-[-0.72px] mobile:text-[18px] dark:text-white text-[#1d1d1d] mobile:tracking-[-0.54px]">Add world-class talent.</b>
                                        <span className="reveal-txt mobile:block  hidden md:group-hover:block fady-in dark:text-white text-[14px] leading-[110%] tracking-[-0.72px] mobile:text-[14px] mobile:tracking-[-0.54px]">When you already have a project underway and need extra creative rocket fuel on the team.</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className="min-h-[396px] mobile:min-h-[auto] mobile:flex mobile:flex-wrap">
                            <button onClick={browseTalent} className="w-full group mobile:self-center cursor-pointer py-[64px] pr-[64px] relative mobile:p-0">
                                
                                <div className="flex flex-col gap-[8px] py-[24px] pl-[24px] text-left mobile:p-[0] mobile:gap-[16px] mobile:flex-row ">
                                <div className="flex justify-end absolute top-[-44px] right-[20px] mobile:top-[-15px] mobile:right-[-15px] group-hover:top-[-36px]"><LockIcon/></div>
                                    <div className=""><img alt="icon" src={orangeIcon} className="min-w-[19px] transition-all left-0 relative group-hover:left-3" /></div>
                                    <div className="mobile:flex mobile:flex-col">
                                        <b className="text-[24px] leading-[110%] tracking-[-0.72px] mobile:text-[18px] dark:text-white text-[#1d1d1d] mobile:tracking-[-0.54px]">Browse the talent.</b>
                                        <span className="reveal-txt mobile:block hidden md:group-hover:block fady-in text-[14px] dark:text-white leading-[110%] tracking-[-0.72px] mobile:text-[14px] mobile:tracking-[-0.54px]">Filter by industry, skills, location and more to browse the talent and work. Contact them instantly.</span>
                                    </div>
                                </div>
                            </button>
                            <div className="w-full hidden mobile:block">{showBrowseTalent && openMobileView && <BrowseTalentPopup close={() => {setShowBrowseTalent(false); document.body.classList.remove("overflow-hidden")}} saveData={props.saveData} nextStep={(e) => {submitHandler('browse_talent')}}/>}</div>
                        </div>
                        <div className="min-h-[396px] mobile:min-h-[auto] mobile:flex mobile:flex-wrap">
                            <button onClick={guideTour} className="w-full group mobile:self-center cursor-pointer py-[64px] pr-[64px] mobile:p-0">
                                <div className="flex flex-col gap-[8px] py-[24px] pl-[24px] text-left mobile:p-[0] mobile:gap-[16px] mobile:flex-row">
                                    <div className=""><img alt="icon" src={orangeIcon} className="min-w-[19px] transition-all left-0 relative group-hover:left-3" /></div>
                                    <div className="mobile:flex mobile:flex-col">
                                        <b className="text-[24px] leading-[110%] tracking-[-0.72px] mobile:text-[18px] dark:text-white text-[#1d1d1d] mobile:tracking-[-0.54px]">Request a demo.</b>
                                        <span className="reveal-txt mobile:block  hidden md:group-hover:block fady-in dark:text-white text-[14px] leading-[110%] tracking-[-0.72px] mobile:text-[14px] mobile:tracking-[-0.54px]">For a human to explain how Moonshoot works.</span>
                                    </div>
                                </div>
                            </button>
                            <div className="w-full hidden mobile:block">{showGuideTour && openMobileView && <RequestDemoPopup close={() => {setShowGuideTour(false); document.body.classList.remove("overflow-hidden")}} saveData={props.saveData} nextStep={(e) => {submitHandler('guided_tour')}}/>}</div>
                        </div>
                    </div>
                    <ProjectSetupFooter activepage="Start Point" activepersentage="-5%" />
                </div>
            </ProjectSetupTemplate>
{showBrowseTalent && <BrowseTalentPopup close={() => {setShowBrowseTalent(false); document.body.classList.remove("overflow-hidden")}} saveData={props.saveData} nextStep={(e) => {submitHandler('browse_talent')}}/>}
            {showGuideTour && <RequestDemoPopup close={() => {setShowGuideTour(false); document.body.classList.remove("overflow-hidden")}} saveData={props.saveData} nextStep={(e) => {submitHandler('guided_tour')}}/>}
        </div>
    )
}