import {ExampleIcon} from "../common/icons/example-icon";
import React from "react";

export const Hover = (props) => {
    return (
    <div className="w-full reveal-txt hidden md:group-hover:block animated slideInDown fady-in selected:hidden">
        <p className="[@media(min-width:1920px)]:w-[313px] hidden text-[14px] text-[#808080] leading-[130%] tracking-[-0.42px] md:group-hover:block transition-all mobile:text-[14px] mobile:leading-[130%] mobile:tracking-[-0.42px] mobile:block dark:text-[#a3a3a3]">Media-neutral,
            {props.children}</p>
        <div className="w-full flex flex-wrap justify-between transition-all mt-[8px] mobile:mt-[16px]">
            <span className="flex items-center gap-[10px] flex-wrap shrink-0 cursor-pointer"
                  onClick={(e) => props.onExample(e, "Big Idea")}><ExampleIcon/><span
                className="text-[16px] leading-[130%] tracking-[-0.48px] mobile:text-[14px] mobile:leading-[130%] dark:text-white text-[#1d1d1d] mobile:tracking-[-0.42px]">Show example</span></span>
        </div>
    </div>);
}