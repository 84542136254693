import { apiService } from '../../utils/service';
import Rendertoast from '../../utils/rendertoast'
import { getAgencyWork } from './agency-onboarding-api';
import { getTalentWork } from './talent-onboardig-api';
//Api to get user data and store them in appropriate store for loading them in pages accoding to who is login
export const CheckLogin = (dispatch, talentStore, agencyStore, commonStore, talentProfileStore, agencyProfileStore) => {
    try {
        if (true || !commonStore.platform) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "user/login",
                headers: { "Content-Type": "application/json" },
                data: ""
            })
                .then((res) => {
                    if (res && res.data && res.data.status && (res.data.status === "OK" || res.data.status === "success")) {
                        if (res.data.data) {
                            if (res.data.data['platform_roles'] && res.data.data['platform_roles'][0]) {
                                dispatch({ type: 'common_details/insertValue', payload: { "id": res.data.data['platform_roles'][0], "name": "platform" } });
                                if (res.data.data['platform_roles'][0] === "talent") {
                                    dispatch({ type: 'common_details/insertValue', payload: { "id": res.data.data.talent['user_id'], "name": "talent_id" } });
                                    console.log("res",res)
                                    Object.entries(talentStore).forEach(key => {
                                        if (typeof key[1] !== "object" && res.data.data[key[0]]) {
                                            dispatch({ type: 'talent_details/insertValue', payload: { "id": res.data.data[key[0]], "name": key[0] } });
                                        } else if (typeof key[1] !== "object" && res.data.data.talent[key[0]]) {
                                            dispatch({ type: 'talent_details/insertValue', payload: { "id": res.data.data.talent[key[0]], "name": key[0] } });
                                        } else if (typeof key[1] !== "object" && !res.data.data.talent[key[0]] && !res.data.data[key[0]]) {
                                            dispatch({ type: 'talent_details/insertValue', payload: { "id": key[1], "name": key[0] } });
                                        } else {
                                            var val = objlooping(key, res.data.data, dispatch);
                                            dispatch({ type: 'talent_details/insertValue', payload: val });
                                        }
                                    });

                                    Object.entries(talentProfileStore).forEach(key => {
                                        if (typeof key[1] !== "object" && res.data.data[key[0]]) {
                                            dispatch({ type: 'talent_profile/insertValue', payload: { "id": res.data.data[key[0]], "name": key[0] } });
                                        } else if (typeof key[1] !== "object" && res.data.data.talent[key[0]]) {
                                            dispatch({ type: 'talent_profile/insertValue', payload: { "id": res.data.data.talent[key[0]], "name": key[0] } });
                                        } else if (typeof key[1] !== "object" && !res.data.data.talent[key[0]] && !res.data.data[key[0]]) {
                                            dispatch({ type: 'talent_profile/insertValue', payload: { "id": key[1], "name": key[0] } });
                                        } else {
                                            var val = objlooping(key, res.data.data, dispatch);
                                            dispatch({ type: 'talent_profile/insertValue', payload: val });
                                        }
                                    });
                                    getTalentWork(dispatch, res.data.data.talent['user_id']);
                                    return { status: true, platform: res.data.data['platform_roles'][0], talent_onboarding_completed_at: res.data.data.talent['talent_onboarding_completed_at'], talent_id: res.data.data.talent['user_id'] }
                                } else if (res.data.data['platform_roles'][0] === "agency") {
                                    if (res.data.data.agencies && res.data.data.agencies[0] && res.data.data.agencies[0].agency_id) {
                                        dispatch({ type: 'common_details/insertValue', payload: { "id": res.data.data.agencies[0].agency_id, "name": "agency_id" } });
                                        Object.entries(agencyStore).forEach(key => {
                                            if (typeof key[1] !== "object" && res.data.data.agencies[0][key[0]]) {
                                                dispatch({ type: 'agency_details/insertValue', payload: { "id": res.data.data.agencies[0][key[0]], "name": key[0] } });
                                            } else {
                                                var val = objlooping(key, res.data.data.agencies[0], dispatch);
                                                dispatch({ type: 'agency_details/insertValue', payload: val });
                                            }
                                        });

                                        Object.entries(agencyProfileStore).forEach(key => {
                                            if (typeof key[1] !== "object" && res.data.data.agencies[0][key[0]]) {
                                                dispatch({ type: 'agency_profile/insertValue', payload: { "id": res.data.data.agencies[0][key[0]], "name": key[0] } });
                                            } else {
                                                var val = objlooping(key, res.data.data.agencies[0], dispatch);
                                                dispatch({ type: 'agency_profile/insertValue', payload: val });
                                            }
                                        });
                                        getAgencyWork(dispatch, res.data.data.agencies[0].agency_id);
                                        return { status: true, platform: res.data.data['platform_roles'][0], agency_onboarding_completed_at: res.data.data.agencies[0]['agency_onboarding_completed_at'], agency_id: res.data.data.agencies[0].agency_id }
                                    } else {
                                        Rendertoast.showToast({ "type": "e", "msg": "Agency Id not defined" });
                                        return { status: false, platform: '' }
                                    }
                                }
                                return { status: true, platform: res.data.data['platform_roles'][0] }
                            } else {
                                Rendertoast.showToast({ "type": "e", "msg": "Platform not defined" });
                                return { status: false, platform: '' }
                            }
                        } else {
                            Rendertoast.showToast({ "type": "e", "msg": res && res.message ? res.message : "something went wrong" });
                            return { status: false, platform: '' }
                        }
                    } else {
                        Rendertoast.showToast({ "type": "e", "msg": res && res.message ? res.message : "something went wrong" });
                        return { status: false, platform: '' }
                    }

                })
                .catch((err) => {
                    console.log(err)
                    Rendertoast.showToast({ "type": "e", "msg": "something went wrong" });
                    return { status: false, platform: '' }
                })
        } else {
            console.log(commonStore)
            return new Promise(function(resolve, reject) {resolve({ status: true, platform: commonStore.platform });});
        }
    } catch (error) {
        console.log(error)
        Rendertoast.showToast({ "type": "e", "msg": "something went wrong" });
        return { status: false, platform: '' }
    }
}
//Api to get user data and store them in appropriate store for loading them in pages
export const resetData = (dispatch, talentStore, agencyStore, commonStore, talentProfileStore, agencyProfileStore, type) => {
    try {
        return apiService.get({
            url: process.env.REACT_APP_BASE_URL + "user/login",
            headers: { "Content-Type": "application/json" },
            data: ""
        })
            .then((res) => {
                if (res && res.data && res.data.status && (res.data.status === "OK" || res.data.status === "success")) {
                    if (res.data.data) {
                        if (res.data.data['platform_roles'] && res.data.data['platform_roles'][0]) {
                            dispatch({ type: 'common_details/insertValue', payload: { "id": res.data.data['platform_roles'][0], "name": "platform" } });
                            if (res.data.data['platform_roles'][0] === "talent") {
                                dispatch({ type: 'common_details/insertValue', payload: { "id": res.data.data.talent['user_id'], "name": "talent_id" } });
                                Object.entries(talentStore).forEach(key => {
                                    if (typeof key[1] !== "object" && res.data.data[key[0]]) {
                                        dispatch({ type: 'talent_details/insertValue', payload: { "id": res.data.data[key[0]], "name": key[0] } });
                                    } else if (typeof key[1] !== "object" && res.data.data.talent[key[0]]) {
                                        dispatch({ type: 'talent_details/insertValue', payload: { "id": res.data.data.talent[key[0]], "name": key[0] } });
                                    } else if (typeof key[1] !== "object" && !res.data.data.talent[key[0]] && !res.data.data[key[0]]) {
                                        dispatch({ type: 'talent_details/insertValue', payload: { "id": key[1], "name": key[0] } });
                                    } else {
                                        var val = objlooping(key, res.data.data, dispatch);
                                        dispatch({ type: 'talent_details/insertValue', payload: val });
                                    }
                                });

                                Object.entries(talentProfileStore).forEach(key => {
                                    if (typeof key[1] !== "object" && res.data.data[key[0]]) {
                                        dispatch({ type: 'talent_profile/insertValue', payload: { "id": res.data.data[key[0]], "name": key[0] } });
                                    } else if (typeof key[1] !== "object" && res.data.data.talent[key[0]]) {
                                        dispatch({ type: 'talent_profile/insertValue', payload: { "id": res.data.data.talent[key[0]], "name": key[0] } });
                                    } else if (typeof key[1] !== "object" && !res.data.data.talent[key[0]] && !res.data.data[key[0]]) {
                                        dispatch({ type: 'talent_profile/insertValue', payload: { "id": key[1], "name": key[0] } });
                                    } else {
                                        var val = objlooping(key, res.data.data, dispatch);
                                        dispatch({ type: 'talent_profile/insertValue', payload: val });
                                    }
                                });
                                dispatch({ type: 'talent_details/insertValue', payload: { "id": 0, "name": "onboarding_progress" } });
                                getTalentWork(dispatch, res.data.data.talent['user_id']);
                                return { status: true, platform: res.data.data['platform_roles'][0], talent_onboarding_completed_at: res.data.data.talent['talent_onboarding_completed_at'] }
                            } else if (res.data.data['platform_roles'][0] === "agency") {
                                if (res.data.data.agencies && res.data.data.agencies[0] && res.data.data.agencies[0].agency_id) {
                                    dispatch({ type: 'common_details/insertValue', payload: { "id": res.data.data.agencies[0].agency_id, "name": "agency_id" } });
                                    Object.entries(agencyStore).forEach(key => {
                                        if (typeof key[1] !== "object" && res.data.data.agencies[0][key[0]]) {
                                            dispatch({ type: 'agency_details/insertValue', payload: { "id": res.data.data.agencies[0][key[0]], "name": key[0] } });
                                        } else {
                                            var val = objlooping(key, res.data.data.agencies[0], dispatch);
                                            dispatch({ type: 'agency_details/insertValue', payload: val });
                                        }
                                    });

                                    Object.entries(agencyProfileStore).forEach(key => {
                                        if (typeof key[1] !== "object" && res.data.data.agencies[0][key[0]]) {
                                            dispatch({ type: 'agency_profile/insertValue', payload: { "id": res.data.data.agencies[0][key[0]], "name": key[0] } });
                                        } else {
                                            var val = objlooping(key, res.data.data.agencies[0], dispatch);
                                            dispatch({ type: 'agency_profile/insertValue', payload: val });
                                        }
                                    });
                                    dispatch({ type: 'agency_details/insertValue', payload: { "id": 0, "name": "onboarding_progress" } });
                                    getAgencyWork(dispatch, res.data.data.agencies[0].agency_id);
                                    return { status: true, platform: res.data.data['platform_roles'][0], agency_onboarding_completed_at: res.data.data.agencies[0]['agency_onboarding_completed_at'] }
                                } else {
                                    Rendertoast.showToast({ "type": "e", "msg": "Agency Id not defined" });
                                    return { status: false, platform: '' }
                                }
                            }
                            return { status: true, platform: res.data.data['platform_roles'][0] }
                        } else {
                            Rendertoast.showToast({ "type": "e", "msg": "Platform not defined" });
                            return { status: false, platform: '' }
                        }
                    } else {
                        Rendertoast.showToast({ "type": "e", "msg": res && res.message ? res.message : "something went wrong" });
                        return { status: false, platform: '' }
                    }
                } else {
                    Rendertoast.showToast({ "type": "e", "msg": res && res.message ? res.message : "something went wrong" });
                    return { status: false, platform: '' }
                }

            })
            .catch((err) => {
                console.log(err)
                Rendertoast.showToast({ "type": "e", "msg": "something went wrong" });
                return { status: false, platform: '' }
            })
    } catch (error) {
        console.log(error)
        Rendertoast.showToast({ "type": "e", "msg": "something went wrong" });
        return { status: false, platform: '' }
    }
}
//function to loop through the data and save them in store
function objlooping(key, data, dispatch) {
    var val = {}
    Object.keys(key[1]).forEach(_key => {
        if (data[_key]) {
            val[_key] = data[_key];
        } else if (data.talent && data.talent[_key]) {
            val[_key] = data.talent[_key];
        } else {
            val[_key] = key[1][_key]
        }
    });
    return { "id": val, "name": key[0] }
}
//Api to get diversity drop-down options
export const GetDiversity = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.diversity.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "diversity/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "diversity" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {

    }
}
//Api to get location drop-down options
export const GetLocation = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.location.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "location/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "location" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {

    }
}

export const GetTone =  (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.tone.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "tone/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        console.log("GetTone",res.data.data)
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "tone" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {

    }
}

//Api to get company drop-down options
export const GetCompanies = (inputValue) => {
    try {
        return apiService.get({
            url: process.env.REACT_APP_BASE_URL + "company/get?contain=" + inputValue,
            data: "",
        })
            .then((res) => {
                if (res && res.data && res.data.data) {

                    return res.data.data;
                } else {
                    return false;
                }
            })
            .catch((err) => {
                return false
            })
    } catch (error) {

    }
}

//Api to get company drop-down options
export const GetCompanyByID = (id) => {
    try {
        return apiService.get({
            url: process.env.REACT_APP_BASE_URL + "company/get/id?id=" + id,
            data: "",
        })
            .then((res) => {
                if (res && res.data && res.data.data) {
                    return res.data.data;
                } else {
                    return false;
                }
            })
            .catch((err) => {
                return false
            })
    } catch (error) {

    }
}

//Api to upload the image link
export const UploadImageLink = (inputValue) => {
    try {
        return apiService.get({
            url: process.env.REACT_APP_BASE_URL + "video/import?u=" + inputValue,
            data: "",
        })
            .then((res) => {
                if (res && res.data && res.data.data) {

                    return res.data.data;
                } else {
                    return false;
                }
            })
            .catch((err) => {
                return false
            })
    } catch (error) {

    }
}
//Api to get the graph data
export const GetGraph = () => {
    try {
        return apiService.get({
            url: process.env.REACT_APP_BASE_URL + "graph/get",
            data: "",
        })
            .then((res) => {
                if (res && res.data) {

                    return res.data;
                } else {
                    return false;
                }
            })
            .catch((err) => {
                return false
            })
    } catch (error) {

    }
}
//local json api to get profile data
export const GetProfileViewDetails = (id) => {
    try {
        return apiService.get({
            url: "./" + id + ".json",
            data: "",
        })
            .then((res) => {
                if (res && res.data) {

                    return res.data;
                } else {
                    return false;
                }
            })
            .catch((err) => {
                return false
            })
    } catch (error) {

    }
}
//Api to get brands drop-down options
export const GetBrands = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.brands.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "company/get?contain=",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        const options = res.data.data.map(i => ({
                            label: i.label,
                            value: i.company_id,
                            company_id: i.company_id,
                            logo: i.logo
                        }));
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": options, "name": "brands" } });
                        return res.data.data;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {

    }
}
//Api to get project categories drop-down options
export const GetProjectCategories = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.project_category.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "category/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "project_category" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {

    }
}
//Api to get skills drop-down options
export const GetSkills = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.skils.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "skill/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "skils" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {

    }
}
//Api to get industry drop-down options
export const GetIndustry = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.industry.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "industry/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "industry" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {

    }
}
//Api to get discipline drop-down options
export const GetDiscipline = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.discipline.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "discipline/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "discipline" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {

    }
}
//Api to get role drop-down options
export const GetRole = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.role.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "role/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "role" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {

    }
}
//Api to set brand in drop-down options
export const AddCompany = (val) => {
    try {
            return apiService.post({
                url: process.env.REACT_APP_BASE_URL + "company/add",
                data: {
                    "name": val
                  },
            })
                .then((res) => {
                    if (res && res.data && res.data.status && res.data.status === "ok") {
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        
    } catch (error) {

    }
}