import { useEffect, useRef, useState } from 'react';
import FormValidationIcon from '../../../utils/form-validation-icon';
import { useDispatch, useSelector } from 'react-redux';
import { SelectFormFieldError } from '../../../utils/error-message';
import { Dropdown } from '../common/icons/dropdown';
import common from '../../../utils/common';
import { ProCancel } from '../common/icons/skills-cancel';

export const IndustryPopup = (props) => {
  const projectSetupStore = useSelector(state => state.projectSetupStore);
  const selectStore = useSelector(state => state.selectStore);
  const [industrydrop, setIndustryDrop] = useState([]);
  const [industry, setIndustry] = useState([]);
  const industryParentRef = useRef(null);
  const industryParentRefDrop = useRef(null);
  const [indIsValid, setIndIsvalid] = useState(true);
  const [indErrorMessage, setIndErrorMsg] = useState("");
  const [isOpen, setisOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=> {
        if(!props.open){
            const popup = document.querySelector(".industry-popup")
            if(popup){
                popup.classList.add("slide-right")
            }
            setTimeout(() => {
                setisOpen(false);
            },100)
        }else{
            setisOpen(true)
        }
    },[props.open])
   

  useEffect(() => {
    var _industries = groupBy(selectStore.industry, 'label');
    var valindustry = [];
    Object.entries(_industries).forEach((v) => {
        var _val = {};
        _val.label = v[0];
        _val.sublist = [];
        v[1].forEach((roles) => {
          var _industries = JSON.parse(JSON.stringify(roles));
          _industries["isChecked"] = false;
          _val.sublist.push(_industries)
        })
        valindustry.push(_val)
    })
    if (projectSetupStore.page3.industry && Array.isArray(projectSetupStore.page3.industry) && projectSetupStore.page3.industry.length) {
      var _industry = []; 
      projectSetupStore.page3.industry.forEach(_val => {
          var index = selectStore.industry.findIndex(x => x.industry_id === _val.industry_id);
          if (index !== -1) {
          var _value = selectStore.industry[index];
          _industry.push(_value)
          valindustry.forEach((_inds, _id) => {
            _inds.sublist.forEach((inds, id) => {
              if (inds.industry_id === _val.industry_id) {
                valindustry[_id].sublist[id]["isChecked"] = true;
                return;
              }
            })
          })
        }
      })
      setIndustry(_industry);
    }
    if (Array.isArray(valindustry) && valindustry.length !== 0) { setIndustryDrop(valindustry); }
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [selectStore.industry]);

  useEffect(() => {
    window.addEventListener("scroll", resetDropdownPosition);
    return () => {
      window.removeEventListener("scroll", resetDropdownPosition);
  };
  }, [])

  /**Recalculate Position on scroll*/
  function resetDropdownPosition() {
    var indusParent = industryParentRef.current;
    if (indusParent) {
      indusParent = indusParent.getBoundingClientRect();
      if (document.querySelector('.inds-primary.demoFixed') && indusParent.top) {
        document.querySelector('.inds-primary.demoFixed').style.top = indusParent.top + 30 + 'px';
      }
    }
  }


  function handleIndustrySelectOnchangeCheckbox(val, checkval, i, _i, e) {
    if(e) {
      e.preventDefault()
      e.stopPropagation();
    }
    var _val = JSON.parse(JSON.stringify(industrydrop));
    if (_val[i].sublist[_i]["isChecked"]) {
    _val[i].sublist[_i]["isChecked"] = false;
    } else {
    _val[i].sublist[_i]["isChecked"] = true;
    }
    setIndustryDrop(_val);
    var _primaryindustry = JSON.parse(JSON.stringify(industry));
    var _index = industry.map((x) => {return x.industry_id; }).indexOf(checkval.industry_id)
    if (_index === -1) { _primaryindustry.push(checkval); } else { _primaryindustry.splice(_index, 1) }
    setIndustry(_primaryindustry)
    IndustrySelectValidation(_primaryindustry, 1, 'Industries', 'inds')
    // onChangeData(_primaryindustry);
    // if(_primaryindustry && _primaryindustry.length >=3){
    //   disableallLevel()
    // }
  }

  function handleIndustrySelectOnClose(_value) {
      var _val = JSON.parse(JSON.stringify(industrydrop));
      _val.forEach((_inds, _id) => {
          _inds.sublist.forEach((inds, id) => {
              // var value = inds.sublabel ? inds.sublabel : inds.label
              if (inds.industry_id === _value.industry_id) {
              _val[_id].sublist[id]["isChecked"] = false;
            // _val[_id].sublist[id]["isPrimary"] = false;
              return;
              }
          })
      })
      setIndustryDrop(_val);
      var _primaryindustry = JSON.parse(JSON.stringify(industry));
      var _index = _primaryindustry.map((x) => {return x.industry_id; }).indexOf(_value.industry_id)
      _primaryindustry.splice(_index, 1);
      setIndustry(_primaryindustry);
      IndustrySelectValidation(_primaryindustry, 1, 'Industries', 'inds')
      onChangeData(_primaryindustry)
  }

  const enableFirstLevel = e => {
    if(e) {
      e.stopPropagation();
    }
    common.visibleAllParent('.inds-primary');
    const mark = document.querySelector(".primary");
    if (mark) {
    mark.classList.remove("move-label");
    }
    if (document.querySelectorAll('.inds-primary .demoFixedSubLevel')) {
    [...document.querySelectorAll('.inds-primary .demoFixedSubLevel')].forEach(div => {
        if (div.style.display !== 'none') {
        div.style.display = 'none'
        }
    });
    }
    const parent = industryParentRef.current.getBoundingClientRect();
    document.querySelector('.inds-primary.demoFixed').style.top = parent.top + 30 + 'px';
    document.querySelector('.inds-primary.demoFixed').style.width = parent.width + 'px';
    document.querySelector('.react-select__menu.inds-primary.demoFixed').style.display = 'block';
    document.querySelector('.inds-primary-multi-select-backdrop').style.display = 'block';
  }

  /* 2nd LEVEL OF INDUSTRY DROPDOWN */
  const enableSecondLevel = (e, i) => {
    var currentEle = document.querySelector('.inds-primary .demoFixedSubLevel.child_' + i)
    if(e.currentTarget.classList.length && e.currentTarget.classList.contains('active')) {
      e.currentTarget.classList.remove('active')
      currentEle.style.display = 'none';
      common.visibleAllParent('.inds-primary');
      return;
    }
    var elems = document.querySelector(".active");
    if (elems !== null) {
      elems.classList.remove("active");
    }
    e.currentTarget.className += " active";
    common.onlyActiveElementVisible('.inds-primary');
    const parent = industryParentRef.current.getBoundingClientRect();
    if(currentEle) {currentEle.style.width = parent.width + 'px';}
    industrydrop.forEach((_val, _i) => {
      if(document.querySelector('.react-select__menu.inds-primary .demoFixedSubLevel.child_' + i)) {
        if (_i === i) {
          document.querySelector('.react-select__menu.inds-primary .demoFixedSubLevel.child_' + i).style.display = 'block';
        } else {
          document.querySelector('.react-select__menu.inds-primary .demoFixedSubLevel.child_' + _i).style.display = 'none';
        }
      } 
    })
  }

  /* DISABLE INDUSTRY DROPDOWN LEVEL */
  const disableallLevel = () => {
      common.visibleAllParent('.inds-primary');
      if (document.getElementsByName('primaryindustry')) {
        document.getElementsByName('primaryindustry').forEach(o => {
          if (o && o.value !== "") {
          o.value = ""
          }
        })
      }
      const mark = document.querySelector(".primary");
      if (mark) {
      mark.classList.add("move-label");
      }
      document.querySelector('.inds-primary.demoFixed').style.display = 'none';
      if (!industry.length >= 3) {
      industrydrop.forEach((_val, _i) => {
          document.querySelector('.react-select__menu.inds-primary .demoFixedSubLevel.child_' + _i).style.display = 'none'
      })
      }
      document.querySelector('.inds-primary-multi-select-backdrop').style.display = 'none';
      const activeElement = document.querySelectorAll('.inds-primary.demoFixed .parent.active');
      if(activeElement && activeElement.length) {
      activeElement.forEach((act, _i) => {
          if(act.classList && act.classList.length) {act.classList.remove('active')}
      })
      }
  }

  function resizeListener() { disableallLevel();  }

  function onChangeData(_Industry) {
    var industries = []; 
    var _data = JSON.parse(JSON.stringify(projectSetupStore.page3));
    selectStore.industry.forEach(_val => {
      var _index = industry.map((x) => {return x.industry_id; }).indexOf(_val.industry_id)
      if (_index !== -1) {
        industries.push({ "industry_id": _val.industry_id })
      }
    })
    _data["industry"] = industries;
    dispatch({ type: "project_setup/insertValue", payload: {"id":_data,"name":"page3"} });
    // props.saveData({ "industry": industries })
  }

  function IndustrySelectValidation(value, min, fieldname, type, max) {
    let message;
    switch (type) {
    case 'inds':
        if (!value.length) {
        setIndIsvalid(false)
        message = common.formValidation(fieldname, 'required')
        setIndErrorMsg(message)
        } else if (value.length < min) {
        setIndIsvalid(false)
        message = common.formValidation(fieldname, 'minValue', min)
        setIndErrorMsg(message)
        } else if (value.length > max) {
        setIndIsvalid(false)
        message = common.formValidation(fieldname, 'maxValue', min, max)
        setIndErrorMsg(message)
        } else {
        setIndIsvalid(true);
        setIndErrorMsg()
        }
        break;
    
    default:
        break;
    }
}
  function groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) { memo[x[property]] = []; }
      memo[x[property]].push(x);
      return memo;
    }, {});
  };
  function submitHandler() {
    try {
    if(industry.length >=1 ){
        var _data = JSON.parse(JSON.stringify(projectSetupStore.page3));
        var _industry = [];
        var api_industry = [];
        selectStore.industry.forEach(_val => {
            var _index = industry.map((x) => {return x.industry_id; }).indexOf(_val.industry_id)
            if (_index !== -1) {
              _industry.push({ "industry_id": _val.industry_id })
              api_industry.push(_val.industry_id)
            } 
        })
        _data["industry"]=_industry
        dispatch({ type: 'project_setup/insertValue', payload: { "id":_data, "name": "page3" } });
        props.saveData({ "industries":api_industry});
        props.close();
    } else {
        validateIndustryForm()
    }
    } catch (err) { console.log(err); }
  }
  function validateIndustryForm() {
    IndustrySelectValidation(industry, 1, 'Industries', 'inds')
  }

  if(isOpen){
  return (
    <div className="relative z-10 mobile:w-full project-setup-industry" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 overlay-background bg-opacity-75 backdrop-blur-sm transition-opacity mobile:hidden"></div>
      <div className={`${props.open ? "slide-left" : ""} industry-popup fixed inset-0 z-10 overflow-y-auto h-full mobile:relative mobile:h-auto mobile:w-full`}>
        <div className='absolute h-full p-[32px] top-0 right-0 bg-[#ffffff] w-[485px] shadow-md mobile:relative mobile:shadow-none mobile:w-full mobile:p-0 mobile:h-auto dark:bg-[#0E0E0E]'>
          <div className="w-full flex flex-wrap">
            <div className='flex mobile:hidden'>
              <div className='flex flex-wrap gap-1 text-left'>
                <p className='text-[#07091b] text-[20px] leading-[130%] tracking-[-0.6px] dark:text-[#fff]'>02. Industry</p>
                <p className='text-[#a3a3a3] text-[14px] leading-[130%] tracking-[-0.42px] dark:text-[#808080]'>What industry experience are most relevant to your project?</p>
              </div>
              <div className="absolute z-10 right-0 xs:mt-[-10px] pr-7 sm:block mt-[4px]">
                <button type="button" onClick={props.close} className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none pr-[7px]"><ProCancel/></button>
              </div>
            </div>
            <div className='w-full flex flex-col mt-[24px] pt-[24px] box-border border-t border-solid border-[#d9d9d9] dark:border-[#A3A3A3]'>
                <div className="field-group w-[100%] mt-[40px] custom-select" tabIndex="0">
                    <label className="text-[14px] top-0 text-[#a3a3a3] move-label primary z-0 duration-300 origin-0 mt-[-26px] select-label">Industries</label>
                    <input ref={industryParentRef} readOnly type="text" name="primaryindustry" onFocus={enableFirstLevel} className="input-fld !relative !z-1" />
                    <span className="absolute right-[4px] bottom-[8px]"><Dropdown/></span>
                    <FormValidationIcon isArray={true} twolevelselect={true} minlen={1} getval={industry}></FormValidationIcon>
                    <div className={'inds-primary-multi-select-backdrop multi-select-backdrop '} onClick={disableallLevel}></div>
                </div>
                <div className="multi-select react-select__menu inds-primary demoFixed" ref={industryParentRefDrop} >
                    <div className="react-select__menu-list react-select__menu-list--is-multi">
                        {industrydrop.map((com_id, i) =>
                        <div className="react-select__option" key={i}  >
                            <li onClick={(e) => { enableSecondLevel(e, i) }} className={com_id.checked ? "parent active" : "parent"}><span className="txt">
                            {com_id.label}
                            <span className="expand">
                                <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.23654 4.79183L1.06988 8.9585L0.0976562 7.98627L3.2921 4.79183L0.0976559 1.59738L1.06988 0.625163L5.23654 4.79183Z" fill="#0E0E0E"/></svg>
                            </span></span>
                            </li>
                            <ul className={"child demoFixedSubLevel child_" + i}>
                            {com_id["sublist"].map((_v, _i) => 
                            <li key={_i} onClick={(e) => { handleIndustrySelectOnchangeCheckbox(com_id, _v, i, _i, e) }}>
                              <span className="txt" key={_i}> {_v.sublabel ? _v.sublabel : _v.label}
                                <label className="control control--checkbox">
                                  <input type="checkbox" checked={_v.isChecked} onChange={() => { handleIndustrySelectOnchangeCheckbox(com_id, _v, i, _i) }} />
                                  <div className="control__indicator"></div>
                                </label>
                              </span>
                            </li>)}
                            </ul>
                        </div>
                        )}
                    </div>
                    </div>
                <SelectFormFieldError isValid={indIsValid} message={indErrorMessage}></SelectFormFieldError>
                <div className={`${industry.length ? '!mt-0 !mb-[24px] custom-multi-select' : ''}`}>
                    {JSON.parse(JSON.stringify(industry)).map((val, key) => (
                    <div className="react-select__control new inline-block " key={key}>
                        <div className="react-select__value-container">
                        <div className="react-select__multi-value new">
                            {/* <span className='w-[16.14px] h-[15.46px] flex justify-center items-center bg-[#1D1D1D] text-[#FFF] m-auto rounded-[24px] border-[1px] border-[#1D1D1D] dark:border-[#FFFFFF] dark:bg-[#FFFFFF] dark:text-[#0E0E0E] font-medium text-[11px]'>{key + 1}</span> */}
                            <div className="react-select__multi-value__label css-wsp0cs-MultiValueGeneric">{val.sublabel ? val.sublabel : val.label}</div>
                            <div role="button" className="react-select__multi-value__remove" onClick={() => handleIndustrySelectOnClose(val)}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.72184 15.2738C4.82196 15.3739 4.938 15.4399 5.06997 15.4717C5.20648 15.5036 5.33845 15.5036 5.46587 15.4717C5.59784 15.4399 5.7116 15.3762 5.80717 15.2806L10.0051 11.0827L14.1962 15.2806C14.2918 15.3762 14.4056 15.4399 14.5375 15.4717C14.6695 15.5036 14.8015 15.5036 14.9334 15.4717C15.0654 15.4399 15.1815 15.3739 15.2816 15.2738C15.3771 15.1782 15.4408 15.0644 15.4727 14.9325C15.5046 14.8005 15.5046 14.6708 15.4727 14.5434C15.4408 14.4114 15.3771 14.2954 15.2816 14.1953L11.0904 9.99733L15.2816 5.8062C15.3771 5.70609 15.4408 5.59232 15.4727 5.4649C15.5091 5.33294 15.5091 5.20097 15.4727 5.069C15.4408 4.93703 15.3771 4.82327 15.2816 4.7277C15.1769 4.62759 15.0586 4.5616 14.9266 4.52975C14.7992 4.4979 14.6695 4.4979 14.5375 4.52975C14.4056 4.55705 14.2918 4.62076 14.1962 4.72088L10.0051 8.91883L5.80717 4.72088C5.7116 4.62531 5.59784 4.5616 5.46587 4.52975C5.3339 4.4979 5.20193 4.4979 5.06997 4.52975C4.938 4.5616 4.82196 4.62759 4.72184 4.7277C4.62628 4.82327 4.56257 4.93703 4.53072 5.069C4.49886 5.20097 4.49886 5.33294 4.53072 5.4649C4.56257 5.59687 4.62628 5.70836 4.72184 5.79937L8.91297 9.99733L4.72184 14.2021C4.62628 14.2931 4.5603 14.4046 4.52389 14.5366C4.49204 14.6685 4.49204 14.8005 4.52389 14.9325C4.55575 15.0644 4.62173 15.1782 4.72184 15.2738Z" className="dark:fill-white" fill="#1D1D1D" />
                            </svg>
                            </div></div>
                        </div></div>
                    ))}
                </div>
            </div>
          </div>
          <div className='absolute bottom-[32px] mobile:mt-[24px] mobile:relative mobile:bottom-auto'>
            <button disabled={!industry.length} onClick={submitHandler} className='bg-c-black disabled:opacity-[0.3] text-white w-[421px] h-[45px] font-bold mobile:text-[12px] mobile:tracking-[-0.36px] text-[16px] leading-[130%] tracking-[-0.48px] rounded-full px-[24px] py-[12px] mobile:w-full dark:!bg-[#fff] dark:text-[#0E0E0E]'>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}
}