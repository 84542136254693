import logo from "../../../assets/img/initial-logo.svg";
import darklogo from "../../../assets/img/dark-initial-logo.svg";
import button from "../../../assets/img/initial-btn-2.svg";
import darkbutton from "../../../assets/img/dark-initial-btn-2.svg";
import buttonSym from "../../../assets/img/initial-btn-sym.svg";
import background from "../../../assets/img/Isolation_Mode.svg";
import mlogo from "../../../assets/img/m-logo-light.png";
import darkmlogo from "../../../assets/img/m-logo-dark.png";
import { useNavigate } from "react-router-dom";
import { AddNewProject, UpdateProjectEstimation } from "../../service/project-setup-api";
import { useDispatch, useSelector } from "react-redux";
import common from "../../../utils/common";
import { useEffect } from "react";
import BrandFetch from "../brand-fetch";
import {signOut} from "supertokens-web-js/recipe/session";
export const InitialPage = (props) => {
    const commonStore = useSelector(state => state.commonStore);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        if (commonStore.theme) {
            if (commonStore.theme === 'light') {
                document.body.classList.remove('dark')
            } else {
                document.body.classList.add(commonStore.theme);
            }
        }
    }, [commonStore.theme])

    function createNewProject() {
        var dummayProjectName = Date.now() + ((Math.random() * 100000).toFixed());
        var req = { input: { name: dummayProjectName } }
        AddNewProject(req).then((res) => {
            if (res && res.status === 200 && res.data && res.data.project_id) {
                dispatch({ type: 'project_setup/insertValue', payload: { "id": res.data.project_id, "name": "project_id" } });
                /**CALL PROJECT ESTIMATION API TO GET DUMMY VALUE */
                UpdateProjectEstimation(res.data.project_id);
                navigate(`./project-setup?${common.encryptdecrypt("encrypt", res.data.project_id)}`)
            }
        }
        )
    }

    return (
        <div className="main-container project_initial">
            <div className="setup-container w-full overflow-auto mobile:overflow-hidden tablet:overflow-hidden min-h-[720px]">

                <div className="max-w-[1920px] w-full mx-auto h-full relative bg-white dark:bg-[#1D1D1D] flex-row flex mobile:flex-col tablet:flex-col tablet:justify-between mobile:gap-[19px]">

                    <div className="w-[46%] [@media(min-width:1920px)]:w-[676px] tablet:w-full h-full p-[88px] mobile:py-[64px] mobile:w-full mobile:px-[24px] mobile:h-auto">

                        <div className="relative w-full h-full flex items-center mobile:h-auto mobile:items-start tablet:h-auto tablet:items-start flex-wrap mobile:gap-[48px] tablet:gap-[48px]">

                            <div className="w-full absolute top-0 left-0 mobile:relative mobile:top-auto mobile:left-auto mobile:float-left mobile:flex mobile:justify-center tablet:relative tablet:top-auto tablet:left-auto tablet:float-left tablet:flex tablet:justify-center">

                                <img className="dark:hidden mobile:max-w-[138px]" alt="logo" src={logo} />
                                <img className="hidden dark:block mobile:max-w-[138px]" alt="logo" src={darklogo} />

                            </div>

                            <div className="flex-col justify-center items-start gap-12 flex self-center w-full">
                                <div className="flex-col justify-center items-start gap-6 w-[500px] flex mobile:gap-[24px] tablet:gap[24px] tablet:w-full mobile:w-full">
                                    <div className="w-full text-stone-950 text-[63px] font-semibold leading-[91.5%] tracking-[-1.89px] mobile:text-[48px] mobile:text-center tablet:text-[48px] tablet:text-center dark:text-[#fff]">Weaponize creativity</div>
                                    <div className="w-full text-neutral-800 text-[20px] font-normal leading-[130%] tablet:text-[16px] tablet:text-center mobile:text-[16px] mobile:text-center dark:text-[#A3A3A3]">This is mission control. The place where we get all the right inputs to so we can align the creative superstars to crush up your business problem. </div>
                                </div>
                                <div className="w-full relative mobile:flex mobile:justify-center tablet:justify-center tablet:flex">
                                    <button className="w-[229.3px] h-[76.02px] " onClick={createNewProject} >
                                        <div className="w-full h-full justify-center items-center gap-[7.88px] flex">
                                            <div className="absolute top-[0] left-[0] mobile:left-[calc(50%-115px)] tablet:left-[calc(50%-115px)]"><img alt="icon-bg" src={button} className="dark:hidden" /><img alt="icon-bg" src={darkbutton} className="hidden dark:block" /></div>
                                            <div className="w-[9.14px] h-[22.05px] relative">
                                                <img alt="icon" src={buttonSym} />
                                            </div>
                                            <div className="text-center text-black text-[16.5px] font-bold dark:text-[#fff]">Launch now</div>
                                        </div>
                                        <div className="w-full h-full justify-center items-center gap-[7.88px] flex">
                                            <div className="absolute top-[0] left-[0] mobile:left-[calc(50%-115px)] tablet:left-[calc(50%-115px)]"><img alt="icon-bg" src={button} className="dark:hidden" /><img alt="icon-bg" src={darkbutton} className="hidden dark:block" /></div>

                                            <div onClick={async () => {
                                                    await signOut();
                                                    navigate("/");
                                           }} className="text-center  underline text-[16.5px]  dark:text-[#fff]">Logout</div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/*<BrandFetch/>*/}

                    </div>


                    <div className="w-[54%] [@media(min-width:1920px)]:flex-grow h-full mobile:w-full mobile:h-auto tablet:w-full tablet:h-auto" >

                        <div className="relative float-left w-full h-full  bg-cover  flex items-center mobile:h-[400px] mobile:items-start tablet:h-[400px] tablet:items-start !dark:bg-none" style = {{ background: `url(${commonStore.theme === 'light' ? background : ''}) no-repeat top right`}} >
                            <div className="pl-[10%] pr-[22%] mobile:px-[20%] tablet:px-[30%] mobile:m-auto tablet:m-auto">
                                <span className="">

                                    <img  alt="mlogo" src={mlogo} className="absolute top-64 z-40 dark:hidden mobile:dark:hidden tablet:dark:hidden"/>
                                    <img alt="mlogo" src={darkmlogo} className=" dark:block hidden mobile:dark:block tablet:dark:block"/>
                                    <svg className="absolute top-0 right-0 z-0 h-full tablet:hidden mobile:hidden" viewBox="0 0 790 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.02" d="M625.867 1296.02C240.387 1250.63 -35.0157 904.024 10.7709 521.809C56.5454 139.618 406.174 -133.414 791.655 -88.0313C1177.15 -42.6489 1452.55 303.986 1406.76 686.177C1360.98 1068.38 1011.36 1341.41 625.867 1296.02Z" fill="#DFDFDF"/>
                                    <path opacity="0.04" d="M628.146 1294.64C244.653 1249.49 -29.3234 904.655 16.2194 524.421C61.7622 144.2 409.575 -127.429 793.08 -82.2766C1176.59 -37.1238 1450.56 307.722 1405.02 687.943C1359.48 1068.18 1011.65 1339.79 628.146 1294.64Z" fill="#DFDEE0"/>
                                    <path opacity="0.06" d="M630.426 1293.26C248.908 1248.34 -23.6423 905.295 21.6689 527.032C66.9679 148.781 412.988 -121.447 794.495 -76.5239C1176.01 -31.6007 1448.58 311.456 1403.26 689.707C1357.95 1067.97 1011.94 1338.19 630.414 1293.26H630.426Z" fill="#DFDEE0"/>
                                    <path opacity="0.08" d="M632.694 1291.87C253.174 1247.19 -17.9615 905.935 27.1059 529.642C72.1855 153.361 416.402 -115.465 795.921 -70.7834C1175.45 -26.1019 1446.59 315.179 1401.52 691.46C1356.44 1067.75 1012.24 1336.57 632.694 1291.87Z" fill="#DEDDE1"/>
                                    <path opacity="0.1" d="M634.972 1290.5C257.44 1246.05 -12.2815 906.578 32.5542 532.255C77.39 157.931 419.802 -109.48 797.347 -65.0285C1174.89 -20.5767 1444.61 318.903 1399.78 693.226C1354.94 1067.55 1012.53 1334.95 634.972 1290.5Z" fill="#DEDDE1"/>
                                    <path opacity="0.12" d="M637.252 1289.12C261.707 1244.9 -6.60064 907.216 38.0035 534.851C82.6077 162.51 423.216 -103.5 798.761 -59.2777C1174.32 -15.0556 1442.63 322.635 1398.02 694.989C1353.42 1067.34 1012.81 1333.34 637.252 1289.12Z" fill="#DEDCE2"/>
                                    <path opacity="0.14" d="M639.532 1287.74C265.962 1243.75 -0.907215 907.858 43.4531 537.463C87.8135 167.092 426.63 -97.5038 800.188 -53.5234C1173.77 -9.54293 1440.64 326.371 1396.28 696.755C1351.92 1067.14 1013.1 1331.73 639.532 1287.74Z" fill="#DEDCE2"/>
                                    <path opacity="0.16" d="M641.811 1286.35C270.228 1242.6 4.77271 908.487 48.9014 540.073C93.0302 171.672 430.03 -91.5215 801.613 -47.7707C1173.21 -4.01985 1438.66 330.106 1394.54 698.507C1350.41 1066.92 1013.39 1330.1 641.811 1286.35Z" fill="#DEDBE2"/>
                                    <path opacity="0.18" d="M644.079 1284.97C274.495 1241.45 10.4536 909.127 54.3507 542.684C98.2357 176.241 433.443 -85.5389 803.04 -42.0177C1172.65 1.50343 1436.69 333.84 1392.79 700.272C1348.9 1066.71 1013.69 1328.49 644.091 1284.97H644.079Z" fill="#DEDBE3"/>
                                    <path opacity="0.2" d="M646.359 1283.59C278.762 1240.31 16.135 909.766 59.8005 545.292C103.454 180.819 436.858 -79.5587 804.455 -36.2792C1172.08 7.00026 1434.69 337.561 1391.04 702.022C1347.38 1066.49 1013.98 1326.86 646.359 1283.58V1283.59Z" fill="#DDDAE3"/>
                                    <path opacity="0.22" d="M648.638 1282.21C283.028 1239.17 21.8154 910.41 65.2371 547.895C108.659 185.403 440.27 -73.5724 805.881 -30.5226C1171.52 12.5273 1432.72 341.299 1389.29 703.79C1345.87 1066.29 1014.27 1325.26 648.638 1282.21Z" fill="#DDDAE4"/>
                                    <path opacity="0.24" d="M650.918 1280.83C287.282 1238.02 27.5078 911.048 70.6857 550.503C113.876 189.982 443.671 -67.5802 807.306 -24.7721C1170.95 18.0481 1430.74 345.019 1387.55 705.552C1344.36 1066.09 1014.57 1323.65 650.918 1280.83Z" fill="#DDDAE4"/>
                                    <path opacity="0.25" d="M653.197 1279.45C291.549 1236.87 33.1887 911.688 76.135 553.113C119.081 194.55 447.085 -61.5977 808.721 -19.0192C1170.38 23.5593 1428.74 348.753 1385.8 707.317C1342.85 1065.88 1014.85 1322.03 653.185 1279.45H653.197Z" fill="#DDD9E5"/>
                                    <path opacity="0.27" d="M655.464 1278.07C295.815 1235.72 38.8687 912.317 81.5833 555.724C124.298 199.13 450.497 -55.6153 810.146 -13.2665C1169.82 29.0824 1426.77 352.488 1384.05 709.081C1341.34 1065.69 1015.14 1320.42 655.464 1278.07Z" fill="#DDD9E5"/>
                                    <path opacity="0.29" d="M657.745 1276.68C300.07 1234.57 44.5502 912.957 87.0333 558.322C129.504 203.711 453.899 -49.633 811.574 -7.51376C1169.26 34.6055 1424.79 356.222 1382.31 710.846C1339.83 1065.48 1015.43 1318.81 657.745 1276.69V1276.68Z" fill="#DDD8E5"/>
                                    <path opacity="0.31" d="M660.023 1275.3C304.336 1233.43 50.2302 913.598 92.4816 560.944C134.721 208.291 457.312 -43.6508 812.999 -1.77326C1168.7 40.1042 1422.8 359.944 1380.57 712.598C1338.33 1065.26 1015.72 1317.18 660.023 1275.3Z" fill="#DCD8E6"/>
                                    <path opacity="0.33" d="M662.304 1273.93C308.603 1232.28 55.9234 914.24 97.931 563.557C139.926 212.873 460.725 -37.6665 814.414 3.98142C1168.13 45.6293 1420.82 363.681 1378.81 714.364C1336.8 1065.06 1016 1315.58 662.291 1273.93H662.304Z" fill="#DCD7E6"/>
                                    <path opacity="0.35" d="M664.583 1272.54C312.87 1231.13 61.6043 914.868 103.38 566.155C145.144 217.442 464.127 -31.6721 815.84 9.734C1167.57 51.1522 1418.84 367.415 1377.07 716.116C1335.29 1064.84 1016.31 1313.94 664.571 1272.54H664.583Z" fill="#DCD7E7"/>
                                    <path opacity="0.37" d="M666.85 1271.16C317.123 1229.98 67.2839 915.519 108.816 568.763C150.348 222.02 467.539 -25.6917 817.265 15.4848C1167 56.6613 1416.85 371.135 1375.32 717.879C1333.79 1064.63 1016.6 1312.33 666.85 1271.16Z" fill="#DCD6E7"/>
                                    <path opacity="0.39" d="M669.13 1269.78C321.391 1228.84 72.9653 916.163 114.266 571.378C155.566 226.604 470.953 -19.7055 818.692 21.2414C1166.44 62.1883 1414.88 374.874 1373.58 719.647C1332.28 1064.43 1016.89 1310.73 669.142 1269.78H669.13Z" fill="#DCD6E8"/>
                                    <path opacity="0.41" d="M671.41 1268.39C325.657 1227.69 78.6462 916.789 119.715 573.974C160.772 231.183 474.354 -13.7251 820.107 26.9922C1165.87 67.6973 1412.89 378.606 1371.83 721.409C1330.77 1064.22 1017.17 1309.11 671.41 1268.4V1268.39Z" fill="#DCD6E8"/>
                                    <path opacity="0.43" d="M673.69 1267.01C329.924 1226.54 84.3394 917.43 125.165 576.585C165.99 235.751 477.768 -7.74268 821.533 32.7329C1165.31 73.2084 1410.91 382.328 1370.08 723.162C1329.26 1064.01 1017.47 1307.49 673.69 1267.01Z" fill="#DCD5E8"/>
                                    <path opacity="0.45" d="M675.969 1265.64C334.19 1225.39 90.0193 918.072 130.613 579.197C171.194 240.333 481.18 -1.74661 822.959 38.4872C1164.76 78.7331 1408.93 386.064 1368.34 724.928C1327.75 1063.8 1017.76 1305.88 675.969 1265.64Z" fill="#DBD5E9"/>
                                    <path opacity="0.47" d="M678.237 1264.26C338.446 1224.25 95.7009 918.712 136.051 581.807C176.413 244.914 484.582 4.23576 824.374 44.2399C1164.19 84.2441 1406.95 389.799 1366.58 726.692C1326.23 1063.6 1018.04 1304.26 678.237 1264.26Z" fill="#DBD4E9"/>
                                    <path opacity="0.49" d="M680.515 1262.87C342.711 1223.09 101.38 919.339 141.498 584.415C181.617 249.492 487.994 10.2162 825.799 49.9907C1163.63 89.7653 1404.96 393.531 1364.84 728.455C1324.72 1063.39 1018.34 1302.65 680.515 1262.88V1262.87Z" fill="#DBD4EA"/>
                                    <path opacity="0.51" d="M682.796 1261.49C346.979 1221.95 107.062 919.981 146.949 587.028C186.835 254.062 491.409 16.2006 827.226 55.7456C1163.07 95.2905 1402.98 397.256 1363.1 730.221C1323.21 1063.19 1018.64 1301.04 682.796 1261.5V1261.49Z" fill="#DBD3EA"/>
                                    <path opacity="0.53" d="M685.076 1260.11C351.246 1220.81 112.755 920.632 152.398 589.636C192.041 258.641 494.81 22.1811 828.653 61.4963C1162.51 100.8 1401 400.988 1361.35 731.983C1321.71 1062.98 1018.93 1299.43 685.076 1260.12V1260.11Z" fill="#DBD3EB"/>
                                    <path opacity="0.55" d="M687.354 1258.72C355.511 1219.65 118.434 921.261 157.845 592.236C197.257 263.223 498.222 28.177 830.065 67.2385C1161.93 106.312 1399.01 404.712 1359.6 733.725C1320.19 1062.75 1019.21 1297.8 687.342 1258.72H687.354Z" fill="#DBD2EB"/>
                                    <path opacity="0.57" d="M689.622 1257.35C359.766 1218.51 124.116 921.902 163.283 594.847C202.463 267.803 501.636 34.1593 831.492 72.9912C1161.37 111.835 1397.04 408.446 1357.86 735.49C1318.68 1062.55 1019.5 1296.18 689.622 1257.35Z" fill="#DAD2EC"/>
                                    <path opacity="0.59" d="M691.902 1255.97C364.033 1217.36 129.797 922.542 168.733 597.457C207.669 272.384 505.038 40.1417 832.907 78.7439C1160.8 117.346 1395.04 412.181 1356.1 737.254C1317.17 1062.34 1019.78 1294.57 691.89 1255.97H691.902Z" fill="#DAD1EC"/>
                                    <path opacity="0.61" d="M694.181 1254.59C368.299 1216.21 135.477 923.181 174.181 600.065C212.885 276.962 508.45 46.1221 834.333 84.4947C1160.24 122.867 1393.06 415.913 1354.36 739.016C1315.65 1062.13 1020.08 1292.96 694.169 1254.59H694.181Z" fill="#DAD1EC"/>
                                    <path opacity="0.63" d="M696.461 1253.2C372.566 1215.07 141.17 923.811 179.631 602.666C218.103 281.532 511.864 52.1065 835.759 90.2373C1159.68 128.38 1391.09 419.637 1352.61 740.771C1314.14 1061.92 1020.38 1291.33 696.449 1253.2H696.461Z" fill="#DAD1ED"/>
                                    <path opacity="0.65" d="M698.741 1251.82C376.833 1213.92 146.851 924.451 185.08 605.276C223.309 286.113 515.278 58.0888 837.186 95.9901C1159.12 133.891 1389.1 423.36 1350.87 742.535C1312.64 1061.71 1020.67 1289.72 698.741 1251.82Z" fill="#DAD0ED"/>
                                    <path opacity="0.67" d="M701.007 1250.44C381.086 1212.77 152.531 925.091 190.516 607.898C228.513 290.704 518.665 64.0947 838.599 101.754C1158.54 139.426 1387.11 427.105 1349.11 744.311C1311.12 1061.52 1020.95 1288.11 701.007 1250.44Z" fill="#DAD0EE"/>
                                    <path opacity="0.69" d="M703.288 1249.06C385.353 1211.63 158.212 925.734 195.966 610.499C233.719 295.263 522.079 70.0674 840.026 107.497C1157.98 144.939 1385.14 430.83 1347.37 746.066C1309.61 1061.3 1021.25 1286.5 703.288 1249.06Z" fill="#DACFEE"/>
                                    <path opacity="0.71" d="M705.568 1247.68C389.621 1210.47 163.894 926.373 201.428 613.107C238.949 299.842 525.506 76.0478 841.453 113.248C1157.42 150.448 1383.15 434.563 1345.63 747.828C1308.11 1061.09 1021.54 1284.89 705.568 1247.68Z" fill="#D9CFEF"/>
                                    <path opacity="0.73" d="M707.845 1246.3C393.885 1209.33 169.585 927.015 206.875 615.719C244.165 304.424 528.917 82.0439 842.877 119.002C1156.86 155.973 1381.17 438.299 1343.88 749.582C1306.59 1060.88 1021.83 1283.26 707.845 1246.29V1246.3Z" fill="#D9CEEF"/>
                                    <path opacity="0.75" d="M710.126 1244.91C398.153 1208.18 175.266 927.641 212.324 618.316C249.371 308.99 532.331 88.0126 844.304 124.742C1156.3 161.483 1379.2 442.019 1342.14 751.333C1305.1 1060.66 1022.12 1281.64 710.126 1244.9V1244.91Z" fill="#D9CEEF"/>
                                    <path opacity="0.76" d="M712.394 1243.53C402.396 1207.03 180.948 928.284 217.762 620.928C254.577 313.573 535.733 93.9969 845.719 130.496C1155.74 166.995 1377.2 445.756 1340.39 753.099C1303.57 1060.45 1022.42 1280.03 712.394 1243.52V1243.53Z" fill="#D9CDF0"/>
                                    <path opacity="0.78" d="M714.672 1242.15C406.661 1205.88 186.627 928.924 223.21 623.538C259.793 318.153 539.132 99.991 847.144 136.249C1155.18 172.518 1375.23 449.478 1338.64 754.863C1302.06 1060.25 1022.71 1278.41 714.672 1242.14V1242.15Z" fill="#D9CDF0"/>
                                    <path opacity="0.8" d="M716.953 1240.78C410.929 1204.75 192.309 929.566 228.66 626.151C265 322.735 542.547 105.975 848.572 142.003C1154.62 178.031 1373.25 453.214 1336.9 756.63C1300.56 1060.04 1023 1276.8 716.953 1240.76V1240.78Z" fill="#D9CDF1"/>
                                    <path opacity="0.82" d="M719.233 1239.39C415.196 1203.59 198.002 930.193 234.109 628.747C270.217 327.302 545.961 111.944 849.998 147.742C1154.06 183.541 1371.27 456.935 1335.16 758.38C1299.05 1059.83 1023.29 1275.17 719.233 1239.37V1239.39Z" fill="#D8CCF1"/>
                                    <path opacity="0.84" d="M721.511 1238.01C419.461 1202.44 203.681 930.833 239.557 631.357C275.433 331.882 549.373 117.938 851.423 153.495C1153.5 189.063 1369.29 460.669 1333.41 760.144C1297.54 1059.62 1023.59 1273.56 721.511 1237.99V1238.01Z" fill="#D8CCF2"/>
                                    <path opacity="0.86" d="M723.792 1236.63C423.728 1201.3 209.375 931.485 245.007 633.98C280.639 336.474 552.787 123.932 852.838 159.259C1152.93 194.586 1367.3 464.415 1331.66 761.92C1296.03 1059.43 1023.87 1271.97 723.779 1236.63H723.792Z" fill="#D8CBF2"/>
                                    <path opacity="0.88" d="M726.058 1235.24C427.982 1200.14 215.043 932.115 250.443 636.58C285.844 341.044 556.187 129.917 854.251 165.002C1152.35 200.099 1365.3 468.139 1329.9 763.675C1294.5 1059.21 1024.15 1270.34 726.046 1235.24H726.058Z" fill="#D8CBF2"/>
                                    <path opacity="0.9" d="M728.339 1233.86C432.249 1199.01 220.724 932.754 255.893 639.188C291.062 345.623 559.589 135.897 855.678 170.753C1151.79 205.62 1363.33 471.872 1328.16 765.437C1292.99 1059 1024.45 1268.73 728.339 1233.86Z" fill="#D8CAF3"/>
                                    <path opacity="0.92" d="M730.618 1232.48C436.516 1197.86 226.417 933.396 261.342 641.801C296.267 350.205 563.002 141.881 857.104 176.507C1151.23 211.133 1361.35 475.596 1326.42 767.192C1291.49 1058.79 1024.74 1267.11 730.618 1232.47V1232.48Z" fill="#D8CAF3"/>
                                    <path opacity="0.94" d="M732.898 1231.09C440.783 1196.7 232.098 934.025 266.792 644.399C301.485 354.774 566.416 147.864 858.531 182.248C1150.67 216.644 1359.37 479.318 1324.67 768.944C1289.98 1058.57 1025.04 1265.48 732.898 1231.08V1231.09Z" fill="#D7C9F4"/>
                                    <path opacity="0.96" d="M735.177 1229.72C445.049 1195.56 237.79 934.665 272.24 647.009C306.701 359.354 569.828 153.846 859.956 188.001C1150.11 222.167 1357.39 483.053 1322.93 770.708C1288.47 1058.36 1025.33 1263.87 735.177 1229.7V1229.72Z" fill="#D7C9F4"/>
                                    <path opacity="0.98" d="M737.444 1228.34C449.303 1194.42 243.459 935.307 277.677 649.622C311.895 363.936 573.23 159.83 861.371 193.768C1149.54 227.693 1355.39 486.801 1321.18 772.487C1286.96 1058.17 1025.61 1262.27 737.444 1228.34Z" fill="#D7C8F5"/>
                                    <path d="M739.724 1226.96C453.57 1193.27 249.139 935.946 283.126 652.23C317.112 368.515 576.643 165.823 862.797 199.518C1148.98 233.213 1353.42 490.533 1319.43 774.249C1285.45 1057.96 1025.9 1260.66 739.724 1226.96Z" fill="#D7C8F5"/>
                                    <path d="M737.238 1224.76C453.973 1191.4 251.615 936.695 285.26 655.843C318.905 374.992 575.802 174.355 859.067 207.7C1142.36 241.057 1344.73 495.778 1311.09 776.617C1277.45 1057.47 1020.54 1258.11 737.25 1224.75L737.238 1224.76Z" fill="#D8C7F1"/>
                                    <path d="M734.751 1222.56C454.387 1189.56 254.089 937.432 287.38 659.457C320.672 381.47 574.961 182.876 855.325 215.894C1135.72 248.913 1336.02 501.024 1302.73 779.011C1269.44 1057 1015.14 1255.58 734.739 1222.57L734.751 1222.56Z" fill="#D8C6EC"/>
                                    <path d="M732.263 1220.36C454.788 1187.69 256.563 938.181 289.513 663.059C322.463 387.948 574.119 191.396 851.606 224.064C1129.1 256.732 1327.36 506.257 1294.39 781.367C1261.44 1056.48 1009.76 1253.02 732.263 1220.35V1220.36Z" fill="#D9C5E8"/>
                                    <path d="M729.776 1218.18C455.191 1185.85 259.037 938.932 291.646 666.686C324.255 394.44 573.29 199.942 847.875 232.272C1122.49 264.601 1318.66 511.515 1286.05 783.762C1253.45 1056.01 1004.4 1250.51 729.788 1218.16L729.776 1218.18Z" fill="#DAC4E3"/>
                                    <path d="M727.278 1215.98C455.582 1183.98 261.489 939.681 293.756 670.299C326.024 400.918 572.438 208.474 844.122 240.466C1115.84 272.457 1309.95 516.773 1277.68 786.154C1245.41 1055.54 998.987 1247.98 727.278 1215.99V1215.98Z" fill="#DAC4DF"/>
                                    <path d="M724.79 1213.77C455.995 1182.12 263.962 940.416 295.888 673.91C327.814 407.405 571.595 217.005 840.403 248.645C1109.22 280.298 1301.27 522.003 1269.34 788.521C1237.41 1055.03 993.622 1245.43 724.79 1213.77Z" fill="#DBC3DB"/>
                                    <path d="M722.304 1211.57C456.398 1180.27 266.437 941.153 298.022 677.512C329.607 413.871 570.767 225.525 836.673 256.828C1102.6 288.142 1292.58 527.249 1261 790.89C1229.42 1054.53 988.246 1242.88 722.316 1211.56L722.304 1211.57Z" fill="#DCC2D6"/>
                                    <path d="M719.818 1209.39C456.801 1178.42 268.912 941.916 300.144 681.14C331.375 420.363 569.926 234.06 832.931 265.036C1095.97 296.012 1283.87 532.52 1252.64 793.296C1221.41 1054.07 982.859 1240.36 719.818 1209.4V1209.39Z" fill="#DCC1D2"/>
                                    <path d="M717.331 1207.19C457.215 1176.56 271.387 942.655 302.277 684.755C333.167 426.843 569.085 242.594 829.213 273.219C1089.37 303.845 1275.21 537.755 1244.3 795.667C1213.41 1053.58 977.483 1237.82 717.331 1207.19Z" fill="#DDC0CD"/>
                                    <path d="M714.844 1204.99C457.617 1174.7 273.849 943.404 304.398 688.356C334.947 433.321 568.244 251.114 825.47 281.401C1082.72 311.689 1266.5 543 1235.95 798.036C1205.4 1053.07 972.095 1235.28 714.844 1204.98V1204.99Z" fill="#DEBFC9"/>
                                    <path d="M712.357 1202.8C458.02 1172.85 276.324 944.153 306.531 691.982C336.739 439.81 567.403 259.658 821.741 289.607C1076.1 319.556 1257.81 548.257 1227.6 800.428C1197.4 1052.6 966.719 1232.75 712.357 1202.8Z" fill="#DFBEC4"/>
                                    <path d="M709.858 1200.6C458.41 1170.99 278.786 944.902 308.652 695.595C338.518 446.288 566.562 268.191 817.998 297.789C1069.47 327.399 1249.11 553.502 1219.24 802.809C1189.37 1052.12 961.319 1230.21 709.858 1200.6Z" fill="#DFBDC0"/>
                                    <path d="M707.371 1198.4C458.824 1169.14 281.26 945.639 310.773 699.208C340.286 452.778 565.72 276.723 814.267 305.983C1062.84 335.255 1240.41 558.758 1210.9 805.189C1181.39 1051.62 955.955 1227.67 707.371 1198.41V1198.4Z" fill="#E0BDBC"/>
                                    <path d="M704.883 1196.2C459.225 1167.28 283.734 946.375 312.906 702.808C342.077 459.242 564.878 285.23 810.536 314.163C1056.22 343.085 1231.72 563.99 1202.55 807.556C1173.38 1051.12 950.566 1225.12 704.883 1196.2Z" fill="#E1BCB7"/>
                                    <path d="M702.397 1194.01C459.628 1165.43 286.197 947.135 315.027 706.433C343.857 465.731 564.038 293.774 806.795 322.357C1049.59 350.94 1223.02 569.247 1194.19 809.949C1165.36 1050.65 945.166 1222.6 702.385 1194.01H702.397Z" fill="#E1BBB3"/>
                                    <path d="M699.911 1191.81C460.043 1163.57 288.672 947.872 317.161 710.047C345.65 472.221 563.197 302.306 803.077 330.551C1042.97 358.795 1214.35 574.492 1185.86 812.329C1157.37 1050.17 939.815 1220.07 699.911 1191.83V1191.81Z" fill="#E2BAAE"/>
                                    <path d="M697.424 1189.62C460.445 1161.71 291.147 948.623 319.295 713.65C347.442 478.689 562.368 310.829 799.347 338.723C1036.35 366.629 1205.66 579.727 1177.51 814.688C1149.37 1049.65 934.427 1217.51 697.424 1189.6V1189.62Z" fill="#E3B9AA"/>
                                    <path d="M694.937 1187.42C460.847 1159.86 293.621 949.361 321.415 717.264C349.221 485.167 561.526 319.361 795.604 346.917C1029.72 374.485 1196.96 584.984 1169.15 817.081C1141.34 1049.18 929.038 1214.98 694.924 1187.42H694.937Z" fill="#E3B8A6"/>
                                    <path d="M692.451 1185.23C461.25 1158.01 296.084 950.124 323.549 720.891C351.001 491.659 560.686 327.895 791.886 355.125C1023.1 382.354 1188.28 590.243 1160.82 819.475C1133.37 1048.71 923.675 1212.46 692.463 1185.24L692.451 1185.23Z" fill="#E4B7A1"/>
                                    <path d="M689.952 1183.03C461.652 1156.15 298.547 950.86 325.67 724.504C352.781 498.148 559.845 336.427 788.144 363.306C1016.47 390.185 1179.57 595.488 1152.46 821.844C1125.35 1048.2 918.275 1209.91 689.952 1183.03Z" fill="#E5B69D"/>
                                    <path d="M687.464 1180.83C462.054 1154.29 301.021 951.598 327.791 728.106C354.56 504.614 559.003 344.948 784.401 371.489C1009.84 398.029 1170.87 600.721 1144.1 824.213C1117.33 1047.7 912.886 1207.36 687.452 1180.82L687.464 1180.83Z" fill="#E5B598"/>
                                    <path d="M684.978 1178.63C462.457 1152.43 303.496 952.347 329.924 731.72C356.353 511.092 558.162 353.48 780.683 379.683C1003.22 405.885 1162.2 605.978 1135.77 826.606C1109.34 1047.23 907.523 1204.84 684.99 1178.64L684.978 1178.63Z" fill="#E6B594"/>
                                    <path d="M682.491 1176.44C462.872 1150.58 305.971 953.095 332.046 735.344C358.133 517.593 557.322 362.024 776.942 387.876C996.586 413.74 1153.5 611.235 1127.41 828.985C1101.32 1046.75 902.135 1202.31 682.479 1176.44H682.491Z" fill="#E7B490"/>
                                    <path d="M680.003 1174.24C463.272 1148.73 308.432 953.845 334.177 738.946C359.923 524.059 556.479 370.545 773.21 396.058C989.965 421.583 1144.81 616.468 1119.06 831.355C1093.31 1046.24 896.746 1199.76 679.991 1174.23L680.003 1174.24Z" fill="#E7B38B"/>
                                    <path d="M677.517 1172.04C463.676 1146.87 310.907 954.582 336.312 742.56C361.704 530.538 555.651 379.066 769.493 404.252C983.359 429.427 1136.13 621.725 1110.73 833.748C1085.34 1045.77 891.383 1197.23 677.53 1172.06L677.517 1172.04Z" fill="#E8B287"/>
                                    <path d="M675.03 1169.84C464.09 1145.01 313.382 955.319 338.433 746.173C363.484 537.015 554.81 387.598 765.75 412.434C976.715 437.271 1127.42 626.958 1102.37 836.116C1077.32 1045.27 885.995 1194.68 675.03 1169.84Z" fill="#E9B182"/>
                                    <path d="M672.531 1167.65C464.48 1143.16 315.844 956.08 340.554 749.786C365.263 543.505 553.956 396.13 762.007 420.628C970.083 445.126 1118.72 632.215 1094.01 838.497C1069.3 1044.78 880.594 1192.14 672.519 1167.64L672.531 1167.65Z" fill="#EAB07E"/>
                                    <path d="M670.045 1165.46C464.883 1141.3 318.319 956.817 342.675 753.4C367.044 549.983 553.116 404.662 758.278 428.822C963.464 452.982 1110.04 637.472 1085.67 840.889C1061.3 1044.31 875.231 1189.63 670.045 1165.47V1165.46Z" fill="#EAAF79"/>
                                    <path d="M667.557 1163.26C465.284 1139.45 320.781 957.566 344.808 757.013C368.835 556.46 552.274 413.195 754.547 437.004C956.832 460.825 1101.35 642.705 1077.32 843.258C1053.29 1043.81 869.842 1187.08 667.557 1163.26Z" fill="#EBAE75"/>
                                    <path d="M665.071 1161.07C465.699 1137.6 323.256 958.318 346.941 760.642C370.615 562.966 551.445 421.743 750.817 445.213C950.213 468.696 1092.66 647.978 1068.97 845.654C1045.3 1043.34 864.454 1184.55 665.058 1161.07H665.071Z" fill="#ECAE71"/>
                                    <path d="M662.585 1158.87C466.102 1135.74 325.731 959.066 349.063 764.242C372.395 569.43 550.605 430.25 747.087 453.394C943.594 476.526 1083.97 653.209 1060.63 848.021C1037.3 1042.83 859.092 1182 662.585 1158.87Z" fill="#ECAD6C"/>
                                    <path d="M660.098 1156.67C466.505 1133.88 328.206 959.803 351.197 767.856C374.188 575.908 549.764 438.782 743.358 461.576C936.963 484.37 1075.27 658.454 1052.28 850.402C1029.29 1042.35 853.704 1179.46 660.098 1156.67Z" fill="#EDAC68"/>
                                    <path d="M657.61 1154.47C466.918 1132.01 330.679 960.54 353.329 771.469C375.978 582.386 548.934 447.314 739.626 469.77C930.343 492.225 1066.58 663.711 1043.93 852.783C1021.28 1041.87 848.327 1176.93 657.61 1154.47Z" fill="#EEAB63"/>
                                    <path d="M655.112 1152.28C467.309 1130.17 333.131 961.303 355.439 775.084C377.747 588.877 548.082 455.848 735.885 477.953C923.712 500.07 1057.88 668.946 1035.58 855.153C1013.27 1041.36 842.94 1174.38 655.112 1152.27V1152.28Z" fill="#EEAA5F"/>
                                    <path d="M652.625 1150.08C467.711 1128.32 335.605 962.04 357.572 778.698C379.539 595.355 547.24 464.381 732.154 486.147C917.081 507.926 1049.2 674.203 1027.23 857.546C1005.26 1040.89 837.563 1171.86 652.625 1150.08Z" fill="#EFA95B"/>
                                    <path d="M650.138 1147.88C468.113 1126.46 338.08 962.789 359.693 782.311C381.306 601.833 546.399 472.913 728.412 494.341C910.449 515.77 1040.49 679.448 1018.87 859.926C997.256 1040.4 832.163 1169.32 650.126 1147.88H650.138Z" fill="#F0A856"/>
                                    <path d="M647.65 1145.68C468.527 1124.59 340.553 963.524 361.825 785.922C383.097 608.321 545.557 481.431 724.693 502.521C903.841 523.611 1031.81 684.691 1010.54 862.293C989.27 1039.91 826.798 1166.77 647.663 1145.68H647.65Z" fill="#F0A752"/>
                                    <path d="M645.164 1143.49C468.93 1122.74 343.029 964.285 363.96 789.536C384.89 614.799 544.729 489.964 720.963 510.715C897.222 531.467 1023.12 689.949 1002.19 864.686C981.262 1039.42 821.423 1164.25 645.164 1143.49Z" fill="#F1A64D"/>
                                    <path d="M642.677 1141.29C469.331 1120.88 345.49 965.022 366.08 793.149C386.669 621.276 543.887 498.496 717.22 518.909C890.578 539.322 1014.42 695.194 993.829 867.067C973.24 1038.94 816.022 1161.71 642.665 1141.29H642.677Z" fill="#F2A649"/>
                                    <path d="M640.191 1139.1C469.747 1119.02 347.966 965.761 368.214 796.765C388.462 627.768 543.047 507.03 713.503 527.093C883.972 547.167 1005.74 700.44 985.504 869.437C965.256 1038.45 810.672 1159.16 640.203 1139.1H640.191Z" fill="#F2A545"/>
                                    <path d="M637.692 1136.9C470.137 1117.17 350.429 966.511 370.323 800.366C390.218 634.234 542.194 515.551 709.749 535.275C877.328 555.011 997.037 705.674 977.13 871.818C957.236 1037.95 805.26 1156.63 637.68 1136.9H637.692Z" fill="#F3A440"/>
                                    <path d="M635.205 1134.71C470.539 1115.33 352.903 967.262 372.456 803.994C392.009 640.726 541.352 524.085 706.018 543.483C870.696 562.869 988.344 710.945 968.779 874.213C949.226 1037.48 799.871 1154.11 635.193 1134.71H635.205Z" fill="#F4A33C"/>
                                    <path d="M632.718 1132.51C470.941 1113.46 355.378 968.01 374.59 807.607C393.801 647.203 540.523 532.618 702.3 551.665C864.089 570.712 979.665 716.19 960.453 876.581C941.242 1036.98 794.52 1151.56 632.731 1132.51H632.718Z" fill="#F5A237"/>
                                    <path d="M630.231 1130.31C471.355 1111.6 357.84 968.747 376.71 811.22C395.581 653.693 539.682 541.15 698.557 559.859C857.457 578.568 970.961 721.435 952.09 878.962C933.22 1036.5 789.119 1149.02 630.231 1130.31Z" fill="#F5A133"/>
                                    <path d="M627.745 1128.12C471.758 1109.75 360.315 969.496 378.844 814.834C397.373 660.171 538.841 549.682 694.827 568.053C850.826 586.423 962.27 726.692 943.74 881.354C925.211 1036.02 783.743 1146.49 627.732 1128.12H627.745Z" fill="#F6A02E"/>
                                    <path d="M625.258 1125.92C472.16 1107.9 362.789 970.245 380.977 818.447C399.165 666.649 538.012 558.214 691.109 576.234C844.219 594.267 953.59 731.924 935.414 883.723C917.227 1035.52 778.379 1143.94 625.27 1125.92H625.258Z" fill="#F79F2A"/>
                                    <path d="M622.77 1123.72C472.574 1106.04 365.263 970.982 383.098 822.06C400.932 673.138 537.17 566.747 687.366 584.428C837.587 602.11 944.886 737.181 927.051 886.103C909.217 1035.04 772.979 1141.42 622.77 1123.72Z" fill="#F79F26"/>
                                    <path d="M620.284 1121.52C472.977 1104.18 367.738 971.732 385.231 825.662C402.724 679.605 536.33 575.255 683.637 592.599C830.956 609.942 936.207 742.415 918.701 888.473C901.208 1034.53 767.603 1138.87 620.272 1121.51L620.284 1121.52Z" fill="#F89E21"/>
                                    <path d="M617.785 1119.34C473.367 1102.33 370.189 972.481 387.341 829.287C404.493 686.094 535.477 583.8 679.895 600.804C824.325 617.809 927.503 747.672 910.352 890.865C893.2 1034.06 762.216 1136.34 617.785 1119.34Z" fill="#F99D1D"/>
                                    <path d="M615.298 1117.14C473.769 1100.47 372.663 973.23 389.473 832.901C406.284 692.572 534.635 592.332 676.164 608.998C817.705 625.665 918.811 752.929 902.001 893.246C885.19 1033.57 756.839 1133.8 615.298 1117.14Z" fill="#F99C18"/>
                                    <path d="M612.811 1114.94C474.184 1098.61 375.138 973.967 391.607 836.514C408.076 699.062 533.806 600.864 672.434 617.18C811.086 633.508 910.12 758.162 893.651 895.626C877.182 1033.09 751.451 1131.26 612.811 1114.95V1114.94Z" fill="#FA9B14"/>
                                    <g style={{ mixBlendMode: 'color-burn' }}>
                                        <path opacity="0.02" d="M1174.11 991.224C976.008 1203.96 641.458 1217.19 426.885 1020.77C212.312 824.355 198.964 492.671 397.092 279.947C595.184 67.212 929.733 53.978 1144.31 250.385C1358.89 446.817 1372.23 778.477 1174.11 991.224Z" fill="#D6C7F5"/>
                                        <path opacity="0.04" d="M1174.27 989.242C977.178 1200.88 644.36 1214.04 430.896 1018.65C217.432 823.243 204.157 493.263 401.249 281.639C598.329 70.004 931.148 56.8425 1144.61 252.223C1358.09 447.639 1371.35 777.595 1174.26 989.242H1174.27Z" fill="#D6C5F5"/>
                                        <path opacity="0.06" d="M1174.43 987.246C978.359 1197.78 647.26 1210.88 434.906 1016.49C222.551 822.105 209.337 493.841 405.417 283.294C601.473 72.758 932.573 59.657 1144.94 254.034C1357.31 448.435 1370.51 776.687 1174.44 987.246H1174.43Z" fill="#D5C4F6"/>
                                        <path opacity="0.08" d="M1174.59 985.264C979.542 1194.71 650.161 1207.74 438.904 1014.37C227.647 820.993 214.506 494.433 409.574 284.985C604.606 75.5372 933.987 62.5086 1145.24 255.87C1356.51 449.256 1369.64 775.804 1174.59 985.264Z" fill="#D4C3F6"/>
                                        <path opacity="0.1" d="M1174.75 983.283C980.714 1191.63 653.064 1204.59 442.916 1012.23C232.768 819.858 219.7 495.014 413.733 286.666C607.741 78.3177 935.403 65.3616 1145.55 257.708C1355.71 450.091 1368.77 774.922 1174.75 983.283Z" fill="#D4C2F6"/>
                                        <path opacity="0.12" d="M1174.9 981.3C981.895 1188.55 655.976 1201.44 446.925 1010.09C237.887 818.733 224.88 495.594 417.9 288.345C610.897 81.097 936.828 68.2014 1145.87 259.545C1354.92 450.912 1367.91 774.04 1174.9 981.3Z" fill="#D3C0F6"/>
                                        <path opacity="0.14" d="M1175.06 979.306C983.077 1185.47 658.878 1198.29 450.936 1007.95C242.995 817.609 230.061 496.174 422.057 290.025C614.03 83.8645 938.229 71.0414 1146.17 261.37C1354.11 451.722 1367.03 773.133 1175.05 979.306H1175.06Z" fill="#D2BFF6"/>
                                        <path opacity="0.16" d="M1175.22 977.324C984.26 1182.39 661.779 1195.14 454.947 1005.81C248.115 816.486 235.254 496.754 426.227 291.706C617.175 86.645 939.656 73.8944 1146.49 263.207C1353.33 452.544 1366.18 772.252 1175.22 977.324Z" fill="#D2BEF7"/>
                                        <path opacity="0.18" d="M1175.37 975.342C985.418 1179.3 664.668 1191.99 458.945 1003.68C253.222 815.361 240.423 497.358 430.371 293.397C620.295 89.4361 941.058 76.7459 1146.78 265.056C1352.52 453.39 1365.3 771.381 1175.35 975.354L1175.37 975.342Z" fill="#D1BDF7"/>
                                        <path opacity="0.2" d="M1175.53 973.359C986.601 1176.23 667.57 1188.85 462.956 1001.54C258.343 814.237 245.604 497.938 434.541 295.077C623.453 92.2036 942.484 79.5859 1147.11 266.88C1351.74 454.199 1364.45 770.486 1175.54 973.359H1175.53Z" fill="#D0BBF7"/>
                                        <path opacity="0.22" d="M1175.68 971.368C987.783 1173.14 670.47 1185.69 466.966 999.395C263.45 813.104 250.796 498.509 438.709 296.748C626.597 94.9743 943.91 82.4291 1147.43 268.708C1350.94 455.012 1363.6 769.595 1175.7 971.368H1175.68Z" fill="#CFBAF7"/>
                                        <path opacity="0.24" d="M1175.84 969.382C988.965 1170.06 673.383 1182.54 470.976 997.264C268.569 811.987 255.977 499.097 442.866 298.423C629.73 97.7495 945.324 85.2649 1147.73 270.529C1350.15 455.817 1362.73 768.684 1175.84 969.37V969.382Z" fill="#CFB9F7"/>
                                        <path opacity="0.25" d="M1176 967.401C990.135 1166.97 676.285 1179.4 474.975 995.126C273.677 810.853 261.158 499.678 447.023 300.104C632.863 100.518 946.726 88.1182 1148.04 272.367C1349.35 456.64 1361.85 767.803 1176 967.389V967.401Z" fill="#CEB7F8"/>
                                        <path opacity="0.27" d="M1176.16 965.408C991.318 1163.89 679.186 1176.23 478.986 992.988C278.785 809.73 266.339 500.272 451.18 301.786C636.008 103.3 948.14 90.96 1148.34 274.206C1348.54 457.476 1360.99 766.922 1176.15 965.42L1176.16 965.408Z" fill="#CDB6F8"/>
                                        <path opacity="0.29" d="M1176.32 963.425C992.501 1160.81 682.088 1173.09 482.997 990.848C283.905 808.606 271.52 500.851 455.349 303.465C639.154 106.079 949.567 93.7996 1148.66 276.03C1347.75 458.285 1360.13 766.027 1176.32 963.425Z" fill="#CDB5F8"/>
                                        <path opacity="0.31" d="M1176.48 961.442C993.671 1157.73 684.99 1169.95 487.008 988.708C289.026 807.48 276.714 501.43 459.519 305.144C642.299 108.857 950.993 96.6506 1148.98 277.866C1346.97 459.106 1359.27 765.144 1176.48 961.442Z" fill="#CCB4F8"/>
                                        <path opacity="0.33" d="M1176.64 959.46C994.854 1154.66 687.903 1166.79 491.018 986.58C294.133 806.356 281.894 502.022 463.676 306.835C645.432 111.637 952.408 99.5025 1149.28 279.715C1346.17 459.939 1358.4 764.261 1176.62 959.472L1176.64 959.46Z" fill="#CBB2F8"/>
                                        <path opacity="0.35" d="M1176.78 957.465C996.024 1151.56 690.792 1163.64 495.017 984.429C299.241 805.22 287.063 502.59 467.832 308.503C648.577 114.404 953.821 102.33 1149.6 281.527C1345.37 460.749 1357.55 763.354 1176.79 957.465H1176.78Z" fill="#CBB1F9"/>
                                        <path opacity="0.37" d="M1176.94 955.485C997.207 1148.48 693.694 1160.5 499.027 982.304C304.361 804.11 292.256 503.184 471.989 310.197C651.71 117.198 955.224 105.197 1149.9 283.379C1344.58 461.585 1356.67 762.486 1176.94 955.497V955.485Z" fill="#CAB0F9"/>
                                        <path opacity="0.39" d="M1177.1 953.503C998.376 1145.41 696.594 1157.34 503.037 980.165C309.468 802.986 297.436 503.776 476.158 311.877C654.855 119.966 956.649 108.037 1150.22 285.204C1343.79 462.394 1355.82 761.592 1177.11 953.503H1177.1Z" fill="#C9AFF9"/>
                                        <path opacity="0.41" d="M1177.26 951.519C999.559 1142.33 699.496 1154.2 507.048 978.024C314.589 801.848 302.618 504.355 480.315 313.543C657.988 122.731 958.064 110.863 1150.52 287.027C1342.98 463.202 1354.95 760.696 1177.26 951.507V951.519Z" fill="#C9ADF9"/>
                                        <path opacity="0.43" d="M1177.41 949.526C1000.74 1139.24 702.409 1151.03 511.046 975.885C319.696 800.725 307.798 504.935 484.472 315.224C661.133 125.512 959.477 113.716 1150.83 288.864C1342.18 464.025 1354.08 759.802 1177.4 949.526H1177.41Z" fill="#C8ACF9"/>
                                        <path opacity="0.45" d="M1177.57 947.546C1001.92 1136.16 705.31 1147.89 515.057 973.748C324.816 799.603 312.979 505.517 488.64 316.905C664.278 128.281 960.903 116.558 1151.14 290.691C1341.4 464.848 1353.22 758.91 1177.57 947.534V947.546Z" fill="#C7ABFA"/>
                                        <path opacity="0.47" d="M1177.73 945.563C1003.09 1133.09 708.211 1144.75 519.067 971.62C329.923 798.49 318.16 506.109 492.797 318.597C667.411 131.072 962.317 119.41 1151.45 292.54C1340.59 465.682 1352.36 758.039 1177.72 945.563H1177.73Z" fill="#C7A9FA"/>
                                        <path opacity="0.49" d="M1177.89 943.581C1004.28 1130.01 711.113 1141.6 523.078 969.481C335.044 797.354 323.353 506.69 496.967 320.277C670.556 133.853 963.732 122.263 1151.77 294.377C1339.8 466.504 1351.49 757.157 1177.89 943.593V943.581Z" fill="#C6A8FA"/>
                                        <path opacity="0.51" d="M1178.04 941.585C1005.45 1126.91 714.002 1138.44 527.076 967.328C340.151 796.216 328.521 507.268 501.111 321.943C673.689 136.618 965.133 125.089 1152.07 296.188C1339 467.3 1350.63 756.248 1178.04 941.585Z" fill="#C5A7FA"/>
                                        <path opacity="0.53" d="M1178.19 939.604C1006.62 1123.84 716.916 1135.3 531.087 965.202C345.259 795.106 333.703 507.861 505.28 323.637C676.834 139.4 966.56 127.942 1152.39 298.039C1338.22 468.147 1349.77 755.38 1178.21 939.617L1178.19 939.604Z" fill="#C4A6FA"/>
                                        <path opacity="0.55" d="M1178.35 937.623C1007.8 1120.76 719.817 1132.15 535.098 963.064C350.379 793.983 338.896 508.442 509.438 325.317C679.967 142.18 967.974 130.795 1152.69 299.864C1337.41 468.958 1348.9 754.474 1178.35 937.623Z" fill="#C4A4FA"/>
                                        <path opacity="0.57" d="M1178.51 935.64C1008.98 1117.68 722.719 1129 539.109 960.936C355.499 792.858 344.077 509.033 513.607 327.008C683.113 144.971 969.401 133.647 1153.01 301.713C1336.62 469.791 1348.04 753.603 1178.51 935.652V935.64Z" fill="#C3A3FB"/>
                                        <path opacity="0.59" d="M1178.67 933.645C1010.16 1114.59 725.619 1125.85 543.119 958.783C360.606 791.721 349.269 509.6 517.775 328.663C686.269 147.713 970.814 136.461 1153.33 303.524C1335.83 470.587 1347.19 752.695 1178.68 933.645H1178.67Z" fill="#C2A2FB"/>
                                        <path opacity="0.61" d="M1178.83 931.663C1011.33 1111.51 728.52 1122.69 547.117 956.645C365.714 790.597 354.438 510.181 521.92 330.343C689.39 150.494 972.216 139.314 1153.62 305.35C1335.02 471.41 1346.3 751.801 1178.82 931.651L1178.83 931.663Z" fill="#C2A0FB"/>
                                        <path opacity="0.63" d="M1178.99 929.68C1012.52 1108.43 731.423 1119.55 551.129 954.517C370.835 789.484 359.62 510.785 526.09 332.035C692.536 153.285 973.643 142.166 1153.94 307.198C1334.23 472.243 1345.45 750.931 1178.99 929.68Z" fill="#C19FFB"/>
                                        <path opacity="0.65" d="M1179.15 927.699C1013.7 1105.36 734.335 1116.41 555.138 952.379C375.954 788.362 364.812 511.366 530.246 333.716C695.668 156.054 975.056 145.019 1154.25 309.036C1333.44 473.066 1344.59 750.037 1179.15 927.699Z" fill="#C09EFB"/>
                                        <path opacity="0.67" d="M1179.3 925.707C1014.88 1102.27 737.237 1113.24 559.149 950.229C381.062 787.215 369.993 511.936 534.415 335.385C698.825 158.823 976.483 147.849 1154.57 310.851C1332.65 473.865 1343.73 749.133 1179.3 925.695V925.707Z" fill="#C09DFC"/>
                                        <path opacity="0.69" d="M1179.45 923.722C1016.04 1099.18 740.126 1110.1 563.148 948.099C386.169 786.1 375.162 512.525 538.56 337.075C701.946 161.612 977.885 150.699 1154.86 312.697C1331.84 474.696 1342.85 748.26 1179.45 923.722Z" fill="#BF9BFC"/>
                                        <path opacity="0.71" d="M1179.61 921.741C1017.22 1096.1 743.026 1106.96 567.157 945.961C391.288 784.977 380.354 513.106 542.728 338.755C705.09 164.381 979.298 153.552 1155.18 314.523C1331.05 475.519 1341.99 747.366 1179.61 921.741Z" fill="#BE9AFC"/>
                                        <path opacity="0.73" d="M1179.77 919.758C1018.4 1093.03 745.928 1103.8 571.168 943.833C396.397 783.865 385.535 513.698 546.898 340.447C708.236 167.172 980.725 156.403 1155.5 316.372C1330.26 476.352 1341.13 746.495 1179.78 919.77L1179.77 919.758Z" fill="#BE99FC"/>
                                        <path opacity="0.75" d="M1179.93 917.766C1019.58 1089.94 748.842 1100.65 575.18 941.684C401.517 782.719 390.717 514.28 551.055 342.117C711.381 169.941 982.139 159.233 1155.8 318.199C1329.46 477.164 1340.26 745.591 1179.93 917.778V917.766Z" fill="#BD98FC"/>
                                        <path opacity="0.76" d="M1180.09 915.781C1020.76 1086.86 751.744 1097.5 579.191 939.542C406.638 781.592 395.91 514.857 555.225 343.781C714.527 172.706 983.566 162.071 1156.12 320.009C1328.67 477.959 1339.41 744.681 1180.09 915.769V915.781Z" fill="#BC96FD"/>
                                        <path opacity="0.78" d="M1180.24 913.8C1021.94 1083.79 754.644 1094.35 583.188 937.416C411.732 780.481 401.078 515.45 559.368 345.475C717.647 175.487 984.967 164.924 1156.41 321.859C1327.85 478.806 1338.52 743.813 1180.23 913.8H1180.24Z" fill="#BC95FD"/>
                                        <path opacity="0.8" d="M1180.4 911.82C1023.12 1080.71 757.546 1091.21 587.2 935.278C416.853 779.359 406.26 516.032 563.538 347.156C720.793 178.269 986.394 167.766 1156.74 323.698C1327.08 479.63 1337.68 742.932 1180.4 911.82Z" fill="#BB94FD"/>
                                        <path opacity="0.82" d="M1180.56 909.824C1024.29 1077.61 760.447 1088.05 591.21 933.138C421.972 778.221 411.452 516.611 567.707 348.835C723.949 181.047 987.808 170.617 1157.06 325.521C1326.29 480.438 1336.81 742.036 1180.57 909.824H1180.56Z" fill="#BA92FD"/>
                                        <path opacity="0.84" d="M1180.71 907.842C1025.46 1074.54 763.348 1084.9 595.208 930.999C427.068 777.098 416.621 517.203 571.852 350.515C727.07 183.815 989.21 173.457 1157.35 327.358C1325.48 481.26 1335.94 741.142 1180.71 907.842Z" fill="#B991FD"/>
                                        <path opacity="0.86" d="M1180.87 905.862C1026.65 1071.46 766.25 1081.76 599.219 928.861C432.188 775.975 421.814 517.785 576.021 352.196C730.216 186.596 990.636 176.299 1157.67 329.185C1324.69 482.071 1335.08 740.249 1180.87 905.849V905.862Z" fill="#B990FE"/>
                                        <path opacity="0.88" d="M1181.02 903.88C1027.83 1068.38 769.15 1078.61 603.228 926.734C437.307 774.863 426.994 518.377 580.177 353.889C733.347 189.388 992.049 179.163 1157.97 331.034C1323.88 482.917 1334.21 739.379 1181.02 903.88Z" fill="#B88FFE"/>
                                        <path opacity="0.9" d="M1181.18 901.884C1029 1065.3 772.052 1075.45 607.24 924.581C442.427 773.714 432.175 518.944 584.346 355.555C736.505 192.142 993.476 181.99 1158.29 332.858C1323.1 483.725 1333.35 738.483 1181.18 901.884Z" fill="#B78DFE"/>
                                        <path opacity="0.92" d="M1181.34 899.901C1030.18 1062.21 774.953 1072.31 611.25 922.453C447.535 772.601 437.368 519.535 588.503 357.246C739.638 194.933 994.89 184.841 1158.59 334.694C1322.3 484.546 1332.49 737.6 1181.34 899.913V899.901Z" fill="#B78CFE"/>
                                        <path opacity="0.94" d="M1181.5 897.922C1031.36 1059.14 777.867 1069.15 615.261 920.317C452.656 771.48 442.55 520.118 592.673 358.917C742.784 197.704 996.304 187.684 1158.91 336.522C1321.5 485.359 1331.62 736.697 1181.5 897.922Z" fill="#B68BFE"/>
                                        <path opacity="0.96" d="M1181.66 895.926C1032.53 1056.04 780.768 1066 619.259 918.164C457.763 770.342 447.718 520.696 596.829 360.583C745.916 200.469 997.718 190.51 1159.23 338.333C1320.71 486.167 1330.77 735.788 1181.66 895.914V895.926Z" fill="#B58AFF"/>
                                        <path opacity="0.98" d="M1181.82 893.943C1033.72 1052.97 783.669 1062.86 623.27 916.036C462.871 769.217 452.911 521.288 600.986 362.274C749.061 203.249 999.132 193.362 1159.53 340.181C1319.92 487 1329.9 734.918 1181.82 893.943Z" fill="#B588FF"/>
                                        <path d="M1181.97 891.962C1034.9 1049.89 786.57 1059.7 627.28 913.898C467.99 768.094 458.092 521.869 605.155 363.955C752.206 206.029 1000.56 196.215 1159.85 342.019C1319.13 487.823 1329.04 734.036 1181.97 891.962Z" fill="#B487FF"/>
                                        <path d="M1179.52 892.492C1033.94 1048.82 788.119 1058.54 630.438 914.21C472.757 769.881 462.956 526.145 608.532 369.827C754.096 213.496 999.937 203.779 1157.62 348.108C1315.29 492.438 1325.1 736.161 1179.52 892.492Z" fill="#B586FB"/>
                                        <path d="M1177.07 893.023C1032.97 1047.76 789.667 1057.38 633.595 914.524C477.524 771.669 467.832 530.424 611.909 375.7C755.986 220.965 999.315 211.345 1155.39 354.2C1311.45 497.055 1321.16 738.288 1177.07 893.023Z" fill="#B685F7"/>
                                        <path d="M1174.62 893.557C1032.01 1046.7 791.215 1056.22 636.752 914.828C482.29 773.448 472.696 534.692 615.298 381.564C757.887 228.424 998.706 218.912 1153.17 360.293C1307.62 501.673 1317.22 740.417 1174.62 893.557Z" fill="#B783F4"/>
                                        <path d="M1172.17 894.089C1031.05 1045.63 792.763 1055.05 639.909 915.142C487.056 775.237 477.559 538.958 618.674 387.438C759.776 235.893 998.083 226.478 1150.94 366.384C1303.78 506.29 1313.29 742.544 1172.17 894.089Z" fill="#B882F0"/>
                                        <path d="M1169.72 894.621C1030.08 1044.57 794.299 1053.89 643.067 915.457C491.822 777.025 482.424 543.237 622.051 393.312C761.666 243.362 997.462 234.044 1148.71 372.488C1299.94 510.919 1309.35 744.696 1169.71 894.633L1169.72 894.621Z" fill="#B981EC"/>
                                        <path d="M1167.28 895.154C1029.13 1043.51 795.86 1052.73 646.236 915.761C496.613 778.804 487.312 547.504 625.452 399.175C763.592 250.821 996.877 241.611 1146.5 378.568C1296.11 515.525 1305.42 746.812 1167.28 895.154Z" fill="#BB80E8"/>
                                        <path d="M1164.83 895.695C1028.17 1042.44 797.407 1051.57 649.393 916.084C501.379 780.602 492.175 551.792 628.828 405.058C765.481 258.311 996.255 249.186 1144.27 384.669C1292.27 520.151 1301.49 748.949 1164.83 895.695Z" fill="#BC7EE4"/>
                                        <path d="M1162.38 896.227C1027.21 1041.38 798.956 1050.41 652.551 916.399C506.146 782.379 497.04 556.071 632.205 410.932C767.371 265.781 995.634 256.752 1142.04 390.773C1288.43 524.781 1297.55 751.088 1162.37 896.239L1162.38 896.227Z" fill="#BD7DE0"/>
                                        <path d="M1159.93 896.761C1026.24 1040.32 800.504 1049.25 655.708 916.702C510.913 784.157 501.916 560.339 635.595 416.795C769.273 273.239 995.024 264.32 1139.82 396.853C1284.6 529.387 1293.61 753.205 1159.93 896.761Z" fill="#BE7CDD"/>
                                        <path d="M1157.48 897.292C1025.28 1039.25 802.053 1048.09 658.866 917.016C515.679 785.945 506.781 564.617 638.972 422.669C771.163 280.708 994.403 271.885 1137.59 402.944C1280.76 534.016 1289.68 755.332 1157.48 897.292Z" fill="#BF7BD9"/>
                                        <path d="M1155.03 897.821C1024.32 1038.19 803.601 1046.91 662.023 917.327C520.445 787.731 511.644 568.892 642.348 428.527C773.052 288.162 993.781 279.436 1135.36 409.032C1276.92 538.629 1285.74 757.456 1155.02 897.821H1155.03Z" fill="#C07AD5"/>
                                        <path d="M1152.6 898.355C1023.35 1037.12 805.161 1045.75 665.193 917.632C525.224 789.51 516.533 573.149 645.749 434.391C774.966 295.621 993.184 286.992 1133.15 415.114C1273.11 543.236 1281.81 759.573 1152.6 898.343V898.355Z" fill="#C178D1"/>
                                        <path d="M1150.15 898.886C1022.39 1036.06 806.698 1044.59 668.351 917.945C529.991 791.298 521.397 577.426 649.126 440.264C776.856 303.089 992.563 294.569 1130.93 421.204C1269.28 547.852 1277.89 761.711 1150.16 898.874L1150.15 898.886Z" fill="#C277CD"/>
                                        <path d="M1147.69 899.418C1021.43 1035 808.245 1043.42 671.506 918.26C534.756 793.087 526.259 581.705 652.502 446.138C778.744 310.559 991.952 302.135 1128.7 427.308C1265.44 552.481 1273.95 763.851 1147.69 899.418Z" fill="#C376C9"/>
                                        <path d="M1145.24 899.95C1020.46 1033.93 809.792 1042.26 674.663 918.562C539.522 794.864 531.123 585.972 655.89 452C780.645 318.016 991.342 309.689 1126.48 433.388C1261.61 557.086 1270.02 765.966 1145.26 899.938L1145.24 899.95Z" fill="#C475C6"/>
                                        <path d="M1142.79 900.483C1019.5 1032.87 811.342 1041.1 677.822 918.878C544.29 796.653 536 590.251 659.268 457.875C782.536 325.486 990.721 317.268 1124.25 439.48C1257.76 561.704 1266.07 768.095 1142.81 900.471L1142.79 900.483Z" fill="#C674C2"/>
                                        <path d="M1140.36 901.015C1018.55 1031.8 812.902 1039.94 680.991 919.192C549.08 798.442 540.876 594.529 662.657 463.748C784.45 332.967 990.112 324.833 1122.03 445.583C1253.93 566.333 1262.15 770.234 1140.36 901.015Z" fill="#C772BE"/>
                                        <path d="M1137.91 901.545C1017.59 1030.73 814.45 1038.77 684.148 919.492C553.847 800.217 545.74 598.794 666.046 469.608C786.351 340.423 989.502 332.385 1119.82 451.661C1250.11 570.936 1258.22 772.347 1137.92 901.533L1137.91 901.545Z" fill="#C871BA"/>
                                        <path d="M1135.46 902.091C1016.63 1029.68 815.998 1037.62 687.306 919.821C558.613 802.02 550.616 603.086 669.422 475.496C788.241 347.906 988.88 339.965 1117.59 457.778C1246.27 575.579 1254.27 774.5 1135.47 902.091H1135.46Z" fill="#C970B6"/>
                                        <path d="M1133.01 902.621C1015.66 1028.62 817.547 1036.46 690.451 920.134C563.368 803.795 555.468 607.364 672.787 481.369C790.119 355.374 988.247 347.542 1115.34 463.869C1242.41 580.195 1250.33 776.626 1132.99 902.634L1133.01 902.621Z" fill="#CA6FB3"/>
                                        <path d="M1130.56 903.154C1014.7 1027.55 819.082 1035.3 693.608 920.437C568.134 805.572 560.332 611.619 676.176 487.231C792.02 362.831 987.637 355.096 1113.12 469.948C1238.59 584.8 1246.4 778.742 1130.56 903.154Z" fill="#CB6DAF"/>
                                        <path d="M1128.11 903.686C1013.74 1026.49 820.63 1034.13 696.765 920.751C572.9 807.361 565.208 615.897 679.552 493.105C793.909 370.3 987.027 362.662 1110.89 476.052C1234.75 589.429 1242.45 780.893 1128.09 903.698L1128.11 903.686Z" fill="#CC6CAB"/>
                                        <path d="M1125.67 904.217C1012.79 1025.43 822.191 1032.97 699.934 921.064C577.678 809.149 570.084 620.175 682.941 498.978C795.811 377.769 986.418 370.239 1108.67 482.142C1230.92 594.045 1238.52 783.02 1125.65 904.229L1125.67 904.217Z" fill="#CD6BA7"/>
                                        <path d="M1123.22 904.749C1011.82 1024.36 823.739 1031.81 703.092 921.367C582.445 810.926 574.948 624.441 686.331 504.84C797.713 385.226 985.809 377.793 1106.46 488.222C1227.09 598.65 1234.6 785.135 1123.22 904.749Z" fill="#CE6AA3"/>
                                        <path d="M1120.77 905.279C1010.86 1023.3 825.287 1030.63 706.249 921.679C587.211 812.713 579.812 628.718 689.707 510.712C799.602 392.693 985.187 385.357 1104.22 494.323C1223.25 603.277 1230.65 787.273 1120.75 905.291L1120.77 905.279Z" fill="#CF699F"/>
                                        <path d="M1118.32 905.814C1009.9 1022.24 826.835 1029.48 709.406 921.997C591.977 814.505 584.688 633 693.083 516.589C801.491 400.166 984.565 392.938 1101.99 500.418C1219.4 607.897 1226.71 789.403 1118.3 905.826L1118.32 905.814Z" fill="#D1679C"/>
                                        <path d="M1115.87 906.344C1008.93 1021.17 828.383 1028.31 712.563 922.297C596.743 816.28 589.551 637.264 696.472 522.448C803.405 407.633 983.955 400.49 1099.77 506.495C1215.57 612.5 1222.79 791.516 1115.87 906.344Z" fill="#D26698"/>
                                        <path d="M1113.42 906.875C1007.97 1020.1 829.931 1027.15 715.721 922.611C601.522 818.069 594.416 641.543 699.849 528.322C805.295 415.102 983.334 408.056 1097.54 512.599C1211.73 617.141 1218.84 793.655 1113.4 906.888L1113.42 906.875Z" fill="#D36594"/>
                                        <path d="M1110.98 907.406C1007.02 1019.03 831.48 1025.98 718.89 922.924C606.301 819.856 599.304 645.807 703.238 534.182C807.197 422.557 982.724 415.608 1095.33 518.676C1207.9 621.744 1214.91 795.769 1110.97 907.406H1110.98Z" fill="#D46490"/>
                                        <path d="M1108.53 907.951C1006.06 1017.98 833.027 1024.83 722.047 923.239C611.067 821.646 604.168 650.087 706.627 540.057C809.098 430.028 982.114 423.187 1093.11 524.769C1204.07 626.362 1210.99 797.909 1108.53 907.939V907.951Z" fill="#D5638C"/>
                                        <path d="M1106.08 908.483C1005.09 1016.92 834.576 1023.67 725.205 923.554C615.834 823.434 609.032 654.365 710.004 545.931C810.988 437.497 981.505 430.753 1090.89 530.872C1200.25 630.991 1207.05 800.048 1106.08 908.483Z" fill="#D66188"/>
                                        <path d="M1103.63 909.015C1004.13 1015.85 836.124 1022.5 728.362 923.868C620.6 825.211 613.895 658.644 713.38 551.805C812.877 444.966 980.883 438.319 1088.66 536.963C1196.41 635.608 1203.11 802.176 1103.63 909.015Z" fill="#D76085"/>
                                        <path d="M1101.18 909.546C1003.17 1014.79 837.673 1021.34 731.52 924.17C625.368 826.988 618.773 662.91 716.77 557.666C814.78 452.423 980.275 445.884 1086.44 543.055C1192.58 640.225 1199.19 804.302 1101.19 909.546H1101.18Z" fill="#D85F81"/>
                                        <path d="M1098.74 910.078C1002.22 1013.73 839.233 1020.18 734.689 924.484C630.146 828.776 623.648 667.188 720.159 563.54C816.681 459.892 979.665 453.45 1084.22 549.146C1188.75 644.842 1195.25 806.43 1098.74 910.078Z" fill="#D95E7D"/>
                                        <path d="M1096.29 910.61C1001.25 1012.66 840.782 1019.02 737.847 924.799C634.913 830.565 628.513 671.467 723.536 569.414C818.571 467.361 979.044 461.016 1081.99 555.237C1184.91 649.458 1191.31 808.557 1096.29 910.61Z" fill="#DA5C79"/>
                                        <path d="M1093.84 911.143C1000.29 1011.6 842.329 1017.85 741.003 925.102C639.678 832.343 633.388 675.735 726.924 575.277C820.472 474.819 978.433 468.583 1079.77 561.33C1181.08 654.077 1187.39 810.685 1093.84 911.143Z" fill="#DC5B75"/>
                                        <path d="M1091.39 911.675C999.327 1010.54 843.864 1016.69 744.16 925.417C644.444 834.132 638.251 680.001 730.3 581.151C822.361 482.289 977.811 476.149 1077.54 567.421C1177.23 658.694 1183.44 812.813 1091.39 911.675Z" fill="#DD5A72"/>
                                        <path d="M1088.94 912.206C998.364 1009.46 845.413 1015.53 747.318 925.73C649.211 835.92 643.116 684.279 733.677 587.024C824.263 489.769 977.19 483.714 1075.31 573.512C1173.39 663.31 1179.5 814.951 1088.94 912.206Z" fill="#DE596E"/>
                                        <path d="M1086.49 912.737C997.401 1008.4 846.961 1014.37 750.475 926.031C653.989 837.696 647.992 688.544 737.066 592.885C826.164 497.225 976.592 491.267 1073.09 579.602C1169.56 667.926 1175.57 817.077 1086.49 912.737Z" fill="#DF586A"/>
                                        <path d="M1084.05 913.268C996.45 1007.33 848.521 1013.19 753.644 926.345C658.768 839.484 652.868 692.822 740.455 598.757C828.066 504.693 975.983 498.844 1070.87 585.693C1165.74 672.542 1171.64 819.203 1084.05 913.268Z" fill="#E05666"/>
                                        <path d="M1081.6 913.8C995.475 1006.27 850.07 1012.03 756.802 926.659C663.535 841.272 657.732 697.1 743.832 604.631C829.956 512.162 975.361 506.409 1068.64 591.784C1161.9 677.158 1167.7 821.331 1081.6 913.8Z" fill="#E15562"/>
                                        <path d="M1079.15 914.344C994.512 1005.22 851.618 1010.89 759.959 926.973C668.301 843.061 662.596 701.379 747.22 610.505C831.857 519.632 974.752 513.975 1066.42 597.888C1158.07 681.788 1163.77 823.47 1079.15 914.344Z" fill="#E2545E"/>
                                        <path d="M1076.7 914.875C993.55 1004.15 853.167 1009.71 763.117 927.287C673.068 844.849 667.472 705.656 750.598 616.378C833.747 527.1 974.13 521.552 1064.19 603.978C1154.23 686.403 1159.82 825.596 1076.7 914.875Z" fill="#E3535B"/>
                                        <path d="M1074.25 915.407C992.587 1003.09 854.715 1008.55 766.275 927.601C677.834 846.638 672.336 709.935 753.987 622.252C835.649 534.569 973.521 529.118 1061.97 610.082C1150.4 691.033 1155.9 827.736 1074.26 915.419L1074.25 915.407Z" fill="#E45257"/>
                                        <path d="M1071.8 915.941C991.623 1002.03 856.25 1007.39 769.431 927.906C682.6 848.405 677.199 714.204 757.363 628.116C837.538 542.028 972.898 536.674 1059.74 616.163C1146.56 695.64 1151.96 829.853 1071.8 915.941Z" fill="#E55053"/>
                                        <path d="M1069.36 916.472C990.672 1000.96 857.81 1006.23 772.6 928.219C687.378 850.193 682.088 718.469 760.751 633.977C839.44 549.485 972.289 544.24 1057.52 622.242C1142.73 700.244 1148.02 831.968 1069.36 916.46V916.472Z" fill="#E74F4F"/>
                                        <path d="M1066.91 917.004C989.71 999.901 859.359 1005.06 775.758 928.534C692.145 851.982 686.952 722.748 764.141 639.851C841.342 556.954 971.692 551.806 1055.31 628.345C1138.89 704.885 1144.1 834.119 1066.92 917.004H1066.91Z" fill="#E84E4B"/>
                                        <path d="M1064.46 917.534C988.746 998.836 860.907 1003.9 778.903 928.834C696.899 853.757 691.804 727.012 767.505 645.711C843.231 564.421 971.058 559.357 1053.06 634.423C1135.04 709.488 1140.15 836.232 1064.45 917.522L1064.46 917.534Z" fill="#E94D47"/>
                                        <path d="M1062.01 918.068C987.784 997.762 862.456 1002.74 782.061 929.15C701.667 855.547 696.681 731.293 770.883 651.587C845.121 571.892 970.437 566.925 1050.84 640.516C1131.21 714.107 1136.21 838.361 1062.01 918.056V918.068Z" fill="#EA4B44"/>
                                        <path d="M1059.56 918.597C986.819 996.696 864.003 1001.57 785.217 929.462C706.443 857.334 701.543 735.569 774.27 657.458C847.021 579.359 969.826 574.5 1048.62 646.617C1127.39 718.733 1132.29 840.498 1059.56 918.597Z" fill="#EB4A40"/>
                                        <path d="M1057.12 919.129C985.869 995.633 865.564 1000.41 788.387 929.765C711.223 859.111 706.42 739.835 777.66 663.32C848.924 586.816 969.218 582.054 1046.41 652.696C1123.56 723.338 1128.36 842.613 1057.12 919.117V919.129Z" fill="#EC493C"/>
                                    </g>
                                </svg>

                                </span>
                                
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}