import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ProCancel } from '../../common/icons/skills-cancel';
import {useGetDiversityQuery, useGetSkillQuery} from "../../../../store/api/api";

export const DiversityPopup = (props) => {
    const selectedDiversity = useSelector(state => {return state.projectEditStore.diversity?state.projectEditStore.diversity:[]});
    const selectStore = useSelector(state => state.selectStore);
    const [diversityLabel, setdiversityLabel] = useState([]);
    // const [showok, setshowok] = useState(false);
    const [diversity,setdiversity] = useState([]);
    const dailogRef = useRef();
    const diversityRef = useRef();
    const [isOpen, setisOpen] = useState(false);
    const dispatch = useDispatch();
    const { data: diversityLookupData, error: diversityLookupError, isLoading: diversityLookupIsLoading } = useGetDiversityQuery();


    useEffect(()=> {
        if (props.open === undefined) {
            return
        }
        console.log("props.open",props.open)
        if(!props.open){
            const popup = document.querySelector(".diversity-popup")
            if(popup){
                popup.classList.add("slide-right")
            }
            setTimeout(() => {
                setisOpen(false);
            },100)
        }else{
            setisOpen(true)
        }
    },[props.open])
    
    const { setValue } = useForm({
        mode: "all",
        reValidateMode: "onChange", criteriaMode: "all"
    });


    
    function diversityOnchange(val, i, e) {
        if (selectedDiversity.includes(val.diversity_id)) {
            dispatch({type:"project-edit/remove",payload:{"key":"diversity","value":val.diversity_id}})
        } else {
            dispatch({type:"project-edit/append",payload:{"key":"diversity","value":val.diversity_id}})
        }


    }
    function submitHandler(div) {
        // let val;
        // if(div && div.length){
        //     val = div ? div.map(a => a.diversity_id) : [];
        // }else{
        //     val = diversity ? diversity.map(a => a.diversity_id) : [];
        // }
        // var _data = JSON.parse(JSON.stringify(projectSetupStore.page3));
        // _data["diversity"] = diversity;
        // dispatch({ type: 'project_setup/insertValue', payload: { "id": _data, "name": "page3" } });
        // props.saveData({ "diversity": val });
        // // setshowok(true)
        // props.close();
    }
    
    function skipHandler() {
        // const val = [diversityLabel[0]].map(a => a.diversity_id);
        // var _data = JSON.parse(JSON.stringify(projectSetupStore.page3));
        // _data["diversity"] = [diversityLabel[0]];
        // dispatch({ type: 'project_setup/insertValue', payload: { "id": _data, "name": "page3" } });
        // props.saveData({ "diversity": val });
        // // setshowok(true)
        // // submitHandler([diversityLabel[0]])
        // props.close();
    }

    if(isOpen){
        console.log("OPENNNN",isOpen,props.typ)
    return(
        <div className="relative z-10 mobile:w-full project-diversity-edge" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 overlay-background bg-opacity-75 backdrop-blur-sm transition-opacity mobile:hidden"></div>
            <div className={`fixed inset-0 z-10 diversity-popup  h-full mobile:relative mobile:h-auto mobile:w-full ${props.open ? "slide-left" : ""}`}>
                <div ref={dailogRef} className='absolute flex flex-col justify-between top-0 right-0 bg-[#ffffff] w-[485px] shadow-md mobile:relative mobile:shadow-none mobile:w-full mobile:p-0 dark:bg-[#0E0E0E] md:h-[100vh]'>
                    <div className="h-[calc(100%-259px)] w-full flex flex-col gap-[16px] mobile:h-auto">
                        <div className='flex border-b border-solid mx-[32px] pt-[32px] border-[#d9d9d9] pb-[16px] mobile:hidden'>
                            <div className='flex flex-wrap gap-1 text-left'>
                                <p className='text-[#07091b] text-[20px] leading-[130%] tracking-[-0.6px] dark:text-[#fff]'>01. Diversity</p>
                                <p className='text-[#a3a3a3] text-[14px] leading-[130%] tracking-[-0.42px] dark:text-[#A3A3A3]'>Inclusivity and diversity are essential for fostering creativity. If you are seeking a particular affinity, please inform us.</p>
                            </div>
                            <div className="absolute right-0 xs:mt-[-10px]  pr-7 sm:block mt-[4px]">
                                <button type="button" onClick={props.close} className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none pr-[7px]"><ProCancel/></button>
                            </div>
                        </div> 
                        <div className='w-full h-[calc(100%-5px)] flex flex-col mobile:mt-[24px] mobile:pt-[24px] mobile:box-border mobile:border-t mobile:border-solid mobile:border-[#d9d9d9] mobile:dark:border-[#A3A3A3]'>
                            <div ref={diversityRef} className='h-[full] mobile:gap-[6px] mobile:items-start [@media(min-width:768px)]:px-[32px] flex flex-col box-border [@media(min-width:700px)]:max-h-[750px] mobile:!max-h-none dark:border-[#A3A3A3] [@media(min-width:768px)]:overflow-y-auto '>
                                {diversityLookupData.data.map((data,i) => {
                                    return(
                                        <div className='diversity-popup input-fld mb-0 group child-hover:child:text-[#4C4C4C]  dark:child-hover:child:text-[#FFF]' key={i} onClick={(e) => { diversityOnchange(data,i, e) }}>
                                            <div className=' flex items-center justify-between py-[16px] border-y-[10px] border border-white dark:border-black pr-[10px] transition-all hover:bg-[#f5f5f5] dark:hover:bg-[#4C4C4C] hover:text-[#4C4C4C] dark:hover:text-[#FFF] rounded-[8px] hover:p-[10px] h-[73px] dark:text-[#A3A3A3] text-[#A3A3A3]'>{data.label}
                                            <label className="control control--checkbox text-[#a3a3a3] group-hover:text-[#1d1d1d] mr-[10px] peer-checked:text-[#1d1d1d] text-[14px]" htmlFor={i}> 
                                                <input type="checkbox" checked={selectedDiversity.includes(data.diversity_id)}  id={i} className='h-[24px] peer w-[24px]' onChange={() => { diversityOnchange(data,i) }}/>
                                                <div className="control__indicator"></div>
                                            </label>
                                            </div>
                                            <hr className={`${i >= diversityLabel.length-1 ? 'hidden': ''} box-border border-solid border-[#ececec] dark:border-[#A3A3A3]`}/>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex md:p-[32px] flex-wrap gap-[32px] bottom-0'>
                        <div className='w-full flex flex-col gap-[16px]'>
                       <button className='text-[#1D1D1D] font-bold text-[16px] leading-[130%] tracking-[-0.48px] border border-black rounded-full w-full h-[45px] dark:text-[#fff] dark:bg-[#0E0E0E] dark:border-[1px] dark:border-[solid] dark:border-[#fff] mobile:hidden' onClick={props.close}>Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
   }
}