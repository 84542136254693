import React, {useState} from "react";
import {MiniLogo} from "../common/icons/mini-logo";
import moonlogin from "../../../assets/img/logo-title.svg";
import moonlight from "../../../assets/img/logo-title.svg";
import moondark from "../../../assets/img/logo-title.svg";
import DynamicLoader from "../../../utils/dynamicLoader";
import classNames from "classnames";


export const ClientRegister = (props) => {
    const [ready, setReady] = useState(false)
    const [email, setEmail] = useState()
    const [emailErr, setEmailErr] = useState()
    const [first, setFirst] = useState()
    const [last, setLast] = useState()
    const [linkedin, setLinkedin] = useState()
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [linkedinErr, setLinkedinErr] = useState()
    const [passwordErr, setPasswordErr] = useState()
    const [confirmPasswordErr, setConfirmPasswordErr] = useState()
    const [waitingForEmailVerification,setWaitingForEmailVerification] = useState(false)

    function isValidEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // First, check if the email matches the basic structure of an email address
        if (!regex.test(email)) {
            return false;
        }

        return true;
    }

    function isBusinessEmail(email) {
        const blockedDomains = ["gmail.com", "hotmail.com", "yahoo.com"]; // Add more as needed

        // Extract the domain from the email
        const domain = email.split('@')[1];

        // Check if the domain is in the list of blocked domains
        if (blockedDomains.includes(domain.toLowerCase())) {
            return false;
        }

        return true
    }

    function isValidLinkedInProfile(url) {
        // Regular expression to match the structure of a LinkedIn profile URL
        const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[A-Za-z0-9-_\u00C0-\u00FF]+\/?$/;

        return regex.test(url);
    }

    function validatePassword(password) {
        // Check if password length is at least 8 characters
        if (password.length < 8) {
            return "Password should be at least 8 characters long";
        }

        // Check if password contains at least one uppercase letter
        const hasUppercase = /[A-Z]/.test(password);
        if (!hasUppercase) {
            return "Password should include uppercase letter";
        }

        // Check if password contains at least one lowercase letter
        const hasLowercase = /[a-z]/.test(password);
        if (!hasLowercase) {
            return "Password should include lowercase letter";
        }

        // Check if password contains at least one number
        const hasNumber = /\d/.test(password);
        if (!hasNumber) {
            return "Password should include number letter";
        }

        // If all conditions are met, the password is valid
        return null;
    }

    function validateComparePasswords(password,confirmPassword) {
        if (password && confirmPassword) {
            return password === confirmPassword
        }

        return true
    }

    function onEmailChange(email) {
         setEmail(email);
         if (!isValidEmail(email)) {
             setEmailErr("Invalid email.")
         } else if (!isBusinessEmail(email)) {
             setEmailErr("Only business emails are allowed.")
         } else {
             setEmailErr(null)
         }
        isReady()
    }



    function onLinkedinUrlChange(url) {
        setLinkedin(url);
        if (!isValidLinkedInProfile(url)) {
            setLinkedinErr("Must be a valid Linkedin profile address.")
        } else {
            setLinkedinErr(null)
        }
        isReady()
    }

    function onPasswordChange(password) {
        setPassword(password);
        let err = validatePassword(password)
        if (err) {
            setPasswordErr(err)
        } else {
            setPasswordErr(null)
        }
        isReady()
    }

    function onConfirmPasswordChange(confirmPassword) {
        setConfirmPassword(confirmPassword);
        if (!validateComparePasswords(password,confirmPassword)) {
            setConfirmPasswordErr("Passwords are not the same")
        } else {
            setConfirmPasswordErr(null)
        }
        isReady()
    }

    function isReady() {
        setReady(email && !emailErr && first && last && linkedin && !linkedinErr && password && !passwordErr && confirmPassword && !confirmPasswordErr)
    }

    if (waitingForEmailVerification) {
        return <DynamicLoader name="Check your inbox...." ></DynamicLoader>
    }


    return (
        <div className="page-section">
            <div className="header">
                <div className="logo p-16">
                    <img className={`${props.isLogin ? "!hidden" : "[@media(max-width:999px)]:hidden"} dark:hidden  block h-[50px]`}  src={moonlight} alt="carrousela"/>
                    {!props.isLogin && <span className="flex [@media(min-width:999px)]:hidden h-[48px] w-[48px] items-center"><MiniLogo/></span> }
                    <img className={`${props.isLogin ? "!block" : "[@media(max-width:999px)]:!hidden"} dark:block hidden h-[50px]`}  src={moondark} alt="carrousela"/>
                </div>
            </div>
            <div className="main-container">
                <div className='h-full [@media(min-width:1000px)]:grid [@media(max-width:1000px)]:place-items-center [@media(min-width:1000px)]:grid-cols-2 [@media(max-width:1000px)]:overflow-auto  flex flex-col w-full '>
                    <div className='self-center left-dark [@media(min-width:1000px)]:max-h-full [@media(min-width:1000px)]:overflow-y-auto [@media(max-width:999px)]:text-center [@media(max-width:1000px)]:bg-[#f5f5f5]  text-white flex [@media(max-width:1000px)]:justify-center justify-end w-full'>
                       <div className='[@media(max-width:1335px)]:p-10 [@media(min-width:1335px)]:pr-[8.5rem] [@media(min-width:1335px)]:w-[623px] flex gap-4 w-full align-right'>
                           <svg width="20" height="45" viewBox="0 0 20 45" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.13654 10.0672L16.6827 22.4457C16.6827 22.4457 22.6792 17.6711 16.3483 12.6301C14.3898 11.0708 6.41555 4.72135 0.976562 0.390625L1.13654 10.0672Z" fill="#F05831"></path><path d="M1.13653 34.8238L16.6827 22.4453C16.6827 22.4453 22.6792 27.2199 16.3482 32.2608C14.3898 33.8202 6.41555 40.1696 0.976562 44.5003L1.13653 34.8238Z" fill="#F05831"></path></svg>
                           <div className="flex-col">
                               <span className="text-[48px] text-black font-extralight leading-[120%] inline-block align-center normal-case">Welcome <span className="text-[48px] font-bold">aboard</span></span>
                               <p className="form-sub-head">Register as a client.</p>
                           </div>
                       </div>
                    </div>
                    <div className='self-center right-dark max-h-full w-full  [@media(max-width:640px)]:mt-5 [@media(min-width:1000px)]:overflow-auto pt-5'>
                       <div className="
                          [@media(max-width:1335px)]:p-10 [@media(min-width:1335px)]:pl-[8.5rem] [@media(max-width:1335px)]:m-auto w-full [@media(min-width:1000px)]:w-[494px] [@media(max-width:1000px)]:mx-auto max-w-[445px] [@media(min-width:1000px)]:max-w-[496px]">
                          <form className="form-layer  space-y-12">
                              <div className="field-group">
                                  <input type="text" name="email" onChange={(ev) => onEmailChange(ev.target.value)} placeholder=" " className="input-fld"/>
                                      <span className="absolute bottom-[8px] right-[1px]">
                                          {email && !emailErr && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="tick-icon dark:hidden"><path d="M7.99651 15.5C12.1179 15.5 15.5 12.1248 15.5 8.00349C15.5 3.88215 12.1179 0.5 7.99651 0.5C3.87517 0.5 0.5 3.88215 0.5 8.00349C0.5 12.1248 3.87517 15.5 7.99651 15.5ZM7.2364 11.546C6.88773 11.546 6.60879 11.3856 6.37169 11.0997L4.79568 9.24477C4.60042 9.02162 4.53766 8.84031 4.53766 8.61018C4.53766 8.11506 4.94212 7.73152 5.42329 7.73152C5.70223 7.73152 5.91841 7.8431 6.11367 8.07322L7.22245 9.39121L9.80265 5.31869C10.0188 4.98396 10.2629 4.80962 10.5837 4.80962C11.0649 4.80962 11.4972 5.16527 11.4972 5.65342C11.4972 5.8417 11.4344 6.05091 11.295 6.24616L8.07322 11.0788C7.87796 11.3717 7.57113 11.546 7.2364 11.546Z" fill="#1D1D1D"></path></svg>}
                                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="tick-icon hidden dark:block"><path d="M7.99651 15.6011C12.1179 15.6011 15.5 12.2259 15.5 8.10456C15.5 3.98322 12.1179 0.601074 7.99651 0.601074C3.87517 0.601074 0.5 3.98322 0.5 8.10456C0.5 12.2259 3.87517 15.6011 7.99651 15.6011ZM7.2364 11.6471C6.88773 11.6471 6.60879 11.4867 6.37169 11.2008L4.79568 9.34584C4.60042 9.12269 4.53766 8.94138 4.53766 8.71126C4.53766 8.21614 4.94212 7.83259 5.42329 7.83259C5.70223 7.83259 5.91841 7.94417 6.11367 8.1743L7.22245 9.49229L9.80265 5.41976C10.0188 5.08503 10.2629 4.9107 10.5837 4.9107C11.0649 4.9107 11.4972 5.26635 11.4972 5.75449C11.4972 5.94278 11.4344 6.15198 11.295 6.34724L8.07322 11.1799C7.87796 11.4728 7.57113 11.6471 7.2364 11.6471Z" fill="white"></path></svg></span><label htmlFor="email" className="input-lbl">Your work email</label>
                              </div>
                              <div className="form-error"><span>{emailErr}</span></div>
                              <div className="field-group">
                                  <input type="text" name="first" placeholder=" " onChange={(ev) => {setFirst(ev.target.value);isReady()}}  className="input-fld"/>
                                  <span className="absolute bottom-[8px] right-[1px]">
                                         {first && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="tick-icon dark:hidden"><path d="M7.99651 15.5C12.1179 15.5 15.5 12.1248 15.5 8.00349C15.5 3.88215 12.1179 0.5 7.99651 0.5C3.87517 0.5 0.5 3.88215 0.5 8.00349C0.5 12.1248 3.87517 15.5 7.99651 15.5ZM7.2364 11.546C6.88773 11.546 6.60879 11.3856 6.37169 11.0997L4.79568 9.24477C4.60042 9.02162 4.53766 8.84031 4.53766 8.61018C4.53766 8.11506 4.94212 7.73152 5.42329 7.73152C5.70223 7.73152 5.91841 7.8431 6.11367 8.07322L7.22245 9.39121L9.80265 5.31869C10.0188 4.98396 10.2629 4.80962 10.5837 4.80962C11.0649 4.80962 11.4972 5.16527 11.4972 5.65342C11.4972 5.8417 11.4344 6.05091 11.295 6.24616L8.07322 11.0788C7.87796 11.3717 7.57113 11.546 7.2364 11.546Z" fill="#1D1D1D"></path></svg>}
                                             <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="tick-icon hidden dark:block"><path d="M7.99651 15.6011C12.1179 15.6011 15.5 12.2259 15.5 8.10456C15.5 3.98322 12.1179 0.601074 7.99651 0.601074C3.87517 0.601074 0.5 3.98322 0.5 8.10456C0.5 12.2259 3.87517 15.6011 7.99651 15.6011ZM7.2364 11.6471C6.88773 11.6471 6.60879 11.4867 6.37169 11.2008L4.79568 9.34584C4.60042 9.12269 4.53766 8.94138 4.53766 8.71126C4.53766 8.21614 4.94212 7.83259 5.42329 7.83259C5.70223 7.83259 5.91841 7.94417 6.11367 8.1743L7.22245 9.49229L9.80265 5.41976C10.0188 5.08503 10.2629 4.9107 10.5837 4.9107C11.0649 4.9107 11.4972 5.26635 11.4972 5.75449C11.4972 5.94278 11.4344 6.15198 11.295 6.34724L8.07322 11.1799C7.87796 11.4728 7.57113 11.6471 7.2364 11.6471Z" fill="white"></path></svg>
                                         </span><label htmlFor="email" className="input-lbl">First name</label>
                              </div>
                              <div className="field-group">
                                  <input type="text" name="last" placeholder=" " onChange={(ev) => {setLast(ev.target.value);isReady()}}  className="input-fld"/>
                                  <span className="absolute bottom-[8px] right-[1px]">
                                          {last && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="tick-icon dark:hidden"><path d="M7.99651 15.5C12.1179 15.5 15.5 12.1248 15.5 8.00349C15.5 3.88215 12.1179 0.5 7.99651 0.5C3.87517 0.5 0.5 3.88215 0.5 8.00349C0.5 12.1248 3.87517 15.5 7.99651 15.5ZM7.2364 11.546C6.88773 11.546 6.60879 11.3856 6.37169 11.0997L4.79568 9.24477C4.60042 9.02162 4.53766 8.84031 4.53766 8.61018C4.53766 8.11506 4.94212 7.73152 5.42329 7.73152C5.70223 7.73152 5.91841 7.8431 6.11367 8.07322L7.22245 9.39121L9.80265 5.31869C10.0188 4.98396 10.2629 4.80962 10.5837 4.80962C11.0649 4.80962 11.4972 5.16527 11.4972 5.65342C11.4972 5.8417 11.4344 6.05091 11.295 6.24616L8.07322 11.0788C7.87796 11.3717 7.57113 11.546 7.2364 11.546Z" fill="#1D1D1D"></path></svg>}
                                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="tick-icon hidden dark:block"><path d="M7.99651 15.6011C12.1179 15.6011 15.5 12.2259 15.5 8.10456C15.5 3.98322 12.1179 0.601074 7.99651 0.601074C3.87517 0.601074 0.5 3.98322 0.5 8.10456C0.5 12.2259 3.87517 15.6011 7.99651 15.6011ZM7.2364 11.6471C6.88773 11.6471 6.60879 11.4867 6.37169 11.2008L4.79568 9.34584C4.60042 9.12269 4.53766 8.94138 4.53766 8.71126C4.53766 8.21614 4.94212 7.83259 5.42329 7.83259C5.70223 7.83259 5.91841 7.94417 6.11367 8.1743L7.22245 9.49229L9.80265 5.41976C10.0188 5.08503 10.2629 4.9107 10.5837 4.9107C11.0649 4.9107 11.4972 5.26635 11.4972 5.75449C11.4972 5.94278 11.4344 6.15198 11.295 6.34724L8.07322 11.1799C7.87796 11.4728 7.57113 11.6471 7.2364 11.6471Z" fill="white"></path></svg>
                                  </span><label htmlFor="email" className="input-lbl">Last name</label>
                              </div>
                              <div className="field-group">
                                  <input type="text" name="linkedin" placeholder=" " onChange={(ev) => onLinkedinUrlChange(ev.target.value)}  className="input-fld"/>
                                  <span className="absolute bottom-[8px] right-[1px]">
                                          {linkedin && !linkedinErr && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="tick-icon dark:hidden"><path d="M7.99651 15.5C12.1179 15.5 15.5 12.1248 15.5 8.00349C15.5 3.88215 12.1179 0.5 7.99651 0.5C3.87517 0.5 0.5 3.88215 0.5 8.00349C0.5 12.1248 3.87517 15.5 7.99651 15.5ZM7.2364 11.546C6.88773 11.546 6.60879 11.3856 6.37169 11.0997L4.79568 9.24477C4.60042 9.02162 4.53766 8.84031 4.53766 8.61018C4.53766 8.11506 4.94212 7.73152 5.42329 7.73152C5.70223 7.73152 5.91841 7.8431 6.11367 8.07322L7.22245 9.39121L9.80265 5.31869C10.0188 4.98396 10.2629 4.80962 10.5837 4.80962C11.0649 4.80962 11.4972 5.16527 11.4972 5.65342C11.4972 5.8417 11.4344 6.05091 11.295 6.24616L8.07322 11.0788C7.87796 11.3717 7.57113 11.546 7.2364 11.546Z" fill="#1D1D1D"></path></svg>}
                                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="tick-icon hidden dark:block"><path d="M7.99651 15.6011C12.1179 15.6011 15.5 12.2259 15.5 8.10456C15.5 3.98322 12.1179 0.601074 7.99651 0.601074C3.87517 0.601074 0.5 3.98322 0.5 8.10456C0.5 12.2259 3.87517 15.6011 7.99651 15.6011ZM7.2364 11.6471C6.88773 11.6471 6.60879 11.4867 6.37169 11.2008L4.79568 9.34584C4.60042 9.12269 4.53766 8.94138 4.53766 8.71126C4.53766 8.21614 4.94212 7.83259 5.42329 7.83259C5.70223 7.83259 5.91841 7.94417 6.11367 8.1743L7.22245 9.49229L9.80265 5.41976C10.0188 5.08503 10.2629 4.9107 10.5837 4.9107C11.0649 4.9107 11.4972 5.26635 11.4972 5.75449C11.4972 5.94278 11.4344 6.15198 11.295 6.34724L8.07322 11.1799C7.87796 11.4728 7.57113 11.6471 7.2364 11.6471Z" fill="white"></path></svg></span>
                                  <label htmlFor="email" className="input-lbl">Linkedin URL</label>
                              </div>
                              <div className="form-error"><span>{linkedinErr}</span></div>
                              <div className="field-group w-full">
                                  <input type="password" name="password" placeholder=" " className="input-fld" onChange={(ev) => onPasswordChange(ev.target.value)}/>
                                  <span className="absolute bottom-[8px] right-[1px]">
                                      {password && !passwordErr && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="tick-icon dark:hidden"><path d="M7.99651 15.5C12.1179 15.5 15.5 12.1248 15.5 8.00349C15.5 3.88215 12.1179 0.5 7.99651 0.5C3.87517 0.5 0.5 3.88215 0.5 8.00349C0.5 12.1248 3.87517 15.5 7.99651 15.5ZM7.2364 11.546C6.88773 11.546 6.60879 11.3856 6.37169 11.0997L4.79568 9.24477C4.60042 9.02162 4.53766 8.84031 4.53766 8.61018C4.53766 8.11506 4.94212 7.73152 5.42329 7.73152C5.70223 7.73152 5.91841 7.8431 6.11367 8.07322L7.22245 9.39121L9.80265 5.31869C10.0188 4.98396 10.2629 4.80962 10.5837 4.80962C11.0649 4.80962 11.4972 5.16527 11.4972 5.65342C11.4972 5.8417 11.4344 6.05091 11.295 6.24616L8.07322 11.0788C7.87796 11.3717 7.57113 11.546 7.2364 11.546Z" fill="#1D1D1D"></path></svg>}
                                  </span>
                                  <label htmlFor="password" className="input-lbl">Password</label>
                              </div>
                              <div className="form-error"><span>{passwordErr}</span></div>
                              <div className="field-group w-full">
                                  <input type="confirmPassword" name="confirmPassword" placeholder=" " className="input-fld" onChange={(ev) => onConfirmPasswordChange(ev.target.value)}/>
                                  <span className="absolute bottom-[8px] right-[1px]">
                                      {confirmPassword && !confirmPasswordErr && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="tick-icon dark:hidden"><path d="M7.99651 15.5C12.1179 15.5 15.5 12.1248 15.5 8.00349C15.5 3.88215 12.1179 0.5 7.99651 0.5C3.87517 0.5 0.5 3.88215 0.5 8.00349C0.5 12.1248 3.87517 15.5 7.99651 15.5ZM7.2364 11.546C6.88773 11.546 6.60879 11.3856 6.37169 11.0997L4.79568 9.24477C4.60042 9.02162 4.53766 8.84031 4.53766 8.61018C4.53766 8.11506 4.94212 7.73152 5.42329 7.73152C5.70223 7.73152 5.91841 7.8431 6.11367 8.07322L7.22245 9.39121L9.80265 5.31869C10.0188 4.98396 10.2629 4.80962 10.5837 4.80962C11.0649 4.80962 11.4972 5.16527 11.4972 5.65342C11.4972 5.8417 11.4344 6.05091 11.295 6.24616L8.07322 11.0788C7.87796 11.3717 7.57113 11.546 7.2364 11.546Z" fill="#1D1D1D"></path></svg>}
                                  </span>
                                  <label htmlFor="confirmPassword" className="input-lbl">Confirm Password</label>
                              </div>
                              <div className="form-error"><span>{confirmPasswordErr}</span></div>
                          </form>
                       </div>
                    </div>
                </div>
            </div>
            <footer className="footer mobile:p-[10px] py-[48px] px-[88px]">
                <div className="footer-layer"><div className="footer-split"><
                    div className="rounded-lg [@media(max-width:1000px)]:flex hidden items-center justify-start"></div>
                    <div className="flex-1 rounded-lg flex items-center justify-between [@media(max-width:1000px)]:justify-end"><div>

                    </div><div className="flex"></div><div className="[@media(max-width:1000px)]:flex"><div></div>
                        <button disabled={!ready}
                                className={classNames({
                            "inline-flex items-center rounded-full border border-transparent dark:bg-[#FFFFFF] enabled:dark:hover:!bg-[#F5F5F5] enabled:hover:bg-[#4C4C4C] bg-[#1D1D1D] dark:text-[#090909] text-[#FFFFFF] px-[24px] py-[12px] text-base font-bold shadow-sm focus:outline-none h-[48px]":true,
                            "bg-opacity-50 cursor-not-allowed ": !ready,
                        })}  onClick={() => {setWaitingForEmailVerification(true)}}>Send Verification Email <span className="ml-3 [@media(min-width:1000px)]:hidden block"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_4417_34088)"><path className=" dark:fill-black dark:stroke-black fill-white stroke-white" d="M14.0384 18.1788L13.8636 18.3496L13.6889 18.1788L12.7275 17.2388L12.5448 17.0601L12.7274 16.8814L16.776 12.9167L5 12.9167H4.75V12.6667V11.3333V11.0833H5L16.776 11.0833L12.7274 7.11862L12.5448 6.93986L12.7275 6.76125L13.6889 5.82125L13.8636 5.65035L14.0384 5.82125L20.1748 11.8212L20.3576 12L20.1748 12.1788L14.0384 18.1788Z" fill="#090909" stroke="#090909" stroke-width="0.5"></path></g><defs><clipPath id="clip0_4417_34088"><rect width="24" height="24" fill="white"></rect></clipPath></defs></svg></span><div className="ml-3 [@media(min-width:1000px)]:block hidden"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_4417_34088)"><path className=" dark:fill-black dark:stroke-black fill-white stroke-white" d="M14.0384 18.1788L13.8636 18.3496L13.6889 18.1788L12.7275 17.2388L12.5448 17.0601L12.7274 16.8814L16.776 12.9167L5 12.9167H4.75V12.6667V11.3333V11.0833H5L16.776 11.0833L12.7274 7.11862L12.5448 6.93986L12.7275 6.76125L13.6889 5.82125L13.8636 5.65035L14.0384 5.82125L20.1748 11.8212L20.3576 12L20.1748 12.1788L14.0384 18.1788Z" fill="#090909" stroke="#090909" stroke-width="0.5"></path></g><defs><clipPath id="clip0_4417_34088"><rect width="24" height="24" fill="white"></rect></clipPath></defs></svg></div></button></div></div></div></div></footer>
        </div>
    )
}