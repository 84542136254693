import React from "react";
import {useGetAwardQuery, useGetWorkQuery} from "../../../store/api/api";


export default function AboutAwards({awards}) {

    // Step 1: Count occurrences
    const awardCounts = awards.reduce((acc, obj) => {
        const awardId = obj.award_id;
        acc[awardId] = (acc[awardId] || 0) + 1;
        return acc;
    }, {});

     // Step 2: Convert to array and sort by frequency
     awards = Object.entries(awardCounts).sort((a, b) => b[1] - a[1]);

     if (!awards || awards.length === 0) {
         return (<></>)
     }

    return (
        <div className="flex flex-col gap-[8px]">
            <span className="text-[#808080] dark:text-[#A3A3A3] text-[14px]">Awards</span>
            <div className="grid grid-cols-3">
                {awards && awards.map((award,i) => {
                    return (<span key={i} className="flex text-[24px] font-medium [@media(max-width:720px)]:w-full [@media(max-width:720px)]:float-none mb-[16px] w-1/2 text-[#0E0E0E] dark:text-[#FFF] float-left whitespace-pre-wrap">
                                <div className="w-[38px] h-[38px]">
                                   <img src={"https://static.moonshoot.inc/app/awards/" + award[0] + ".png"} alt="logo"/>
                               </div> {' x ' + award[1]}
                            </span>)
                })}
             </div>
        </div>

    )

}

