import {EditIcon} from "../common/icons/edit-icon";


export default function Experience({agency,projects}) {
    console.log("agency",agency)
    return (<>
        <div className="text-4xl font-semibold pb-4">
            Experience
        </div>
        <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-y-[12px] gap-x-[8px] lg:gap-x-[5px]">
        {agency.brands && agency.brands.map((brand, i) => {
            return <div className="group relative h-36 grayscale m-1 border-2 py-4 rounded-lg">
                       <img src={brand.logo} alt={brand.label} className="h-full w-full rounded-lg object-contain" />
                  </div>
        })}
            {projects && projects.map((project, i) => {
                return <div className="group relative h-36 grayscale m-1 border-2 py-4 rounded-lg">
                    <img src={project.company.logo} alt={project.company.label} className="h-full w-full rounded-lg object-contain" />
                </div>
            })}
        </div>
    </>)
}
