import { useEffect, useRef, useState } from 'react';
// import { Cancel } from '../common/icons/cancel';
import { useForm } from 'react-hook-form';
// import Select from "react-select";
// import { Dropdown } from '../common/icons/dropdown';
// import { Dollar } from '../common/icons/dollar';
import FormValidationIcon from '../../../utils/form-validation-icon';
import FormFieldError from '../../../utils/error-message';
import { useDispatch, useSelector } from 'react-redux';
import common from '../../../utils/common';
import { ProCancel } from '../common/icons/skills-cancel';
// import { QuestionIcon } from '../common/icons/question';
import greyquestion from "../../../assets/img/questioncircle-grey.png";
import question from "../../../assets/img/questioncircle.png";
import { Tooltip as ReactTooltip } from "react-tooltip";

export const BudgetPopup = (props) => {
    const projectSetupStore = useSelector(state => state.projectSetupStore)
    // const [currency, setCurrency] = useState('$USD');
    // const [budget, setBudget] = useState();
    const [isChecked, setisChecked] = useState(true);
    const [isChecked2, setisChecked2] = useState(true);
    // const [currenyLabel, setCurrenyLabel] = useState(false);
    // const [currenyOptions, setCurrencyOptions] = useState([
    //     {
    //         "value": "$USD",
    //         "label": "$ USD"
    //     }]);
    const { register, trigger, formState: { errors, isValid, touchedFields, dirtyFields }, setValue, getValues, watch } = useForm({
        mode: "all",
        reValidateMode: "onChange", criteriaMode: "all", shouldUnregister: true
    });
    let totalBudget = 0;
    const [isOpen, setisOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=> {
        if(!props.open){
            const popup = document.querySelector(".budget-popup")
            if(popup){
                popup.classList.add("slide-right")
            }
            setTimeout(() => {
                setisOpen(false);
            },100)
        }else{
            setisOpen(true)
        }
    },[props.open])    
    const dailogRef = useRef();
    

    useEffect(() => {
        // setCurrencyOptions([
        //     {
        //         "value": "$USD",
        //         "label": "$ USD"
        //     }])
        Object.entries(projectSetupStore.page5).forEach(val => {
            if (val[1] && (val[1] !== "" || (Array.isArray(val[1]) && val[1].length))) {
                if(val[0] === 'budget' || val[0] === 'production_budget' || val[0] === 'creative_budget' || val[0] === 'media_budget') {
                    setValue(val[0], val[1] ? '$' + val[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :  val[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),{ shouldTouch: true, shouldValidate: true, shouldDirty: true});
                } else {
                    setValue(val[0], val[1], { shouldTouch: true, shouldValidate: true, shouldDirty: true});
                }
            }
            if(val[0] === "separate_budget_information") {
                setValue('separate_budget_information', !val[1])
                setisChecked2(!val[1])
            }
            if(val[0] === "share_budget_information") {
                setValue("share_budget_information", !val[1]);
                setisChecked(!val[1])
            }
            // if(val[0] === 'budget_currency') {
            //     var index = currenyOptions.findIndex(x => x.value === val[1]);
            //     if(index !== -1){
            //         setCurrency(currenyOptions[index])
            //     }
            // }
        })
       
    }, [])

    function toggleChangeHandler() {
        setisChecked(!isChecked);
    }

    function secondtoggleHandler() {
        if (!isChecked && isChecked2) {
            setisChecked2(false);
        } else {
            setisChecked2(true);
        }
    }

    function getTotal() {
        var ttl = 0;
        if (watch('creative_budget') || getValues('creative_budget')) {
            ttl += parseInt(watch('creative_budget').replaceAll(',', '').replaceAll('$', ''))
        } if (watch('production_budget') || getValues('production_budget')) {
            ttl += parseInt(watch('production_budget').replaceAll(',', '').replaceAll('$', ''))
        } if (watch('media_budget') || getValues('media_budget')) {
            ttl += parseInt(watch('media_budget').replaceAll(',', '').replaceAll('$', ''));
        }
        totalBudget = ttl;
        return ttl ? '$' + ttl.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
    }

    /** HANDLES YEAR DROPDOWN SELECT ONCHANGE */
    // function handleCurrencySelectOnchange(e) {
    //     try {
    //         if (e) {
    //             setCurrency(e);
    //             setCurrenyLabel(true)
    //             setValue("budget_currency", e.value, { shouldTouch: true, shouldValidate: true, shouldDirty: true });
    //         } else {
    //             setCurrency("");
    //             setCurrenyLabel(false)
    //             setValue("budget_currency", '', { shouldTouch: false, shouldValidate: false, shouldDirty: false });
    //         }
    //     } catch (err) { console.log(err); }
    // }

    function submitHandler() {
        if (isValid) {
            var data = getValues();
            data['separate_budget_information'] = !isChecked2;
            data['share_budget_information'] = !isChecked;
            // data['budget_currency'] = '$USD';
            if(data['share_budget_information'] && !data['separate_budget_information']) {
                data['production_budget'] = 0;
                data['creative_budget'] = 0;
                data['media_budget'] = 0;
                totalBudget = 0;
                data['budget'] = parseInt(data['budget'].replaceAll(',', '').replaceAll('$', ''));
            } else if(data['separate_budget_information']) {
                data['budget'] = parseInt(totalBudget.toString().replaceAll(',', '').replaceAll('$', ''));
                data['production_budget'] = parseInt(data['production_budget'].replaceAll(',', '').replaceAll('$', ''));
                data['creative_budget'] = parseInt(data['creative_budget'].replaceAll(',', '').replaceAll('$', ''));
                data['media_budget'] = parseInt(data['media_budget'].replaceAll(',', '').replaceAll('$', ''));
            }
            var newObj = {}
            Object.entries(data).forEach(val => {
                if(typeof val[1] !== "string" && isNaN(val[1])) {
                    val[1] = 0
                }
                newObj[val[0]] = val[1]
            })
            dispatch({ type: 'project_setup/insertValue', payload: {"id":newObj,"name":"page5"} });
            props.saveData(newObj);
            props.close();
        } else {
            trigger();
        }
    }

    function currencyOnChange(e, fieldname) {
        let val = e.target.value.replaceAll(',', '');
        val = val.replaceAll('$', '');
        var _val =  val ? '$' +val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setValue(fieldname, _val)
    }

    if(isOpen){
    return (
        <div className="relative z-10 mobile:w-full" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 overlay-background bg-opacity-75 backdrop-blur-sm transition-opacity mobile:hidden"></div>
            <div className={`${props.open ? "slide-left" : ""} budget-popup fixed inset-0 z-10 overflow-y-auto h-full mobile:relative mobile:h-auto mobile:w-full`}>
                <div className='absolute z-10 h-full p-[32px] top-0 right-0 bg-[#ffffff] w-[485px] shadow-md mobile:relative mobile:shadow-none mobile:w-full mobile:p-0 mobile:h-auto dark:bg-[#0E0E0E]' ref={dailogRef}>
                    <div className="w-full flex flex-wrap">
                        <div className='flex mobile:hidden'>
                            <div className='flex flex-wrap gap-1 text-left'>
                                <p className='text-[#07091b] text-[20px] leading-[130%] tracking-[-0.6px] dark:text-[#fff]'>06. Budget</p>
                                <p className='text-[#a3a3a3] text-[14px] leading-[130%] tracking-[-0.42px] dark:text-[#808080]'>Specifying your budget helps us match you with the perfect team. You can skip this step if you don’t have that information yet. You’ll be able to discuss and adjust when talking with teams. </p>
                            </div>
                            <div className="absolute right-0 xs:mt-[-10px] pr-7 sm:block mt-[4px]">
                                <button type="button" onClick={props.close} className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none pr-[7px]"><ProCancel/></button>
                            </div>
                        </div>
                        <hr className='w-full mt-[24px] pt-[24px] box-border border-t border-solid border-[#d9d9d9]' />
                        <div className='flex flex-col items-end gap-[48px]'>
                            <div className='w-full flex justify-center items-center gap-[40px] '>
                                <span className='text-[16px] font-[#000] font-medium archivo tracking-[-0.48px] leading-[20.8px]'>Do you want to share information on your budget?</span>
                                <div className='flex items-center gap-[4px]'><span className='text-[16px] font-[#000] font-medium'>No</span>
                                    <label className="z-10 switch">
                                        <input className='toggle-checkbox' checked={isChecked} {...register('share_budget_information')} onChange={() => toggleChangeHandler()} type='checkbox'></input>
                                        <div className='toggle-slot dark:border-[1px] dark:border-white w-[46px]'>
                                            <div className='toggle-button translate-x-[0.8rem] translate-y-[-3px]'></div>
                                        </div>
                                    </label>
                                    <span className='text-[16px] font-[#000] font-medium'>Yes</span>
                                </div>
                            </div>
                            {!isChecked && <div className='flex flex-col items-end gap-[40px]'>
                                <div className='w-full flex justify-center items-center gap-[40px] '>
                                    <span className='text-[16px] font-[#000] font-medium archivo tracking-[-0.48px] leading-[20.8px]'>Do you have separate budgets for creative, production and media?</span>
                                    <div className='flex items-center gap-[4px]'><span className='text-[16px] font-[#000] font-medium'>No</span>
                                        <label className="z-10 switch">
                                            <input className='toggle-checkbox' checked={isChecked2} {...register('separate_budget_information')} onChange={() => secondtoggleHandler()} type='checkbox'></input>
                                            <div className='toggle-slot dark:border-[1px] dark:border-white w-[46px]'>
                                                <div className='toggle-button translate-x-[0.8rem] translate-y-[-3px]'></div>
                                            </div>
                                        </label>
                                        <span className='text-[16px] font-[#000] font-medium'>Yes</span>
                                    </div>
                                </div>
                            </div>}
                            {<div className='flex flex-col items-end w-full'>
                                <div className={`w-full gap-[16px] ${!isChecked && isChecked2 ? 'flex' : 'hidden'}`}>
                                    {/* <div className='field-group flex items-center w-[20%]'>
                                        <label className={(currenyLabel || getValues('budget_currency', {required: { value: !watch('share_budget_information') && watch('separate_budget_information'), message: common.formValidation('Currency', 'required')}}) ? "text-[16px] top-0 text-[#a3a3a3] -z-1 duration-300 origin-0 flex select-label" : "hidden")}>Currency</label>
                                        <Controller
                                            name="budget_currency"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    menuPortalTarget={document.body} menuPosition={'fixed'}
                                                    isClearable
                                                    value={currency}
                                                    onChange={(e) => { handleCurrencySelectOnchange(e) }}
                                                    onBlurName="onChange"
                                                    ref={field.ref}
                                                    onFocus={() => (setCurrenyLabel(true))}
                                                    onBlur={() => { setCurrenyLabel(currency ? true : false) }}
                                                    classNamePrefix={"budget react-select"}
                                                    placeholder={<label className={(!currenyLabel && !getValues("budget_currency")) ? "text-[16px] top-0 text-[#a3a3a3] -z-1 duration-300 origin-0 flex" : 'hidden'}><Dollar /> USD</label>}
                                                    options={currenyOptions}
                                                />
                                            )}
                                        />
                                        <span className="absolute right-[4px] bottom-[15px]"><Dropdown /></span>
                                    </div> */}
                                    
                                    <div className='field-group flex  input-before  items-center w-full'>
                                        <input autoComplete='off' name="budget" placeholder=" " className="input-fld"  {...register("budget", {required: { value: !watch('share_budget_information') && watch('separate_budget_information'), message: common.formValidation('Total Budget', 'required')}, 
                                        pattern: {
                                            value:  /^[$?0-9,-]*$/ ,
                                            message: common.formValidation('Budget', 'pattern')}})} onChange={(e) => { currencyOnChange(e, 'budget') }} />
                                        <FormValidationIcon getval={getValues("budget")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"budget"}></FormValidationIcon>
                                        <label htmlFor="budget" className="absolute text-[16px] top-[0px] text-[#a3a3a3] -z-1 duration-300 origin-0">Total Budget</label>
                                    </div>
                                </div>
                                <div className={`${!isChecked2 ? 'hidden': ''} flex gap-[16px] w-full justify-between form-error`}>
                                    {/* <div className='relative  w-[20%]'><FormFieldError errors={errors} fieldname={"budget_currency"}></FormFieldError></div> */}
                                    <div className='relative w-full'><FormFieldError errors={errors} fieldname={"budget"}></FormFieldError></div>
                                </div>
                                
                                <div className={`w-full ${!isChecked && !isChecked2 ? 'flex flex-col' : 'hidden'}`}>
                                    <p className='text-[14px] font-[#000] mb-[8px] leading-[18.2px] tracking-[-0.42px]'>Please choose your currency</p>
                                    {/* <div className="field-group flex items-center w-full mt-[24px]">
                                        <label className={ (currenyLabel || getValues('budget_currency')) ? "input-lbl select-label flex" : "hidden"}>Currency</label>
                                        <Controller
                                            name="budget_currency"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    menuPortalTarget={document.body} menuPosition={'fixed'}
                                                    isClearable
                                                    value={currency}
                                                    onChange={(e) => { handleCurrencySelectOnchange(e) }}
                                                    onBlurName="onChange"
                                                    ref={field.ref}
                                                    onFocus={() => (setCurrenyLabel(true))}
                                                    onBlur={() => { setCurrenyLabel(currency ? true : false) }}
                                                    classNamePrefix={"option1 budget react-select"}
                                                    placeholder={<label className={(!currenyLabel && !getValues("budget_currency")) ? "text-[14px] top-0 text-[#a3a3a3] -z-1 duration-300 origin-0 flex" : 'hidden'}><Dollar /> USD</label>}
                                                    options={currenyOptions}
                                                />
                                            )}
                                        />
                                        <span className="absolute right-[4px] bottom-[15px]"><Dropdown /></span>
                                    </div>
                                    <FormFieldError errors={errors} fieldname={"currency"}></FormFieldError> */}
                                    <div className='field-group flex items-center w-full mt-[24px]'>
                                        <input name="creative_budget" autoComplete="off" placeholder=" " className="input-fld"  {...register("creative_budget", {required: { value: !watch('share_budget_information') && !watch('separate_budget_information'), message: common.formValidation('Creative', 'required')}, pattern: {value:  /^[$?0-9,-]*$/ ,
                                            message: common.formValidation('Creative', 'pattern')}})} onChange={(e) => { currencyOnChange(e, 'creative_budget') }
                                    }/>
                                        <FormValidationIcon getval={getValues("creative_budget")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"creative_budget"}></FormValidationIcon>
                                        <div className="absolute z-[1] left-[-25px] top-[8px]" >
                                        <span data-tooltip-id={`tooltip-0`} autoComplete="off" className=""><img alt='.' src={greyquestion} className='cursor-pointer'/><img alt='.' src={question} className='hidden'/></span></div>
                                        <label htmlFor="creative_budget" className="flex justify-center items-center -z-1 gap-[2px] absolute text-[16px] top-0 text-[#a3a3a3] duration-300 origin-0">Creative</label> 
                                    </div>
                                        <ReactTooltip content={'When we say creative, we mean ideation and development'} className='tooltip-top text-[12px] text-[#4C4C4C] leading-[18px]' id={`tooltip-0`} variant='light' place="top"/>
                                    <FormFieldError errors={errors} fieldname={"creative_budget"}></FormFieldError>
                                    <div className='field-group flex items-center w-full mt-[24px]'>
                                        <input name="production_budget" autoComplete="off" placeholder=" " className="input-fld"  {...register("production_budget", {required: { value: !watch('share_budget_information') && !watch('separate_budget_information'), message: common.formValidation('Production', 'required')},  pattern: {value:  /^[$?0-9,-]*$/ ,
                                            message: common.formValidation('Production', 'pattern')}})} onChange={(e) => { currencyOnChange(e, 'production_budget') }}/>
                                        <FormValidationIcon getval={getValues("production_budget")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"production_budget"}></FormValidationIcon>
                                        <label htmlFor="production_budget" className="absolute text-[16px] top-0 text-[#a3a3a3] -z-1 duration-300 origin-0">Production</label>
                                    </div>
                                    <FormFieldError errors={errors} fieldname={"production_budget"}></FormFieldError>
                                    <div className='field-group flex items-center w-full mt-[24px]'>
                                        <input name="media_budget" placeholder=" " className="input-fld"  {...register("media_budget", {required: { value: !watch('share_budget_information') && !watch('separate_budget_information'), message: common.formValidation('Media', 'required')},pattern: {value:  /^[$?0-9,-]*$/ ,
                                            message: common.formValidation('Media', 'pattern')}})} onChange={(e) => { currencyOnChange(e, 'media_budget') }}/>
                                        <FormValidationIcon getval={getValues("media_budget")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"media_budget"}></FormValidationIcon>
                                        <label htmlFor="media_budget" className="absolute text-[16px] top-0 text-[#a3a3a3] -z-1 duration-300 origin-0">Media</label>
                                    </div>
                                    <FormFieldError errors={errors} fieldname={"media_budget"}></FormFieldError>
                                    <div className='flex items-center w-full origin-0 p-[32px] justify-between bg-[#F5F5F5] dark:bg-[#262626] mt-[34px]'>
                                        <p className='text-[14px] leading-[18.2px] tracking-[-0.42px] text-[#1D1D1D]'>Total budget</p> <span className='text-[#1D1D1D] text-[24px] font-bold tracking-[-0.72px] leading-[21.96px]'>{getTotal()}</span>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className='absolute bottom-[32px] mt-[10%] mobile:mt-[24px] mobile:relative mobile:bottom-auto'>
                        <button className={`bg-c-black text-white w-[421px] h-[45px] font-bold mobile:text-[12px] mobile:tracking-[-0.36px] text-[16px] leading-[130%] tracking-[-0.48px] rounded-full px-[24px] py-[12px] mobile:w-full dark:!bg-[#fff] dark:text-[#0E0E0E] ${!isValid && !isChecked ?  'btn-disable':''}`} onClick={() => !isChecked ? submitHandler() : props.close()}>{isChecked ? 'Skip' : 'Save'}</button>
                    </div>
                </div>
            </div>
        </div>
    )
    }
}