import axios from 'axios';
export const apiService = {
    get,
    post,
    put,
    getHeader,
    onerror,
    onstatus,
    logOut
};

function get(data) {
    var obj = getHeader(data);
    obj.url = (obj.data !== "") ? obj.url + "?" + obj.data : obj.url;
    const cancelTokenSource = axios.CancelToken.source();
    return axios.get(obj.url, { timeout: 60000, headers: obj.headers, cancelToken: cancelTokenSource.token }).then((response) => {
        if (response.status === 200) {
            if (response && response.data && response.data.Status === undefined) {
                return (response);
            } else {
                onstatus(obj, response)
            }
        } else {
            onerror(obj, response)
        }
    }).catch((err) => {
        console.log(err)
        onerror(obj, err)
        return (err)
    })
}
function post(data) {
    var obj = getHeader(data);
    return axios.post(obj.url, obj.data, { timeout: 60000, headers: obj.headers }).then((response) => {
        if (response.status === 200) {
            if (response && response.data && response.data.Status === undefined) {
                return (response);
            } else {
                onstatus(obj, response)
            }
        } else {
            onerror(obj, response)
        }
    }).catch((err) => {
        onerror(obj, err)
        return (err)
    })
}
function put(data) {
    var obj = getHeader(data);
    return axios.put(obj.url, obj.data, { timeout: 60000, headers: obj.headers }).then((response) => {
        if (response.status === 200) {
            if (response && response.data && response.data.Status === undefined) {
                return (response);
            } else {
                onstatus(obj, response)
            }
        } else {
            onerror(obj, response)
        }
    }).catch((err) => {
        onerror(obj, err)
        return (err)
    })
}
function onstatus(obj, response) {
    if ((response.data.Status || response.data.status) && obj.urltype === "local") {
        logOut((response && response.data && response.data.Message) ? response.data.Message : "Service not available, please contact your admin.", obj.url);
    } else if ((response.data.Status || response.data.status) && obj.urltype === "portal") {
        if (response.data.Status === 401 || response.data.status === 401) {
            logOut((response && response.data && response.data.Message) ? response.data.Message : "Service not available, please contact your admin.", obj.url);
        } else {
            return (response);
        }
    } else {
        return (response);
    }
}
function onerror(obj, err) {
    if (obj.urltype === "local") {
        logOut("Service not available, please contact your admin.", obj.url);
    } else if (obj.urltype === "portal") {
        if (err.Status === 401 || err.status === 401) {
            logOut((err && err.data && err.data.Message) ? err.data.Message : "Service not available, please contact your admin.", obj.url);
        } else {
            return (err);
        }
    } else {
        return (err);
    }
}
function logOut(res, url) {
    window.alert(res);
    window.alert = function () { };
}
function getHeader(obj) {
    return obj;
}