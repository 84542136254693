import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import nav from "../../../assets/img/nav-arrow.png";
import userplaceholder from '../../../assets/img/new_moonsh_placeholder.svg'
import shoe from "../../../assets/images/shoe.png";
import { ProfileImage } from '../common/profile-image'
// import { ArrowTilt } from '../common/icons/arrow-tilt';
import { Arrowback } from '../common/icons/arrow-back';
import { Arrownext } from '../common/icons/arrow-next';
import { AddIcon } from '../common/icons/addIcon';
import { FixedHeader } from '../common/fixed-header';
import { ProfileFooter } from '../common/profile-footer';
import { useNavigate,useParams} from 'react-router-dom';
import { GetCompanyByID } from '../../service/commonapi';
import { AgencyUpdateReset, getAgencyWorkById } from '../../service/agency-onboarding-api';
import common from '../../../utils/common';
import {Dropdown} from "../common/icons/dropdown";
import {EditIcon} from "../common/icons/edit-icon";
import {useGetAgencyQuery, useGetWorkQuery} from "../../../store/api/api";

export const WorkView = (props) => {
    const selectStore = useSelector(state => state.selectStore);
    const [showMore, setShowMore] = useState(false);
    const mediaExtensions = ["image/jpeg", "image/png", "image/webp", "image/jpg","jpg","jpeg","png","webp"];
    const [isControls,setisControls] = useState(false);

    let work = props.work


    // else if (props.id) {
    //     var {data, error, isLoading} = useGetWorkQuery({
    //         filter: {
    //             work_id: [props.id]
    //         }
    //     });
    //
    //     if (isLoading) {
    //         return (<div>LOADING....</div>)
    //     }
    //
    //     if (data.works.length === 0) {
    //         return (<div>Project not found....</div>)
    //     }
    //
    //     work = data.works[0]
    // }

    console.log(work)

    /** GET PROJECT ROLES DATAS */
    function getRoles(value,roles){
        let storeData;
        Object.entries(roles).forEach(val => {
            if (val[1].role_id === value) {
                storeData = val[1]['sublabel'] ? val[1]['sublabel'] : val[1]['label']
            }
        });

        return storeData
    }

    /** GET PROJECT SKILLS DATAS */
    function getSkills(value,skills){
        let storeData;
        Object.entries(skills).forEach(val => {
            if (val[1].skill_id === value) {
                storeData = val[1]['sublabel'] ? val[1]['sublabel'] : val[1]['label']
            }
        });

        return storeData
    }

    function shortenText() {
        return work && work.description ? work.description.substring(0,600): ''
    }



    /** GET PROJECT INDUSTRIES DATAS */
    function getIndustries(value,industries){
        let storeData;
        Object.entries(industries).forEach(val => {
            if (val[1].industry_id === value) {
                storeData = val[1]['sublabel'] ? val[1]['sublabel'] : val[1]['label']
            }
        });

        return storeData

    }


    function playHandler(e,index){
        e.preventDefault();
        const video =document.getElementsByClassName(`video-${index}`)[0];
        const playBtn =document.getElementsByClassName(`play-btn-${index}`)[0];
        if(video){
            video.play()
            video.onpause= (e)=> {
                playBtn.classList.remove("hidden")
            }
            video.onplay= (e)=> {
                playBtn.classList.add("hidden")
            }
            video.addEventListener('ended', function() {
                playBtn.classList.remove("hidden")
                video.load();
            });
            playBtn.classList.add("hidden")
        }
    }

    // build
    function getDescription() {
        if (!work || !work.description) {
            return ''
        }

        if (work.description.length <= 600) {
            return <p className="short-text abt-cnt whitespace-pre-wrap text-[18px] leading-[23.4px] font-normal mt-[16px] lg:mt-[8px] text-[#0E0E0E] dark:text-[#FFF]">{work.description}</p>
        }

        if (showMore) {
            return <p className="short-text abt-cnt whitespace-pre-wrap text-[18px] leading-[23.4px] font-normal mt-[16px] lg:mt-[8px] text-[#0E0E0E] dark:text-[#FFF]">{work.description}
                <button onClick={(e)=> setShowMore(!showMore)} className="text-[14px] items-center font-bold text-[#1D1D1D] dark:text-white flex gap-[4px] mt-[8px]">Read Less
                    <span className="-rotate-180 expand relative top-0" ><Dropdown/></span>
                </button>
            </p>
        } else {
            return <p className="short-text abt-cnt whitespace-pre-wrap text-[18px] leading-[23.4px] font-normal mt-[16px] lg:mt-[8px] text-[#0E0E0E] dark:text-[#FFF]">{shortenText()}...
                <button onClick={(e)=> setShowMore(!showMore)} className="text-[14px] items-center font-bold text-[#1D1D1D] dark:text-white flex gap-[4px] mt-[8px]">Read More
                    <span className="expand relative top-0" ><Dropdown/></span>
                </button>
            </p>
        }
    }
    // if (work.description.length <= 400) {
    //     return (<p className="bla abt-cnt text-[18px] leading-[23.4px] font-normal mt-[16px] lg:mt-[8px] text-[#0E0E0E] dark:text-[#FFF]">
    //         {work.description}
    //         <p>)
    //             }
    //
    //             return (<>
    //                 <p className={`${showMore ? '': 'hidden'} bli abt-cnt text-[18px] leading-[23.4px] font-normal mt-[16px] lg:mt-[8px] text-[#0E0E0E] dark:text-[#FFF]`} id="full-text">{work && work.description ? work.description : ''}
    //                     {work && work.description && work.description.length > 400 ?
    //                         <button onClick={(e)=> setShowMore(!showMore)} className="text-[14px] items-center font-bold text-[#1D1D1D] dark:text-white flex gap-[4px] mt-[8px]">Read {showMore ? "Less" : "More"}
    //                             <span className={`${showMore ? "-rotate-180" : ''} expand relative top-0`} ><Dropdown/></span>
    //                         </button> :''}
    //                 </p>
    //                 {!showMore && <p className="bla abt-cnt text-[18px] leading-[23.4px] font-normal mt-[16px] lg:mt-[8px] text-[#0E0E0E] dark:text-[#FFF]">{shortenText()}<span className="font-bold text-[18px] dark:text-[#FFFFFF]">{work && work.description ? work.description : ''}</span>...<button onClick={(e) => { shortenText(); setShowMore(!showMore) }} className="text-[14px] font-bold text-[#1D1D1D] items-center dark:text-white flex gap-[4px] mt-[8px]">Read {showMore ? "Less" : "More"} <span className={`${showMore ? "-rotate-180" : ''} expand relative top-0`} ><svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M5.58928 5.44342C5.26384 5.76886 4.7362 5.76886 4.41076 5.44342L1.07743 2.11009C0.751993 1.78465 0.751993 1.25701 1.07743 0.931577C1.40287 0.60614 1.93051 0.60614 2.25594 0.931577L5.00002 3.67566L7.7441 0.931577C8.06953 0.60614 8.59717 0.60614 8.92261 0.931577C9.24805 1.25701 9.24805 1.78465 8.92261 2.11009L5.58928 5.44342Z" className="dark:fill-white" fill="black" /> </svg></span></button></p>}
    //             </> );
    //             }


    return(
        <div className='w-full  bg-white dark:bg-[#363636] dark:text-[#FFFFFF] text-[#07091B] archivo h-full'>
            <div className='h-auto test bg-white dark:bg-[#363636] dark:text-[#FFFFFF] text-[#07091B]'>
                <div className='max-w-[1088px] dark:bg-[#0E0E0E] mx-auto p-[64px] bg-white [@media(max-width:960px)]:p-0 [@media(max-width:960px)]:pb-[24px]'>
                    <section className='mx-auto max-w-[960px] [@media(max-width:960px)]:!max-w-[100%]'>
                        <div className='flex flex-col gap-[70px] cursor-pointer relative'>
                            {work && work.creatives && work.creatives.length && work.creatives.map((media, index) => {
                                if(common.checkIsMedia(media.url, mediaExtensions)){
                                    return (
                                        <div style={{backgroundImage:`url(${media.url !== "" ? media.url : ''})`}} alt='media' key={index} className='[@media(max-width:960px)]:rounded-[0px] media-img [@media(max-width:960px)]:654px'/>
                                    )} else {
                                    return (
                                        // <a key={index} href={media.url} rel="noopener noreferrer" target='_blank' alt="url" className=''>{media.url}</a>
                                        <div className='relative'>
                                            {media.poster_url !== "" ? <video poster={media.poster_url} className={`video-${index} w-full bg-white [@media(max-width:960px)]:rounded-[0px] [@media(max-width:960px)]:654px`} controls={isControls} onClick={()=> setisControls(true)}>
                                                    <source src={media.url}></source>
                                                </video> :
                                                <video className={`video-${index} w-full  bg-white [@media(max-width:960px)]:654px`} controls={isControls} onClick={()=> setisControls(true)}>
                                                    <source src={`${media.url}#t=0.9`}></source>
                                                </video>}
                                            <button onClick={(e) => playHandler(e,index)} className={`play-btn-${index} absolute top-[41%] right-[45%]`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                                                    <g filter="url(#filter0_b_8584_3463)">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80ZM33.75 54.6721L56.25 42.096C57.9167 41.1645 57.9167 38.8355 56.25 37.904L33.75 25.3279C32.0833 24.3963 30 25.5608 30 27.4239L30 52.5761C30 54.4392 32.0833 55.6037 33.75 54.6721Z" fill="white" fillOpacity="0.3"/>
                                                    </g>
                                                    <defs>
                                                        <filter id="filter0_b_8584_3463" x="-16" y="-16" width="112" height="112" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="8"/>
                                                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8584_3463"/>
                                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_8584_3463" result="shape"/>
                                                        </filter>
                                                    </defs>
                                                </svg></button>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </section>
                    <hr className='border-b-[#d9d9d9] mt-[50px] mx-auto max-w-[960px]'/>

                    <section id="project" className='mx-auto max-w-[960px] [@media(max-width:960px)]:p-[24px] [@media(max-width:960px)]:!pt-0'>
                        {/*<h1 className='text-[#07091b] text-[88px]  dark:text-[#FFFFFF] [@media(max-width:960px)]:text-[32px] mb-[50px] [@media(max-width:960px)]:mb-[32px]  [@media(max-width:960px)]:leading-[35.2px] leading-[96.8px]'>{work && work.name ? work.name : ''}</h1>*/}
                        <div className='[@media(max-width:1023px)]:block flex gap-[100px] pt-6'>
                            <div className='text-[#07091b]  [@media(max-width:1023px)]:w-full w-1/2 dark:text-[#FFFFFF]'>
                                <div className='font-bold text-[18px] mb-[24px]'>More About The Project</div>
                                {getDescription()}
                            </div>
                            <div className='flex flex-col gap-[32px] w-1/2 [@media(max-width:1023px)]:w-full '>
                                <div className='flex flex-col gap-[8px]'>
                                    <span className='text-[#808080]'>Client</span>
                                    <span className='flex items-center gap-[16px] font-bold text-[18px]'>{work.company && work.company.logo && <img alt='logo' src={work.company && work.company.logo} className='w-[48px] h-[48px] p-[5px] border border-[#A3A3A3]'/>}{work.company && work.company.label}</span>
                                </div>
                                <div className='flex flex-col gap-[8px]'>
                                    <span className='text-[#808080]'>Industries</span>
                                    <div>
                                        {work.industries && work.industries.map((data,i)=>{
                                            return(
                                                <span key={i} className={`font-bold mb-[16px] w-1/2 [@media(max-width:960px)]:w-full ${i%2===0 ? "float-left" :"float-right"}`}>{getIndustries(data, selectStore.industry)}</span>
                                            )
                                        })}
                                    </div>
                                </div>
                                {/* <div className=''>
                                <span className='text-[#808080] mb-[8px]'>Category</span>
                                <div>
                                {category.map((data,i)=>{
                                    return(
                                        <span key={i} className={`font-bold mb-[16px] w-1/2 ${i%2===0 ? "float-left" :"float-right"}`}>{data}</span>
                                        )
                                    })}
                                </div>
                            </div> */}
                                <div className=''>
                                    <span className='text-[#808080] mb-[8px]'>Roles</span>
                                    <div>
                                        {work.roles &&  work.roles.map((data,i)=>{
                                            return(
                                                <span key={i} className={`font-bold mb-[16px] w-1/2 [@media(max-width:960px)]:w-full ${i%2===0 ? "float-left" :"float-right"}`}>{getRoles(data, selectStore.role)}</span>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className=''>
                                    <span className='text-[#808080] mb-[24px]'>Skills</span>
                                    <div>
                                        {work.skills && work.skills.map((data,i)=>{
                                            return(
                                                <span key={i} className={`font-bold mb-[16px] w-1/2 [@media(max-width:960px)]:w-full ${i%2===0 ? "float-left" :"float-right"}`}>{getSkills(data, selectStore.skils)}</span>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                {/* <button className='relative bottom-[300px] z-4'><Scrollarrow/></button> */}
            </div>
            <ProfileFooter></ProfileFooter>
        </div>
    )
}

