import question from "../../../assets/img/questioncircle.png";
import { RadioGrp } from '../common/radioGrp';
import { useEffect, useState } from 'react';

// import { Cancel } from '../common/icons/cancel';
import { useDispatch, useSelector } from "react-redux";
import { ProCancel } from "../common/icons/skills-cancel";

export const BigIdeaPopup = (props) => {
    const projectSetupStore = useSelector(state => state.projectSetupStore);
    const [choice, setChoice] = useState([]);
    const [isOpen, setisOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=> {
        if (props.open === undefined) {
            return
        }
        console.log("useEffect",props.open)
        if(!props.open){
            const popup = document.querySelector(".big-popup")
            if(popup){
                popup.classList.add("slide-right")
            }
            setTimeout(() => {
                console.log(" setisOpen(false)")

                setisOpen(false);
            },100)
        }else{
            console.log(" setisOpen(true)")
            setisOpen(true)
        }
    },[props.open])

    useEffect(() => {
        if(projectSetupStore.skils_group && Object.keys(projectSetupStore.skils_group).length) {
            var bigIdea = JSON.parse(JSON.stringify(projectSetupStore.skils_group['Big Idea']));
            var d = []
            bigIdea.forEach(obj => {
                if(obj.skill_id === 'bigidea') {obj['tooltip'] = "Media-Neutral, Game-changing creativity with provocative, boundary-busting, envy-inspiring work that marks a new direction for the industry and moves it forward"}
                else if(obj.skill_id === 'bigidea-purpose') {obj['tooltip'] = "Creative ideas/Concepts for Purpose-Driven / Brand-Activism which address social, ethical and environmental issues that will  impact communities by connecting customers to culture"}
                else if(obj.skill_id === 'bigidea-multiculture') {obj['tooltip'] = "Relevant, creative and compelling work that engages a multicultural audience and issues of gender, race, ethnicity, sexual, identity, or ability."}
                else {obj['tooltip'] = ''}
                obj['text'] = obj.sublabel ? obj.sublabel : obj.label;
                obj['value'] = obj.skill_id
                d.push(obj)
            })
            setChoice(d)
        }
    }, [projectSetupStore.skils_group])

    useEffect(() => {
        if (projectSetupStore.page2.skills && Array.isArray(projectSetupStore.page2.skills) && projectSetupStore.page2.skills.length) {
            projectSetupStore.page2.skills.forEach((skls) => {
                var valIndex = choice.filter((val) => val.skill_id === skls.skill_id);
                if(valIndex !== -1) {
                    setSelected(skls.skill_id)
                }
            })
            
        }
    }, [projectSetupStore])
    
    const [selected,setSelected] = useState("")

    function onChange(e){
        setSelected(e.target.value)
    }

    function onClick(e) {
        if(e) {
            e.preventDefault()
            e.stopPropagation();
        }
        var val = e.target.getAttribute('data-value');
        setSelected(val ? val : '')
    }

    function submitHandler(val){
        const data =[];
        data.push(val ? val : selected);
        var _data = JSON.parse(JSON.stringify(projectSetupStore.page2));
        _data["skills"] = data;
        dispatch({ type: 'project_setup/insertValue', payload: { "id":  _data , "name": "page2" } });
        props.saveData({"skills":data})
        document.body.classList.remove("overflow-hidden")
        props.nextStep();
    }


//     const choice= [
//         {text:"Big Idea Platform",value:"bigidea",tooltip:"Media-Neutral, Game-changing creativity with provocative, boundary-busting, envy-inspiring work that marks a new direction for the industry and moves it forward"},
//         {text:"Purpose-led Concept",value:"bigidea-purpose",tooltip:"Creative ideas/Concepts for Purpose-Driven / Brand-Activism which address social, ethical and environmental issues that will  impact communities by connecting customers to culture"},
//         {text:"Multicultural Creative",value:"bigidea-multiculture",tooltip:"Relevant, creative and compelling work that engages a multicultural audience and issues of gender, race, ethnicity, sexual, identity, or ability."}
// ]
if(isOpen){
    return(
        <div className={`relative z-10 mobile:w-full big-idea-popup`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className={`fixed inset-0 overlay-background bg-opacity-75 backdrop-blur-sm transition-opacity mobile:hidden`}></div>
                <div className={`fixed inset-0 big-popup z-10 overflow-y-auto h-full mobile:relative mobile:h-auto mobile:w-full ${props.open ? "slide-left" : ""}`}>
                    <div className='h-full absolute flex flex-wrap content-between top-0 right-0 gap-[32px] bg-[#ffffff] w-[485px] shadow-md min-h-full mobile:border-solid mobile:border-[#d9d9d9] mobile:border-t-[1px] mobile:relative mobile:w-full mobile:min-h-[auto] mobile:p-[0] mobile:mt-[16px] mobile:pt-[24px] dark:bg-[#0E0E0E]'>
                        <div className='h-[calc(100%-371px)] w-full flex flex-col'>
                            <div className='flex justify-between box-border border-b border-solid border-[#d9d9d9] pb-[16px] md:pt-[32px] md:mx-[32px] dark:border-[#A3A3A3] mobile:border-none mobile:pb-0'>
                                <div className='flex-col gap-1'>
                                    <p className='text-[#07091b] text-[20px] leading-[130%] tracking-[-0.6px] font-bold mobile:pt-[16px] mobile:text-[14px] mobile:leading-[130%] mobile:tracking-[-0.42px] dark:text-[#fff]'>Big idea. Deep dive.</p>
                                    <p className='text-[#A3A3A3] text-[14px] leading-[130%] tracking-[-0.42px] mt-[4px] mobile:text-[14px] mobile:leading-[130%] mobile:tracking-[-0.42px]'>Choose one from the choice checkboxes:</p>
                                </div>
                                <div className="absolute right-0 xs:mt-[-10px] pr-7 mt-[4px] mobile:hidden">
                                    <button type="button" onClick={props.close} className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none pr-[7px]"><ProCancel/></button>
                                </div>
                            </div>
                            <form className='overflow-auto input-fld mb-0 md:px-[32px] md:pb-[32px]'>
                                <RadioGrp tooltip={true} selected={selected} options={choice} onChange={onChange} onClick={onClick}></RadioGrp>
                            </form>
                        </div>
                        <div className='w-full flex md:p-[32px] flex-wrap gap-[32px] bottom-0'>
                            <div className='w-full flex flex-col gap-[16px]'>
                                <button onClick={e => submitHandler(false)} className='bg-[#1D1D1D] disabled:opacity-[0.3] text-white w-full h-[45px] font-bold text-[16px] leading-[130%] tracking-[-0.48px] rounded-full py-[12px] px-[24px] dark:bg-[#fff] dark:text-[#0E0E0E]' disabled={!selected}>Next</button>
                                <button onClick={e => submitHandler('bigidea')} className='text-[#1D1D1D] font-bold text-[16px] leading-[130%] tracking-[-0.48px] border border-black rounded-full w-full h-[45px] dark:text-[#fff] dark:bg-[#0E0E0E] dark:border-[1px] dark:border-[solid] dark:border-[#fff] mobile:hidden'>Skip</button>
                                <button className='text-[#1D1D1D] font-bold text-[16px] leading-[130%] tracking-[-0.48px] border border-black rounded-full w-full h-[45px] dark:text-[#fff] dark:bg-[#0E0E0E] dark:border-[1px] dark:border-[solid] dark:border-[#fff] mobile:hidden' onClick={props.close}>Back</button>
                            </div>
                            <hr className='w-full border-solid border-[#d9d9d9] dark:border-[#A3A3A3] mobile:hidden'/>
                            {/*<div className='w-full flex flex-wrap items-center gap-[4px] mobile:hidden'>*/}
                            {/*    <img alt='.' src={question} className='cursor-pointer'/>*/}
                            {/*    <span className='text-[#808080] text-[14px] leading-[130%] tracking-[-0.42px]'>Why we ask</span>*/}
                            {/*    <p className='text-[#808080] text-[14px] leading-[130%] tracking-[-0.42px]'>Our talent network has varied levels of expertise and skills.</p>*/}
                            {/*</div>*/}
                        </div>
                    </div>
            </div>
        </div>
    )  
}   
}