import React from 'react'

export const ExampleIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" className='example-icon'>
      <g clipPath="url(#clip0_9947_25757)">
        <path className='dark:stroke-white' d="M1.66663 10.8349L9.70182 14.8525C9.81114 14.9072 9.86579 14.9345 9.92313 14.9453C9.97391 14.9548 10.026 14.9548 10.0768 14.9453C10.1341 14.9345 10.1888 14.9072 10.2981 14.8525L18.3333 10.8349M1.66663 15.0016L9.70182 19.0192C9.81114 19.0739 9.86579 19.1012 9.92313 19.1119C9.97391 19.1215 10.026 19.1215 10.0768 19.1119C10.1341 19.1012 10.1888 19.0739 10.2981 19.0192L18.3333 15.0016M1.66663 6.66827L9.70182 2.65067C9.81114 2.59601 9.86579 2.56868 9.92313 2.55793C9.97391 2.5484 10.026 2.5484 10.0768 2.55793C10.1341 2.56868 10.1888 2.59601 10.2981 2.65067L18.3333 6.66827L10.2981 10.6859C10.1888 10.7405 10.1341 10.7679 10.0768 10.7786C10.026 10.7881 9.97391 10.7881 9.92313 10.7786C9.86579 10.7679 9.81114 10.7405 9.70182 10.6859L1.66663 6.66827Z" stroke="#1D1D1D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_9947_25757">
          <rect width="20" height="20" fill="white" transform="translate(0 0.833984)"/>
        </clipPath>
      </defs>
    </svg>
  )
}