import React, { useEffect, useState,useRef } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import FormValidationIcon from '../../../../utils/form-validation-icon';
import { Cancel } from '../../common/icons/cancel';
import common from '../../../../utils/common';
import FormFieldError from "../../../../utils/error-message";
import Select from "react-select";
import {useGetLocationQuery} from "../../../../store/api/api";

export const LocationPopup = ({location,onSave,onCancel}) => {
    const dailogRef = useRef();
    const { data: locationLookupData, error: locationLookupError, isLoading: locationLookupIsLoading } = useGetLocationQuery();

    let [updatedLocation,setUpdatedLocation] = useState(location)

    function getLocations() {
       if (locationLookupIsLoading) {
           return []
       }

       return locationLookupData.data.map((loc) => {return {
           "value": loc.location_id,
           "label": loc.label }})
    }

    return (
        <div className="relative z-10 editAbout-popup" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 overlay-background bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full  justify-center p-4 sm:items-center sm:p-0" ref={dailogRef}>
                    <div className="relative w-[595px] m-auto transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[600px] dark:bg-[#1D1D1D] dark:shadow-[0px_4px_21px_rgba(138, 138, 138, 0.2)]">
                        <div className="sm:items-start w-full flex">
                            <div className="m-[24px] sm:text-left w-full">
                                <div className="absolute right-[21px] xs:mt-[-10px] pr-4 sm:block mt-[4px]">
                                    <button type="button" onClick={() => {onCancel()}} className="rounded-md !bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none">
                                        <Cancel />
                                    </button>
                                </div>
                                <h2 className='text-[20px] font-bold'>Edit Location</h2>
                                <hr className='mt-[10px]' />
                                <div className="field-group w-[100%] mt-[40px] h-96">
                                    <Select
                                        className="border-b border-b-2"
                                        isClearable
                                        value = {
                                            getLocations().filter(option =>
                                                option.value === updatedLocation)
                                        }
                                        onChange={(e) => { setUpdatedLocation(e.value) }}
                                        onBlurName="onChange"
                                        classNamePrefix={"react-select"}
                                        placeholder="Select your location"
                                        options={getLocations()}
                                        />
                                </div>
                                <div className='flex justify-end mt-[35px] [@media(max-width:640px)]:flex-col-reverse [@media(max-width:640px)]:gap-[10px]'>
                                    <button onClick={() => {onSave(updatedLocation)}} className={'w-[168px] h-[34px] rounded-fill-button dark:hover:!bg-[#4C4C4C] [@media(max-width:640px)]:w-full'}  >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
