import { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { ProCancel } from "../common/icons/skills-cancel";
import { useForm } from "react-hook-form";
import FormFieldError, { SelectFormFieldError } from "../../../utils/error-message";
import FormValidationIcon from "../../../utils/form-validation-icon";
import common from "../../../utils/common";
import { GetCompanies } from "../../service/commonapi";
import { Dropdown } from "../common/icons/dropdown";
import { ProjectDemoRequest } from '../../service/project-setup-api';

export const RequestDemoPopup = (props) => {
    const selectStore = useSelector(state => state.selectStore);
    const [companyDrop, setCompanyDrop] = useState([]);
    const [companyTempDrop, setCompanyTempDrop] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [companyIsValid, setCompanyIsValid] = useState(true);
    const [companyErrorMessage, setCompanyErrorMsg] = useState("");
    const companyRef = useRef();
    const companyRefDrop = useRef();
    const { register, trigger, formState: { errors, isValid, touchedFields, dirtyFields }, getValues } = useForm({
        mode: "all",
        reValidateMode: "onChange", criteriaMode: "all", shouldUnregister: true
    });

    useEffect(() => {
        getCompany();
    }, []);

    /**ADJUST DROPDOWN POSITION WHENEVER OPTION IS SELECTED/BRANDS STATE IS UPDATED */
    useEffect(() => {
        var parent = companyRef.current;
        if (parent) {
            parent = parent.getBoundingClientRect();
            if (document.querySelector('.brandsDemo.demoFixed')) {
                document.querySelector('.brandsDemo.demoFixed').style.top = parent.top + 30 + 'px';
            }
        }
    }, [companies])

    /**EVENT LISTENER TO FIX DROPDOWN POSITION ON SCROLL */
    useEffect(() => {
        window.addEventListener("scroll", resetDropdownPosition);
        return () => {
            window.removeEventListener("scroll", resetDropdownPosition);
        };
    })

    /**RECALCULATE POSITION ON SCROLL*/
    function resetDropdownPosition() {
        var otherParent = companyRef.current;
        if (otherParent) {
            otherParent = otherParent.getBoundingClientRect();
            if (document.querySelector('.brandsDemo.demoFixed') && otherParent.top) {
                document.querySelector('.brandsDemo.demoFixed').style.top = otherParent.top + 30 + 'px';
            }
        }
    }

    /**LOAD DEFAULT OPTIONS ON INITIAL CLICK - COMPANY */
    function getCompany() {
        try {
            GetCompanies("").then((res) => {
                if (res) {
                    var modifiedObj = res.map(obj => ({ ...obj, isChecked: false }))
                    setCompanyDrop(modifiedObj);
                    setCompanyTempDrop(modifiedObj)
                } else {
                    setCompanyDrop([]);
                    setCompanyTempDrop([])
                }
            })
        } catch (err) { console.log(err); }
    }

    /**BRANDS FIELD ON BLUR EVENT */
    function brandsBlurHandler() {
        const mark = document.querySelector(".brands-label");
        if (mark) {
            mark.classList.add("move-label");
        }
        if (document.getElementsByName('brands')) {
            document.getElementsByName('brands').forEach(o => {
                if (o && o.value !== "") {
                    o.value = ""
                }
            })
            BrandsSelectValidation(companies, 1, 'Company', 'brands')
        }
    }

    /**ENABLE BRANDS OPTIONS */
    const enableBrandsFirstLevel = () => {
        if (document.querySelectorAll('.brands .demoFixedSubLevel')) {
            [...document.querySelectorAll('.brands .demoFixedSubLevel')].forEach(div => {
                if (div.style.display !== 'none') {
                    div.style.display = 'none'
                }
            });
        }
        const mark = document.querySelector(".brands-label");
        if (mark) {
            mark.classList.remove("move-label");
        }
        const parent = companyRef.current.getBoundingClientRect()
        if(parent) {
            document.querySelector('.brandsDemo.demoFixed').style.left = parent.left + 'px';
            document.querySelector('.brandsDemo.demoFixed').style.top = parent.top + 30 + 'px';
            document.querySelector('.brandsDemo.demoFixed').style.width = parent.width + 'px';
            document.querySelector('.react-select__menu.brandsDemo.demoFixed').style.display = 'block';
            document.querySelector('.brands-multi-select-backdrop').style.display = 'block';
        }
    }


    /** SELECT DISABLE BRANDS ALLLEVEL */
    const disableAllBrandsLevel = () => {
        if (document.querySelector('.brands.demoFixed').style) {
            document.querySelector('.brands.demoFixed').style.display = 'none';
            if (companies && !companies.length >= 1) {
                selectStore.brands.forEach((_val, _i) => {
                    document.querySelector('.react-select__menu.brands .demoFixedSubLevel.child_' + _i).style.display = 'none'
                })
            }
            document.querySelector('.brands-multi-select-backdrop').style.display = 'none';
            BrandsSelectValidation(companies, 1, 'Company', 'brands')
        }
    }

    /**BRANDS OPTION CHANGE EVENT */
    function brandsOnchange(val, i, e) {
        if (e) {
            e.preventDefault()
            e.stopPropagation();
        }
        var _val = JSON.parse(JSON.stringify(companyDrop));
        var _companies = JSON.parse(JSON.stringify(companies));
        var _valueIndex = _companies.findIndex(value => value.company_id === val.company_id);
        if (_val[i]["isChecked"]) {
            _val[i]["isChecked"] = false;
            if (_valueIndex !== -1) {
                _companies.splice(_valueIndex, 1);
                setCompanies(_companies);
            }
        } else {
            _val.forEach((val, j) => {
                if (i === j) {
                    _val[j]["isChecked"] = true;
                    _companies.push(_val[j])
                    setCompanies(_companies);
                }
            })
        }
        setCompanyDrop(_val);
        BrandsSelectValidation(_companies, 1, 'Company', 'companies')
        if (_companies && _companies.length >= 1) {
            disableAllBrandsLevel()
        }
    }

    /**BRANDS ONINPUT SEARCH OPTIONS EVENT */
    function onInputChange(event) {
        const _searchText = event.target.value;
        if (_searchText !== "" && _searchText.length >= 3) {
            GetCompanies(_searchText).then((res) => {
                if (res) {
                    var updatedVal = []
                    res.forEach((v) => {
                        var _val = {};
                        _val["label"] = v.label;
                        _val["company_id"] = v.company_id;
                        _val["isChecked"] = false;
                        _val["logo"] = v.logo
                        updatedVal.push(_val)
                    })
                    var brns = JSON.parse(JSON.stringify(companies));
                    brns.forEach((val, i) => {
                        var ind = updatedVal.findIndex(v => v.company_id === val.company_id)
                        if (ind !== -1) {
                            updatedVal[ind]["isChecked"] = true
                        }
                    })
                    setCompanyDrop(updatedVal)
                    return updatedVal;
                }
            })
        } else if (!_searchText || _searchText.length === 0 || _searchText === "") {
            setCompanyDrop(companyTempDrop)
        }
    }

    /**REMOVE SELECTED OPTIONS - BRANDS */
    function removeHandler(val, i) {
        const brandValue = [...companies];
        brandValue.splice(i, 1);
        setCompanies(brandValue);
        BrandsSelectValidation(brandValue, 1, 'Company', 'companies');
        var _val = JSON.parse(JSON.stringify(companyDrop));
        var _valueIndex = _val.findIndex(value => value.company_id === val.company_id);
        if (_valueIndex !== -1) {
            _val[_valueIndex]['isChecked'] = !val.isChecked;
        }
        setCompanyDrop(_val)
    }

    /** HANDLES INDUSTRY SELECT VALUE VALIDATION */
    function BrandsSelectValidation(value, min, fieldname, type, max) {
        let message;
        switch (type) {
            case 'companies':
                if (!value.length) {
                    setCompanyIsValid(false)
                    message = common.formValidation(fieldname, 'required')
                    setCompanyErrorMsg(message)
                } else if (value.length < min) {
                    setCompanyIsValid(false)
                    message = common.formValidation(fieldname, 'minValue', min)
                    setCompanyErrorMsg(message)
                } else if (value.length > max) {
                    setCompanyIsValid(false)
                    message = common.formValidation(fieldname, 'maxValue', min, max)
                    setCompanyErrorMsg(message)
                } else {
                    setCompanyIsValid(true);
                    setCompanyErrorMsg()
                }
                break;
            default:
                break;
        }
    }

    /**FORM ON SUBMIT EVENT */
    function submitHandler(val) {
        if (companies.length >= 1 && isValid) {
            document.body.classList.remove("overflow-hidden");
            var data = getValues();
            data['company'] = companies.length && companies[0].label ? companies[0].label : ''
            ProjectDemoRequest(data).then(res => {
                if (res) {
                    props.close();
                }
            })
        } else {
            BrandsSelectValidation(companies, 1, 'Company', 'companies');
            trigger();
        }
    }

    return (
        <div className="relative z-10 request-demo-popup " aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 overlay-background bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className='flex min-h-full  justify-center p-4 sm:items-center sm:p-0'>
                <div className=" relative w-[360px] m-auto transform overflow-hidden rounded-md bg-white p-[15px] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[500px] dark:bg-[#1D1D1D] dark:shadow-[0px_4px_21px_rgba(138, 138, 138, 0.2)]">
                    <div className='h-[calc(100%-339px)] w-full flex flex-col gap-[24px]'>
                        <div className='flex justify-between box-border border-b border-solid border-[#d9d9d9] dark:border-[#A3A3A3]'>
                            <div className='flex-col gap-1 mb-[5px]'>
                                <p className='text-[#07091b] text-[20px] leading-[130%] tracking-[-0.6px] font-bold mobile:pt-[16px] mobile:text-[14px] mobile:leading-[130%] mobile:tracking-[-0.42px] mobile:hidden dark:text-[#fff]'>Request a demo.</p>
                                <p className='text-[#A3A3A3] text-[14px] leading-[18.2px] w-[calc(100%-25px)] tracking-[-0.42px] mt-[4px] mobile:text-[14px] mobile:leading-[18.2px] mobile:tracking-[-0.42px] mobile:w-full mobile:mt-[0]'>In this meeting, a senior member of our team will show you how to use Moonshoot.<br/><br/> If you have a project in mind, we'll help you find the perfect team for your brand during the demo.</p>
                            </div>
                            <div className="absolute right-0 xs:mt-[-10px] pr-7 mt-[4px] mobile:hidden">
                                <button type="button" onClick={props.close} className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "><ProCancel /></button>
                            </div>
                        </div>

                        <form className='overflow-auto input-fld mb-0 md:px-[10px] md:pb-[10px]'>
                            <div className={`w-full flex flex-col`}>
                                <div className='field-group flex items-center w-full mt-[33px]'>
                                    <input type="text" name="fullname" placeholder=" " className="input-fld"  {...register("fullname", {
                                        required: common.formValidation("Full Name", "required"),
                                        maxLength: {
                                            value: 30,
                                            message: common.formValidation(
                                                "Full Name",
                                                "maxLength",
                                                "",
                                                30
                                            ),
                                        },
                                    })}
                                    />
                                    <FormValidationIcon getval={getValues("fullname")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"fullname"}></FormValidationIcon>
                                    <label htmlFor="fullname" className="input-lbl">Full Name</label>
                                </div>
                                <FormFieldError errors={errors} fieldname={"fullname"}></FormFieldError>
                                <div className="field-group flex items-center w-full mt-[33px]">
                                    <input type="text" name="email" placeholder=" " className="input-fld"
                                        {...register("email", {
                                            required: common.formValidation("Work Email", "required"),
                                            pattern: {
                                                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                                message: common.formValidation("Work Email", "pattern"),
                                            },
                                            minLength: {
                                                value: 5,
                                                message: common.formValidation("Work Email", "minLength", 5),
                                            },
                                            maxLength: {
                                                value: 120,
                                                message: common.formValidation("Work Email", "maxLength", "", 120),
                                            },
                                        })}
                                    />
                                    <FormValidationIcon getval={getValues("email")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"email"}></FormValidationIcon>
                                    <label htmlFor="email" className="input-lbl">Work Email</label>
                                </div>
                                <FormFieldError errors={errors} fieldname={"email"}></FormFieldError>
                                <div className="field-group flex items-center w-full mt-[33px]">
                                    <input type="text" name="linkedinurl" placeholder=" " className="input-fld"
                                        {...register("linkedin_profile_url", {
                                            required: common.formValidation("Linkedin URL", "required"),
                                            pattern: {
                                                value:
                                                    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                                                message: common.formValidation("Linkedin URL", "pattern"),
                                            },
                                            minLength: {
                                                value: 5,
                                                message: common.formValidation(
                                                    "Linkedin URL",
                                                    "minLength",
                                                    5
                                                ),
                                            },
                                        })}
                                    />
                                    <FormValidationIcon getval={getValues("linkedin_profile_url")} errors={errors} dirtyFields={dirtyFields} fieldTouched={touchedFields} fieldname={"linkedin_profile_url"}></FormValidationIcon>
                                    <label htmlFor="linkedinurl" className="input-lbl">LinkedIn URL</label>
                                </div>
                                <FormFieldError errors={errors} fieldname={"linkedin_profile_url"}></FormFieldError>

                                <div className="field-group custom-select brands-placeholder  mt-[33px]">
                                    <label className={"absolute move-label brands-label diversity-edge text-[14px] text-[#a3a3a3] origin-0 select-label"}> Company</label>
                                    <input type="text" ref={companyRef} autoComplete='off' name="brands" onFocus={enableBrandsFirstLevel} onInputCapture={onInputChange} onBlur={brandsBlurHandler} className="relative input-fld" />
                                    {companies && companies.length < 1 && <span className="absolute right-[4px] bottom-[8px]"><Dropdown /></span>}
                                    <FormValidationIcon isArray={true} twolevelselect={true} minlen={1} getval={companies}></FormValidationIcon>
                                    <div className={'brands-multi-select-backdrop multi-select-backdrop '} onClick={disableAllBrandsLevel}></div>
                                </div>
                                <div ref={companyRefDrop} className="multi-select react-select__menu brands brandsDemo demoFixed z-20">
                                    <div className="react-select__menu-list react-select__menu-list--is-multi single-level">
                                        {JSON.parse(JSON.stringify(companyDrop)).length <= 0 && <div className="react-select__menu-notice react-select__menu-notice--no-options css-1wlit7h-NoOptionsMessage">Enter company name</div>}
                                        {JSON.parse(JSON.stringify(companyDrop)).map((diver, i) =>
                                            <div className={"react-select__option"} key={i}  >
                                                <li className={"cursor-pointer"} onClick={(e) => { brandsOnchange(diver, i, e) }}>
                                                    <span className="txt" >   <div className='flex items-center'>
                                                        <span>{diver.label}</span>
                                                    </div>
                                                        <label className="control control--checkbox">
                                                            <input type="checkbox" onChange={() => { brandsOnchange(diver, i) }} readOnly checked={diver.isChecked} />
                                                            <div className="control__indicator"></div>
                                                        </label>
                                                    </span>
                                                </li>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <SelectFormFieldError isValid={companyIsValid} message={companyErrorMessage}></SelectFormFieldError>
                                <div className={`${companies && companies.length ? '!mt-0 !mb-[24px] custom-multi-select' : ''}  `}>
                                    {companies && companies.map((val, i) => {
                                        return (
                                            <div key={i} className="react-select__multi-value new">
                                                <div className="react-select__multi-value__label">
                                                    <div className="brands-option flex m-auto">
                                                        <span className="mar-label">{val.label}</span>
                                                    </div>
                                                </div>
                                                <div
                                                    role="button"
                                                    onClick={() => removeHandler(val, i)}
                                                    className="react-select__multi-value__remove"
                                                    aria-label="Remove X"
                                                >
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.72184 15.2738C4.82196 15.3739 4.938 15.4399 5.06997 15.4717C5.20648 15.5036 5.33845 15.5036 5.46587 15.4717C5.59784 15.4399 5.7116 15.3762 5.80717 15.2806L10.0051 11.0827L14.1962 15.2806C14.2918 15.3762 14.4056 15.4399 14.5375 15.4717C14.6695 15.5036 14.8015 15.5036 14.9334 15.4717C15.0654 15.4399 15.1815 15.3739 15.2816 15.2738C15.3771 15.1782 15.4408 15.0644 15.4727 14.9325C15.5046 14.8005 15.5046 14.6708 15.4727 14.5434C15.4408 14.4114 15.3771 14.2954 15.2816 14.1953L11.0904 9.99733L15.2816 5.8062C15.3771 5.70609 15.4408 5.59232 15.4727 5.4649C15.5091 5.33294 15.5091 5.20097 15.4727 5.069C15.4408 4.93703 15.3771 4.82327 15.2816 4.7277C15.1769 4.62759 15.0586 4.5616 14.9266 4.52975C14.7992 4.4979 14.6695 4.4979 14.5375 4.52975C14.4056 4.55705 14.2918 4.62076 14.1962 4.72088L10.0051 8.91883L5.80717 4.72088C5.7116 4.62531 5.59784 4.5616 5.46587 4.52975C5.3339 4.4979 5.20193 4.4979 5.06997 4.52975C4.938 4.5616 4.82196 4.62759 4.72184 4.7277C4.62628 4.82327 4.56257 4.93703 4.53072 5.069C4.49886 5.20097 4.49886 5.33294 4.53072 5.4649C4.56257 5.59687 4.62628 5.70836 4.72184 5.79937L8.91297 9.99733L4.72184 14.2021C4.62628 14.2931 4.5603 14.4046 4.52389 14.5366C4.49204 14.6685 4.49204 14.8005 4.52389 14.9325C4.55575 15.0644 4.62173 15.1782 4.72184 15.2738Z" className="dark:fill-white" fill="#1D1D1D" />
                                                    </svg>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className='flex justify-between mt-[35px] [@media(max-width:640px)]:flex-col-reverse [@media(max-width:640px)]:gap-[10px]'>
                        <button className='w-[215px] h-[36px] rounded-border-button [@media(max-width:640px)]:w-full' onClick={props.close}>Back</button>
                        <button className={`w-[215px] h-[36px] rounded-fill-button [@media(max-width:640px)]:w-full dark:hover:!bg-[#4C4C4C] ${isValid ? " " : "btn-disable"}`} onClick={submitHandler} disabled={!isValid && !companies.length}>Request</button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}