import {ArrowSmall} from "./icons/arrow-small";
import {useState} from "react";
import classNames from "classnames";

export default function MultiselectTree(props) {
    const [selected, setSelected] = useState()
    const [items, setItems] = useState(props.items)


    function onHeaderCleared() {
        setSelected(null)
    }

    function onHeaderSelect(item) {
        setSelected(item)
    }

    function onClick(item) {
        item.isSelected = !item.isSelected
        setSelected({...selected})
        if (props.onSelect)  {
            props.onSelect(item)
        }

    }

    if (!selected) {
        return (
            <div className="shadow-xl">
            {items.map((item, index) => {
                return (
                    <span key={index} className={classNames({
                        'bg-[#f0f0f0] font-black border-b border-gray-300': item.isOpen,
                        "select-none hover:bg-[#f0f0f0] flex justify-between items-center p-5": true
                    })}
                          onClick={(event) => {event.stopPropagation(); onHeaderSelect(item)}}>
                    {item.label}
                        <span className={classNames({'rotate-180': item.isOpen})}>
                        <ArrowSmall/>
                    </span>
                </span>
                )
            })}
            </div>
        )
    } else {
        return (
            <div className="shadow-xl">
                {console.log("selected",selected)}
            <span className={classNames({
                        'bg-[#f0f0f0] font-black border-b border-gray-300': true,
                        "select-none hover:bg-[#f0f0f0] flex justify-between items-center p-5 ": true
                    })}
                          onClick={() => onHeaderCleared()}>
                    {selected.label}
                        <span className={classNames({'rotate-180': true})}>
                        <ArrowSmall/>
                    </span>
              </span>
        {selected.subItems.map((item, index) => {
                  return (
                      <span key={index} className={classNames({
                          "select-none hover:bg-[#f0f0f0] flex justify-between items-center p-5 ":true})}
                            onClick={(event) => {event.stopPropagation();onClick(item)}}>
                    {item.label}
                          <span className={classNames({"h-5 w-5 flex flex-col justify-center border-gray-300":true,"border-2":!item.isSelected})}>
                                  <input type="checkbox" className={classNames({"h-full w-full transition duration-300 ease-in-out":true,"opacity-0 z-[-1]":!item.isSelected})}  checked={item.isSelected}/>
                          </span>

                </span>
                  )
              })}
            </div>

        )
    }
}