

// src/apiService.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiService = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://api.moonshoot.inc/' }),
    tagTypes: ['Agency'],
    endpoints: (builder) => ({

        // Skills lookup
        getAward: builder.query({
            query: () => 'award/get',
        }),

        // Skills lookup
        getSkill: builder.query({
            query: () => 'skill/get',
        }),

        // Industry lookup
        getIndustry: builder.query({
            query: () => 'industry/get',
        }),

        // Roles
        getRole: builder.query({
            query: () => 'role/get',
        }),

        // Diversity
        getDiversity: builder.query({
            query: () => 'diversity/get',
        }),

        // Brands
        getBrand: builder.query({
            query: (search) => 'company/get?contain='+search,
        }),

        // Locations
        getLocation: builder.query({
            query: () => 'location/get',
        }),

        getAgency: builder.query({
            query: (body) => ({
                url:'agency/get',
                method: 'POST',
                body,
            }),
            providesTags: (_) => ["Agency"],
        }),

        updateAgency: builder.mutation({
            query: (body, ...rest) => ({
                url:'agency/update',
                method: 'POST',
                body: {
                    "input": body,
                },
            }),
            invalidatesTags: (_) => ["Agency"],
        }),


        getWork: builder.query({
            query: (body) => ({
                url:'work/get',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useGetAwardQuery,
    useGetSkillQuery ,
    useGetIndustryQuery,
    useGetRoleQuery,
    useGetDiversityQuery,
    useGetBrandQuery ,
    useGetLocationQuery,
    useGetAgencyQuery,
    useUpdateAgencyMutation,
    useGetWorkQuery} = apiService;

