import React, { useState,useEffect } from 'react';
import nextArrowWhite from '../../../assets/img/next-arrow-white.svg';
import { Header } from '../common/header';
import { emailPasswordSignUp } from "supertokens-web-js/recipe/thirdpartyemailpassword";


async function signUpClicked(email,password) {
    try {
        let response = await emailPasswordSignUp({
            formFields: [{
                id: "email",
                value: email
            }, {
                id: "password",
                value: password
            }]
        });

        if (response.status === "FIELD_ERROR") {
            // one of the input formFields failed validation
            response.formFields.forEach(formField => {
                if (formField.id === "email") {
                    // Email validation failed (for example incorrect email syntax),
                    // or the email is not unique.
                    window.alert(formField.error);
                } else if (formField.id === "password") {
                    // Password validation failed.
                    // Maybe it didn't match the password strength
                    window.alert(formField.error);
                }
            });
        } else if (response.status === "SIGN_UP_NOT_ALLOWED") {
            // this can happen during automatic account linking. Tell the user to use another
            // login method, or go through the password reset flow.
            // Perhaps you could also alert the user here:
            window.alert("Sign up is not allowed at this time.");
        } else {
            // sign up successful. The session tokens are automatically handled by
            // the frontend SDK.
            window.location.href = "/";
        }
    } catch (err) {
        if (err.isSuperTokensGeneralError === true) {
            // this may be a custom error message sent from the API by you.
            window.alert(err.message);
        } else {
            // This is a catch-all for other errors.
            window.alert("Oops! Something went wrong.");
        }
    }
}

function Signup(props) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // This function is called every time the input changes
    const handlePasswordChange = (event) => {
        // Update the state to the input's new value
        setPassword(event.target.value);
    };

    const handleEmailChange = (event) => {
        // Update the state to the input's new value
        setEmail(event.target.value);
    };


    useEffect(() => {
        if(document.body.classList.contains('dark')) {
            document.body.classList.remove('dark');
        }



    }, [])
    return (
        <div className="page-section authentication">
            <Header isLogin={true}></Header>
            <div className="main-container flex flex-1 overflow-hidden w-full mx-auto">
                <div className='h-full sm:grid [@media(max-width:640px)]:place-items-center [@media(min-width:640px)]:grid-cols-2 [@media(max-width:640px)]:overflow-auto  flex flex-col w-full'>
                    <div className='[@media(min-width:640px)]:items-end [@media(max-width:640px)]:max-h-[400px]  [@media(max-width:640px)]:self-baseline [@media(max-width:640px)]:p-[24px] p-10 w-full [@media(max-width:640px)]:text-center h-full flex flex-col [@media(max-width:640px)]:bg-c-black text-white justify-center'>
                         <div className='[@media(max-width:1250px)]:p-10 [@media(min-width:1250px)]:pr-36 lg:w-[630px] align-right'>
                             <h1 className="form-head">Welcome</h1>
                             <p className='text-[18px] mt-[21px] text-[#000000] [@media(max-width:640px)]:w-full font-normal lg:w-[480px]'>You're just a few steps away from joining our community of the most creative brains on the planet.</p>
                         </div>
                    </div>
                    <div className='self-center [@media(max-width:640px)]:mt-[50px] w-full max-h-full [@media(min-width:640px)]:overflow-auto sm:w-[70%] lg:w-[60%] [@media(min-width:640px)]:ml-[9%]'>
                        <form className="overflow-hidden [@media(max-width:640px)]:p-[24px] p-10 space-y-8 sm:space-y-10 w-full pb-0" autoComplete="off">
                            <div className="field-group w-full">
                                <input type="text" name="email" onChange={handleEmailChange} placeholder=" " className="input-fld" />
                                <label htmlFor="email" className="input-lbl">Email</label>
                            </div>
                            <div className="field-group w-full">
                                <input type="password" name="password" onChange={handlePasswordChange} placeholder=" " className="input-fld" />
                                <label htmlFor="password" className="input-lbl">Password</label>
                            </div>
                            <div className="field-group w-full">
                                <input type="password" name="confirmpassword" placeholder=" " className="input-fld" />
                                <label htmlFor="confirmpassword" className="input-lbl">Confirm Password</label>
                            </div>
                        </form>
                        <div className='overflow-hidden [@media(max-width:640px)]:mb-[60px] [@media(max-width:640px)]:px-[24px] p-10 space-y-7 sm:space-y-7 pt-0 '>
                        <div className="relative !mt-[18px] flex flex-col gap-4 w-full">
                                <div className='text-left'><input type="checkbox" id="vehicle1" name="vehicle1" checked value="Bike"/><label htmlFor="vehicle1" className='p-[14px] text-color-4C4C4C font-normal'>I agree to the terms and the privacy policy.</label></div>
                            </div>
                            <div className='w-full'>
                                <button type="button" onClick={() => signUpClicked(email,password)} className=" w-full text-center flex items-center rounded-full bg-[#121212] text-white text-[16px] px-8 py-3  font-bold shadow-sm h-[48px] mr-4 justify-center">Create account<img src={nextArrowWhite} alt="Next Arrow" className='p-[10px]'/></button>
                            </div>
                            <div className='w-full text-left'>
                                <p className='text-[16px] font-normal text-color-4C4C4C'>Already have an account? please <a href="/login"><b className='text-[16px] font-bold'>Log in.</b></a></p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default Signup;