import React, { useRef,useEffect, useState } from 'react';
import common from '../../../../utils/common';
import {WorkView} from "../../work/WorkView";
import Modal from "../../common/Modal";



export const Matchingprojects = (props) => {

    const [showWork, setShowWork] = useState('');


    const triggerRef = useRef(null);
    const handleTrigger = (work) => {
        setShowWork(work)
    }

    const id = props.id;
    const [workDetails, setWorkDetails] = useState([]);
    const [worksMoreExpanded, setWorksMoreExpanded] = useState(false);

    useEffect(() => {
        if (props.editDetails) {
            {console.log("props.editDetails",props.editDetails)}

            var userDetails;
            var temp = props.type === 'agency' ? props.editDetails.filter(val => val.agency_id === id) : props.editDetails.filter(val => val.user_id === id);
            if (temp && temp.length) {
                userDetails = temp[0];
                if (userDetails.works && userDetails.works.length) {
                    setWorkDetails(userDetails.works);
                }
            }
        }
    }, [])

    return (
        <div className={`${workDetails.length ? "relative" : "hidden"}`}>


            <hr className='my-[88px]'></hr>
    <div >
            <span className='text-[36px] font-bold'>Work from {props.name}</span>
            {!worksMoreExpanded && <div className='pt-[32px] grid grid-cols-2 gap-[48px]'>
                {workDetails.slice(0, 4).map((work, i) => {
                    return (
                        <div className='flex flex-col gap-[4px]'>
                            <div className="h-[257px] [@media(max-width:720px)]:h-[157px] project-pic">
                                {work.creatives && work.creatives.length ? common.checkIsMedia(work.creatives[0].url, ['video/mp4', 'video/mov',"mp4"]) && (work.creatives[0].poster_url === "")
                                    ? <video className={`w-full media-img`} height="257">
                                        <source src={work.creatives[0].url}></source>
                                    </video> :
                                    <div style={{ backgroundImage: `url(${!(common.checkIsMedia(work.creatives[0].url, ['video/mp4', 'video/mov',"mp4"])) ? work.creatives[0].url : work.creatives[0].poster_url})` }} className={`w-full h-full media-img`}>
                                    </div>
                                    : <span className="bg-[#F5F5F5] dark:bg-[#333333] block w-full h-full [@media(max-width:720px)]:rounded-[64px]"></span>}
                            </div>
                            <div className="flex justify-between">
                            <span className='text-[24px] pt-[20px] font-bold tracking-[-0.72px] leading-[100%] capitalize'>{work.name ? work.name : 'Project name'}</span>
                           <button ref={triggerRef} onClick={() => handleTrigger(work)} >
                            <div className="flex pt-[20px] hover:cursor-pointer" style={{
                                fontFamily: 'Archivo',
                                fontSize: '14px',
                                fontWeight: 600,
                                lineHeight: '18px',
                                letterSpacing: '-0.03em',
                                textAlign: 'left'
                            }} >
                                Project Page
                                <i className="inline-block ml-[3px]">
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 5.05079H11.3591C14.3684 5.05079 16.3189 5.05079 17.2105 5.02381L3 18.327L4.58824 20L18.743 6.69683C18.7152 7.56032 18.7152 9.42222 18.7152 12.3365V19.946L21 17.8952V3H5.00619L3 5.05079Z" fill="black"/>
                                    </svg>
                                </i>
                            </div>
                           </button>
                            </div>
                            <span className='text-[18px] leading-[130%] tracking-[-0.54px]'>{work.company && work.company.label}</span>
                        </div>
                    )
                })}
            </div>}
            {worksMoreExpanded && <div className='pt-[32px] grid grid-cols-2 gap-[48px]'>
                {workDetails.map((work, i) => {
                    return (
                        <div className='flex flex-col gap-[4px]'>
                            <button className="flex justify-between" ref={triggerRef} onClick={handleTrigger}>
                                <div className="w-full flex pt-[20px] hover:cursor-pointer" style={{
                                fontFamily: 'Archivo',
                                fontSize: '18px',
                                fontWeight: 600,
                                lineHeight: '18px',
                                letterSpacing: '-0.03em',
                                textAlign: 'left'
                            }} >
                                Project Page
                                <i className="inline-block ml-[3px]">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 5.05079H11.3591C14.3684 5.05079 16.3189 5.05079 17.2105 5.02381L3 18.327L4.58824 20L18.743 6.69683C18.7152 7.56032 18.7152 9.42222 18.7152 12.3365V19.946L21 17.8952V3H5.00619L3 5.05079Z" fill="black"/>
                                    </svg>
                                </i>
                            </div>
                            </button>
                            <div className="h-[257px] [@media(max-width:720px)]:h-[157px] project-pic">
                                {work.creatives && work.creatives.length ? common.checkIsMedia(work.creatives[0].url, ['video/mp4', 'video/mov',"mp4"]) && (work.creatives[0].poster_url === "")
                                    ? <video className={`w-full media-img`} height="257">
                                        <source src={work.creatives[0].url}></source>
                                    </video> :
                                    <div style={{ backgroundImage: `url(${!(common.checkIsMedia(work.creatives[0].url, ['video/mp4', 'video/mov',"mp4"])) ? work.creatives[0].url : work.creatives[0].poster_url})` }} className={`w-full h-full media-img`}>
                                    </div>
                                    : <span className="bg-[#F5F5F5] dark:bg-[#333333] block w-full h-full  [@media(max-width:720px)]:rounded-[64px]"></span>}
                            </div>
                            <span className='text-[24px] pt-[20px] font-bold tracking-[-0.72px] leading-[100%] capitalize'>{work.name ? work.name : 'Project name'}</span>
                            <span className='text-[18px] leading-[130%] tracking-[-0.54px]'>{work.company && work.company.label}</span>
                        </div>
                    )
                })}
            </div>}
            {workDetails.length > 4 && <div className='w-full flex justify-center'>
                <button className='mt-[42px] border-[1px] text-[16px] border-[#1d1d1d] px-[24px] font-bold rounded-full py-[12px]' onClick={(e) => { setWorksMoreExpanded(!worksMoreExpanded) }}>{worksMoreExpanded ? 'Load less' : 'Load More'}</button>
            </div>}
        </div>
            <Modal title={showWork && showWork.name} open={showWork} onClose={() => setShowWork(null)}><WorkView work={showWork}/></Modal>
        </div>)
}
