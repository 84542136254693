import logoWhite from "../../../assets/img/logo_inc_white.svg";

export const ProfileFooter = (props) => {
    return(
        <footer className={`bg-[#0E0E0E] hidden [@media(max-width:767px)]:flex w-full [@media(max-width:720px)]:px-[64px] [@media(max-width:720px)]:py[32px] [@media(max-width:1023px)]:h-[263.59px] h-[80px] py-[16px] bottom-[0] px-[24px] lg:px-[64px] [@media(max-width:1023px)]:flex-col [@media(max-width:1023px)]:justify-center  z-20 items-center gap-[20px] [@media(min-width:1023px)]:gap-[30px] justify-between`}>
            <div className='flex [@media(max-width:1023px)]:flex-col items-center gap-[50px] text-[#07091B] flex-shrink-0'>
                <a href='/' className='cursor-pointer'><img alt="." src={logoWhite} /></a>
            </div>
            <div className='flex items-center gap-[15px] [@media(max-width:1023px)]:flex-col [@media(min-width:767px)]:hidden'>
                <a href='/' className={`cursor-pointer archivo text-[14px] text-white`}>Terms of Use</a>
                <a href='/' className={`cursor-pointer archivo text-[14px] text-white`}>Privacy Policy</a>
                <a href='/' className={`cursor-pointer archivo text-[14px] text-white`}>Discover</a>
            </div>
            <div className="flex items-center flex-shrink-0">
                <p className="m-auto text-white text-[18px] [@media(max-width:1023px)]:text-[14px] Archivo text-center">© 2023 moOnshoot.inc. All rights reserved.</p>
            </div>
            <div className='flex items-center gap-[24px] flex-shrink-0'>
                <a href='/' className={`cursor-pointer archivo text-[18px] text-white [@media(max-width:767px)]:hidden`}>Terms of Use</a>
                <a href='/' className={`cursor-pointer archivo text-[18px] text-white [@media(max-width:767px)]:hidden`}>Privacy Policy</a>
                <a href='/' className={`cursor-pointer archivo text-[18px] text-white [@media(max-width:767px)]:hidden`}>Discover</a>
            </div>
        </footer>
    )
}