import { useForm } from 'react-hook-form';
import FormSearchIcon from '../../../utils/form-search-icon';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormValidationIcon from "../../../utils/form-validation-icon";
import { SelectFormFieldError } from "../../../utils/error-message";
import common from "../../../utils/common";
import { GetMarket } from '../../service/talent-onboardig-api';
import { ProCancel } from '../common/icons/skills-cancel';


export const TargetPopup = (props) => {
    const { setValue } = useForm({ mode: "all", reValidateMode: "onChange", criteriaMode: "all", });
    const marketRef = useRef()
    const projectSetupStore = useSelector(state => state.projectSetupStore)
    const marketRefDrop = useRef()
    const [market, setMarket] = useState([]);
    const [marketDrop, setMarketDrop] = useState([]);
    const [marketTempDrop, setMarketTempDrop] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isDisabled, setisDisabled] = useState(true);
    const selectStore = useSelector((state) => state.selectStore);
    const [isOpen, setisOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=> {
        if(!props.open){
            const popup = document.querySelector(".target-popup")
            if(popup){
                popup.classList.add("slide-right")
            }
            setTimeout(() => {
                setisOpen(false);
            },100)
        }else{
            setisOpen(true)
        }
    },[props.open])

  useEffect(()=>{
    GetMarket(dispatch,selectStore)
    setisDisabled(projectSetupStore.page3.targetGlobal || !market.length)
    if(!projectSetupStore.page3.targetGlobal){
        setMarket([])
    }
    window.addEventListener("scroll", resetDropdownPosition);
    return () => {
      window.removeEventListener("scroll", resetDropdownPosition);
    };
  },[])

  /**Recalculate Position on scroll*/
  function resetDropdownPosition() {
    var targetParent = marketRef.current;
    if (targetParent) {
      targetParent = targetParent.getBoundingClientRect();
      if (document.querySelector('.market.demoFixed') && targetParent.top) {
        document.querySelector('.market.demoFixed').style.top = targetParent.top + 30 + 'px';
      }
    }
  }

  useEffect(()=>{
    var valmarket = [];
    selectStore.market.forEach((v) => {
      var _val = {};
      _val["label"] = v.label;
      _val["market_id"] = v.market_id;
      _val["isChecked"] = false;
      valmarket.push(_val)
    })
    Object.entries(projectSetupStore.page3).forEach((val) => {
      if (
        val[1] && (val[1] !== "" || (Array.isArray(val[1]) && val[1].length))
      ) {
        if (val[0] === "markets" && val[1].length) {
          setisDisabled(false)
          var _market = []
          val[1].forEach((_val, i) => {
            var index = valmarket.findIndex(x => x.market_id === _val);
            var marVal = valmarket.filter(x => x.market_id === _val)[0];
            _market.push(marVal);
            if (index !== -1) {
              valmarket[index]["isChecked"] = true;
            }
          })
          if (_market.length >= 1) {
            setValue(val[0], _market, {
              shouldTouch: true,
              shouldValidate: true,
              shouldDirty: true,
            });
            setMarket(_market);
          }
        } else {
          // setisDisabled(true)
          setValue(val[0], val[1], {
            shouldTouch: true,
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }
    });
    setMarketTempDrop(valmarket);
    setMarketDrop(valmarket)
  },[projectSetupStore.page3.markets, selectStore.market])

  // useEffect(()=>{
    
  //   if(isDisabled){
  //       console.log(isDisabled)
  //       var valmarket = [];
  //       setMarket([])
  //       selectStore.market.forEach((v) => {
  //           var _val = {};
  //           _val["label"] = v.label;
  //           _val["market_id"] = v.market_id;
  //           _val["isChecked"] = false;
  //           valmarket.push(_val)
  //         })
  //         setMarketDrop(valmarket);
  //       // dispatch({type: "project_setup/insertValue",payload: { id: _data, name: "page3" }});    
  //   }
  // },[isDisabled])

  function marketBlurHandler() {
    // setMarketLabel(false)
    const isDropdownOpen = document.querySelector('.multi-select.react-select__menu.market.demoFixed');
    if(isDropdownOpen && isDropdownOpen.style.display === 'none') {
      const mark = document.querySelector(".market-label");
      if (mark && !searchText.length) {
        mark.classList.add("move-label");
      }
      if (document.getElementsByName('serving_markets')) {
        document.getElementsByName('serving_markets').forEach(o => {
          if (o && o.value !== "") {
            o.value = ""
          }
        })
      }
    } else {
      const mark1 = document.querySelector(".market-label");
      if (mark1) {
        mark1.classList.remove("move-label");
      }
      marketRef.current.focus()
    }
  }

  const enableFirstLevel = () => {
    common.visibleAllParent('.market-label');
    var isDropdownOpened = document.querySelector('.multi-select.react-select__menu.market.demoFixed');
    if(isDropdownOpened && isDropdownOpened.style.display === 'none') {
      setMarketDrop(marketDrop)
    }
    // if(marketDrop.length === dropLength && !searchText.length){
    //   setMarketDrop(marketDrop)
    // } else {
    //   var drop = JSON.parse(JSON.stringify(marketTwm))
    //   var mrkt = JSON.parse(JSON.stringify(market))
    //   mrkt.forEach((v, index) => {
    //     var findIn = mrkt.findIndex(value => value.market_id === v.market_id);
    //     if(findIn !== -1) {
    //       drop[findIn]['isChecked'] = true
    //     }
    //   })
    //   console.log(drop)
    // }
    // setMarketDrop(marketDrop)
    if (document.querySelectorAll('.market .demoFixedSubLevel')) {
      [...document.querySelectorAll('.market .demoFixedSubLevel')].forEach(div => {
        if (div.style.display !== 'none') {
          div.style.display = 'none'
        }
      });
    }
    const mark = document.querySelector(".market-label");
    if (mark) {
      mark.classList.remove("move-label");
    }
    const parent = marketRef.current.getBoundingClientRect()
    document.querySelector('.market.demoFixed').style.left = parent.left + 'px';
    document.querySelector('.market.demoFixed').style.top = parent.top + 30 + 'px';
    document.querySelector('.market.demoFixed').style.width = parent.width + 'px';
    document.querySelector('.react-select__menu.market.demoFixed').style.display = 'block';
    document.querySelector('.market-multi-select-backdrop').style.display = 'block';
  }

  const disableallLevel = () => {
    common.visibleAllParent('.market');
    setSearchText("")
    if (document.querySelector('.market.demoFixed').style) {
      document.querySelector('.market.demoFixed').style.display = 'none';
      if (market && !market.length >= 3) {
        selectStore.market.forEach((_val, _i) => {
          document.querySelector('.react-select__menu.market .demoFixedSubLevel.child_' + _i).style.display = 'none'
        })
      }
      document.querySelector('.market-multi-select-backdrop').style.display = 'none';
      marketBlurHandler();
      document.activeElement.blur();
      const mark = document.querySelector(".market-label");
      if (mark) {
        mark.classList.add("move-label");
      }
      setMarketDrop(marketTempDrop)
    }
  }

  function removeHandler(val, i) {
    const marketValue = [...market];
    marketValue.splice(i, 1);
    setMarket(marketValue);
    var _data = JSON.parse(JSON.stringify(projectSetupStore.page3));
    let data = [];
    marketValue.forEach((_val) => {
      data.push(_val.market_id);
    });
    _data["targetGeographic"] = marketValue;
    dispatch({
      type: "project_setup/insertValue",
      payload: { id: {'markets': _data}, name: "page3" },
    });
    // props.saveData({ serving_markets: data });
  }


  function marketOnchange(val, i, e) {
    if(e) {
      e.preventDefault()
      e.stopPropagation();
    }
    var _val = JSON.parse(JSON.stringify(marketDrop));
    var _valTemp = JSON.parse(JSON.stringify(marketTempDrop));
    var _market = JSON.parse(JSON.stringify(market));
    var _valueIndex = _market.findIndex(value => value.market_id === val.market_id)
    var _valueTempIndex = _valTemp.findIndex(value => value.market_id === val.market_id)
    if (_val[i]["isChecked"]) {
      _val[i]["isChecked"] = false;
      if(_valueTempIndex !== -1){
        _valTemp[_valueTempIndex]['isChecked'] = false;
      }
      if (_valueIndex !== -1) {
        _market.splice(_valueIndex, 1);
      }
    } else {
      _val.forEach((val, j) => {
        if (i === j) {
          _val[j]["isChecked"] = true;
          if(_valueTempIndex !== -1){
            _valTemp[_valueTempIndex]['isChecked'] = true;
          }
          _market.push(_val[j])
        }
      })
    }
    setMarket(_market);
    setMarketDrop(_val)
    setMarketTempDrop(_valTemp);
    // if(searchText.length) {
    //   marketRef.current.focus()
    // }
    // var _data = JSON.parse(JSON.stringify(talentStore.page1));
    // let data = [];
    // _market.forEach((_val) => {
    //   data.push(_val.market_id);
    // });
    // _data["serving_markets"] = _market;
    // dispatch({
    //   type: "talent_details/insertValue",
    //   payload: { id: _data, name: "page1" },
    // });
    // props.saveData({ serving_markets: data });
  }

  function submitHandler(){
      var _data = JSON.parse(JSON.stringify(projectSetupStore.page3));        
    if(isDisabled){
        _data["targetGlobal"] = true;
        _data["markets"] = []
        dispatch({
          type: "project_setup/insertValue",
          payload: { id: _data, name: "page3" },
        });
        props.saveData({ "markets": [] });
    }else{
        const marketValue=[...market]
        _data["targetGlobal"] = false;
        _data["markets"] = marketValue.filter((val => val.market_id)).map((obj) => obj.market_id );
        dispatch({
          type: "project_setup/insertValue",
          payload: { id: _data,  name: "page3" },
        });
        var val = _data["markets"]
        props.saveData({ "markets": val });
    }
    props.close()
  }
  function onInputChange(event) {
    const _searchText = event.target.value;
    setSearchText(_searchText)
    if (_searchText !== "") {
      var val = JSON.parse(JSON.stringify(marketTempDrop))
      let newArray = val.filter(function (o) {
        return Object.keys(o).some(k => {
          return typeof o[k] === 'string' && o[k].toLowerCase().includes(_searchText.toLowerCase());
        });
      }
      );
      if (newArray.length) {
        setMarketDrop(newArray);
      } else {
        setMarketDrop([]);
      }
    }
    else if (!_searchText || _searchText.length === 0 || _searchText === "") {
      setMarketDrop(marketTempDrop)
    }
  }

  function onChangeHandler(e){
    const temp = []
    if(e.target.value === "on"){
        setisDisabled(false)
        temp.push()
    }else{
        setisDisabled(true)
        var valmarket = [];
        setMarket([])
        selectStore.market.forEach((v) => {
            var _val = {};
            _val["label"] = v.label;
            _val["market_id"] = v.market_id;
            _val["isChecked"] = false;
            valmarket.push(_val)
          })
          setMarketDrop(valmarket);
    }
  }
    

if(isOpen){
    return(
        <div className="relative z-10 mobile:w-full" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 overlay-background bg-opacity-75 backdrop-blur-sm transition-opacity mobile:hidden"></div>
            <div className={`fixed target-popup inset-0 z-10 overflow-y-auto h-full mobile:relative mobile:h-auto mobile:w-full ${props.open ? "slide-left" : ""}`}>
                <div className='absolute h-full p-[32px] top-0 right-0 bg-[#ffffff] w-[485px] shadow-md mobile:relative mobile:shadow-none mobile:w-full mobile:p-0 mobile:h-auto dark:bg-[#0E0E0E]'>
                    <div className="w-full flex flex-wrap">
                        <div className='flex mobile:hidden'>
                            <div className='flex flex-wrap gap-1 text-left'>
                                <p className='text-[#07091b] text-[20px] leading-[130%] tracking-[-0.6px] dark:text-[#fff]'>04. Target Geography</p>
                                <p className='text-[#a3a3a3] text-[14px] leading-[130%] tracking-[-0.42px] dark:text-[#808080]'>Are we targeting specific geographies, regions or are we global?</p>
                            </div>
                            <div className="absolute right-0 xs:mt-[-10px] pr-7 sm:block mt-[4px]">
                                <button type="button" onClick={props.close} className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none pr-[7px]"><ProCancel/></button>
                            </div>
                        </div>
                        <div className='w-full flex mt-[16px] pt-[24px] box-border border-t border-solid border-[#d9d9d9]'>
                            <div className="flex items-center w-full dark:border-none" >
                                <div className='input-fld mb-0 text-[14px] leading-[130%] tracking-[-0.42px]'>
                                    <div className='flex flex-row-reverse justify-between items-center h-[73px] py-[10px] md:pr-[10px]'>
                                        <input checked={isDisabled} onChange={onChangeHandler} id="radio" type='radio' name='target' value={"off"} className='h-[24px] w-[24px] peer border-[#D9D9D9] cursor-pointer' />  
                                        <label htmlFor="radio" className="text-[#a3a3a3] text-[16px] text-left peer-checked:text-[#000] dark:peer-checked:text-[#FFF] !transform-none mobile:text-[14px] mobile:leading-[130%] mobile:tracking-[-0.42px]">We are global</label> 
                                    </div>
                                    <hr className='box-border border-b border-solid border-[#ececec]'/>
                                    <div className='md:h-[73px] py-[16px] md:pr-[10px] mt-[10px]'>
                                        <input checked={!isDisabled} onChange={onChangeHandler} id="radio" type='radio' name='target' className='h-[24px] float-right w-[24px] peer border-[#D9D9D9] cursor-pointer'/> 
                                        <div className="field-group w-[350px] float-left custom-select market-placeholder mobile:w-[calc(100%-44px)]">
                                            <label className={`absolute move-label market-label diversity-edge text-[14px] text-[#a3a3a3] origin-0 select-label ${!isDisabled ? "text-[#000] dark:text-[#FFF]" : ""}`}>Targeting specific markets</label>
                                            <input autoComplete="off" type="text" disabled={isDisabled} ref={marketRef} name="serving_markets" onFocus={enableFirstLevel} onInputCapture={onInputChange} onBlur={marketBlurHandler} className="input-fld position-inherit z-[2]" />
                                            {market && market.length < 1 && <FormSearchIcon />}
                                            <FormValidationIcon isArray={true} twolevelselect={true} minlen={1} getval={market}></FormValidationIcon>
                                            <div className={'market-multi-select-backdrop multi-select-backdrop '} onClick={disableallLevel}></div>
                                        </div>
                                        <div ref={marketRefDrop} className="multi-select react-select__menu market demoFixed">
                                            <div className="react-select__menu-list react-select__menu-list--is-multi single-level">
                                             {marketDrop.length <= 0 && <div className="react-select__menu-notice react-select__menu-notice--no-options css-1wlit7h-NoOptionsMessage">No options</div>}
                                                {JSON.parse(JSON.stringify(marketDrop)).map((diver, i) =>
                                                    <div className={"react-select__option inline-block"} key={i}  >
                                                        <li className="cursor-pointer"  onClick={(e) => { marketOnchange(diver, i, e) }}><span className="txt"> {diver.label}
                                                            <label className="control control--checkbox">
                                                            <input type="checkbox"  onChange={() => { marketOnchange(diver, i) }} readOnly checked={diver.isChecked}/>
                                                            <div className="control__indicator"></div>
                                                            </label>
                                                        </span></li>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <SelectFormFieldError isValid={true}></SelectFormFieldError>
                                        <div className={`${market && market.length ? 'mt-[20px] md:!mb-[24px] custom-multi-select' : ''}  `}>
                                            {market && !isDisabled && market.map((val, i) => {
                                                return (
                                                    <div className="react-select__control new inline-block" key={i}><div className="react-select__value-container">
                                                        <div className="react-select__multi-value new">
                                                            <div className="react-select__multi-value__label">
                                                                <div className="market-option flex m-auto">
                                                                    <span className="mar-label txt">{val.label}</span>
                                                                </div>
                                                            </div>
                                                            <div role="button" onClick={() => removeHandler(val, i)} className="react-select__multi-value__remove" aria-label="Remove X">
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.72184 15.2738C4.82196 15.3739 4.938 15.4399 5.06997 15.4717C5.20648 15.5036 5.33845 15.5036 5.46587 15.4717C5.59784 15.4399 5.7116 15.3762 5.80717 15.2806L10.0051 11.0827L14.1962 15.2806C14.2918 15.3762 14.4056 15.4399 14.5375 15.4717C14.6695 15.5036 14.8015 15.5036 14.9334 15.4717C15.0654 15.4399 15.1815 15.3739 15.2816 15.2738C15.3771 15.1782 15.4408 15.0644 15.4727 14.9325C15.5046 14.8005 15.5046 14.6708 15.4727 14.5434C15.4408 14.4114 15.3771 14.2954 15.2816 14.1953L11.0904 9.99733L15.2816 5.8062C15.3771 5.70609 15.4408 5.59232 15.4727 5.4649C15.5091 5.33294 15.5091 5.20097 15.4727 5.069C15.4408 4.93703 15.3771 4.82327 15.2816 4.7277C15.1769 4.62759 15.0586 4.5616 14.9266 4.52975C14.7992 4.4979 14.6695 4.4979 14.5375 4.52975C14.4056 4.55705 14.2918 4.62076 14.1962 4.72088L10.0051 8.91883L5.80717 4.72088C5.7116 4.62531 5.59784 4.5616 5.46587 4.52975C5.3339 4.4979 5.20193 4.4979 5.06997 4.52975C4.938 4.5616 4.82196 4.62759 4.72184 4.7277C4.62628 4.82327 4.56257 4.93703 4.53072 5.069C4.49886 5.20097 4.49886 5.33294 4.53072 5.4649C4.56257 5.59687 4.62628 5.70836 4.72184 5.79937L8.91297 9.99733L4.72184 14.2021C4.62628 14.2931 4.5603 14.4046 4.52389 14.5366C4.49204 14.6685 4.49204 14.8005 4.52389 14.9325C4.55575 15.0644 4.62173 15.1782 4.72184 15.2738Z" className="dark:fill-white" fill="#1D1D1D" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>);
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='absolute bottom-[32px] mt-[10%] mobile:mt-[24px] mobile:relative mobile:bottom-auto'>
                        <button onClick={submitHandler} disabled={(((market  && market.length) || isDisabled) ? false : true)} className='bg-c-black disabled:opacity-[0.3] text-white w-[421px] h-[45px] font-bold mobile:text-[12px] mobile:tracking-[-0.36px] text-[16px] leading-[130%] tracking-[-0.48px] rounded-full px-[24px] py-[12px] mobile:w-full dark:!bg-[#fff] dark:text-[#0E0E0E]'>Save </button>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}