import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import logo from "../../../assets/img/initial-logo.svg";
import clkInteraction from "../../../assets/img/ok.svg";
import orangeIcon from "../../../assets/img/arrow-orange-icon.svg";
import mlogo from "../../../assets/img/logo-mini-color.svg";
import minilogo from '../../../assets/img/logo-mini-color.svg';
import layer from "../../../assets/img/layer.png";
import { useState } from "react";
import { DiversityPopup } from "./pinpoints-popup/diversity-popup";
import { IndustryPopup } from "./pinpoints-popup/industry-popup";
import { BrandsPopup } from "./pinpoints-popup/brands-popup";
import { TargetPopup } from "./pinpoints-popup/target-popup";
import { BrandVoicePopup } from "./pinpoints-popup/brandVoice-popup";
import { BudgetPopup } from "./pinpoints-popup/budget-popup";
import { FinalQuestionpopup} from "./pinpoints-popup/finalQuestion-popup";
import { ProjectSetupTemplate } from "../common/project-setup-template";
import { ProjectSetupFooter } from "../common/project-setup-footer";
import darklogo from "../../../assets/img/dark-initial-logo.svg";
import darkminilogo from '../../../assets/img/dark-logo-mini.svg';
import darklayer from "../../../assets/img/LayerDark.png";
import { DropUpdown } from "../common/icons/dropupdown";
import { useSelector } from "react-redux";
import violetOk from "../../../assets/img/violet-ok.svg"
import nav from "../../../assets/img/nav-arrow.png";

export const ProjectPinpoints = (props) => {

    const projectSetupStore = useSelector(state => state.projectSetupStore)
    const [openDiversity,setOpenDiversity] = useState(false);
    const [openIndustry,setOpenIndustry] = useState(false);
    const [openBrands,setOpenBrands] = useState(false);
    const [openTarget,setOpenTarget] = useState(false);
    const [openBrandVoice,setOpenBrandVoice] = useState(false);
    const [openBudget,setOpenBudget] = useState(false);
    const [openFinal,setOpenFinal] = useState(false);
    const [openMobileView,setMobileView] = useState(false);
    const [selectedSkills, setSelectedSkills] = useState()
    const navigate = useNavigate();

    const selectedDiversity = useSelector(state => {return state.projectEditStore.diversity?state.projectEditStore.diversity:[]});



    function showPopup(setState,state,index){
        setState(!state);
        if(window.innerWidth < 767) {
            const revealTxt = document.getElementsByClassName('reveal-txt'); 
            if(revealTxt) {
                if(revealTxt[index].style.display === "block") {
                    revealTxt[index].style.display = "none"
                } else {
                    revealTxt[index].style.display = "block"
                }
            }
        }
        if(window.innerWidth > 767) {
            document.body.classList.add("overflow-hidden")
        }
    }

    useEffect(() => {
        resizeListener();
        if(projectSetupStore.page2 && projectSetupStore.page2.skills && projectSetupStore.page2.skills.length) {
            var content = projectSetupStore.page2.skills[0].split('-');
            if(content && content.length) {
                if(content[0] === 'bigidea') {
                    setSelectedSkills('Big Idea')
                } else if(content[0] === 'experiential') {
                    setSelectedSkills('Experiential & Activation')
                } else if(content[0] === 'metaverse') {
                    setSelectedSkills('Gaming, AI & New Realities')
                } else if(content[0] === 'strategybrand') {
                    setSelectedSkills('Strategy')
                } else if(content[0] === 'social') {
                    setSelectedSkills('Social & Creators')
                } else if(content[0] === 'brand') {
                    setSelectedSkills('Content & Entertainment')
                }
            }
        }


        window.addEventListener('resize', resizeListener);
        return () => {
        window.removeEventListener('resize', resizeListener);
        }


    },[]);


    function resizeListener() { 
        if(window.innerWidth < 768) {
            setMobileView(true);
        } else {
            setMobileView(false);
        }
    }
    // function showFinal(){
    //     setOpenFinal(true);
    // }

    function onBack() {
        navigate("/project/skills")
    }

    function PinpointText(){
        return(
            <div className="w-full flex flex-col justify-between bg-[#F5F5F5] dark:bg-[#161616] gap-[64px]">
                <div className="flex flex-col gap-[88px] mobile:gap-[48px] tablet:gap-[48px]">
                    <div className="flex flex-row gap-[16px] mobile:h-[64px] tablet:py-[5px] tablet:px-[24px] tablet:bg-white tablet:dark:bg-[#0E0E0E]">
                        <img alt="mlogo" className="tablet:p-[10px] [@media(min-width:768px)]:hidden dark:hidden" src={minilogo} width="48px" /><img alt="mlogo" className="tablet:p-[10px] mobile:hidden dark:hidden" src={mlogo} width="56px" height="50px" /><img alt="logo" className="mobile:hidden dark:hidden" src={logo} width="182px" />
                        <img alt="mlogo" className="tablet:p-[10px] hidden mobile:dark:block" src={darkminilogo} width="48px" /><img alt="mlogo" className="tablet:p-[10px] mobile:dark:hidden dark:block hidden" src={darkminilogo} width="56px" height="50px" /><img alt="logo" className="mobile:hidden dark:block hidden" src={darklogo} width="182px" />
                    </div>
                    <div className="flex flex-col  text-[#1D1D1D] dark:text-[#fff] tablet:px-[24px]">
                        <nav className="flex flex-col items-start gap-[6px] pb-[16px] " aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                <li className="inline-flex items-center">
                                    <div onClick={() => props.prevStep("startingpoint")} className="inline-flex cursor-pointer items-center text-[14px] font-medium text-[#0e0e0e] dark:text-[#fff] hover:!text-[#F05831] capitalize whitespace-nowrap">Start</div>
                                </li>
                                <img alt='navigation' src={nav}/>
                                <li>
                                    <div onClick={props.prevStep} className="ml-1 cursor-pointer inline-flex items-center text-[14px] dark:text-[#fff] text-[#0e0e0e] font-medium  hover:!text-[#F05831] capitalize">Skills</div>
                                </li>
                                <img alt='navigation' src={nav}/>
                                <li>
                                    <div className="ml-1 inline-flex cursor-pointer text-[14px] font-semibold text-[#0e0e0e] hover:!text-[#F05831] block dark:text-[#FFF] capitalize whitespace-nowrap">{selectedSkills && selectedSkills !== "" ?selectedSkills + ' - ' : ''}Pinpoints</div>
                                </li>
                            </ol>
                        </nav>
                        <hr className="border-solid border-[#d9d9d9] dark:border-[#A3A3A3]"/>
                        <p className="[@media(width:1920px)]:w-[624px] archivo text-[40px] pt-[24px] font-extralight leading-[120%] tracking-[-1.2px] mobile:text-[24px] mobile:leading-[120%] mobile:tracking-[-0.72px] dark:text-[#fff]"><span className="archivo w-full float-left font-extrabold text-[40px] mobile:text-[24px] mobile:w-auto mobile:float-none">Get specific. </span>The devil is in the detail.</p>
                    </div>
                </div>
                <div className="w-full flex-col justify-start items-start gap-[24px] flex tablet:hidden">
                    <div className="w-full flex-col justify-start items-start gap-[24px] flex height-ctrl:hidden">
                        <div className="relative dark:hidden"><img alt="." src={layer} height="168px" width="236px" /></div>
                        <div className="relative hidden dark:block"><img alt="." src={darklayer} height="168px" width="236px" /></div>
                        <div className="self-stretch border-t-[1px] border-solid border-[#d9d9d9]"></div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="page-section">
            <ProjectSetupTemplate>
                <PinpointText></PinpointText>
                <div className="h-[calc(100%-115px)] flex flex-wrap content-between gap-[64px] tablet:gap-[48px] tablet:h-auto">
                    <div className="w-full h-full">
                        <div className="w-full h-[calc(100%-84px)] overflow-auto grid grid-cols-[repeat(auto-fill,_minmax(350px,_1fr))] gap-[12px] child:flex child:min-h-[228px] child:py-[12px] child:pr-[88px] child:bg-white mobile:grid-cols-none child:mobile:p-[24px] mobile:child:h-[auto] child:mobile:min-h-[auto] dark:child:bg-[#0E0E0E] dark:text-[#fff]">
                            <div className="relative flex flex-wrap cursor-pointer group items-center bg-white hover:bg-">
                                {/* Diversity */}
                                <div onClick={() => showPopup(setOpenDiversity,openDiversity,0)} className="w-full flex flex-wrap gap-[8px] p-[24px] pr-[0] text-left mobile:p-[0]">
                                    <span className="archivo w-full flex items-center mobile:justify-between">
                                        <span className="mobile:flex mobile:items-center text-[24px] leading-[100%] tracking-[-0.72px] mobile:gap-[16px] mobile:text-[18px] mobile:leading-[110%] mobile:tracking-[-0.54px]">
                                            <img alt="icon" src={selectedDiversity.length ? violetOk : orangeIcon} className="mb-[8px] mobile:mb-[0] mobile:float-left transition-all left-0 relative group-hover:left-3"/><img alt="icon" src={clkInteraction} className="mb-[8px] mobile:mb-[0] mobile:float-left hidden"/>01. Diversity
                                        </span>
                                        <button className="hidden px-[4px] py-[3.5px] shrink-0 mobile:block"><DropUpdown/></button>
                                    </span>
                                    <div className="w-full reveal-txt hidden md:group-hover:block animated slideInDown">
                                        <p className="[@media(width:1920px)]:w-[313px] text-[14px] text-[#808080] leading-[130%] tracking-[-0.42px] mobile:text-[14px] fady-in mobile:leading-[130%] mobile:tracking-[-0.42px] dark:text-[#a3a3a3]">Inclusivity and diversity are essential for fostering creativity. If you are seeking a particular affinity, let us know.</p>
                                        {selectedDiversity.length > 0 &&
                                        <button className="right-[32px] bottom-[32px] absolute mobile:right-[0] mobile:bottom-[0] mobile:relative mobile:ml-[10px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                <path className="dark:stroke-white" d="M11 4.33324H6.8C5.11984 4.33324 4.27976 4.33324 3.63803 4.66022C3.07354 4.94784 2.6146 5.40678 2.32698 5.97126C2 6.613 2 7.45308 2 9.13324V17.5332C2 19.2134 2 20.0535 2.32698 20.6952C2.6146 21.2597 3.07354 21.7186 3.63803 22.0063C4.27976 22.3332 5.11984 22.3332 6.8 22.3332H15.2C16.8802 22.3332 17.7202 22.3332 18.362 22.0063C18.9265 21.7186 19.3854 21.2597 19.673 20.6952C20 20.0535 20 19.2134 20 17.5332V13.3332M7.99997 16.3332H9.67452C10.1637 16.3332 10.4083 16.3332 10.6385 16.278C10.8425 16.229 11.0376 16.1482 11.2166 16.0385C11.4184 15.9148 11.5914 15.7419 11.9373 15.396L21.5 5.83324C22.3284 5.00481 22.3284 3.66166 21.5 2.83323C20.6716 2.00481 19.3284 2.00481 18.5 2.83323L8.93723 12.396C8.59133 12.7419 8.41838 12.9148 8.29469 13.1167C8.18504 13.2956 8.10423 13.4907 8.05523 13.6948C7.99997 13.9249 7.99997 14.1695 7.99997 14.6587V16.3332Z" stroke="#1D1D1D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </button>}
                                    </div>
                                </div>
                                {console.log(",openDiversity",openDiversity)}
                                <div className="w-full hidden mobile:block">{openDiversity && <DiversityPopup typ="mobile" open={openDiversity} saveData={props.saveData} close={() => {setOpenDiversity(false);document.body.classList.remove("overflow-hidden")}}/>}</div>
                                {/* <button className="absolute bottom-[0] right-[0] items-center gap-[10px] flex-wrap p-[24px] shrink-0 mobile:hidden"><EditIcon/></button> */}
                            </div>

                            {/* Industry */}
                            <div className="relative group cursor-pointer flex flex-wrap items-center bg-white">
                                <div onClick={() => showPopup(setOpenIndustry,openIndustry,1)} className="w-full flex flex-wrap gap-[8px] p-[24px] pr-[0] text-left mobile:p-[0]">
                                    <span className="archivo w-full flex items-center mobile:justify-between">
                                        <span className="mobile:flex mobile:items-center text-[24px] leading-[100%] tracking-[-0.72px] mobile:gap-[16px] mobile:text-[18px] mobile:leading-[110%] mobile:tracking-[-0.54px]">
                                            <img alt="icon" src={projectSetupStore.page3.industry && projectSetupStore.page3.industry.length ? violetOk : orangeIcon} className="mb-[8px] mobile:mb-[0] mobile:float-left transition-all left-0 relative group-hover:left-3"/><img alt="icon" src={clkInteraction} className="mb-[8px] mobile:mb-[0] mobile:float-left hidden"/>02. Industry
                                        </span>
                                        <button className="hidden px-[4px] py-[3.5px] shrink-0 mobile:block"><DropUpdown/></button>
                                    </span>
                                    <div className="w-full reveal-txt hidden md:group-hover:block animated slideInDown">
                                        <p className="[@media(width:1920px)]:w-[313px] text-[14px] text-[#808080] leading-[130%] tracking-[-0.42px] mobile:text-[14px] fady-in mobile:leading-[130%] mobile:tracking-[-0.42px] dark:text-[#a3a3a3]">What industry experience is most relevant to your project?</p>  
                                        {projectSetupStore.page3.industry && projectSetupStore.page3.industry.length > 0 && 
                                        <button className="right-[32px] bottom-[32px] absolute mobile:right-[0] mobile:bottom-[0] mobile:relative mobile:ml-[10px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                <path className="dark:stroke-white" d="M11 4.33324H6.8C5.11984 4.33324 4.27976 4.33324 3.63803 4.66022C3.07354 4.94784 2.6146 5.40678 2.32698 5.97126C2 6.613 2 7.45308 2 9.13324V17.5332C2 19.2134 2 20.0535 2.32698 20.6952C2.6146 21.2597 3.07354 21.7186 3.63803 22.0063C4.27976 22.3332 5.11984 22.3332 6.8 22.3332H15.2C16.8802 22.3332 17.7202 22.3332 18.362 22.0063C18.9265 21.7186 19.3854 21.2597 19.673 20.6952C20 20.0535 20 19.2134 20 17.5332V13.3332M7.99997 16.3332H9.67452C10.1637 16.3332 10.4083 16.3332 10.6385 16.278C10.8425 16.229 11.0376 16.1482 11.2166 16.0385C11.4184 15.9148 11.5914 15.7419 11.9373 15.396L21.5 5.83324C22.3284 5.00481 22.3284 3.66166 21.5 2.83323C20.6716 2.00481 19.3284 2.00481 18.5 2.83323L8.93723 12.396C8.59133 12.7419 8.41838 12.9148 8.29469 13.1167C8.18504 13.2956 8.10423 13.4907 8.05523 13.6948C7.99997 13.9249 7.99997 14.1695 7.99997 14.6587V16.3332Z" stroke="#1D1D1D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </button>}
                                    </div>
                                </div>
                                <div className="w-full hidden mobile:block">{openIndustry && <IndustryPopup open={openIndustry}  saveData={props.saveData} close={() => {setOpenIndustry(false);document.body.classList.remove("overflow-hidden")}}/>}</div>
                                {/* <button className="absolute bottom-[0] right-[0] items-center gap-[10px] flex-wrap p-[24px] shrink-0 mobile:hidden"><EditIcon/></button> */}
                                {/* {openIndustry && <IndustryPopup close={() => setOpenIndustry(false)}/>} */}
                            </div>

                            {/* Brands*/}
                            <div className="relative cursor-pointer flex flex-wrap group items-center bg-white">
                                <div onClick={() => showPopup(setOpenBrands,openBrands,2)} className="w-full flex flex-wrap gap-[8px] p-[24px] pr-[0] text-left mobile:p-[0]">
                                    <span className="archivo w-full flex items-center mobile:justify-between">
                                        <span className="mobile:flex mobile:items-center text-[24px] leading-[100%] tracking-[-0.72px] mobile:gap-[16px] mobile:text-[18px] mobile:leading-[110%] mobile:tracking-[-0.54px]">
                                            <img alt="icon" src={projectSetupStore.page3.inspiration && projectSetupStore.page3.inspiration.length ? violetOk : orangeIcon} className="mb-[8px] mobile:mb-[0] mobile:float-left transition-all left-0 relative group-hover:left-3"/><img alt="icon" src={clkInteraction} className="mb-[8px] mobile:mb-[0] mobile:float-left hidden"/>03. Brands that inspire you
                                        </span>
                                        <button className="hidden px-[4px] py-[3.5px] shrink-0 mobile:block"><DropUpdown/></button>
                                    </span>
                                    <div className="w-full reveal-txt hidden md:group-hover:block animated slideInDown">
                                        <p className="[@media(width:1920px)]:w-[313px] text-[14px] text-[#808080] leading-[130%] fady-in tracking-[-0.42px] mobile:text-[14px] mobile:leading-[130%] mobile:tracking-[-0.42px] dark:text-[#a3a3a3]">Although your brand is unique, we can find talent whose creative work you find inspiring and relevant to your project.</p>
                                        {projectSetupStore.page3.inspiration && projectSetupStore.page3.inspiration.length > 0 && 
                                        <button className="right-[32px] bottom-[32px] absolute mobile:right-[0] mobile:bottom-[0] mobile:relative mobile:ml-[10px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                <path className="dark:stroke-white" d="M11 4.33324H6.8C5.11984 4.33324 4.27976 4.33324 3.63803 4.66022C3.07354 4.94784 2.6146 5.40678 2.32698 5.97126C2 6.613 2 7.45308 2 9.13324V17.5332C2 19.2134 2 20.0535 2.32698 20.6952C2.6146 21.2597 3.07354 21.7186 3.63803 22.0063C4.27976 22.3332 5.11984 22.3332 6.8 22.3332H15.2C16.8802 22.3332 17.7202 22.3332 18.362 22.0063C18.9265 21.7186 19.3854 21.2597 19.673 20.6952C20 20.0535 20 19.2134 20 17.5332V13.3332M7.99997 16.3332H9.67452C10.1637 16.3332 10.4083 16.3332 10.6385 16.278C10.8425 16.229 11.0376 16.1482 11.2166 16.0385C11.4184 15.9148 11.5914 15.7419 11.9373 15.396L21.5 5.83324C22.3284 5.00481 22.3284 3.66166 21.5 2.83323C20.6716 2.00481 19.3284 2.00481 18.5 2.83323L8.93723 12.396C8.59133 12.7419 8.41838 12.9148 8.29469 13.1167C8.18504 13.2956 8.10423 13.4907 8.05523 13.6948C7.99997 13.9249 7.99997 14.1695 7.99997 14.6587V16.3332Z" stroke="#1D1D1D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </button>}
                                    </div>
                                </div>
                                <div className="w-full hidden mobile:block">{openBrands && <BrandsPopup open={openBrands} saveData={props.saveData} close={() => {setOpenBrands(false);document.body.classList.remove("overflow-hidden")}}/>}</div>
                              </div>

                            {/* Target Geography */}
                            <div className="relative cursor-pointer flex flex-wrap group items-center bg-white">
                                <div onClick={() => showPopup(setOpenTarget,openTarget,3)} className="w-full flex flex-wrap gap-[8px] p-[24px] pr-[0] text-left mobile:p-[0]">
                                    <span className="archivo w-full flex items-center mobile:justify-between">
                                        <span className="mobile:flex mobile:items-center text-[24px] leading-[100%] tracking-[-0.72px] mobile:gap-[16px] mobile:text-[18px] mobile:leading-[110%] mobile:tracking-[-0.54px]">
                                            <img alt="icon" src={(projectSetupStore.page3.targetGlobal || (projectSetupStore.page3.markets && projectSetupStore.page3.markets.length)) ? violetOk :  orangeIcon} className="mb-[8px] mobile:mb-[0] mobile:float-left transition-all left-0 relative group-hover:left-3"/><img alt="icon" src={clkInteraction} className="mb-[8px] mobile:mb-[0] mobile:float-left hidden"/>04. Target Geography
                                        </span>
                                        <button className="hidden px-[4px] py-[3.5px] shrink-0 mobile:block"><DropUpdown/></button>
                                    </span>
                                    <div className="w-full reveal-txt hidden md:group-hover:block animated slideInDown">
                                        <p className="[@media(width:1920px)]:w-[313px] text-[14px] text-[#808080] fady-in leading-[130%] tracking-[-0.42px] mobile:text-[14px] mobile:leading-[130%] mobile:tracking-[-0.42px] dark:text-[#a3a3a3]">Are we targeting specific geographies, regions or are we global?</p>
                                        {(projectSetupStore.page3.targetGlobal || (projectSetupStore.page3.markets && projectSetupStore.page3.markets.length > 0)) && 
                                        <button  className="right-[32px] bottom-[32px] absolute mobile:right-[0] mobile:bottom-[0] mobile:relative mobile:ml-[10px]">
                                            <svg className="dark:stroke-white" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                <path d="M11 4.33324H6.8C5.11984 4.33324 4.27976 4.33324 3.63803 4.66022C3.07354 4.94784 2.6146 5.40678 2.32698 5.97126C2 6.613 2 7.45308 2 9.13324V17.5332C2 19.2134 2 20.0535 2.32698 20.6952C2.6146 21.2597 3.07354 21.7186 3.63803 22.0063C4.27976 22.3332 5.11984 22.3332 6.8 22.3332H15.2C16.8802 22.3332 17.7202 22.3332 18.362 22.0063C18.9265 21.7186 19.3854 21.2597 19.673 20.6952C20 20.0535 20 19.2134 20 17.5332V13.3332M7.99997 16.3332H9.67452C10.1637 16.3332 10.4083 16.3332 10.6385 16.278C10.8425 16.229 11.0376 16.1482 11.2166 16.0385C11.4184 15.9148 11.5914 15.7419 11.9373 15.396L21.5 5.83324C22.3284 5.00481 22.3284 3.66166 21.5 2.83323C20.6716 2.00481 19.3284 2.00481 18.5 2.83323L8.93723 12.396C8.59133 12.7419 8.41838 12.9148 8.29469 13.1167C8.18504 13.2956 8.10423 13.4907 8.05523 13.6948C7.99997 13.9249 7.99997 14.1695 7.99997 14.6587V16.3332Z" stroke="#1D1D1D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </button>}
                                    </div>
                                </div>
                                <div className="w-full hidden mobile:block">{openTarget && openMobileView && <TargetPopup open={openTarget}  saveData={props.saveData} close={() => {setOpenTarget(false);document.body.classList.remove("overflow-hidden")}}/>}</div>
                            </div>
                            <div className="relative flex flex-wrap cursor-pointer  group items-center bg-white">
                                <div onClick={() => showPopup(setOpenBrandVoice,openBrandVoice,4)} className="w-full flex flex-wrap gap-[8px] p-[24px] pr-[0] text-left mobile:p-[0]">
                                    <span className="archivo w-full flex items-center mobile:justify-between">
                                        <span className="mobile:flex mobile:items-center text-[24px] leading-[100%] tracking-[-0.72px] mobile:gap-[16px] mobile:text-[18px] mobile:leading-[110%] mobile:tracking-[-0.54px]">
                                            <img alt="icon" src={projectSetupStore.page3.tone_of_voice && projectSetupStore.page3.tone_of_voice.length ? violetOk :orangeIcon} className="mb-[8px] mobile:mb-[0] mobile:float-left transition-all left-0 relative group-hover:left-3"/><img alt="icon" src={clkInteraction} className="mb-[8px] mobile:mb-[0] mobile:float-left hidden"/>05. Brand Voice (optional)
                                        </span>
                                        <button className="hidden px-[4px] py-[3.5px] shrink-0 mobile:block"><DropUpdown/></button>
                                    </span>
                                    <div className="w-full reveal-txt hidden md:group-hover:block animated slideInDown">
                                        <p className="[@media(width:1920px)]:w-[313px] text-[14px] fady-in text-[#808080] leading-[130%] tracking-[-0.42px] mobile:text-[14px] mobile:leading-[130%] mobile:tracking-[-0.42px] dark:text-[#a3a3a3]">We can find talent whose creative work matches your brand voice.</p>
                                        {projectSetupStore.page3.tone_of_voice && projectSetupStore.page3.tone_of_voice.length > 0 && 
                                        <button className="right-[32px] bottom-[32px] absolute mobile:right-[0] mobile:bottom-[0] mobile:relative mobile:ml-[10px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                    <path className="dark:stroke-white" d="M11 4.33324H6.8C5.11984 4.33324 4.27976 4.33324 3.63803 4.66022C3.07354 4.94784 2.6146 5.40678 2.32698 5.97126C2 6.613 2 7.45308 2 9.13324V17.5332C2 19.2134 2 20.0535 2.32698 20.6952C2.6146 21.2597 3.07354 21.7186 3.63803 22.0063C4.27976 22.3332 5.11984 22.3332 6.8 22.3332H15.2C16.8802 22.3332 17.7202 22.3332 18.362 22.0063C18.9265 21.7186 19.3854 21.2597 19.673 20.6952C20 20.0535 20 19.2134 20 17.5332V13.3332M7.99997 16.3332H9.67452C10.1637 16.3332 10.4083 16.3332 10.6385 16.278C10.8425 16.229 11.0376 16.1482 11.2166 16.0385C11.4184 15.9148 11.5914 15.7419 11.9373 15.396L21.5 5.83324C22.3284 5.00481 22.3284 3.66166 21.5 2.83323C20.6716 2.00481 19.3284 2.00481 18.5 2.83323L8.93723 12.396C8.59133 12.7419 8.41838 12.9148 8.29469 13.1167C8.18504 13.2956 8.10423 13.4907 8.05523 13.6948C7.99997 13.9249 7.99997 14.1695 7.99997 14.6587V16.3332Z" stroke="#1D1D1D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                        </button>}
                                    </div> 
                                </div> 
                                <div className="w-full hidden mobile:block">{openBrandVoice && openMobileView && <BrandVoicePopup saveData={props.saveData} close={() => {setOpenBrandVoice(false);document.body.classList.remove("overflow-hidden")}}/>}</div>
                             </div>
                            <div className="relative cursor-pointer group flex flex-wrap items-center bg-white">
                                <div onClick={() => {showPopup(setOpenBudget,openBudget,5)}} className="w-full flex flex-wrap gap-[8px] p-[24px] pr-[0] text-left mobile:p-[0]">
                                    <span className="archivo w-full flex items-center mobile:justify-between">
                                        <span className="mobile:flex mobile:items-center text-[24px] leading-[100%] tracking-[-0.72px] mobile:gap-[16px] mobile:text-[18px] mobile:leading-[110%] mobile:tracking-[-0.54px]">
                                            <img alt="icon" src={projectSetupStore.page5 && projectSetupStore.page5.share_budget_information ? violetOk :orangeIcon} className="mb-[8px] mobile:mb-[0] mobile:float-left transition-all left-0 relative group-hover:left-3"/><img alt="icon" src={clkInteraction} className="mb-[8px] mobile:mb-[0] mobile:float-left hidden"/>06. Budget (optional)
                                        </span>
                                        <button className="hidden px-[4px] py-[3.5px] shrink-0 mobile:block"><DropUpdown/></button>
                                    </span>
                                    <div className="w-full reveal-txt hidden md:group-hover:block animated slideInDown">
                                        <p className="[@media(width:1920px)]:w-[313px] text-[14px] fady-in text-[#808080] leading-[130%] tracking-[-0.42px] mobile:text-[14px] mobile:leading-[130%] mobile:tracking-[-0.42px] dark:text-[#a3a3a3]">Let us know how far your investment for the project stretches.</p>
                                        {projectSetupStore.page5 && projectSetupStore.page5.share_budget_information && 
                                        <button className="right-[32px] bottom-[32px] absolute mobile:right-[0] mobile:bottom-[0] mobile:relative mobile:ml-[10px]">
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                <path className="dark:stroke-white" d="M11 4.33324H6.8C5.11984 4.33324 4.27976 4.33324 3.63803 4.66022C3.07354 4.94784 2.6146 5.40678 2.32698 5.97126C2 6.613 2 7.45308 2 9.13324V17.5332C2 19.2134 2 20.0535 2.32698 20.6952C2.6146 21.2597 3.07354 21.7186 3.63803 22.0063C4.27976 22.3332 5.11984 22.3332 6.8 22.3332H15.2C16.8802 22.3332 17.7202 22.3332 18.362 22.0063C18.9265 21.7186 19.3854 21.2597 19.673 20.6952C20 20.0535 20 19.2134 20 17.5332V13.3332M7.99997 16.3332H9.67452C10.1637 16.3332 10.4083 16.3332 10.6385 16.278C10.8425 16.229 11.0376 16.1482 11.2166 16.0385C11.4184 15.9148 11.5914 15.7419 11.9373 15.396L21.5 5.83324C22.3284 5.00481 22.3284 3.66166 21.5 2.83323C20.6716 2.00481 19.3284 2.00481 18.5 2.83323L8.93723 12.396C8.59133 12.7419 8.41838 12.9148 8.29469 13.1167C8.18504 13.2956 8.10423 13.4907 8.05523 13.6948C7.99997 13.9249 7.99997 14.1695 7.99997 14.6587V16.3332Z" stroke="#1D1D1D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </button>}
                                    </div>
                                </div>
                                <div className="w-full hidden mobile:block">{openBudget && openMobileView && <BudgetPopup saveData={props.saveData} close={() => {setOpenBudget(false);document.body.classList.remove("overflow-hidden")}}/>}</div>
                                {/* <button className="absolute bottom-[0] right-[0] items-center gap-[10px] flex-wrap p-[24px] shrink-0 mobile:hidden"><EditIcon/></button> */}
                                {/* {openBudget && <BudgetPopup close={() => setOpenBudget(false)}/>} */}
                            </div>
                        </div>
                        <div className="w-full flex gap-[12px]">
                            <button onClick={onBack} type="button" className="w-full flex group items-center bg-[#fff] p-[24px] mt-[12px] group dark:bg-[#0E0E0E] ">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="back-arrow transition-all group-hover:-translate-x-[12px]">
                                    <rect width="24" height="24" transform="matrix(-1 0 0 1 24 0)" fill="transparent"/>
                                    <path d="M15.4898 7.18733L9.44658 11.9992C9.44658 11.9992 7.11559 10.1432 9.57661 8.18361C10.3379 7.57744 13.4377 5.10925 15.552 3.42578L15.4898 7.18733Z" fill="#0E0E0E"/>
                                    <path d="M15.4896 16.8118L9.44634 12C9.44634 12 7.11536 13.856 9.57637 15.8156C10.3377 16.4217 13.4375 18.8899 15.5518 20.5734L15.4896 16.8118Z" fill="#0E0E0E"/>
                                </svg>
                                <span className="text-[24px] leading-[100%] tracking-[-0.72px] uppercase dark:text-[#fff]">Back</span>
                            </button>
                            <button disabled={!(projectSetupStore.page3.diversity && projectSetupStore.page3.industry && projectSetupStore.page3.inspiration  && projectSetupStore.page3.diversity.length && projectSetupStore.page3.inspiration.length && projectSetupStore.page3.industry.length && (projectSetupStore.page3.targetGlobal || (projectSetupStore.page3.markets && projectSetupStore.page3.markets.length)))} onClick={() => {setOpenFinal(true); document.body.classList.add('overflow-hidden');}} type="button" className="w-full group flex items-center justify-end bg-[#F05831] hover:bg-[#f17e61] disabled:bg-[#dc5836] disabled:opacity-[35%] p-[24px] mt-[12px]">
                                <span className="text-[#FFF] text-[24px] leading-[100%] tracking-[-0.72px]">SUMMARY</span>
                                <svg width="24" height="24" className='group-hover:translate-x-[12px] transition-all' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Frame 6938">
                                <rect width="24" height="24" fill="transparent"/>
                                <g id="Group 8090">
                                <path id="Vector" d="M8.51043 7.18733L14.5537 11.9992C14.5537 11.9992 16.8846 10.1432 14.4236 8.18361C13.6623 7.57744 10.5625 5.10925 8.44824 3.42578L8.51043 7.18733Z" fill="#FFF"/>
                                <path id="Vector_2" d="M8.51042 16.8118L14.5537 12C14.5537 12 16.8846 13.856 14.4236 15.8156C13.6623 16.4217 10.5625 18.8899 8.44824 20.5734L8.51042 16.8118Z" fill="#FFF"/>
                                </g>
                                </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <ProjectSetupFooter activepage="Pinpoints" activepersentage="40%" />
                </div>
                
            </ProjectSetupTemplate>
            <div className="w-full mobile:hidden">{<DiversityPopup typ="desktop" open={openDiversity} saveData={props.saveData} close={() => {setOpenDiversity(false);document.body.classList.remove("overflow-hidden")}} nextStep={props.nextStep}/>}</div>
            <div className="w-full mobile:hidden">{<IndustryPopup open={openIndustry} saveData={props.saveData} close={() => {setOpenIndustry(false);document.body.classList.remove("overflow-hidden")}} nextStep={props.nextStep}/>}</div>
            <div className="w-full mobile:hidden">{<BrandsPopup open={openBrands} saveData={props.saveData} close={() => {setOpenBrands(false);document.body.classList.remove("overflow-hidden")}} nextStep={props.nextStep}/>}</div>
            <div className="w-full mobile:hidden">{<TargetPopup open={openTarget} saveData={props.saveData} close={() => {setOpenTarget(false);document.body.classList.remove("overflow-hidden")}} nextStep={props.nextStep}/>}</div>
            <div className="w-full mobile:hidden">{<BrandVoicePopup open={openBrandVoice} saveData={props.saveData} close={() => {setOpenBrandVoice(false);document.body.classList.remove("overflow-hidden")}} nextStep={props.nextStep}/>}</div>
            <div className="w-full mobile:hidden">{<BudgetPopup open={openBudget} saveData={props.saveData} close={() => {setOpenBudget(false);document.body.classList.remove("overflow-hidden")}} nextStep={props.nextStep}/>}</div>
            <div className="w-full">{openFinal && <FinalQuestionpopup saveData={props.saveData} close={() => {setOpenFinal(false); document.body.classList.remove('overflow-hidden');}} nextStep={props.nextStep} />}</div>
        </div>
    )
}