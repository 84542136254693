import { useForm } from 'react-hook-form';
import FormValidationIcon from '../../../utils/form-validation-icon';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from '../common/icons/dropdown';
import { useEffect, useRef, useState } from 'react';
import { SelectFormFieldError } from '../../../utils/error-message';
import { ProCancel } from '../common/icons/skills-cancel';

export const BrandVoicePopup = (props) => {
    const projectSetupStore = useSelector(state => state.projectSetupStore);
    const selectStore = useSelector(state => state.selectStore);
    const [brandvoice, setbrandvoice] = useState([]);
    const [brandtone, setbrandtone] = useState([]);
    const [brandlanguage, setbrandlanguage] = useState([]);
    const [brandpurpose, setbrandpurpose] = useState([]);
    const [brandvoiceDrop, setBrandvoiceDrop] = useState([]);
    // const [brandvoiceTempDrop, setBrandvoiceTempDrop] = useState([]);
    const [brandtoneDrop, setBrandtoneDrop] = useState([]);
    // const [brandtoneTempDrop, setBrandtoneTempDrop] = useState([]);
    const [brandlanguageDrop, setBrandlanguageDrop] = useState([]);
    // const [brandlanguageTempDrop, setBrandlanguageTempDrop] = useState([]);
    const [brandPurposeDrop, setBrandpurposeDrop] = useState([]);
    // const [brandPurposeTempDrop, setBrandpurposeTempDrop] = useState([]);
    const brandVoiceRef = useRef();
    const brandToneRef = useRef();
    const brandLanguageRef = useRef();
    const brandPurposeRef = useRef();
    const [isOpen, setisOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=> {
        if(!props.open){
            const popup = document.querySelector(".voice-popup")
            if(popup){
                popup.classList.add("slide-right")
            }
            setTimeout(() => {
                setisOpen(false);
            },100)
        }else{
            setisOpen(true)
        }
    },[props.open])

    const { setValue} = useForm({
        mode: "all",
        reValidateMode: "onChange", criteriaMode: "all"
    });

    useEffect(() => {
      var valToneOfVoice = [];
      selectStore.tone.forEach((v) => {
        var _val = {};
        _val["label"] = v.label;
        _val["tone_of_voice_id"] = v.tone_of_voice_id;
        _val["isChecked"] = false;
        _val["type"] = v.type
        valToneOfVoice.push(_val)
      })
      
      var _brandvoice = []; var _brandtone = []; var _brandlanguage = []; var _brandpurpose = [];
      
      var _brandvoiceDropVal = JSON.parse(JSON.stringify(valToneOfVoice)); var _brandtoneDropVal = JSON.parse(JSON.stringify(valToneOfVoice)); var _brandlanguageDropVal = JSON.parse(JSON.stringify(valToneOfVoice)); var _brandpurposeDropVal = JSON.parse(JSON.stringify(valToneOfVoice));
      if (Array.isArray(projectSetupStore.page3.tone_of_voice) && projectSetupStore.page3.tone_of_voice.length) {
        projectSetupStore.page3.tone_of_voice.forEach(_val => { 
          if (_val.type === "brandvoice") {
            var bindex = selectStore.tone.findIndex(x => x.tone_of_voice_id === _val.tone_of_voice_id);
            if (bindex !== -1) { 
              _brandvoice.push({...selectStore.tone[bindex], type: 'brandvoice'});
              _brandvoiceDropVal[bindex]['isChecked'] = true;
            }
          } else if (_val.type === "brandtone") {
            var btindex = selectStore.tone.findIndex(x => x.tone_of_voice_id === _val.tone_of_voice_id);
            if (btindex !== -1) { 
              _brandtone.push({...selectStore.tone[btindex], type: 'brandtone'}); 
              _brandtoneDropVal[btindex]['isChecked'] = true 
            }
          } else if (_val.type === "brandlanguage") {
            var blindex = selectStore.tone.findIndex(x => x.tone_of_voice_id === _val.tone_of_voice_id);
            if (blindex !== -1) { 
              _brandlanguage.push({...selectStore.tone[blindex], type: 'brandlanguage'}); 
              _brandlanguageDropVal[blindex]['isChecked'] = true 
            }
          } else {
            var eindex = selectStore.tone.findIndex(x => x.tone_of_voice_id === _val.tone_of_voice_id);
            if (eindex !== -1) { 
              _brandpurpose.push({...selectStore.tone[eindex], type: 'brandpurpose'}); 
              _brandpurposeDropVal[eindex]['isChecked'] = true 
            }
          }
        })
        var brandvoiceArray = (_brandvoice ? _brandvoice.map(function (obj) {
          return obj.tone_of_voice_id;
        }) : [])
        var brandtoneArray = (_brandtone ? _brandtone.map(function (obj) {
          return obj.tone_of_voice_id;
        }) : [])
        var brandlanguageArray = (_brandlanguage ? _brandlanguage.map(function (obj) {
          return obj.tone_of_voice_id;
        }) : [])
        var brandpurposeArray = (_brandpurpose ? _brandpurpose.map(function (obj) {
          return obj.tone_of_voice_id;
        }) : [])

        setValue("brandvoice", brandvoiceArray, { shouldTouch: true, shouldValidate: true, shouldDirty: true });
        setValue("brandtone", brandtoneArray, { shouldTouch: true, shouldValidate: true, shouldDirty: true });
        setValue("brandlanguage", brandlanguageArray, { shouldTouch: true, shouldValidate: true, shouldDirty: true });
        setValue("brandpurpose", brandpurposeArray, { shouldTouch: true, shouldValidate: true, shouldDirty: true });
        
        setbrandvoice(_brandvoice); setbrandtone(_brandtone); setbrandlanguage(_brandlanguage); setbrandpurpose(_brandpurpose);
        
        setBrandvoiceDrop(_brandvoiceDropVal);
        // setBrandvoiceTempDrop(_brandvoiceDropVal);
        setBrandtoneDrop(_brandtoneDropVal);
        // setBrandtoneTempDrop(_brandtoneDropVal);
        setBrandlanguageDrop(_brandlanguageDropVal);
        // setBrandlanguageTempDrop(_brandlanguageDropVal);
        setBrandpurposeDrop(_brandpurposeDropVal);
        // setBrandpurposeTempDrop(_brandpurposeDropVal);
      } else {
        setBrandvoiceDrop(_brandvoiceDropVal);
        // setBrandvoiceTempDrop(_brandvoiceDropVal);
        setBrandtoneDrop(_brandtoneDropVal);
        // setBrandtoneTempDrop(_brandtoneDropVal);
        setBrandlanguageDrop(_brandlanguageDropVal);
        // setBrandlanguageTempDrop(_brandlanguageDropVal);
        setBrandpurposeDrop(_brandpurposeDropVal);
        // setBrandpurposeTempDrop(_brandpurposeDropVal);
      }

      window.addEventListener("scroll", resetDropdownPosition);
        return () => {
          window.removeEventListener("scroll", resetDropdownPosition);
      };

    }, [projectSetupStore.page3.tone_of_voice]);


    /**Recalculate Position on scroll*/
  function resetDropdownPosition() {
    var voiceParent = brandVoiceRef.current;
    var toneParent = brandToneRef.current;
    var lanParent = brandLanguageRef.current;
    var purposeParent = brandPurposeRef.current;
    if (voiceParent) {
      voiceParent = voiceParent.getBoundingClientRect();
      if (document.querySelector('.brandvoice.demoFixed') && voiceParent.top) {
        document.querySelector('.brandvoice.demoFixed').style.top = voiceParent.top + 30 + 'px';
      }
    }
    if (toneParent) {
      toneParent = toneParent.getBoundingClientRect();
      if (document.querySelector('.brandtone.demoFixed') && toneParent.top) {
        document.querySelector('.brandtone.demoFixed').style.top = toneParent.top + 30 + 'px';
      }
    }
    if (lanParent) {
      lanParent = lanParent.getBoundingClientRect();
      if (document.querySelector('.brandlanguage.demoFixed') && lanParent.top) {
        document.querySelector('.brandlanguage.demoFixed').style.top = lanParent.top + 30 + 'px';
      }
    }
    if (purposeParent) {
      purposeParent = purposeParent.getBoundingClientRect();
      if (document.querySelector('.brandpurpose.demoFixed') && purposeParent.top) {
        document.querySelector('.brandpurpose.demoFixed').style.top = purposeParent.top + 30 + 'px';
      }
    }
  }

    const enableBrandvoiceFirstLevel = () => {
        // setBrandvoiceDrop(brandvoiceTempDrop)
        if (document.querySelectorAll('.brandvoice .demoFixedSubLevel')) {
          [...document.querySelectorAll('.brandvoice .demoFixedSubLevel')].forEach(div => {
            if (div.style.display !== 'none') {
              div.style.display = 'none'
            }
          });
        }
        const mark = document.querySelector(".brandvoice-label");
        if (mark) {
          mark.classList.remove("move-label");
        }
        const parent = brandVoiceRef.current.getBoundingClientRect()
        document.querySelector('.brandvoice.demoFixed').style.left = parent.left + 'px';
        document.querySelector('.brandvoice.demoFixed').style.top = parent.top + 30 + 'px';
        document.querySelector('.brandvoice.demoFixed').style.width = parent.width + 'px';
        document.querySelector('.react-select__menu.brandvoice.demoFixed').style.display = 'block';
        document.querySelector('.brandvoice-multi-select-backdrop').style.display = 'block';
    }
    
    const disableallBrandvoiceLevel = () => {
      // setBrandvoiceDrop(brandvoiceTempDrop)
      if(document.getElementsByName('brandvoice')) {
        document.getElementsByName('brandvoice').forEach(o => {
        if(o && o.value !== "") {
          o.value = ""
        }
        })
      }
      // setvoiceLabel(false)(false)
      const mark = document.querySelector(".brandvoice-label");
      if (mark) {
        mark.classList.add("move-label");
      }
      if (document.querySelector('.brandvoice.demoFixed').style) {
        document.querySelector('.brandvoice.demoFixed').style.display = 'none';
        // if (voice && !voice.length >= 3) {
        //   voiceDrop.forEach((_val, _i) => {
        //     document.querySelector('.react-select__menu.brandvoice .demoFixedSubLevel.child_' + _i).style.display = 'none'
        //   })
        // }
        document.querySelector('.brandvoice-multi-select-backdrop').style.display = 'none';
      }
    }

    function brandvoiceOnchange(val, i, e) {
      if(e) {
        e.preventDefault()
        e.stopPropagation();
      }
      var _val = JSON.parse(JSON.stringify(brandvoiceDrop)).filter(_d => _d.type === "persona");
      var _brandvoice = JSON.parse(JSON.stringify(brandvoice));
      var _valueIndex = _brandvoice.findIndex(value => value.tone_of_voice_id === val.tone_of_voice_id)
      if (_val[i]["isChecked"]) {
        _val[i]["isChecked"] = false;
        if (_valueIndex !== -1) {
          _brandvoice.splice(_valueIndex, 1);
          setbrandvoice(_brandvoice);
        }
        setBrandvoiceDrop(_val);
      } else {
        _val.forEach((val, j) => {
          if (i === j) {
            _brandvoice.push({..._val[j], 'type': 'brandvoice'})
            setbrandvoice(_brandvoice);
            _val[j]["isChecked"] = true;
          }
        })
        setBrandvoiceDrop(_val);
      }
      // var updatedArr = [ ...brandlanguage.map(v => ({...v, type: 'brandlanguage'})), ...brandpurpose.map(v => ({...v, type: 'brandpurpose'})), ...brandtone.map(v => ({...v, type: 'brandtone'})), ..._brandvoice]
      // setBrandvoiceDrop(_val);
      // setVoiceTempDrop(_val);
      // submitHandler(updatedArr)
      if(_brandvoice && _brandvoice.length >=3){
        disableallBrandvoiceLevel()
      }
    }
   
    function brandvoiceRemoveHandler(val, i) {
      var _val = JSON.parse(JSON.stringify(brandvoiceDrop));
      if (_val[i]["isChecked"]) {
        _val[i]["isChecked"] = false;
      } else {
        _val.forEach((val, j) => {
          if (i === j) {
            _val[j]["isChecked"] = true;
          }
        })
      }
      const _brandvoice = [...brandvoice];
      _brandvoice.splice(i, 1);
      setbrandvoice(_brandvoice);
      setBrandvoiceDrop(_val)
      var updatedArr = [..._brandvoice, ...brandlanguage.map(v => ({...v, type: 'brandlanguage'})), ...brandpurpose.map(v => ({...v, type: 'brandpurpose'})), ...brandtone.map(v => ({...v, type: 'brandtone'}))]
      submitHandler(updatedArr, true)
    }

    function brandvoiceBlurHandler() {
      // setbrandvoiceLabel(false)(false)
      const mark = document.querySelector(".brandvoice-label");
      if (mark) {
        mark.classList.add("move-label");
      }
      if (document.getElementsByName('brandvoice')) {
        document.getElementsByName('brandvoice').forEach(o => {
          if (o && o.value !== "") {
            o.value = ""
          }
        })
      }
      // setBrandvoiceDrop(brandvoiceTempDrop)
    }

    const enableBrandLanguageFirstLevel = () => {
      // setBrandlanguageDrop(brandlanguageTempDrop)
      if (document.querySelectorAll('.brandlanguage .demoFixedSubLevel')) {
        [...document.querySelectorAll('.brandlanguage .demoFixedSubLevel')].forEach(div => {
          if (div.style.display !== 'none') {
            div.style.display = 'none'
          }
        });
      }
      const mark = document.querySelector(".brandlanguage-label");
      if (mark) {
        mark.classList.remove("move-label");
      }
      const parent = brandLanguageRef.current.getBoundingClientRect()
      document.querySelector('.brandlanguage.demoFixed').style.left = parent.left + 'px';
      document.querySelector('.brandlanguage.demoFixed').style.top = parent.top + 30 + 'px';
      document.querySelector('.brandlanguage.demoFixed').style.width = parent.width + 'px';
      document.querySelector('.react-select__menu.brandlanguage.demoFixed').style.display = 'block';
      document.querySelector('.brandlanguage-multi-select-backdrop').style.display = 'block';
    }

    const disableallBrandLanguageLevel = () => {
      // setBrandlanguageDrop(brandlanguageTempDrop)
      if(document.getElementsByName('brandlanguage')) {
        document.getElementsByName('brandlanguage').forEach(o => {
        if(o && o.value !== "") {
          o.value = ""
        }
        })
      }
      const mark = document.querySelector(".brandlanguage-label");
      if (mark) {
        mark.classList.add("move-label");
      }
      if (document.querySelector('.brandlanguage.demoFixed').style) {
        document.querySelector('.brandlanguage.demoFixed').style.display = 'none';
        if (brandlanguage && !brandlanguage.length >= 3) {
          brandlanguageDrop.forEach((_val, _i) => {
            document.querySelector('.react-select__menu.brandlanguage .demoFixedSubLevel.child_' + _i).style.display = 'none'
          })
        }
        document.querySelector('.brandlanguage-multi-select-backdrop').style.display = 'none';
      }
    }

    function brandlanguageOnchange(val, i, e) {
      if(e) {
        e.preventDefault()
        e.stopPropagation();
      }
      var _val = JSON.parse(JSON.stringify(brandlanguageDrop)).filter(_d => _d.type === "language");
      var _brandlanguage = JSON.parse(JSON.stringify(brandlanguage));
      var _valueIndex = _brandlanguage.findIndex(value => value.tone_of_voice_id === val.tone_of_voice_id)
      if (_val[i]["isChecked"]) {
        _val[i]["isChecked"] = false;
        if (_valueIndex !== -1) {
          _brandlanguage.splice(_valueIndex, 1);
          setbrandlanguage(_brandlanguage);
        }
      } else {
        _val.forEach((val, j) => {
          if (i === j) {
            _brandlanguage.push({..._val[j], 'type': 'brandlanguage'})
            setbrandlanguage(_brandlanguage);
            _val[j]["isChecked"] = true;
          }
        })
      }
      // var updatedArr = [ ...brandvoice.map(v => ({...v, type: 'brandvoice'})), ...brandpurpose.map(v => ({...v, type: 'brandpurpose'})), ...brandtone.map(v => ({...v, type: 'brandtone'})), ..._brandlanguage]
      setBrandlanguageDrop(_val);
      // setVoiceTempDrop(_val);
      // submitHandler(updatedArr)
      if(_brandlanguage && _brandlanguage.length >=3){
        disableallBrandLanguageLevel()
      }
    }
    
    function brandlanguageRemoveHandler(val, i) {
      const _brandlanguage = [...brandlanguage];
      _brandlanguage.splice(i, 1);
      setbrandlanguage(_brandlanguage);
      var _val = JSON.parse(JSON.stringify(brandlanguageDrop));
      if (_val[i]["isChecked"]) {
        _val[i]["isChecked"] = false;
      } else {
        _val.forEach((val, j) => {
          if (i === j) {
            _val[j]["isChecked"] = true;
          }
        })
      }
      setBrandlanguageDrop(_val)
      var updatedArr = [..._brandlanguage, ...brandvoice.map(v => ({...v, type: 'brandvoice'})), ...brandpurpose.map(v => ({...v, type: 'brandpurpose'})), ...brandtone.map(v => ({...v, type: 'brandtone'}))]
      submitHandler(updatedArr, true)
    }

    function brandlanguageBlurHandler() {
      const mark = document.querySelector(".brandlanguage-label");
      if (mark) {
        mark.classList.add("move-label");
      }
      if (document.getElementsByName('brandlanguage')) {
        document.getElementsByName('brandlanguage').forEach(o => {
          if (o && o.value !== "") {
            o.value = ""
          }
        })
      }
      // setBrandlanguageDrop(brandlanguageTempDrop)
    }
  
    const enableBrandToneFirstLevel = () => {
      // setBrandtoneDrop(brandtoneTempDrop)
      if (document.querySelectorAll('.brandtone .demoFixedSubLevel')) {
        [...document.querySelectorAll('.brandtone .demoFixedSubLevel')].forEach(div => {
          if (div.style.display !== 'none') {
            div.style.display = 'none'
          }
        });
      }
      const mark = document.querySelector(".brandtone-label");
      if (mark) {
        mark.classList.remove("move-label");
      }
      const parent = brandToneRef.current.getBoundingClientRect()
      document.querySelector('.brandtone.demoFixed').style.left = parent.left + 'px';
      document.querySelector('.brandtone.demoFixed').style.top = parent.top + 30 + 'px';
      document.querySelector('.brandtone.demoFixed').style.width = parent.width + 'px';
      document.querySelector('.react-select__menu.brandtone.demoFixed').style.display = 'block';
      document.querySelector('.brandtone-multi-select-backdrop').style.display = 'block';
    }

    const disableallBrandToneLevel = () => {
      // setBrandtoneDrop(brandtoneTempDrop)
      if(document.getElementsByName('brandtone')) {
        document.getElementsByName('brandtone').forEach(o => {
        if(o && o.value !== "") {
          o.value = ""
        }
        })
      }
      const mark = document.querySelector(".brandtone-label");
      if (mark) {
        mark.classList.add("move-label");
      }
      if (document.querySelector('.brandtone.demoFixed').style) {
        document.querySelector('.brandtone.demoFixed').style.display = 'none';
        if (brandtone && !brandtone.length >= 3) {
          brandtoneDrop.forEach((_val, _i) => {
            document.querySelector('.react-select__menu.brandtone .demoFixedSubLevel.child_' + _i).style.display = 'none'
          })
        }
        document.querySelector('.brandtone-multi-select-backdrop').style.display = 'none';
      }
    }

    function brandtoneOnchange(val, i, e) {
      if(e) {
        e.preventDefault()
        e.stopPropagation();
      }
      var _val = JSON.parse(JSON.stringify(brandtoneDrop)).filter(_d => _d.type === "tone");
      var _brandtone = JSON.parse(JSON.stringify(brandtone));
      var _valueIndex = _brandtone.findIndex(value => value.tone_of_voice_id === val.tone_of_voice_id)
      if (_val[i]["isChecked"]) {
        _val[i]["isChecked"] = false;
        if (_valueIndex !== -1) {
          _brandtone.splice(_valueIndex, 1);
          setbrandtone(_brandtone);
        }
      } else {
        _val.forEach((val, j) => {
          if (i === j) {
            _brandtone.push({..._val[j], 'type': 'brandtone'})
            setbrandtone(_brandtone);
            _val[j]["isChecked"] = true;
          }
        })
      }
      // var updatedArr = [...brandvoice.map(v => ({...v, type: 'brandvoice'})), ...brandlanguage.map(v => ({...v, type: 'brandlanguage'})), ...brandpurpose.map(v => ({...v, type: 'brandpurpose'})), ..._brandtone]
      setBrandtoneDrop(_val);
      // setVoiceTempDrop(_val);
      // submitHandler(updatedArr)
      if(_brandtone && _brandtone.length >=3){
        disableallBrandToneLevel()
      }
    }
   
    function brandtoneRemoveHandler(val, i) {
      const _brandtone = [...brandtone];
      _brandtone.splice(i, 1);
      var _val = JSON.parse(JSON.stringify(brandtoneDrop));
      if (_val[i]["isChecked"]) {
        _val[i]["isChecked"] = false;
      } else {
        _val.forEach((val, j) => {
          if (i === j) {
            _val[j]["isChecked"] = true;
          }
        })
      }
      setBrandtoneDrop(_val);
      setbrandtone(_brandtone);
      var updatedArr = [..._brandtone, ...brandvoice.map(v => ({...v, type: 'brandvoice'})), ...brandpurpose.map(v => ({...v, type: 'brandpurpose'})), ...brandlanguage.map(v => ({...v, type: 'brandlanguage'}))]
      submitHandler(updatedArr, true)
    }

    function brandtoneBlurHandler() {
      // setbrandtoneLabel(false)(false)
      const mark = document.querySelector(".brandtone-label");
      if (mark) {
        mark.classList.add("move-label");
      }
      if (document.getElementsByName('brandtone')) {
        document.getElementsByName('brandtone').forEach(o => {
          if (o && o.value !== "") {
            o.value = ""
          }
        })
      }
      // setBrandtoneDrop(brandtoneTempDrop)
    }

    const enableBrandPurposeFirstLevel = () => {
      // setBrandpurposeDrop(brandPurposeDrop)
      if (document.querySelectorAll('.brandpurpose .demoFixedSubLevel')) {
        [...document.querySelectorAll('.brandpurpose .demoFixedSubLevel')].forEach(div => {
          if (div.style.display !== 'none') {
            div.style.display = 'none'
          }
        });
      }
      const mark = document.querySelector(".brandpurpose-label");
      if (mark) {
        mark.classList.remove("move-label");
      }
      const parent = brandPurposeRef.current.getBoundingClientRect()
      document.querySelector('.brandpurpose.demoFixed').style.left = parent.left + 'px';
      document.querySelector('.brandpurpose.demoFixed').style.top = parent.top + 30 + 'px';
      document.querySelector('.brandpurpose.demoFixed').style.width = parent.width + 'px';
      document.querySelector('.react-select__menu.brandpurpose.demoFixed').style.display = 'block';
      document.querySelector('.brandpurpose-multi-select-backdrop').style.display = 'block';
    }

    const disableallBrandPurposeLevel = () => {
      // setBrandpurposeDrop(brandPurposeTempDrop)
      if(document.getElementsByName('brandpurpose')) {
        document.getElementsByName('brandpurpose').forEach(o => {
        if(o && o.value !== "") {
          o.value = ""
        }
        })
      }
      const mark = document.querySelector(".brandpurpose-label");
      if (mark) {
        mark.classList.add("move-label");
      }
      if (document.querySelector('.brandpurpose.demoFixed').style) {
        document.querySelector('.brandpurpose.demoFixed').style.display = 'none';
        if (brandpurpose && !brandpurpose.length >= 3) {
          brandPurposeDrop.forEach((_val, _i) => {
            document.querySelector('.react-select__menu.brandpurpose .demoFixedSubLevel.child_' + _i).style.display = 'none'
          })
        }
        document.querySelector('.brandpurpose-multi-select-backdrop').style.display = 'none';
      }
    }

    function brandpurposeOnchange(val, i, e) {
      if(e) {
        e.preventDefault()
        e.stopPropagation();
      }
      var _val = JSON.parse(JSON.stringify(brandPurposeDrop)).filter(_d => _d.type === "purpose");
      var _brandpurpose = JSON.parse(JSON.stringify(brandpurpose));
      var _valueIndex = _brandpurpose.findIndex(value => value.tone_of_voice_id === val.tone_of_voice_id)
      if (_val[i]["isChecked"]) {
        _val[i]["isChecked"] = false;
        if (_valueIndex !== -1) {
          _brandpurpose.splice(_valueIndex, 1);
          setbrandpurpose(_brandpurpose);
        }
      } else {
        _val.forEach((val, j) => {
          if (i === j) {
            _brandpurpose.push({..._val[j], 'type': 'brandpurpose'})
            setbrandpurpose(_brandpurpose);
            _val[j]["isChecked"] = true;
          }
        })
      }
      // var updatedArr = [...brandvoice.map(v => ({...v, type: 'brandvoice'})), ...brandlanguage.map(v => ({...v, type: 'brandlanguage'})), ...brandtone.map(v => ({...v, type: 'brandtone'})), ..._brandpurpose]
      setBrandpurposeDrop(_val);
      // setBrandlanguageTempDrop(_val)
      // submitHandler(updatedArr)
      if(_brandpurpose && _brandpurpose.length >=3){
        disableallBrandPurposeLevel()
      }
    }
    
    function brandpurposeRemoveHandler(val, i) {
      const _brandpurpose = [...brandpurpose];
      _brandpurpose.splice(i, 1);
      setbrandpurpose(_brandpurpose);
      // var _val = JSON.parse(JSON.stringify(brandPurposeDrop));
      // if (_val[i]["isChecked"]) {
      //   _val[i]["isChecked"] = false;
      // } else {
      //   _val.forEach((val, j) => {
      //     if (i === j) {
      //       _val[j]["isChecked"] = true;
      //     }
      //   })
      // }
      // setBrandpurposeDrop(_val)
      var updatedArr = [..._brandpurpose, ...brandlanguage.map(v => ({...v, type: 'brandlanguage'})), ...brandtone.map(v => ({...v, type: 'brandtone'})), ...brandvoice.map(v => ({...v, type: 'brandvoice'}))]
      submitHandler(updatedArr, true)
    }

    function brandpurposeBlurHandler() {
      const mark = document.querySelector(".brandpurpose-label");
      if (mark) {
        mark.classList.add("move-label");
      }
      if (document.getElementsByName('brandpurpose')) {
        document.getElementsByName('brandpurpose').forEach(o => {
          if (o && o.value !== "") {
            o.value = ""
          }
        })
      }
      // setBrandpurposeDrop(brandPurposeTempDrop)
    }

    function submitHandler(modifiedArr, isUpdated) {
      try {
        var value = [];
        if(modifiedArr) {
          value = modifiedArr;
        } else {
          [...brandtone].forEach(val => {
            value.push({'tone_of_voice_id':val.tone_of_voice_id, 'type': 'brandtone'})
          });
          [...brandvoice].forEach(val => {
            value.push({'tone_of_voice_id':val.tone_of_voice_id, 'type': 'brandvoice'})
          });
          [...brandpurpose].forEach(val => {
            value.push({'tone_of_voice_id':val.tone_of_voice_id, 'type': 'brandpurpose'})
          });
          [...brandlanguage].forEach(val => {
            value.push({'tone_of_voice_id':val.tone_of_voice_id, 'type': 'brandlanguage'})
          });
        }
        var result = value.map(({isChecked,...rest}) => ({...rest}));
        var _data = JSON.parse(JSON.stringify(projectSetupStore.page3));
        _data["tone_of_voice"] = result;
        // dispatch({ type: 'project_setup/insertValue', payload: { "id":  _data , "name": "page3" } });
        dispatch({ type: 'project_setup/insertValue', payload: { "id": _data, "name": "page3" } });
        props.saveData({ "tone_of_voice": result}) ;
        if(!isUpdated) {
          props.close();
        }
      } catch (err) { console.log(err); }
    }

    if(isOpen){
    return (
        <div className="relative z-10 mobile:w-full project-setup-brandvoice" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 overlay-background bg-opacity-75 backdrop-blur-sm transition-opacity mobile:hidden"></div>
            <div className={`fixed voice-popup inset-0 z-10 overflow-y-auto h-full mobile:relative mobile:h-auto mobile:w-full ${props.open ? "slide-left" : ""}`}>
                <div className='absolute z-10  h-full p-[32px] top-0 right-0 bg-[#ffffff] w-[485px] shadow-md mobile:relative mobile:shadow-none mobile:w-full mobile:p-0 mobile:h-auto dark:bg-[#0E0E0E]'>
                    <div className="w-full flex flex-wrap">
                        <div className='flex mobile:hidden'>
                            <div className='flex flex-wrap gap-1 text-left'>
                                <p className='text-[#07091b] text-[20px] leading-[130%] tracking-[-0.6px] dark:text-[#fff]'>05. Brand Voice</p>
                                <p className='text-[#a3a3a3] text-[14px] leading-[130%] tracking-[-0.42px] dark:text-[#808080]'>We can find talent whose creative work match your brand voice</p>
                            </div>
                            <div className="absolute right-0 xs:mt-[-10px] pr-7 sm:block mt-[4px]">
                                <button type="button" onClick={props.close} className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none pr-[7px]"><ProCancel/></button>
                            </div>
                        </div>
                        <div className='w-full flex flex-col flex-wrap mt-[24px] pt-[24px] box-border border-t border-solid border-[#d9d9d9]'>
                            <div className={brandvoice.length >= 3 ? "field-group w-full mt-[40px] custom-select disable-option" : "field-group w-full mt-[40px] custom-select"}>
                                <label className={"input-lbl move-label brandvoice-label select-label"}>Persona Voice</label>
                                <input type="text" readOnly ref={brandVoiceRef} name="brandvoice" onFocus={enableBrandvoiceFirstLevel}  onBlur={brandvoiceBlurHandler} className="input-fld" />
                                <span className="absolute right-[4px] bottom-[9px]"><Dropdown /></span>
                                <FormValidationIcon isArray={true} twolevelselect={true} minlen={1} getval={brandvoice}></FormValidationIcon>
                                <div className={'brandvoice-multi-select-backdrop multi-select-backdrop '} onClick={disableallBrandvoiceLevel}></div>
                            </div>
                            {brandvoice && brandvoice.length >= 3
                                ?
                                <div className="multi-select react-select__menu brandvoice demoFixed">
                                <div className="react-select__menu-list react-select__menu-list--is-multi react-select__menu-list--is-multi-max-limit">
                                    Max limit achieved
                                </div>
                                </div>
                                :
                                <div className="multi-select react-select__menu brandvoice demoFixed">
                                <div className="react-select__menu-list react-select__menu-list--is-multi single-level">
                                    {brandvoiceDrop.length <= 0 && <div className="react-select__menu-notice react-select__menu-notice--no-options css-1wlit7h-NoOptionsMessage">No options</div>}
                                    {JSON.parse(JSON.stringify(brandvoiceDrop)).filter(_data => _data.type === "persona").map((diver, i) =>
                                    <div className={"react-select__option"} key={i}  >
                                        <li className={"cursor-pointer"} onClick={(e) => { brandvoiceOnchange(diver, i, e) }}>
                                        <span className="txt" > {diver.label}
                                            {/* <label className="ch-box"><input type="checkbox" onChange={() => { brandvoiceOnchange(diver, i) }} readOnly checked={diver.isChecked} className=" text-blue-600 bg-gray-100 border-gray-300 rounded  dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" />
                                            </label> */}
                                            <label className="control control--checkbox">
                                                <input type="checkbox" onChange={() => { brandvoiceOnchange(diver, i) }} readOnly checked={diver.isChecked}/>
                                                <div className="control__indicator"></div>
                                                </label>
                                            </span>
                                        </li>
                                    </div>
                                    )}
                                </div>
                                </div>}
                            <SelectFormFieldError isValid={true}></SelectFormFieldError>
                            <div className={`${brandvoice.length ? '!mt-0 custom-multi-select' : ''}`}>
                                {JSON.parse(JSON.stringify(brandvoice)).map((val, i) => (
                                <div className="react-select__control inline-block new" key={i}>
                                    <div className="react-select__value-container">
                                    <div className="react-select__multi-value new">
                                        <div className="react-select__multi-value__label">
                                        <div className="brandvoice-option flex m-auto">
                                            <span className="mar-label">{val.label}</span>
                                        </div>
                                        </div>
                                        <div
                                        role="button"
                                        onClick={() => brandvoiceRemoveHandler(val, i)}
                                        className="react-select__multi-value__remove"
                                        aria-label="Remove X"
                                        >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.72184 15.2738C4.82196 15.3739 4.938 15.4399 5.06997 15.4717C5.20648 15.5036 5.33845 15.5036 5.46587 15.4717C5.59784 15.4399 5.7116 15.3762 5.80717 15.2806L10.0051 11.0827L14.1962 15.2806C14.2918 15.3762 14.4056 15.4399 14.5375 15.4717C14.6695 15.5036 14.8015 15.5036 14.9334 15.4717C15.0654 15.4399 15.1815 15.3739 15.2816 15.2738C15.3771 15.1782 15.4408 15.0644 15.4727 14.9325C15.5046 14.8005 15.5046 14.6708 15.4727 14.5434C15.4408 14.4114 15.3771 14.2954 15.2816 14.1953L11.0904 9.99733L15.2816 5.8062C15.3771 5.70609 15.4408 5.59232 15.4727 5.4649C15.5091 5.33294 15.5091 5.20097 15.4727 5.069C15.4408 4.93703 15.3771 4.82327 15.2816 4.7277C15.1769 4.62759 15.0586 4.5616 14.9266 4.52975C14.7992 4.4979 14.6695 4.4979 14.5375 4.52975C14.4056 4.55705 14.2918 4.62076 14.1962 4.72088L10.0051 8.91883L5.80717 4.72088C5.7116 4.62531 5.59784 4.5616 5.46587 4.52975C5.3339 4.4979 5.20193 4.4979 5.06997 4.52975C4.938 4.5616 4.82196 4.62759 4.72184 4.7277C4.62628 4.82327 4.56257 4.93703 4.53072 5.069C4.49886 5.20097 4.49886 5.33294 4.53072 5.4649C4.56257 5.59687 4.62628 5.70836 4.72184 5.79937L8.91297 9.99733L4.72184 14.2021C4.62628 14.2931 4.5603 14.4046 4.52389 14.5366C4.49204 14.6685 4.49204 14.8005 4.52389 14.9325C4.55575 15.0644 4.62173 15.1782 4.72184 15.2738Z" className="dark:fill-white" fill="#1D1D1D" />
                                        </svg>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                ))}
                            </div>
          
                            <div className={brandtone.length >= 3 ? "field-group w-full mt-[40px] custom-select disable-option" : "field-group w-full mt-[40px] custom-select"}>
                                <label className={"input-lbl move-label brandtone-label select-label"}>Tone</label>
                                <input type="text" readOnly ref={brandToneRef} name="brandtone" onFocus={enableBrandToneFirstLevel} onBlur={brandtoneBlurHandler} className="input-fld" />
                                <span className="absolute right-[4px] bottom-[9px]"><Dropdown /></span>
                                <FormValidationIcon isArray={true} twolevelselect={true} minlen={1} getval={brandtone}></FormValidationIcon>
                                <div className={'brandtone-multi-select-backdrop multi-select-backdrop '} onClick={disableallBrandToneLevel}></div>
                            </div>
                            {brandtone && brandtone.length >= 3
                                ?
                                <div className="multi-select react-select__menu brandtone demoFixed">
                                <div className="react-select__menu-list react-select__menu-list--is-multi react-select__menu-list--is-multi-max-limit ">
                                    Max limit achieved
                                </div>
                                </div>
                                :
                                <div className="multi-select react-select__menu brandtone demoFixed">
                                <div className="react-select__menu-list react-select__menu-list--is-multi single-level">
                                    {brandtoneDrop.length <= 0 && <div className="react-select__menu-notice react-select__menu-notice--no-options css-1wlit7h-NoOptionsMessage">No options</div>}
                                    {brandtoneDrop.filter(_data => _data.type === "tone").map((diver, i) =>
                                    <div className={"react-select__option"} key={i}  >
                                        <li className={"cursor-pointer"} onClick={(e) => { brandtoneOnchange(diver, i, e) }}>
                                        <span className="txt" > {diver.label}
                                            {/* <label className="ch-box"><input type="checkbox" onChange={() => { brandtoneOnchange(diver, i) }} readOnly checked={diver.isChecked} className=" absolute w-[18px] h-[18px] text-blue-600 bg-gray-100 border-gray-300 rounded  dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" />
                                            </label> */}
                                            <label className="control control--checkbox">
                                                <input type="checkbox" onChange={() => { brandtoneOnchange(diver, i) }} readOnly checked={diver.isChecked}/>
                                                <div className="control__indicator"></div>
                                            </label>
                                            </span>
                                        </li>
                                    </div>
                                    )}
                                </div>
                                </div>}
                            <SelectFormFieldError isValid={true}></SelectFormFieldError>
                            <div className={`${brandtone.length ? '!mt-0 custom-multi-select' : ''}`}>
                                {JSON.parse(JSON.stringify(brandtone)).map((val, i) => (
                                <div className="react-select__control inline-block new" key={i}>
                                    <div className="react-select__value-container">
                                    <div className="react-select__multi-value new">
                                        <div className="react-select__multi-value__label">
                                        <div className="brandtone-option flex m-auto">
                                            <span className="mar-label">{val.label}</span>
                                        </div>
                                        </div>
                                        <div
                                        role="button"
                                        onClick={() => brandtoneRemoveHandler(val, i)}
                                        className="react-select__multi-value__remove"
                                        aria-label="Remove X"
                                        >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.72184 15.2738C4.82196 15.3739 4.938 15.4399 5.06997 15.4717C5.20648 15.5036 5.33845 15.5036 5.46587 15.4717C5.59784 15.4399 5.7116 15.3762 5.80717 15.2806L10.0051 11.0827L14.1962 15.2806C14.2918 15.3762 14.4056 15.4399 14.5375 15.4717C14.6695 15.5036 14.8015 15.5036 14.9334 15.4717C15.0654 15.4399 15.1815 15.3739 15.2816 15.2738C15.3771 15.1782 15.4408 15.0644 15.4727 14.9325C15.5046 14.8005 15.5046 14.6708 15.4727 14.5434C15.4408 14.4114 15.3771 14.2954 15.2816 14.1953L11.0904 9.99733L15.2816 5.8062C15.3771 5.70609 15.4408 5.59232 15.4727 5.4649C15.5091 5.33294 15.5091 5.20097 15.4727 5.069C15.4408 4.93703 15.3771 4.82327 15.2816 4.7277C15.1769 4.62759 15.0586 4.5616 14.9266 4.52975C14.7992 4.4979 14.6695 4.4979 14.5375 4.52975C14.4056 4.55705 14.2918 4.62076 14.1962 4.72088L10.0051 8.91883L5.80717 4.72088C5.7116 4.62531 5.59784 4.5616 5.46587 4.52975C5.3339 4.4979 5.20193 4.4979 5.06997 4.52975C4.938 4.5616 4.82196 4.62759 4.72184 4.7277C4.62628 4.82327 4.56257 4.93703 4.53072 5.069C4.49886 5.20097 4.49886 5.33294 4.53072 5.4649C4.56257 5.59687 4.62628 5.70836 4.72184 5.79937L8.91297 9.99733L4.72184 14.2021C4.62628 14.2931 4.5603 14.4046 4.52389 14.5366C4.49204 14.6685 4.49204 14.8005 4.52389 14.9325C4.55575 15.0644 4.62173 15.1782 4.72184 15.2738Z" className="dark:fill-white" fill="#1D1D1D" />
                                        </svg>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                ))}
                            </div>

                            <div className={brandlanguage.length >= 3 ? "field-group w-full mt-[40px] custom-select disable-option" : "field-group w-full mt-[40px] custom-select"}>
                                <label className={"input-lbl move-label brandlanguage-label select-label"}>Language</label>
                                <input type="text" readOnly ref={brandLanguageRef} name="brandlanguage" onFocus={enableBrandLanguageFirstLevel} onBlur={brandlanguageBlurHandler} className="input-fld" />
                                <span className="absolute right-[4px] bottom-[9px]"><Dropdown /></span>
                                <FormValidationIcon isArray={true} twolevelselect={true} minlen={1} getval={brandlanguage}></FormValidationIcon>
                                <div className={'brandlanguage-multi-select-backdrop multi-select-backdrop '} onClick={disableallBrandLanguageLevel}></div>
                            </div>
                            {brandlanguage && brandlanguage.length >= 3
                                ?
                                <div className="multi-select react-select__menu brandlanguage demoFixed">
                                <div className="react-select__menu-list react-select__menu-list--is-multi react-select__menu-list--is-multi-max-limit">
                                    Max limit achieved
                                </div>
                                </div>
                                :
                                <div className="multi-select react-select__menu brandlanguage demoFixed">
                                <div className="react-select__menu-list react-select__menu-list--is-multi single-level">
                                    {brandlanguageDrop.length <= 0 && <div className="react-select__menu-notice react-select__menu-notice--no-options css-1wlit7h-NoOptionsMessage">No options</div>}
                                    {brandlanguageDrop.filter(_data => _data.type === "language").map((diver, i) =>
                                    <div className={"react-select__option"} key={i}  >
                                        <li className={"cursor-pointer"} onClick={(e) => { brandlanguageOnchange(diver, i, e) }}>
                                        <span className="txt" > {diver.label}
                                            {/* <label className="ch-box"><input type="checkbox" onChange={() => { brandlanguageOnchange(diver, i) }} readOnly checked={diver.isChecked} className=" text-blue-600 bg-gray-100 border-gray-300 rounded  dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" />
                                            </label> */}
                                            <label className="control control--checkbox">
                                                <input type="checkbox" onChange={() => { brandlanguageOnchange(diver, i) }} readOnly checked={diver.isChecked}/>
                                                <div className="control__indicator"></div>
                                            </label>
                                            </span>
                                        </li>
                                    </div>
                                    )}
                                </div>
                                </div>}
                            <SelectFormFieldError isValid={true}></SelectFormFieldError>
                            <div className={`${brandlanguage.length ? '!mt-0 custom-multi-select' : ''}`}>
                                {JSON.parse(JSON.stringify(brandlanguage)).map((val, i) => (
                                <div className="react-select__control inline-block new" key={i}>
                                    <div className="react-select__value-container">
                                    <div className="react-select__multi-value new">
                                        <div className="react-select__multi-value__label">
                                        <div className="brandlanguage-option flex m-auto">
                                            <span className="mar-label">{val.label}</span>
                                        </div>
                                        </div>
                                        <div
                                        role="button"
                                        onClick={() => brandlanguageRemoveHandler(val, i)}
                                        className="react-select__multi-value__remove"
                                        aria-label="Remove X"
                                        >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.72184 15.2738C4.82196 15.3739 4.938 15.4399 5.06997 15.4717C5.20648 15.5036 5.33845 15.5036 5.46587 15.4717C5.59784 15.4399 5.7116 15.3762 5.80717 15.2806L10.0051 11.0827L14.1962 15.2806C14.2918 15.3762 14.4056 15.4399 14.5375 15.4717C14.6695 15.5036 14.8015 15.5036 14.9334 15.4717C15.0654 15.4399 15.1815 15.3739 15.2816 15.2738C15.3771 15.1782 15.4408 15.0644 15.4727 14.9325C15.5046 14.8005 15.5046 14.6708 15.4727 14.5434C15.4408 14.4114 15.3771 14.2954 15.2816 14.1953L11.0904 9.99733L15.2816 5.8062C15.3771 5.70609 15.4408 5.59232 15.4727 5.4649C15.5091 5.33294 15.5091 5.20097 15.4727 5.069C15.4408 4.93703 15.3771 4.82327 15.2816 4.7277C15.1769 4.62759 15.0586 4.5616 14.9266 4.52975C14.7992 4.4979 14.6695 4.4979 14.5375 4.52975C14.4056 4.55705 14.2918 4.62076 14.1962 4.72088L10.0051 8.91883L5.80717 4.72088C5.7116 4.62531 5.59784 4.5616 5.46587 4.52975C5.3339 4.4979 5.20193 4.4979 5.06997 4.52975C4.938 4.5616 4.82196 4.62759 4.72184 4.7277C4.62628 4.82327 4.56257 4.93703 4.53072 5.069C4.49886 5.20097 4.49886 5.33294 4.53072 5.4649C4.56257 5.59687 4.62628 5.70836 4.72184 5.79937L8.91297 9.99733L4.72184 14.2021C4.62628 14.2931 4.5603 14.4046 4.52389 14.5366C4.49204 14.6685 4.49204 14.8005 4.52389 14.9325C4.55575 15.0644 4.62173 15.1782 4.72184 15.2738Z" className="dark:fill-white" fill="#1D1D1D" />
                                        </svg>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                ))}
                            </div>

                            <div className={brandpurpose.length >= 3 ? "field-group w-full mt-[40px] custom-select disable-option" : "field-group w-full mt-[40px] custom-select"}>
                                <label className="input-lbl move-label brandpurpose-label select-label">Purpose</label>
                                <input type="text" readOnly ref={brandPurposeRef} name="brandpurpose" onFocus={enableBrandPurposeFirstLevel} onBlur={brandpurposeBlurHandler} className="input-fld" />
                                <span className="absolute right-[4px] bottom-[9px]"><Dropdown /></span>
                                <FormValidationIcon isArray={true} twolevelselect={true} minlen={1} getval={brandpurpose}></FormValidationIcon>
                                <div className={'brandpurpose-multi-select-backdrop multi-select-backdrop '} onClick={disableallBrandPurposeLevel}></div>
                            </div>
                            {brandpurpose && brandpurpose.length >= 3
                                ?
                                <div className="multi-select react-select__menu brandpurpose demoFixed">
                                <div className="react-select__menu-list react-select__menu-list--is-multi react-select__menu-list--is-multi-max-limit">
                                    Max limit achieved
                                </div>
                                </div>
                                :
                                <div className="multi-select react-select__menu brandpurpose demoFixed">
                                <div className="react-select__menu-list react-select__menu-list--is-multi single-level">
                                    {brandPurposeDrop.length <= 0 && <div className="react-select__menu-notice react-select__menu-notice--no-options css-1wlit7h-NoOptionsMessage">No options</div>}
                                    {JSON.parse(JSON.stringify(brandPurposeDrop)).filter(_data => _data.type=== "purpose").map((diver, i) =>
                                    <div className={"react-select__option"} key={i}  >
                                        <li className={"cursor-pointer"} onClick={(e) => { brandpurposeOnchange(diver, i, e) }}>
                                        <span className="txt" > {diver.label}
                                            {/* <label className="ch-box"><input type="checkbox" onChange={() => { brandpurposeOnchange(diver, i) }} readOnly checked={diver.isChecked} className=" text-blue-600 bg-gray-100 border-gray-300 rounded  dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" />
                                            </label> */}
                                            <label className="control control--checkbox">
                                                <input type="checkbox" onChange={() => { brandpurposeOnchange(diver, i) }} readOnly checked={diver.isChecked} />
                                                <div className="control__indicator"></div>
                                            </label>
                                            </span>
                                        </li>
                                    </div>
                                    )}
                                </div>
                                </div>}
                            <SelectFormFieldError isValid={true}></SelectFormFieldError>
                            <div className={`${brandpurpose.length ? '!mt-0 custom-multi-select' : ''}`}>
                                {JSON.parse(JSON.stringify(brandpurpose)).map((val, i) => (
                                <div className="react-select__control inline-block new" key={i}>
                                    <div className="react-select__value-container">
                                    <div className="react-select__multi-value new">
                                        <div className="react-select__multi-value__label">
                                        <div className="brandpurpose-option flex m-auto">
                                            <span className="mar-label">{val.label}</span>
                                        </div>
                                        </div>
                                        <div
                                        role="button"
                                        onClick={() => brandpurposeRemoveHandler(val, i)}
                                        className="react-select__multi-value__remove"
                                        aria-label="Remove X"
                                        >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.72184 15.2738C4.82196 15.3739 4.938 15.4399 5.06997 15.4717C5.20648 15.5036 5.33845 15.5036 5.46587 15.4717C5.59784 15.4399 5.7116 15.3762 5.80717 15.2806L10.0051 11.0827L14.1962 15.2806C14.2918 15.3762 14.4056 15.4399 14.5375 15.4717C14.6695 15.5036 14.8015 15.5036 14.9334 15.4717C15.0654 15.4399 15.1815 15.3739 15.2816 15.2738C15.3771 15.1782 15.4408 15.0644 15.4727 14.9325C15.5046 14.8005 15.5046 14.6708 15.4727 14.5434C15.4408 14.4114 15.3771 14.2954 15.2816 14.1953L11.0904 9.99733L15.2816 5.8062C15.3771 5.70609 15.4408 5.59232 15.4727 5.4649C15.5091 5.33294 15.5091 5.20097 15.4727 5.069C15.4408 4.93703 15.3771 4.82327 15.2816 4.7277C15.1769 4.62759 15.0586 4.5616 14.9266 4.52975C14.7992 4.4979 14.6695 4.4979 14.5375 4.52975C14.4056 4.55705 14.2918 4.62076 14.1962 4.72088L10.0051 8.91883L5.80717 4.72088C5.7116 4.62531 5.59784 4.5616 5.46587 4.52975C5.3339 4.4979 5.20193 4.4979 5.06997 4.52975C4.938 4.5616 4.82196 4.62759 4.72184 4.7277C4.62628 4.82327 4.56257 4.93703 4.53072 5.069C4.49886 5.20097 4.49886 5.33294 4.53072 5.4649C4.56257 5.59687 4.62628 5.70836 4.72184 5.79937L8.91297 9.99733L4.72184 14.2021C4.62628 14.2931 4.5603 14.4046 4.52389 14.5366C4.49204 14.6685 4.49204 14.8005 4.52389 14.9325C4.55575 15.0644 4.62173 15.1782 4.72184 15.2738Z" className="dark:fill-white" fill="#1D1D1D" />
                                        </svg>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='absolute bottom-[32px] mt-[10%] mobile:mt-[24px] mobile:relative mobile:bottom-auto'>
                        <button  onClick={() => submitHandler()} className='bg-c-black disabled:opacity-[0.3] text-white w-[421px] h-[45px] font-bold mobile:text-[12px] mobile:tracking-[-0.36px] text-[16px] leading-[130%] tracking-[-0.48px] rounded-full px-[24px] py-[12px] mobile:w-full dark:!bg-[#fff] dark:text-[#0E0E0E]'>{(brandlanguage.length + brandvoice.length + brandpurpose.length + brandtone.length) > 0 ? 'Save' : 'Skip'}</button>
                    </div>
                </div>
            </div>
        </div>
    )
                        }
}