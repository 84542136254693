import {useState} from "react";
import classNames from "classnames";


export default function TextMore({ len, children }) {
    const [collapsed, setCollapsed] = useState(0);

    function shortText() {
        if (children.length > len && !collapsed) {
            return children.substring(0,len) + "..."
        }

        return children
    }

    return (
        <div  className="py-4 text-[18px] whitespace-pre-wrap">

            {shortText()}
        {children.length > len &&
        <button className="text-[14px] font-bold text-[#1D1D1D] items-center dark:text-white flex gap-[4px] mt-[8px]" onClick={() => setCollapsed(!collapsed)}>Read {collapsed ? 'Less': 'More'}
            <span className={classNames("expand relative top-0",{"rotate-180" : collapsed})}>
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M5.58928 5.44342C5.26384 5.76886 4.7362 5.76886 4.41076 5.44342L1.07743 2.11009C0.751993 1.78465 0.751993 1.25701 1.07743 0.931577C1.40287 0.60614 1.93051 0.60614 2.25594 0.931577L5.00002 3.67566L7.7441 0.931577C8.06953 0.60614 8.59717 0.60614 8.92261 0.931577C9.24805 1.25701 9.24805 1.78465 8.92261 2.11009L5.58928 5.44342Z" className="dark:fill-white" fill="black"></path> </svg>
            </span>
        </button>}
    </div>)
}