import React, { useEffect, useState,useRef } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Cancel } from '../../common/icons/cancel';
import { Dropdown } from '../../common/icons/dropdown';
import {useGetDiversityQuery} from "../../../../store/api/api";

export const DiversityPopup = ({diversity,onSave,onCancel}) => {
    const diversityRef = useRef();
    const [diversityLabel, setdiversityLabel] = useState([]);

//const lastnameRef = useRef();
    const dailogRef = useRef();
    const [diversityDrop, setDiversityDrop] = useState([]);
    const { data: diversityLookupData, error: diversityLookupError, isLoading: diversityLookupIsLoading } = useGetDiversityQuery();


    let [updateDiversity,setUpdatedDiversity] = useState(diversity || [])


    function diversityOnchange(data,i) {
        console.log(i,data,updateDiversity)

        let exists = updateDiversity.findIndex(existing => existing.diversity_id === data.diversity_id)
        if (exists>=0) {
            setUpdatedDiversity(updateDiversity.filter(obj => obj.diversity_id !== data.diversity_id))
        } else {
            setUpdatedDiversity(updateDiversity.concat([data]))
        }

    }


    console.log(updateDiversity)


    return (
        <div className="relative z-10 editAbout-popup" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 overlay-background bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full  justify-center p-4 sm:items-center sm:p-0" ref={dailogRef}>
                    <div className="relative w-[595px] m-auto transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[600px] dark:bg-[#1D1D1D] dark:shadow-[0px_4px_21px_rgba(138, 138, 138, 0.2)]">
                        <div className="sm:items-start w-full flex">
                            <div className="m-[24px] sm:text-left w-full">
                                <div className="absolute right-[21px] xs:mt-[-10px] pr-4 sm:block mt-[4px]">
                                    <button type="button" onClick={() => {onCancel()}} className="rounded-md !bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none">
                                        <Cancel />
                                    </button>
                                </div>
                                <h2 className='text-[20px] font-bold'>Edit Diversity Edge</h2>
                                <hr className='mt-[10px]' />
                                <div className="field-group w-[100%] mt-[40px]">
                                    <div className='w-full h-[calc(100%-5px)] flex flex-col mobile:mt-[24px] mobile:pt-[24px] mobile:box-border mobile:border-t mobile:border-solid mobile:border-[#d9d9d9] mobile:dark:border-[#A3A3A3]'>
                                        <div ref={diversityRef} className='h-[full] mobile:gap-[6px] mobile:items-start [@media(min-width:768px)]:px-[32px] flex flex-col box-border [@media(min-width:700px)]:max-h-[750px] mobile:!max-h-none dark:border-[#A3A3A3] [@media(min-width:768px)]:overflow-y-auto '>
                                            {diversityLookupIsLoading === false && diversityLookupData.data.map((data,i) => {
                                                return(
                                                    <div className='diversity-popup input-fld mb-0 group child-hover:child:text-[#4C4C4C]  dark:child-hover:child:text-[#FFF]' key={i}>
                                                        <div className=' flex items-center justify-between py-[16px] border-y-[10px] border border-white dark:border-black pr-[10px] transition-all hover:bg-[#f5f5f5] dark:hover:bg-[#4C4C4C] hover:text-[#4C4C4C] dark:hover:text-[#FFF] rounded-[8px] hover:p-[10px] h-[73px] dark:text-[#A3A3A3] text-[#A3A3A3]'>{data.label}
                                                            <label className="control control--checkbox text-[#a3a3a3] group-hover:text-[#1d1d1d] mr-[10px] peer-checked:text-[#1d1d1d] text-[14px]" htmlFor={i}>
                                                                <input type="checkbox" defaultChecked={updateDiversity.find((obj) => obj.diversity_id===data.diversity_id)}  id={i} className='h-[24px] peer w-[24px]' onChange={() => { diversityOnchange(data,i) }}/>
                                                                <div className="control__indicator"></div>
                                                            </label>
                                                        </div>
                                                        <hr className={`${i >= diversityLabel.length-1 ? 'hidden': ''} box-border border-solid border-[#ececec] dark:border-[#A3A3A3]`}/>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>

                                </div>
                                <div className='flex justify-end mt-[35px] [@media(max-width:640px)]:flex-col-reverse [@media(max-width:640px)]:gap-[10px]'>
                                    <button onClick={() => {onSave(updateDiversity)}} className={'w-[168px] h-[34px] rounded-fill-button dark:hover:!bg-[#4C4C4C] [@media(max-width:640px)]:w-full'}  >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
