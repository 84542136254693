import React from 'react'
import greyquestion from "../../../assets/img/questioncircle-grey.png";
import question from "../../../assets/img/questioncircle.png";
import { Tooltip as ReactTooltip } from "react-tooltip";

export const RadioGrp = ({ options, selected, onChange, tooltip, onClick }) => {

  return (
    <div className="flex overflow-x-hidden flex-col">
      {options.map((choice, index) => (
        <div key={index} className='radio-group-button group child-hover:child:text-[#1D1D1D] dark:child-hover:child:text-[#FFF]'>
          <div className='flex justify-between border-y-[10px] dark:border-[#0e0e0e] border-white items-center flex-row-reverse radio-grp my-auto pr-[10px] gap-[10px] min-h-[73px] transition-all hover:p-[10px] hover:bg-[#f5f5f5] dark:hover:bg-[#4C4C4C] hover:text-[#4C4C4C] dark:hover:text-[#FFF] hover:font-bold'>
            <input key={index} checked={selected === choice.value} onChange={onChange} value={choice.value} id='radio' type='radio' name='bigIdea' className='w-[24px] peer h-[24px] bg-black peer dark:bg-[#262626]' />
            <label htmlFor='radio' data-value={choice.value} onClick={onClick} className='w-full py-[6px] text-[#a3a3a3] group-hover:text-[#1d1d1d] peer-checked:text-[#1d1d1d] dark:peer-checked:text-[#FFFFFF]  text-[14px] leading-[130%] flex gap-[8px] items-center tracking-[-0.42px]'>{choice.text}
              {tooltip && choice.tooltip &&
                <span data-tooltip-id={`tooltip-${index}`} className=""><img alt='.' src={greyquestion} className='cursor-pointer' /><img alt='.' src={question} className='hidden' />
                </span>
              }
            </label>
          </div>
          <hr className={`${index >= options.length - 1 ? 'hidden' : ''} box-border border-solid border-[#ececec] dark:border-[#A3A3A3]`} />
          <ReactTooltip content={`${choice.tooltip}`} className='tooltip-top' id={`tooltip-${index}`} variant='light' place="top" />
        </div>
      ))}
    </div>
  )
}
