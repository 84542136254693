import {useState} from "react";
import classNames from "classnames";


export default function BrandFetch(props) {
    const [brand, setBrand] = useState('')


    return (
        <div className="relative text-gray-400 focus-within:text-gray-600 block">
        <label  className={classNames({
            "relative text-gray-400 focus-within:text-gray-600 block": true,
            "hidden": brand.length === 0
        })}>Brands </label>
        <input type="input" name="brands" id="brands" onChange={(e) => {setBrand(e.target.value)}} placeholder="Brands" className="form-input border-b border-gray-700 py-3 px-1 bg-white placeholder-gray-400 text-gray-500 appearance-none w-full block focus:outline-none"/>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" className="pointer-events-none w-8 h-8 absolute top-1/2 transform -translate-y-1/2 right-1"  xmlns="http://www.w3.org/2000/svg" ><path d="M0.75 5.95901C0.75 8.77653 3.0613 11.0669 5.90451 11.0669C6.96201 11.0669 7.93085 10.7469 8.74139 10.201L11.6543 13.0875C11.8316 13.2695 12.0785 13.3511 12.3255 13.3511C12.8637 13.3511 13.25 12.9495 13.25 12.4286C13.25 12.1776 13.1613 11.9455 12.9904 11.7697L10.0965 8.89575C10.6981 8.07371 11.059 7.05715 11.059 5.95901C11.059 3.14149 8.74772 0.851074 5.90451 0.851074C3.0613 0.851074 0.75 3.14149 0.75 5.95901ZM2.09245 5.95901C2.09245 3.87567 3.79585 2.1814 5.90451 2.1814C8.00684 2.1814 9.71657 3.87567 9.71657 5.95901C9.71657 8.04234 8.00684 9.73662 5.90451 9.73662C3.79585 9.73662 2.09245 8.04234 2.09245 5.95901Z" fill="#D9D9D9"></path></svg>

        </div>
    )
}