import React from 'react';

export const ArrowSmall = () => {
    return (
        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.23654 4.79183L1.06988 8.9585L0.0976562 7.98627L3.2921 4.79183L0.0976559 1.59738L1.06988 0.625163L5.23654 4.79183Z"
                fill="#0E0E0E"></path>
        </svg>
    )
}