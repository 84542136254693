import React, { useEffect, useState,useRef } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import FormValidationIcon from '../../../../utils/form-validation-icon';
import { Cancel } from '../../common/icons/cancel';
import common from '../../../../utils/common';
import FormFieldError from "../../../../utils/error-message";

export const AboutPopup = ({text,onSave,onCancel}) => {
    const aboutRef = useRef();
    //const lastnameRef = useRef();
    const dailogRef = useRef();

    let [updateText,setUpdatedText] = useState(text)
    function textOnchangeSave(name) {

    }

    return (
        <div className="relative z-10 editAbout-popup" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 overlay-background bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full  justify-center p-4 sm:items-center sm:p-0" ref={dailogRef}>
                    <div className="relative w-[595px] m-auto transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[600px] dark:bg-[#1D1D1D] dark:shadow-[0px_4px_21px_rgba(138, 138, 138, 0.2)]">
                        <div className="sm:items-start w-full flex">
                            <div className="m-[24px] sm:text-left w-full">
                                <div className="absolute right-[21px] xs:mt-[-10px] pr-4 sm:block mt-[4px]">
                                    <button type="button" onClick={() => {onCancel()}} className="rounded-md !bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none">
                                        <Cancel />
                                    </button>
                                </div>
                                <h2 className='text-[20px] font-bold'>Edit About</h2>
                                <hr className='mt-[10px]' />
                                <div className="field-group w-[100%] mt-[40px]">
                                    <textarea ref={aboutRef} name="about" defaultValue={updateText} onChange={(event) => {setUpdatedText(event.target.value)}} placeholder=" " className="input-fld h-40"></textarea>
                                </div>
                                <div className='flex justify-end mt-[35px] [@media(max-width:640px)]:flex-col-reverse [@media(max-width:640px)]:gap-[10px]'>
                                    <button onClick={() => {onSave(updateText)}} className={'w-[168px] h-[34px] rounded-fill-button dark:hover:!bg-[#4C4C4C] [@media(max-width:640px)]:w-full'}  >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
