import React from 'react';
export const ColoredProgressBar = (props) => {
    return (
        <>
            <div className="mx-auto mb-[16px] h-[16px] overflow-hidden rounded-[8px] block tab-bar">
                <div className="overflow-hidden h-full text-xs flex bg-[#65FF7C] bar" active-tab="2"></div>
                <div className="active-tab h-full" style={{ "--active-tab": props.activeTabPersentage }}>
                    <svg width="374" height="16" viewBox="0 0 374 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_f_4256_44245)">
                            <circle cx="187.091" cy="8.00073" r="108.589" fill="url(#paint0_radial_4256_44245)" />
                        </g>
                        <defs>
                            <filter id="filter0_f_4256_44245" x="0.782661" y="-178.307" width="372.616" height="372.616"
                                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feGaussianBlur stdDeviation="38.8596" result="effect1_foregroundBlur_4256_44245" />
                            </filter>
                            <radialGradient id="paint0_radial_4256_44245" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(187.091 8.00073) rotate(90) scale(108.589)">
                                <stop stopColor="#DB1562" />
                                <stop offset="1" stopColor="#FEA7CB" />
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
            </div>
            <div className='w-full tab-name float-left block'>
                <div className='w-full [&>*]:w-auto flex justify-between px-[5px] gap-[5px] tablet:px-[0]'>
                    {JSON.parse(JSON.stringify(props.list)).map((val, key) => (
                        <div className="active-name text-[#808080] text-[16px] leading-[110%] tracking-[-0.48px]" key={key} style={{ "--active-name": val.persentage }}>{val.name}</div>
                    ))}
                    {/* {props.activeTab ?  <div className="active-name text-[#808080] text-[16px] leading-[110%] tracking-[-0.48px] px-[10px]" style={{ "--active-name": props.activeTabPersentage }}>{props.activeTab}</div>:""} */}
                </div>
            </div>
        </>

    )
}