import { createSlice } from '@reduxjs/toolkit'

export const talent = createSlice({
    name: 'talent_profile',
    initialState: {
        tab1: {
            profile_avatar_url: "",
            first_name: "", last_name: "",
            location_id: "",
            rating: "",
            title: "",
            about: "",
            heading: "",
            skills: [],
            roles: [],
            awards: [],
            industries: [],
            website_url: "", instagram: "", twitter: "",
            tiktok: "", youtube_channel: "", vimeo_username: "",
            leadership_skill: "",
            discipline_id: "",
            level_id: "",
            cover_photo_url: "",
            career_start_year: 0
        },
        tab2: {
            project: []
        },
        tab3: {
            awards: []
        },
        tab4: {
            industries: []
        },
        tab5: {
            exeperience: []
        },
        tab6: {
            work: []
        },
        tab7: {
            network: []
        },
        tab8: {
            awardList: []
        },
    },
    reducers: {
        insertValue: (state, action) => {
            state[action.payload.name] = action.payload.id;
            return state
        }
    },
})

// Action creators are generated for each case reducer function
export const { insertValue } = talent.actions

export default talent.reducer