import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/**
 * @param {number} currentPosition Current Scroll position
 * @param {Array} sectionPositionArray Array of positions of all sections
 * @param {number} startIndex Start index of array
 * @param {number} endIndex End index of array
 * @return {number} Current Active index
 */
const nearestIndex = (currentPosition,sectionPositionArray,startIndex,endIndex) => {
  if (startIndex === endIndex) return startIndex;
  else if (startIndex === endIndex - 1) {
    if (
      Math.abs(
        sectionPositionArray[startIndex].headerRef.current && sectionPositionArray[startIndex].headerRef.current.offsetTop ? (sectionPositionArray[startIndex].headerRef.current.offsetTop -
          currentPosition) : 0
      ) <
      Math.abs(
        sectionPositionArray[endIndex].headerRef.current && sectionPositionArray[endIndex].headerRef.current.offsetTop ? (sectionPositionArray[endIndex].headerRef.current.offsetTop -
          currentPosition) : 0
      )
    )
      return startIndex;
    else return endIndex;
  } else {
    var nextNearest = ~~((startIndex + endIndex) / 2);
    var a = Math.abs(
      sectionPositionArray[nextNearest].headerRef.current && sectionPositionArray[nextNearest].headerRef.current.offsetTop ? (sectionPositionArray[nextNearest].headerRef.current.offsetTop -
        currentPosition) :0
    );
    var b = Math.abs(
      sectionPositionArray[nextNearest + 1].headerRef.current && sectionPositionArray[nextNearest + 1].headerRef.current.offsetTop ? (sectionPositionArray[nextNearest + 1].headerRef.current.offsetTop -
        currentPosition) : 0
    );
    if (a < b) {
      return nearestIndex(currentPosition,sectionPositionArray,startIndex,nextNearest);
    } else {
      return nearestIndex(currentPosition,sectionPositionArray,nextNearest,endIndex);
    }
  }
};

export default function ScrollHighlightNavbbar(props) {
  const [activeIndex, setActiveIndex] = useState(1);
  useEffect(() => {
    const _handleScroll = (e) => {
      var index = nearestIndex(window.scrollY + 300,props.navHeader,0,props.navHeader.length - 1);
      setActiveIndex(index);
    };
    document.addEventListener("scroll", _handleScroll);
    return () => {
      document.removeEventListener("scroll", _handleScroll);
    };
  }, []);

  return (
    <>
      {props.navHeader.map((header, index) => (
		<button onClick={() => props.handleScroll(header.headerID)} key={index + header.headerID}  href={`#${header.headerID}`} className={activeIndex === index ? props.activeClass : props.normalclass} >{header.headerTitle}</button>
      ))}
    </>
  );
}

ScrollHighlightNavbbar.propTypes = {
  navHeader: PropTypes.arrayOf(
    PropTypes.shape({
      headerID: PropTypes.string,
      headerRef: PropTypes.object.isRequired,
      headerTitle: PropTypes.string.isRequired
    })
  ).isRequired
};
