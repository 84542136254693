

export default function Awards({awards}) {
    console.log("awards",awards)
    return (<>
        <div className="text-4xl font-semibold">
            Awards
        </div>
        {/*{awards && awards.map((award, i) => {*/}
        {/*    return <div key={i}>{i}</div>*/}
        {/*})}*/}
    </>)
}