import React, { useEffect, useRef, useState } from 'react'
// import darklogo from "../../../../assets/img/dark-initial-logo.svg";
// import darkminilogo from '../../../../assets/img/dark-logo-mini.svg';
// import minilogo from '../../../../assets/img/logo-mini-color.svg';
// import logo from "../../../../assets/img/initial-logo.svg";
// import mlogo from "../../../../assets/img/logo-mini-color.svg";
import { Tab } from '@headlessui/react';
// import { About } from './about';
import { useLocation, useParams } from 'react-router-dom';
import { GetIndustry, GetProjectCategories, GetRole, GetSkills } from '../../../service/commonapi';
// import { GetAwards } from '../../service/talent-onboardig-api';
import { useDispatch, useSelector } from 'react-redux';
// import Session from 'supertokens-web-js/recipe/session';
// import dummyLogo from "../../../../assets/img/dummy_logo.png";
// import { ProfileImage } from '../../common/profile-image';
// import { Matchingprojects } from './matching-projects';
import { MatchingProfile } from './matching-profile';
import { MatchingRating } from './matching-rating';
// import { GetAgencyById, GetProjectResults, GetTalentById } from '../../../service/project-setup-api';
// import { ProfileImage } from '../../common/profile-image';
import common from '../../../../utils/common';


export const MatchingSummary = (props) => {

    var { platform, uid } = useParams();
    const selectStore = useSelector(state => state.selectStore);
    // const talentStore = useSelector(state => state.talentStore);
    // const agencyStore = useSelector(state => state.agencyStore);
    // const commonStore = useSelector(state => state.commonStore);
    // const talentProfileStore = useSelector(state => state.talentProfileStore);
    // const agencyProfileStore = useSelector(state => state.agencyProfileStore);
    // const projectSetupStore = useSelector(state => state.projectSetupStore);
    // const [showloader, setshowloader] = useState(false);
    const dispatch = useDispatch();
    const [selectedIndex, setselectedIndex] = useState(props.defaultTab);
    // const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useRef(true);
    // const [selectedIndex, setSelectedIndex] = useState(0);
    // const [dummyData, setDummyData] = useState([])
    const [urlPlatform, setUrlPlatform] = useState(location.pathname.split('/')[2])
    // const [dummyResults, setDummyResults] = useState([])
    const [userDetails, setUserDetails] = useState([])
    const [mapData, setMapData] = useState(props.results.data.length === 6 ? [{Talents:[props.results.data[0],props.results.data[1]]},{Talents:[props.results.data[2],props.results.data[3]]},{Talents:[props.results.data[4],props.results.data[5]]}] : [])
    // const [tempUserDetails, setTempUserDetails] = useState();
    // const mappingData=[];


    useEffect(() => {
        if (isMounted.current || !uid) {
            // setshowloader(true);
            getDummyData()
            getDummyResults()
            //doesSessionExist();
            callAllSelect();
            setUrlPlatform(location.pathname.split('/')[2])
            setMapData(props.results.data.length === 6 ? [{Talents:[props.results.data[0],props.results.data[1]]},{Talents:[props.results.data[2],props.results.data[3]]},{Talents:[props.results.data[4],props.results.data[5]]}] : [])
            // GetProjectResults(dispatch, projectSetupStore.project_id).then(res => console.log('res', projectSetupStore))
        }
    }, [uid]);

    useEffect(() => {
        if(props.userDetails) {
            setUserDetails(props.userDetails)
        }
    }, [props])


    /** checks whether the session exista */
    // async function doesSessionExist() {
    //     if (await Session.doesSessionExist()) {
    //     } else {
    //         navigate("/login");
    //     }
    // }

    /** loads all API */
    function callAllSelect() {
        try {
            // if (!uid || ((commonStore.platform === 'agency' && agencyStore.page1.email === "") || (commonStore.platform === 'talent' && talentStore.page1.email === ""))) {
            //     CheckLogin(dispatch, talentStore, agencyStore, commonStore, talentProfileStore, agencyProfileStore).then((response) => {
            //         if (!uid) {
            //             if (response.platform === "agency") {
            //                 // navigate("/agency-profile?" + common.encryptdecrypt("encrypt", response.agency_id));
            //                 dispatch({ type: 'common_details/insertValue', payload: { "id": { "id": response.agency_id, "platform": "agency" }, "name": "activeProfile" } });
            //             } else {
            //                 // navigate(`/talent-profile?${common.encryptdecrypt("encrypt", response.talent_id)}`)
            //                 dispatch({ type: 'common_details/insertValue', payload: { "id": { "id": response.talent_id, "platform": "talent" }, "name": "activeProfile" } });
            //             }
            //         }
            //     })
            // }
            GetRole(dispatch, selectStore)
            GetSkills(dispatch, selectStore)
            GetIndustry(dispatch, selectStore)
            // GetAwards(dispatch, selectStore)
            GetProjectCategories(dispatch, selectStore)
            // setshowloader(false)
        } catch (err) {
            // setshowloader(false); 
            console.log(err)
        }
    }


    function getDummyData() {
        fetch('../../json/matching-skills.json', { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }).then((res) => res.json()).then((_res) => {
            if (_res && _res.length) {
                // setDummyData(_res)
            }
        })
    }
    function getDummyResults() {
        fetch('../../json/matching-summary.json', { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }).then((res) => res.json()).then((_res) => {
            if (_res && _res.length) {
                // setDummyResults(_res);
            }
        })
    }

    useEffect(() => {
        props.getTalentDetails(mapData)
    },[])

    useEffect(()=>{
        setselectedIndex(props.defaultTab)
    },[props.defaultTab])

    function tabHandler(index){
        setselectedIndex(index);
        if(platform === "agency"){
            [...document.querySelectorAll(".test")].forEach(_node => {
                _node.classList.remove("active-card-agency")
            })
            document.querySelectorAll(`.cards-${index}`)[0].children[1].classList.add("active-card-agency")
        }else{
            [...document.querySelectorAll(".test")].forEach(_node => {
                _node.classList.remove("active-card")
            })
            document.querySelectorAll(`.cards-${index}`)[0].children[1].classList.add("active-card")
        }
    
    }

    function getCommaSeparator(dd) {
        if(dd && dd !== "") {
        let val = dd.toString().replaceAll(',', '');
        val = val.replaceAll('$', '');
        var _val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return _val
        }
    }
    

    return (
        <div className='w-full h-full'>
            <div className='bg-[#f5f5f5]'>
                <div className='max-w-[1088px] [@media(min-width:1023px)]:m-auto'>
                    <Tab.Group selectedIndex={selectedIndex} onChange={(index) => tabHandler(index)}  as={"div"} className="tab-list">
                        <Tab.List>
                            {mapData.map((_data) => {
                                return (
                                    <Tab>{common.getTalentName(_data.Talents, userDetails)}</Tab>
                                )
                            })}
                            {/* <Tab>Mia + Chris</Tab>
                    <Tab>Name + Name</Tab>
                    <Tab>Name + Name</Tab> */}
                        </Tab.List>
                        <Tab.Panels as='div' className='w-full dark:bg-[#0E0E0E] mx-auto bg-white pt-[48px] px-[64px] pb-[88px] [@media(max-width:1023px)]:px-[24px] [@media(max-width:1023px)]:pb-[48px]'>
                            {mapData.map((_data, i) => {
                                return (
                                    <Tab.Panel as='div' className={"w-full"} key={i}>
                                        <div className='w-full'>
                                            <div className='grid mobile:grid-cols-1 grid-cols-2 gap-[120px]'>
                                            <MatchingRating data={_data.Talents[0]} name={_data.Talents[0].name} />
                                                <MatchingRating data={_data.Talents[1]} name={_data.Talents[1].name} />
                                            </div>
                                            <hr className='my-[88px]'></hr>
                                            <Tab.Group as={"div"} className="img-tab-list">
                                                <Tab.List>
                                                    <Tab as='div' className="w-[480px] [@media(max-width:1023px)]:w-[50%] flex items-center gap-[15px] text-[#a3a3a3] border-b-2 pb-[10px] justify-center">
                                                        <div className="w-[64px] h-[64px]">
                                                            {_data && _data.Talents[0].photo_url && _data.Talents[0].photo_url !== "" && 
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 48 48" fill="none" className={`no-image prof-img`}>
                                                                <path d="M30.3842 0.0314609C34.3003 0.0139939 37.4377 0 38.8378 0C44.4244 0 48 3.33891 48 9.34739V14.49V42.8574V48H11.6199C5.78698 48 5.14437 42.7999 5.10795 42.4544C4.68128 42.3862 0 41.545 0 37.3269V32.1843V5.14261V0C0 0.166984 18.6736 0.0836935 30.3842 0.0314609Z" fill={`url(#pattern${_data.Talents[0].photo_url})`}></path>
                                                                <defs>
                                                                    <pattern id={`pattern${_data.Talents[0].photo_url}`} patternContentUnits="objectBoundingBox" width="1" height="1">
                                                                        <use xlinkHref={`#${_data.Talents[0].photo_url}`} transform="scale(0.0005)"></use>
                                                                    </pattern>
                                                                    <image id={_data.Talents[0].photo_url} width="2000" height="2000" xlinkHref={_data.Talents[0].photo_url} preserveAspectRatio="xMidYMid slice"></image>
                                                                </defs>
                                                            </svg>
                                                            }
                                                            {_data.Talents[0] && _data.Talents[0].photo_url && (_data.Talents[0].photo_url === "" || !_data.Talents[0].photo_url) && 
                                                            <svg width="64" height="64" viewBox="0 0 237 212" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g id="Group 8098">
                                                                    <rect id="Rectangle 3107" x="29.6797" y="32.5879" width="64" height="64" fill="#FBFBFB" />
                                                                    <path id="Vector" d="M191.767 0.544922C164.25 0.544922 0.474609 1.64242 0.474609 0.544922C0.474609 0.544922 0.474609 140.429 0.474609 159.065C0.474609 179.841 23.5318 183.984 25.6334 184.32C25.8128 186.022 28.9779 211.634 57.7076 211.634C72.0169 211.634 236.894 211.634 236.894 211.634C236.894 211.634 236.894 72.4181 236.894 46.5844C236.894 16.9903 219.283 0.544922 191.767 0.544922ZM54.3161 146.265H38.405C38.405 146.265 38.405 91.8454 38.405 82.891C38.405 64.2932 54.3161 64.2932 54.3161 64.2932V146.269V146.265ZM185.359 146.265V64.2932H114.031C115.047 64.3145 129.886 64.9313 129.886 82.891V146.265H113.975V64.2932H54.4997C54.4997 64.2932 54.4997 48.4476 73.1745 48.4476H94.1856C108.328 48.4476 111.758 57.5296 112.596 61.9408C113.428 57.5296 116.863 48.4476 131.005 48.4476H167.061C185.735 48.4476 185.735 64.2932 185.735 64.2932H185.415C186.432 64.3145 201.266 64.9313 201.266 82.891C201.266 91.8454 201.266 146.265 201.266 146.265H185.355H185.359Z" fill="#A3A3A3" />
                                                                </g>
                                                            </svg>}
                                                        </div>
                                                        {_data.Talents[0].name}
                                                    </Tab>
                                                    <Tab as="div" className="w-[480px] [@media(max-width:1023px)]:w-[50%] flex items-center gap-[15px] text-[#a3a3a3] justify-center pb-[10px] border-b-2">
                                                        <div className="w-[64px] h-[64px]">
                                                            {_data.Talents[1] && _data.Talents[1].photo_url && _data.Talents[1].photo_url !== "" && 
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 48 48" fill="none" className={`no-image prof-img`}>
                                                                <path d="M30.3842 0.0314609C34.3003 0.0139939 37.4377 0 38.8378 0C44.4244 0 48 3.33891 48 9.34739V14.49V42.8574V48H11.6199C5.78698 48 5.14437 42.7999 5.10795 42.4544C4.68128 42.3862 0 41.545 0 37.3269V32.1843V5.14261V0C0 0.166984 18.6736 0.0836935 30.3842 0.0314609Z" fill={`url(#pattern${_data.Talents[1].photo_url})`}></path>
                                                                <defs>
                                                                    <pattern id={`pattern${_data.Talents[1].photo_url}`} patternContentUnits="objectBoundingBox" width="1" height="1">
                                                                        <use xlinkHref={`#${_data.Talents[1].photo_url}`} transform="scale(0.0005)"></use>
                                                                    </pattern>
                                                                    <image id={_data.Talents[1].photo_url} width="2000" height="2000" xlinkHref={_data.Talents[1].photo_url} preserveAspectRatio="xMidYMid slice"></image>
                                                                </defs>
                                                            </svg>}
                                                            {_data.Talents[1] && _data.Talents[1].photo_url && (_data.Talents[1].photo_url === "" || !_data.Talents[1].photo_url) && 
                                                            <svg width="64" height="64" viewBox="0 0 237 212" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g id="Group 8098">
                                                                    <rect id="Rectangle 3107" x="29.6797" y="32.5879" width="180.056" height="142.019" fill="#FBFBFB" />
                                                                    <path id="Vector" d="M191.767 0.544922C164.25 0.544922 0.474609 1.64242 0.474609 0.544922C0.474609 0.544922 0.474609 140.429 0.474609 159.065C0.474609 179.841 23.5318 183.984 25.6334 184.32C25.8128 186.022 28.9779 211.634 57.7076 211.634C72.0169 211.634 236.894 211.634 236.894 211.634C236.894 211.634 236.894 72.4181 236.894 46.5844C236.894 16.9903 219.283 0.544922 191.767 0.544922ZM54.3161 146.265H38.405C38.405 146.265 38.405 91.8454 38.405 82.891C38.405 64.2932 54.3161 64.2932 54.3161 64.2932V146.269V146.265ZM185.359 146.265V64.2932H114.031C115.047 64.3145 129.886 64.9313 129.886 82.891V146.265H113.975V64.2932H54.4997C54.4997 64.2932 54.4997 48.4476 73.1745 48.4476H94.1856C108.328 48.4476 111.758 57.5296 112.596 61.9408C113.428 57.5296 116.863 48.4476 131.005 48.4476H167.061C185.735 48.4476 185.735 64.2932 185.735 64.2932H185.415C186.432 64.3145 201.266 64.9313 201.266 82.891C201.266 91.8454 201.266 146.265 201.266 146.265H185.355H185.359Z" fill="#A3A3A3" />
                                                                </g>
                                                            </svg>}
                                                        </div>
                                                        {_data.Talents[1].name}
                                                    </Tab>
                                                </Tab.List>
                                                <Tab.Panels>
                                                    <Tab.Panel>
                                                        {/* <Transition appear show={selectedIndex === 0}
                                            enter="transition ease duration-[500ms] transform"
                                            enterFrom="opacity-0 translate-x-[100px]"
                                            enterTo="opacity-100 translate-x-[0px]"
                                            leave="transition ease-out duration-[500ms] transform"
                                            leaveFrom="opacity-100 translate-x-[0px]"
                                            leaveTo="opacity-0 translate-x-[100px]"
                                            > */}
                                                        <MatchingProfile name={_data.Talents[0].name} type={urlPlatform} id={_data.Talents[0].id} editDetails={userDetails} />
                                                        {/* </Transition> */}
                                                    </Tab.Panel>
                                                    <Tab.Panel as="div" className="slidy">
                                                        <MatchingProfile name={_data.Talents[1].name} type={urlPlatform} id={_data.Talents[1].id} editDetails={userDetails} />
                                                    </Tab.Panel>
                                                </Tab.Panels>
                                            </Tab.Group>
                                            <hr className='my-[88px]'></hr>
                                            <div className='flex justify-between mobile:flex-col'>
                                                <span className='text-[48px] font-bold leading-[91.5%] tracking-[-1.44px]'>Estimated cost</span>
                                                <span className='text-[48px] font-bold leading-[91.5%] tracking-[-1.44px] break-words'>${props.results.estimated_cost_min ? getCommaSeparator(props.results.estimated_cost_min) : 0} - ${props.results.estimated_cost_max ? getCommaSeparator(props.results.estimated_cost_max) : 0}</span>
                                            </div>
                                            <hr className='my-[88px]'></hr>
                                            <div className='flex flex-col items-center text-center gap-[48px]'>
                                                <span className='text-[48px] tracking-[-1.44px] leading-[120%]'>{_data.Talents[0].name}, {_data.Talents[1].name} and the team are ready to discuss your brief.</span>
                                                <button className='!bg-[#F05831] !text-[#fff] border-[1px] text-[16px] px-[24px] rounded-full py-[12px] flex !border-none'>Lets's talk
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                        <path d="M8.51043 7.8055L14.5537 12.6173C14.5537 12.6173 16.8846 10.7613 14.4236 8.80177C13.6623 8.1956 10.5625 5.72742 8.44824 4.04395L8.51043 7.8055Z" fill="white" />
                                                        <path d="M8.51042 17.429L14.5537 12.6172C14.5537 12.6172 16.8846 14.4732 14.4236 16.4328C13.6623 17.0389 10.5625 19.5071 8.44824 21.1906L8.51042 17.429Z" fill="white" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </Tab.Panel>
                                )
                            })}

                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </div>
    )
}
