import React, { Suspense, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './app.css';
import "/node_modules/react-grid-layout/css/styles.css"
import "/node_modules/react-resizable/css/styles.css"
import 'react-tooltip/dist/react-tooltip.css'
import GuardedRoute from './utils/authGaurd';
import { useSelector } from 'react-redux';
import ForgotPassword from './component/view/authentication/forgot-password';
import Signup from './component/view/authentication/signup';
import { ErrorAlertPopup } from './component/view/common/error-alert';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-morphing-modal/dist/ReactMorphingModal.css';

import DynamicLoader from './utils/dynamicLoader';
import { InitialPage } from './component/view/project-setup/initial';
import { MatchingSummary } from './component/view/project-setup/matching-summary/matching-summary';
import ClientOnboarding from './component/view/client-onboarding';
import { MatchingSkills } from './component/view/project-setup/matchingSkills';
import {ProjectLaunch} from "./component/view/project/launch";
import {ProjectStart} from "./component/view/project/start";
import {ProjectSkills} from "./component/view/project/skills";
import {ProjectPinpoints} from "./component/view/project/pinpoints";
import {ProjectSummary} from "./component/view/project/summary";
import PublicAgencyProfile from "./component/view/agency/profile";
import {AuthCallbackGoogle} from "./component/view/authentication/auth-callback-google";
import {ClientRegister} from "./component/view/client/client-register";
import {ProjectName} from "./component/view/project/name";

const AgencyOnboarding = React.lazy(() => import('./component/view/agency-onboarding'));
const TalentOnboarding = React.lazy(() => import('./component/view/talent-onboarding'));
const TalentProfile = React.lazy(() => import('./component/view/talent-profile'));
const AgencyProfile = React.lazy(() => import('./component/view/agency-profile'));
const ProjectUpload = React.lazy(() => import('./component/view/project-upload'));
const TalentProject = React.lazy(() => import('./component/view/talent-project'));
const AgencyProject = React.lazy(() => import('./component/view/agency-project'));
const ProjectSetup1 = React.lazy(() => import('./component/view/project-setup'));
const ConfigSetup = React.lazy(() => import('./component/view/config'));
const Login = React.lazy(() => import('./component/view/authentication/login'));

function App() {
    const [error, setError] = useState(false)
    const commonStore = useSelector(state => state.commonStore);
    function close() { setError(true) }

    return (
        <Suspense fallback={<DynamicLoader />}>
            <Router >
                <Routes >
                    <Route exact path="signup" element={<Signup />} />
                    <Route exact path="test" element={<MatchingSummary />} />
                    <Route exact path="forgot-password" element={<ForgotPassword />} />
                    <Route exact path='login' element={<Login />} />
                    <Route exact path='/' element={<Login />} />
                    <Route path='home' element={<GuardedRoute platformPath='home' isPlatform={commonStore.platform} platform={"client"} auth={commonStore.platform && commonStore.platform === 'client'}><InitialPage /></GuardedRoute>} />

                    <Route exact path='project/launch' element={<ProjectLaunch />} />
                    <Route exact path='project/start' element={<ProjectStart />} />
                    <Route exact path='project/name' element={<ProjectName />} />
                    <Route exact path='project/skills' element={<ProjectSkills />} />
                    <Route exact path='project/pinpoints' element={<ProjectPinpoints />} />
                    <Route exact path='project/summary' element={<ProjectSummary />} />

                    <Route exact path='agency/profile/:agencyId' element={<PublicAgencyProfile />} />
                    <Route exact path='client/register' element={<ClientRegister />} />

                    <Route exact path='home/project-setup' element={<GuardedRoute platformPath='home' isPlatform={commonStore.platform} platform={"client"} auth={commonStore.platform && commonStore.platform === 'client'}><ProjectSetup1 /></GuardedRoute>} />
                    <Route exact path="project-teams/:platform/:pid" element={<GuardedRoute platformPath='project-teams' isPlatform={commonStore.platform} platform={"client"} auth={commonStore.platform && commonStore.platform === 'client'}><MatchingSkills /></GuardedRoute>} />
                    <Route exact path="project-teams/:platform" element={<GuardedRoute platformPath='project-teams' isPlatform={commonStore.platform} platform={"client"} auth={commonStore.platform && commonStore.platform === 'client'}><MatchingSkills /></GuardedRoute>} />
                    <Route exact path='talent-onboarding' element={<GuardedRoute platformPath='talent-onboarding' isPlatform={commonStore.platform} platform={"talent"} auth={commonStore.platform && commonStore.platform === 'talent'}><TalentOnboarding /></GuardedRoute>} />
                    <Route exact path='talent-profile' element={<GuardedRoute isProfile={true} platformPath='talent-profile' isPlatform={commonStore.platform} platform={"talent"} auth={commonStore.platform && commonStore.platform === 'talent'}><TalentProfile /></GuardedRoute>} />
                    <Route exact path='talent-project/:platform/:uid/:pid' element={<GuardedRoute platformPath='talent-project' isPlatform={commonStore.platform} platform={"talent"} auth={commonStore.platform && commonStore.platform === 'talent'}><TalentProject /></GuardedRoute>} />
                    <Route exact path='talent-project' element={<GuardedRoute platformPath='talent-project' isPlatform={commonStore.platform} platform={"talent"} auth={commonStore.platform && commonStore.platform === 'talent'}><TalentProject /></GuardedRoute>} />
                    <Route exact path='talent-project-upload/:platform/:uid/:pid' element={<GuardedRoute platformPath='talent-project-upload' isPlatform={commonStore.platform} platform={"talent"} auth={commonStore.platform && commonStore.platform === 'talent'}><ProjectUpload /></GuardedRoute>} />
                    <Route exact path='talent-project-upload' element={<GuardedRoute platformPath='talent-project-upload' isPlatform={commonStore.platform} platform={"talent"} auth={commonStore.platform && commonStore.platform === 'talent'}><ProjectUpload /></GuardedRoute>} />
                    <Route exact path='agency-onboarding' element={<GuardedRoute platformPath='agency-onboarding' isPlatform={commonStore.platform} platform={"agency"} auth={commonStore.platform && commonStore.platform === 'agency'}><AgencyOnboarding /></GuardedRoute>} />
                    <Route exact path='agency-project/:platform/:uid/:pid' element={<GuardedRoute platformPath='agency-project' isPlatform={commonStore.platform} platform={"agency"} auth={commonStore.platform && commonStore.platform === 'agency'}><AgencyProject /></GuardedRoute>} />
                    <Route exact path='agency-project' element={<GuardedRoute platformPath='agency-project' isPlatform={commonStore.platform} platform={"agency"} auth={commonStore.platform && commonStore.platform === 'agency'}><AgencyProject /></GuardedRoute>} />
                    <Route exact path='agency-profile' element={<GuardedRoute platformPath='agency-profile' isProfile={true} isPlatform={commonStore.platform} platform={"agency"} auth={commonStore.platform && commonStore.platform === 'agency'}><AgencyProfile /></GuardedRoute>} />
                    <Route exact path='agency-project-upload/:platform/:uid/:pid' element={<GuardedRoute platformPath='agency-project-upload' isPlatform={commonStore.platform} platform={"agency"} auth={commonStore.platform && commonStore.platform === 'agency'}><ProjectUpload /></GuardedRoute>} />
                    <Route exact path='agency-project-upload' element={<GuardedRoute platformPath='agency-project-upload' isPlatform={commonStore.platform} platform={"agency"} auth={commonStore.platform && commonStore.platform === 'agency'}><ProjectUpload /></GuardedRoute>} />
                    <Route exact path='config-setup' element={<ConfigSetup />} />
                    <Route exact path='client-onboarding' element={<ClientOnboarding />} />

                    <Route exact path='/auth/callback/google' element={<AuthCallbackGoogle />} />

                </Routes >
                {error && <ErrorAlertPopup close={close}></ErrorAlertPopup>}
            </Router>
        </Suspense>
    );
}

export default App;
