
import { thirdPartySignInAndUp } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import {useEffect} from "react";

async function handleGoogleCallback() {
    try {
        const response = await thirdPartySignInAndUp();

        if (response.status === "OK") {
            console.log(response)
            if (response.createdNewRecipeUser && response.user.loginMethods.length === 1) {
                // sign up successful
            } else {
                // sign in successful
            }
           window.location.assign("/login");
        } else if (response.status === "SIGN_IN_UP_NOT_ALLOWED") {
            // this can happen due to automatic account linking. Please see our account linking docs
            console.log(response)

        } else {
            console.log(response)

            // SuperTokens requires that the third party provider
            // gives an email for the user. If that's not the case, sign up / in
            // will fail.

            // As a hack to solve this, you can override the backend functions to create a fake email for the user.

            window.alert("No email provided by social login. Please use another form of login");
           // window.location.assign("/login"); // redirect back to login page
        }
    } catch (err) {
        console.error(err)
        if (err.isSuperTokensGeneralError === true) {
            // this may be a custom error message sent from the API by you.
            window.alert(err.message);
        } else {
            console.log(err)
           // window.location.assign("/login");
        }
    }
}

export function AuthCallbackGoogle() {

    /** On Page Intialize call function to checking session and load all api datas */
    useEffect(() => {
             handleGoogleCallback()
    }, []);

    return (<></>)
}