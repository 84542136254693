import logo from "../../../assets/img/initial-logo.svg";
import mlogo from "../../../assets/img/logo-mini-color.svg";
import minilogo from '../../../assets/img/logo-mini-color.svg';
// import greenIcon from "../../../assets/img/greenIcon.png";
import orangeIcon from "../../../assets/img/arrow-orange-icon.svg";
import violetOk from "../../../assets/img/violet-ok.svg"
import layer from "../../../assets/img/layer.png";
import { ProjectSetupTemplate } from "../common/project-setup-template";
import { ProjectSetupFooter } from "../common/project-setup-footer"
import React, { useEffect, useState } from "react";
import { SkillsPopup } from "./skills-popup/skills-popup";
import { ShowExamplePopup } from "./skills-popup/showExample-popup";
import darklogo from "../../../assets/img/dark-initial-logo.svg";
import darkminilogo from '../../../assets/img/dark-logo-mini.svg';
import darklayer from "../../../assets/img/LayerDark.png";
import nav from "../../../assets/img/nav-arrow.png";
import { DropUpdown } from "../common/icons/dropupdown";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";
import {Hover} from "./hover";
import {useGetSkillQuery} from "../../../store/api/api";


export const ProjectSkills = (props) => {

    const [openBigIdea, setOpenBigIdea] = useState(false);
    const selectStore = useSelector(state => state.selectStore);
    const projectSetupStore = useSelector(state => state.projectSetupStore)
    const [openStrategy, setOpenStrategy] = useState(false);
    const [openExperiential, setOpenExperiential] = useState(false);
    const [openBrand, setOpenBrand] = useState(false);
    const [openSocial, setOpenSocial] = useState(false);
    //const [openAI, setOpenAI] = useState(false);
    const [openExample, setOpenExample] = useState(false);
    const [title,setTitle] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selectedSkill = useSelector(state => state.projectEditStore.skills ? state.projectEditStore.skills : '' );
    const { data: skillLookupData, error: skillLookupError, isLoading: skillLookupIsLoading } = useGetSkillQuery();


    useEffect(() => {
        var _skill = groupBy(selectStore.skils, 'label');
        dispatch({ type: 'project_setup/insertValue', payload: { "id": _skill, "name": "skils_group" } });
    }, [selectStore.skils])

    useEffect(() => {
        
    })

    function resetSelection() {
        dispatch({ type: 'project-edit/update', payload: { key: "skills", value: "" } });
    }
 

    /** GROUP BY LABEL */

  function groupBy(arr, property) {

    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) { memo[x[property]] = []; }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }

  function onNext() {
      navigate("/project/pinpoints")
  }

    function onBack() {
        navigate("/project/start")
    }

    function showPopup(setState,state,index){
        setState(!state);
        if(window.innerWidth < 767) {
            const revealTxt = document.getElementsByClassName('reveal-txt'); 
            if(revealTxt) {
                if(revealTxt[index].style.display === "block") {
                    revealTxt[index].style.display = "none"
                } else {
                    revealTxt[index].style.display = "block"
                }
            }
        }
        if(window.innerWidth > 767) {
            document.body.classList.add("overflow-hidden")
        }
    }

    function findSkillLabel() {
        var skill = skillLookupData.data.find(obj => obj.skill_id === selectedSkill);
        if (skill) {
            return skill.sublabel?skill.sublabel:skill.label
        }
    }
    function showAI(){
        dispatch({type:"project-edit/update",payload:{"key":"skills","value": "metaverse"}})
    }

    function showExample(e,val){
        if(e){
            e.preventDefault()
            e.stopPropagation()
        }
        setOpenExample(true);
        if(window.innerWidth > 767) {
            document.body.classList.add("overflow-hidden")
        }
        setTitle(val)
    }

    function SkillText(){
        return(
            <div className="flex flex-col justify-between bg-[#F5F5F5] dark:bg-[#161616]">
                <div className="flex flex-col gap-[88px] mobile:gap-[48px] tablet:gap-[48px]">
                    <div className="flex flex-row gap-[16px] mobile:h-[64px] tablet:py-[5px] tablet:px-[24px] tablet:bg-white tablet:dark:bg-[#0E0E0E]">
                        <img alt="mlogo" className="tablet:p-[10px] [@media(min-width:768px)]:hidden dark:hidden" src={minilogo} width="48px" /><img alt="mlogo" className="tablet:p-[10px] mobile:hidden dark:hidden" src={mlogo} width="56px" height="50px" /><img alt="logo" className="mobile:hidden dark:hidden" src={logo} width="182px" />
                        <img alt="mlogo" className="tablet:p-[10px] hidden mobile:dark:block" src={darkminilogo} width="48px" /><img alt="mlogo" className="tablet:p-[10px] mobile:dark:hidden dark:block hidden" src={darkminilogo} width="56px" height="50px" /><img alt="logo" className="mobile:hidden dark:block hidden" src={darklogo} width="182px" />
                    </div>
                    <div className="flex flex-col text-[#1D1D1D] dark:text-[#fff] tablet:px-[24px]">
                        <nav className="flex flex-col items-start gap-[6px] pb-[16px] " aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                <li className="inline-flex items-center">
                                    <div onClick={props.prevStep} className="inline-flex cursor-pointer items-center text-[14px] dark:text-[#fff] font-medium text-[#0e0e0e] hover:!text-[#F05831]  capitalize">Start</div>
                                </li>
                                <img alt='navigation' src={nav}/>
                                <li>
                                    <div className="ml-1 inline-flex text-[14px] cursor-pointer font-semibold text-[#0e0e0e] hover:!text-[#F05831]  dark:text-[#FFF] capitalize">Skills</div>
                                </li>
                            </ol>
                        </nav>
                        <hr className="border-solid border-[#d9d9d9] dark:border-[#A3A3A3]"/>
                        <p className="[@media(width:1920px)]:w-[624px] archivo text-[40px] pt-[24px] font-extralight leading-[120%] tracking-[-1.2px] mobile:text-[24px] mobile:leading-[120%] mobile:tracking-[-0.72px] dark:text-[#fff] height-ctrl2:text-[24px] height-ctrl2:tracking-[-0.72px]"><span className="archivo font-bold text-[40px] mobile:text-[24px] mobile:float-none leading-[120%] tracking-[-1.2px] mobile:leading-[120%] mobile:tracking-[-0.72px] dark:text-[#fff] height-ctrl2:text-[24px] height-ctrl2:tracking-[-0.72px]">Skills and expertise are only everything. </span>Tell us what you need and we'll match it.</p>
                    </div>
                </div>
                <div className="w-full flex-col justify-start items-start gap-[24px] flex tablet:hidden">
                    <div className="w-full flex-col justify-start items-start gap-[24px] flex height-ctrl:hidden">
                        <div className="relative dark:hidden"><img alt="." src={layer} height="168px" width="236px" /></div>
                        <div className="relative hidden dark:block"><img alt="." src={darklayer} height="168px" width="236px" /></div>
                        <div className="self-stretch border-t-[1px] border-solid border-[#d9d9d9]"></div>
                    </div>
                    <div className="flex-col justify-start items-start gap-4 flex w-[448px]">
                        <div className="w-[341px] h-[18px] text-stone-900 text-[18px] font-bold uppercase leading-[100%] tracking-[0.9px] dark:text-[#fff]">Why we ask</div>
                        <div className="self-stretch text-black text-[20px] font-normal leading-[130%] dark:text-[#fff]">Make us understand what makes you and your brand tick. The nuances. The tone. The texture. The practicalities and realities. And we’ll do the rest.</div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="page-section skills-section">
            <ProjectSetupTemplate>
                <SkillText></SkillText>
                <div className="h-[calc(100%-115px)] flex flex-wrap content-between gap-[64px] tablet:gap-[48px] tablet:h-auto">
                    <div className="w-full h-full">
                        <div className="w-full h-[calc(100%-84px)] overflow-auto grid grid-cols-[repeat(auto-fit,_minmax(350px,_1fr))] gap-[12px] child:flex child:min-h-[228px] child:pr-[56px] mobile:child:pr-[24px] child:bg-white child:mobile:min-h-[auto] tablet:h-auto mobile:grid-cols-none child:mobile:p-[24px] mobile:child:h-[auto] dark:child:bg-[#0E0E0E] dark:text-[#fff]">


                            {/* Big Idea  */}
                            <div className="group examp-cont cursor-pointer relative flex flex-wrap items-center justify-between hover:items-end gap-[24px] py-[24px] pl-[24px] w-full text-left mobile:p-[0] mobile:gap-[16px] mobile:flex-row">
                                <div  onClick={() => showPopup(setOpenBigIdea,openBigIdea,0)} className="w-full  flex flex-wrap gap-[8px] mobile:gap-[8px] mobile:w-full">
                                    <span className="archivo w-full flex items-center mobile:justify-between">
                                        <span className="mobile:flex mobile:items-center text-[24px] leading-[100%] dark:text-white tracking[-0.72px] mobile:gap-[16px] text-[#1d1d1d] mobile:text-[18px] mobile:leading-[110%] mobile:tracking-[-0.54px]">
                                            <img alt="icon" src={selectedSkill.startsWith("bigidea")?violetOk:orangeIcon} className="mb-[8px] mobile:mb-[0] mobile:float-left transition-all left-0 relative group-hover:left-3"/>Big Idea
                                        </span>
                                        <button className="hidden px-[4px] py-[3.5px] shrink-0 mobile:block"><DropUpdown/></button>
                                    </span>
                                    {selectedSkill.startsWith("bigidea") &&
                                        <div className="mt-3 flex">
                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="20" height="20" fill="none"/>
                                                <path d="M8.50994 7.18733L14.5532 11.9992C14.5532 11.9992 16.8842 10.1432 14.4232 8.18361C13.6619 7.57744 10.562 5.10925 8.44775 3.42578L8.50994 7.18733Z" fill="#BF8CE2"/>
                                                <path d="M8.50994 16.8118L14.5532 12C14.5532 12 16.8842 13.856 14.4231 15.8156C13.6619 16.4217 10.562 18.8899 8.44775 20.5734L8.50994 16.8118Z" fill="#BF8CE2"/>
                                            </svg>
                                            <span className="text-[16px] leading-[130%] tracking-[-0.48px] mobile:text-[14px] mobile:leading-[130%] dark:text-white text-[#1d1d1d] mobile:tracking-[-0.42px]">
                                                {findSkillLabel() }
                                            </span>
                                        </div>
                                     }
                                      {!selectedSkill.startsWith("bigidea") &&<Hover onExample={showExample}>
                                           game-changing creativity that marks a new direction for the industry and moves it forward
                                       </Hover>}
                                    <div className="w-full hidden mobile:block">
                                        {openBigIdea && <SkillsPopup title="Big idea. Deep dive." prefix="bigidea" onNext={onNext} open={openBigIdea} close={() => {setOpenBigIdea(false);document.body.classList.remove("overflow-hidden")}} />}</div>
                                   </div>
                            </div>

                            {/* Strategy  */}
                            <div className="group cursor-pointer relative flex flex-wrap items-center justify-between hover:items-end gap-[24px] py-[24px] pl-[24px] w-full text-left mobile:p-[0] mobile:gap-[16px] mobile:flex-row">
                                <div  onClick={() => showPopup(setOpenStrategy,openStrategy,1)} className="w-full flex flex-wrap gap-[8px] mobile:gap-[8px] mobile:w-full">
                                    <span className="archivo w-full flex items-center mobile:justify-between">
                                        <span className="mobile:flex dark:text-white mobile:items-center text-[24px] leading-[100%] tracking[-0.72px] mobile:gap-[16px] mobile:text-[18px] text-[#1d1d1d] mobile:leading-[110%] mobile:tracking-[-0.54px]">
                                            <img alt="icon" src={selectedSkill.startsWith("strategybrand")?violetOk:orangeIcon}  className="mb-[8px] mobile:mb-[0] mobile:float-left transition-all left-0 relative group-hover:left-3"/>Strategy
                                        </span>
                                        <button className="hidden px-[4px] py-[3.5px] shrink-0 mobile:block"><DropUpdown/></button>
                                    </span>
                                    {selectedSkill.startsWith("strategy") &&
                                        <div className="mt-3 flex">
                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="20" height="20" fill="none"/>
                                                <path d="M8.50994 7.18733L14.5532 11.9992C14.5532 11.9992 16.8842 10.1432 14.4232 8.18361C13.6619 7.57744 10.562 5.10925 8.44775 3.42578L8.50994 7.18733Z" fill="#BF8CE2"/>
                                                <path d="M8.50994 16.8118L14.5532 12C14.5532 12 16.8842 13.856 14.4231 15.8156C13.6619 16.4217 10.562 18.8899 8.44775 20.5734L8.50994 16.8118Z" fill="#BF8CE2"/>
                                            </svg>
                                            <span className="text-[16px] leading-[130%] tracking-[-0.48px] mobile:text-[14px] mobile:leading-[130%] dark:text-white text-[#1d1d1d] mobile:tracking-[-0.42px]">
                                                {findSkillLabel() }
                                            </span>
                                        </div>
                                    }
                                    {!selectedSkill.startsWith("strategy") && <Hover onExample={showExample}>
                                        A-list independent strategy teams<br/> That for develop breakthrough Marketing/Brand/ Communication strategy and concepts
                                    </Hover>}
                                    <div className="w-full hidden mobile:block">
                                        {openStrategy && <SkillsPopup title="Strategy. Deep dive." prefix="strategy" onNext={onNext} open={openStrategy} close={() => {setOpenBigIdea(false);document.body.classList.remove("overflow-hidden")}} />}</div>
                                </div>
                            </div>

                            {/* Experiential & Activation  */}
                            <div className="group cursor-pointer relative flex flex-wrap items-center justify-between hover:items-end gap-[24px] py-[24px] pl-[24px] w-full text-left mobile:p-[0] mobile:gap-[16px] mobile:flex-row">
                                <div  onClick={() => showPopup(setOpenExperiential,openExperiential,1)} className="w-full flex flex-wrap gap-[8px] mobile:gap-[8px] mobile:w-full">
                                    <span className="archivo w-full flex items-center mobile:justify-between">
                                        <span className="mobile:flex dark:text-white mobile:items-center text-[24px] leading-[100%] tracking[-0.72px] mobile:gap-[16px] mobile:text-[18px] text-[#1d1d1d] mobile:leading-[110%] mobile:tracking-[-0.54px]">
                                            <img alt="icon" src={selectedSkill.startsWith("experiential")?violetOk:orangeIcon}  className="mb-[8px] mobile:mb-[0] mobile:float-left transition-all left-0 relative group-hover:left-3"/>Experiential & Activation
                                        </span>
                                        <button className="hidden px-[4px] py-[3.5px] shrink-0 mobile:block"><DropUpdown/></button>
                                    </span>
                                    {selectedSkill.startsWith("experiential") &&
                                        <div className="mt-3 flex">
                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="20" height="20" fill="none"/>
                                                <path d="M8.50994 7.18733L14.5532 11.9992C14.5532 11.9992 16.8842 10.1432 14.4232 8.18361C13.6619 7.57744 10.562 5.10925 8.44775 3.42578L8.50994 7.18733Z" fill="#BF8CE2"/>
                                                <path d="M8.50994 16.8118L14.5532 12C14.5532 12 16.8842 13.856 14.4231 15.8156C13.6619 16.4217 10.562 18.8899 8.44775 20.5734L8.50994 16.8118Z" fill="#BF8CE2"/>
                                            </svg>
                                            <span className="text-[16px] leading-[130%] tracking-[-0.48px] mobile:text-[14px] mobile:leading-[130%] dark:text-white text-[#1d1d1d] mobile:tracking-[-0.42px]">
                                                {findSkillLabel() }
                                            </span>
                                        </div>
                                    }
                                    {!selectedSkill.startsWith("experiential") && <Hover onExample={showExample}>
                                        Brand Experience & Activation ideas such as publicity stunts, pop-ups or street teams to drive customer engagements, PR and earned media
                                    </Hover>}
                                    <div className="w-full hidden mobile:block">
                                        {openExperiential && <SkillsPopup title="Experiential & Activation. Deep dive." prefix="experiential" onNext={onNext} open={openStrategy} close={() => {setOpenBigIdea(false);document.body.classList.remove("overflow-hidden")}} />}</div>
                                </div>
                            </div>

                            {/* Content & Entertainment  */}
                            <div className="group cursor-pointer relative flex flex-wrap items-center justify-between hover:items-end gap-[24px] py-[24px] pl-[24px] w-full text-left mobile:p-[0] mobile:gap-[16px] mobile:flex-row">
                                <div  onClick={() => showPopup(setOpenBrand,openBrand,1)} className="w-full flex flex-wrap gap-[8px] mobile:gap-[8px] mobile:w-full">
                                    <span className="archivo w-full flex items-center mobile:justify-between">
                                        <span className="mobile:flex dark:text-white mobile:items-center text-[24px] leading-[100%] tracking[-0.72px] mobile:gap-[16px] mobile:text-[18px] text-[#1d1d1d] mobile:leading-[110%] mobile:tracking-[-0.54px]">
                                            <img alt="icon" src={selectedSkill.startsWith("brand")?violetOk:orangeIcon}  className="mb-[8px] mobile:mb-[0] mobile:float-left transition-all left-0 relative group-hover:left-3"/>Content & Entertainment
                                        </span>
                                        <button className="hidden px-[4px] py-[3.5px] shrink-0 mobile:block"><DropUpdown/></button>
                                    </span>
                                    {selectedSkill.startsWith("brand") &&
                                        <div className="mt-3 flex">
                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="20" height="20" fill="none"/>
                                                <path d="M8.50994 7.18733L14.5532 11.9992C14.5532 11.9992 16.8842 10.1432 14.4232 8.18361C13.6619 7.57744 10.562 5.10925 8.44775 3.42578L8.50994 7.18733Z" fill="#BF8CE2"/>
                                                <path d="M8.50994 16.8118L14.5532 12C14.5532 12 16.8842 13.856 14.4231 15.8156C13.6619 16.4217 10.562 18.8899 8.44775 20.5734L8.50994 16.8118Z" fill="#BF8CE2"/>
                                            </svg>
                                            <span className="text-[16px] leading-[130%] tracking-[-0.48px] mobile:text-[14px] mobile:leading-[130%] dark:text-white text-[#1d1d1d] mobile:tracking-[-0.42px]">
                                                {findSkillLabel() }
                                            </span>
                                        </div>
                                    }
                                    {!selectedSkill.startsWith("brand") && <Hover onExample={showExample}>
                                        Creative content crafted to build awareness for a brand by associating it with culture and values through entertainment
                                    </Hover>}
                                    <div className="w-full hidden mobile:block">
                                        {openBrand && <SkillsPopup title="Content & Entertainment. Deep dive." prefix="brand" onNext={onNext} open={openStrategy} close={() => {setOpenBigIdea(false);document.body.classList.remove("overflow-hidden")}} />}</div>
                                   </div>
                            </div>



                            {/* Social & Creators  */}
                            <div className="group cursor-pointer relative flex flex-wrap items-center justify-between hover:items-end gap-[24px] py-[24px] pl-[24px] w-full text-left mobile:p-[0] mobile:gap-[16px] mobile:flex-row">
                                <div  onClick={() => showPopup(setOpenSocial,openSocial,1)} className="w-full flex flex-wrap gap-[8px] mobile:gap-[8px] mobile:w-full">
                                    <span className="archivo w-full flex items-center mobile:justify-between">
                                        <span className="mobile:flex dark:text-white mobile:items-center text-[24px] leading-[100%] tracking[-0.72px] mobile:gap-[16px] mobile:text-[18px] text-[#1d1d1d] mobile:leading-[110%] mobile:tracking-[-0.54px]">
                                            <img alt="icon" src={selectedSkill.startsWith("social")?violetOk:orangeIcon}  className="mb-[8px] mobile:mb-[0] mobile:float-left transition-all left-0 relative group-hover:left-3"/>Social & Creators
                                        </span>
                                        <button className="hidden px-[4px] py-[3.5px] shrink-0 mobile:block"><DropUpdown/></button>
                                    </span>
                                    {selectedSkill.startsWith("social") &&
                                        <div className="mt-3 flex">
                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="20" height="20" fill="none"/>
                                                <path d="M8.50994 7.18733L14.5532 11.9992C14.5532 11.9992 16.8842 10.1432 14.4232 8.18361C13.6619 7.57744 10.562 5.10925 8.44775 3.42578L8.50994 7.18733Z" fill="#BF8CE2"/>
                                                <path d="M8.50994 16.8118L14.5532 12C14.5532 12 16.8842 13.856 14.4231 15.8156C13.6619 16.4217 10.562 18.8899 8.44775 20.5734L8.50994 16.8118Z" fill="#BF8CE2"/>
                                            </svg>
                                            <span className="text-[16px] leading-[130%] tracking-[-0.48px] mobile:text-[14px] mobile:leading-[130%] dark:text-white text-[#1d1d1d] mobile:tracking-[-0.42px]">
                                                {findSkillLabel() }
                                            </span>
                                        </div>
                                    }
                                    {!selectedSkill.startsWith("social") &&
                                        <Hover onExample={showExample}>
                                            reative social thinking and influencer marketing ideas that demonstrate creative use of social media, brand ambassadors and influencers/creators
                                        </Hover>}
                                    <div className="w-full hidden mobile:block">
                                        {openSocial && <SkillsPopup title="Content & Entertainment. Deep dive." prefix="social" onNext={onNext} open={openStrategy} close={() => {setOpenBigIdea(false);document.body.classList.remove("overflow-hidden")}} />}</div>
                                </div>
                            </div>


                            {/* Gaming, AI & New Realities  */}
                            <div  onClick={() =>  showAI()} className="group cursor-pointer relative flex flex-wrap items-center justify-between hover:items-end gap-[24px] py-[24px] pl-[24px] w-full text-left mobile:p-[0] mobile:gap-[16px] mobile:flex-row">
                                    <span className="archivo w-full flex items-center mobile:justify-between">
                                        <span className="mobile:flex dark:text-white mobile:items-center text-[24px] leading-[100%] tracking[-0.72px] mobile:gap-[16px] mobile:text-[18px] text-[#1d1d1d] mobile:leading-[110%] mobile:tracking-[-0.54px]">
                                            <img alt="icon" src={selectedSkill.startsWith("metaverse")?violetOk:orangeIcon}  className="mb-[8px] mobile:mb-[0] mobile:float-left transition-all left-0 relative group-hover:left-3"/>Gaming, AI & New Realities
                                        </span>
                                        <button className="hidden px-[4px] py-[3.5px] shrink-0 mobile:block"><DropUpdown/></button>
                                    </span>
                                {selectedSkill.startsWith("metaverse") &&
                                    <div className="mt-3 flex">
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="20" height="20" fill="none"/>
                                            <path d="M8.50994 7.18733L14.5532 11.9992C14.5532 11.9992 16.8842 10.1432 14.4232 8.18361C13.6619 7.57744 10.562 5.10925 8.44775 3.42578L8.50994 7.18733Z" fill="#BF8CE2"/>
                                            <path d="M8.50994 16.8118L14.5532 12C14.5532 12 16.8842 13.856 14.4231 15.8156C13.6619 16.4217 10.562 18.8899 8.44775 20.5734L8.50994 16.8118Z" fill="#BF8CE2"/>
                                        </svg>
                                        <span className="text-[16px] leading-[130%] tracking-[-0.48px] mobile:text-[14px] mobile:leading-[130%] dark:text-white text-[#1d1d1d] mobile:tracking-[-0.42px]">
                                                {findSkillLabel() }
                                            </span>
                                    </div>
                                }
                                {!selectedSkill.startsWith("social") &&
                                    <Hover onExample={showExample}>
                                        Immersive experiences that creatively push the boundaries of technology. Including E.g. Gaming, Web3, AI, gamification, virtual worlds, metaverse, ChatGPT, etc
                                    </Hover>}

                            </div>
                        </div>

                        <div className="w-full flex gap-[12px]">
                            <button onClick={onBack} type="button" className="w-full flex group items-center bg-[#fff] p-[24px] mt-[12px] dark:bg-[#0E0E0E]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="back-arrow relative transition-all group-hover:-translate-x-[12px]">
                                    <rect width="24" height="24" transform="matrix(-1 0 0 1 24 0)" fill="transparent"/>
                                    <path d="M15.4898 7.18733L9.44658 11.9992C9.44658 11.9992 7.11559 10.1432 9.57661 8.18361C10.3379 7.57744 13.4377 5.10925 15.552 3.42578L15.4898 7.18733Z" fill="#0E0E0E"/>
                                    <path d="M15.4896 16.8118L9.44634 12C9.44634 12 7.11536 13.856 9.57637 15.8156C10.3377 16.4217 13.4375 18.8899 15.5518 20.5734L15.4896 16.8118Z" fill="#0E0E0E"/>
                                </svg>
                                <span className="mobile:hidden  text-[24px] leading-[100%]  tracking-[-0.72px] dark:text-[#fff]">BACK</span>
                            </button>
                            <button onClick={resetSelection} type="button" className="w-full flex group justify-center items-center bg-[#fff] p-[24px] disabled:text-[#808080] mt-[12px] dark:bg-[#0E0E0E]" disabled={selectedSkill==''}>
                                <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 10.0986C21 10.0986 18.995 7.36685 17.3662 5.73688C15.7373 4.1069 13.4864 3.09863 11 3.09863C6.02944 3.09863 2 7.12807 2 12.0986C2 17.0692 6.02944 21.0986 11 21.0986C15.1031 21.0986 18.5649 18.3529 19.6482 14.5986M21 10.0986V4.09863M21 10.0986H15" stroke={selectedSkill!==''?"black":"#808080"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span className="mobile:hidden text-[24px] leading-[100%] ml-1 tracking-[-0.72px] uppercase dark:text-[#fff]">RESET SELECTION</span>
                            </button>
                            <button onClick={onNext} type="button" className="w-full flex group items-center justify-end bg-[#fff] p-[24px] mt-[12px] dark:bg-[#0E0E0E]">
                                <span className="mobile:hidden text-[24px] leading-[100%]  tracking-[-0.72px]  dark:text-[#fff]">NEXT</span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" fill="none"/>
                                    <path d="M8.50994 7.18733L14.5532 11.9992C14.5532 11.9992 16.8842 10.1432 14.4232 8.18361C13.6619 7.57744 10.562 5.10925 8.44775 3.42578L8.50994 7.18733Z" fill="#0E0E0E"/>
                                    <path d="M8.50994 16.8118L14.5532 12C14.5532 12 16.8842 13.856 14.4231 15.8156C13.6619 16.4217 10.562 18.8899 8.44775 20.5734L8.50994 16.8118Z" fill="#0E0E0E"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <ProjectSetupFooter activepage="Skills" activepersentage="18%" />
                </div>
            </ProjectSetupTemplate>
            <div className="w-full mobile:hidden">{<SkillsPopup title="Big idea. Deep dive." prefix="bigidea" open={openBigIdea} onNext={onNext}  close={() => {setOpenBigIdea(false);document.body.classList.remove("overflow-hidden")}} />}</div>
            <div className="w-full mobile:hidden">{<SkillsPopup title="Strategy. Deep dive." prefix="strategybrand"  open={openStrategy} onNext={onNext} close={() => {setOpenStrategy(false);document.body.classList.remove("overflow-hidden")}} />}</div>
            <div className="w-full mobile:hidden">{<SkillsPopup title="Experiential & Activation. Deep dive." prefix="experiential" open={openExperiential} onNext={onNext} close={() => {setOpenExperiential(false);document.body.classList.remove("overflow-hidden")}} />}</div>
            <div className="w-full mobile:hidden">{<SkillsPopup title="Content & Entertainment. Deep dive." prefix="brand" open={openBrand} onNext={onNext} close={() => {setOpenBrand(false);document.body.classList.remove("overflow-hidden")}} />}</div>
            <div className="w-full mobile:hidden">{<SkillsPopup title="Social & Creators. Deep dive." prefix="social" open={openSocial} onNext={onNext} close={() => {setOpenSocial(false);document.body.classList.remove("overflow-hidden")}} />}</div>
            {openExample && <ShowExamplePopup open={openExample} close={() => {setOpenExample(false);document.body.classList.remove("overflow-hidden")}} title={title}/>}
        </div>

    )

}