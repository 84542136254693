import { RadioGrp } from '../../common/radioGrp';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { ProCancel } from "../../common/icons/skills-cancel";
import { useGetSkillQuery} from "../../../../store/api/api";
import greyquestion from "../../../../assets/img/questioncircle-grey.png";
import question from "../../../../assets/img/questioncircle.png";
import {Tooltip as ReactTooltip} from "react-tooltip";

export const SkillsPopup = (props) => {
    const selectedSkill = useSelector(state => state.projectEditStore.skills);
    const [options, setOptions] = useState([]);
    const [isOpen, setisOpen] = useState(false);
    const dispatch = useDispatch();
    const { data: skillLookupData, error: skillLookupError, isLoading: skillLookupIsLoading } = useGetSkillQuery();


    useEffect(()=> {
        if (props.open === undefined) {
            return
        }
        if(!props.open){
            const popup = document.querySelector(".big-popup")
            if(popup){
                popup.classList.add("slide-right")
            }
            setTimeout(() => {

                setisOpen(false);
            },100)
        }else{
            setisOpen(true)
        }
    },[props.open])

    useEffect(() => {
        // Verify skills loaded
        if (skillLookupIsLoading) return
        var ops = []

        skillLookupData.data.forEach(skill => {
                if (skill.skill_id.startsWith(props.prefix)) {
                    ops.push(skill)
                }
        })

        setOptions(ops)

    }, [skillLookupData])

    const [selected,setSelected] = useState("")

    function onChange(e){
        setSelected(e.target.value)
    }

    function onClick(e) {
        if(e) {
            e.preventDefault()
            e.stopPropagation();
        }
        var val = e.target.getAttribute('data-value');
        setSelected(val ? val : '')
        dispatch({type:"project-edit/update",payload:{"key":"skills","value":val}})
    }

    function onBack() {
        document.body.classList.remove("overflow-hidden")

    }

    function onNext() {
        document.body.classList.remove("overflow-hidden")
        props.onNext()
    }



if(isOpen){
    return(
        <div className={`relative z-10 mobile:w-full big-idea-popup`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className={`fixed inset-0 overlay-background bg-opacity-75 backdrop-blur-sm transition-opacity mobile:hidden`}></div>
                <div className={`fixed inset-0 big-popup z-10 overflow-y-auto h-full mobile:relative mobile:h-auto mobile:w-full ${props.open ? "slide-left" : ""}`}>
                    <div className='h-full absolute flex flex-wrap content-between top-0 right-0 gap-[32px] bg-[#ffffff] w-[485px] shadow-md min-h-full mobile:border-solid mobile:border-[#d9d9d9] mobile:border-t-[1px] mobile:relative mobile:w-full mobile:min-h-[auto] mobile:p-[0] mobile:mt-[16px] mobile:pt-[24px] dark:bg-[#0E0E0E]'>
                        <div className='h-[calc(100%-371px)] w-full flex flex-col'>
                            <div className='flex justify-between box-border border-b border-solid border-[#d9d9d9] pb-[16px] md:pt-[32px] md:mx-[32px] dark:border-[#A3A3A3] mobile:border-none mobile:pb-0'>
                                <div className='flex-col gap-1'>
                                    <p className='text-[#07091b] text-[20px] leading-[130%] tracking-[-0.6px] font-bold mobile:pt-[16px] mobile:text-[14px] mobile:leading-[130%] mobile:tracking-[-0.42px] dark:text-[#fff]'>{props.title}</p>
                                    <p className='text-[#A3A3A3] text-[14px] leading-[130%] tracking-[-0.42px] mt-[4px] mobile:text-[14px] mobile:leading-[130%] mobile:tracking-[-0.42px]'>Choose one from the choice checkboxes:</p>
                                </div>
                                <div className="absolute right-0 xs:mt-[-10px] pr-7 mt-[4px] mobile:hidden">
                                    <button type="button" onClick={props.close} className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none pr-[7px]"><ProCancel/></button>
                                </div>
                            </div>
                            <form className='overflow-auto input-fld mb-0 md:px-[32px] md:pb-[32px]'>
                                {options.map((ops, index) => (
                                    <div key={index} className='radio-group-button group child-hover:child:text-[#1D1D1D] dark:child-hover:child:text-[#FFF]'>
                                        <div className='flex justify-between border-y-[10px] dark:border-[#0e0e0e] border-white items-center flex-row-reverse radio-grp my-auto pr-[10px] gap-[10px] min-h-[73px] transition-all hover:p-[10px] hover:bg-[#f5f5f5] dark:hover:bg-[#4C4C4C] hover:text-[#4C4C4C] dark:hover:text-[#FFF] hover:font-bold'>
                                            <input key={index} checked={selectedSkill === ops.skill_id} onChange={onChange} value={ops.skill_id} id='radio' type='radio' name='bigIdea' className='w-[24px] peer h-[24px] bg-black peer dark:bg-[#262626]' />
                                            <label htmlFor='radio' data-value={ops.skill_id} onClick={onClick} className='w-full py-[6px] text-[#a3a3a3] group-hover:text-[#1d1d1d] peer-checked:text-[#1d1d1d] dark:peer-checked:text-[#FFFFFF]  text-[14px] leading-[130%] flex gap-[8px] items-center tracking-[-0.42px]'>{ops.sublabel ? ops.sublabel : ops.label}
                                                {ops.tooltip &&
                                                    <span data-tooltip-id={`tooltip-${index}`} className=""><img alt='.' src={greyquestion} className='cursor-pointer' /><img alt='.' src={question} className='hidden' />
                                                </span>
                                                }
                                            </label>
                                        </div>
                                        <hr className={`${index >= options.length - 1 ? 'hidden' : ''} box-border border-solid border-[#ececec] dark:border-[#A3A3A3]`} />
                                        <ReactTooltip content={`${ops.tooltip}`} className='tooltip-top' id={`tooltip-${index}`} variant='light' place="top" />
                                    </div>
                                ))}
                            </form>
                        </div>
                        <div className='w-full flex md:p-[32px] flex-wrap gap-[32px] bottom-0'>
                            <div className='w-full flex flex-col gap-[16px]'>
                                <button onClick={e => onNext()} className='bg-[#1D1D1D] disabled:opacity-[0.3] text-white w-full h-[45px] font-bold text-[16px] leading-[130%] tracking-[-0.48px] rounded-full py-[12px] px-[24px] dark:bg-[#fff] dark:text-[#0E0E0E]' disabled={!selected}>Next</button>
                                <button onClick={props.close} className='text-[#1D1D1D] font-bold text-[16px] leading-[130%] tracking-[-0.48px] border border-black rounded-full w-full h-[45px] dark:text-[#fff] dark:bg-[#0E0E0E] dark:border-[1px] dark:border-[solid] dark:border-[#fff] mobile:hidden'>Back</button>
                            </div>
                            <hr className='w-full border-solid border-[#d9d9d9] dark:border-[#A3A3A3] mobile:hidden'/>
                        </div>
                    </div>
            </div>
        </div>
    )  
}   
}