import React from 'react';
export const ProjectSetupTemplate = (props) => {
    return (
        <div className="main-container h-[100vh] tablet:h-auto project-setup">
            <div className="setup-container overflow-x-hidden w-full">
                <div className='project-container h-full bg-[#F5F5F5] dark:bg-[#161616] text-[#0E0E0E] flex w-full gap-[80px] tablet:flex-col tablet:gap-[48px]'>
                    <div className="w-[37%] mobile:h-auto h-full flex py-[28px] pl-[88px] relative tablet:w-full tablet:h-auto tablet:px-[0] tablet:py-[0]">
                        {props.children[0]}
                    </div>
                    <div className="w-[63%] h-full flex py-[28px] p-[88px] tablet:w-full tablet:px-[24px] tablet:pt-[0]">
                        <div className='w-full h-full'>
                            {props.children[1]}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}