import {useParams, useSearchParams} from "react-router-dom";
import React, {useRef} from "react";
import {useGetAgencyQuery, useGetWorkQuery} from "../../../store/api/api";
import DynamicLoader from "../../../utils/dynamicLoader";
import cover from "../../../assets/icons/cover-img.svg"
import {ProfileImage} from "../common/profile-image";
import Avatar from "./avatar";
import ScrollHighlightNavbbar from "../common/scroll-highlight-navbbar";
import topBtn from "../../../assets/img/top-btn.png";
import AgencyAbout from "./about";
import Projects from "./projects"
import Awards from "./awards";
import Experience from "./experience";


function PublicAgencyProfile(props) {
    let {agencyId} = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const profileRef = useRef();
    const workRef = useRef();
    const awardsRef = useRef();
    const experienceRef = useRef();
    const clientsRef = useRef();
    const networkRef = useRef();

    if (props.agencyId) {
        agencyId = props.agencyId
    }

    // Is edit mode
    const qsEdit = searchParams.get("edit")
    var edit = qsEdit==="1" || qsEdit===true
    if (props.edit) {
        edit = true
    }

    // load agency object
    const {data: agencyData, error: agencyError, isLoading: agencyIsLoading} = useGetAgencyQuery({
        filter: {
            agency_id: [agencyId]
        }
    });

    // load agency projects
    const {data: agencyWorkData, error: agencyWorkError, isLoading: agencyWorkIsLoading} = useGetWorkQuery({
        filter: {
            agency_id: [agencyId]
        }
    });


    const navHeader = [
        {
            headerTitle: "Profile",
            headerRef: profileRef,
            headerID: "Profile"
        },
        {
            headerTitle: "Work",
            headerRef: workRef,
            headerID: "Work"
        },
        {
            headerTitle: "Awards",
            headerRef: awardsRef,
            headerID: "Awards"
        },
        {
            headerTitle: "Experience",
            headerRef: experienceRef,
            headerID: "Experience"
        },
        {
            headerTitle: "Clients",
            headerRef: clientsRef,
            headerID: "Clients"
        },
        {
            headerTitle: "Network",
            headerRef: networkRef,
            headerID: "Network"
        }
    ];


    console.log(agencyData)
    console.log(agencyWorkData)

    if (agencyIsLoading || agencyWorkIsLoading) {
        return (<DynamicLoader name="Agency Data"></DynamicLoader>)
    }

    let agency
    let projects
    let awards

    if (agencyData.agencies && agencyData.agencies.length > 0) {
        agency = agencyData.agencies[0]
        projects = agencyWorkData.works
        awards = projects.flatMap(project => project.awards ? project.awards.map(award => award) : []);
    }

    /** HANDLES AGENCY PAGE SCROLL ON TAB CLICK */
    const handleScroll = (tab) => {
        document.getElementById(tab).scrollIntoView({behavior: 'smooth', block: 'center'});
    }
    function clickHandler(e){
        window.scrollTo({
            top:0,
            behavior:"smooth"
        })
    }




    return (
        <div className="flex-col w-full bg-white">
            {/*Cover Photo*/}
            <div className="w-full h-[275px] tablet:h-[95px] bg-cover bg-center relative"
                 style={{backgroundImage: `url(${agency.cover_photo_url ? agency.cover_photo_url : cover})`}}>
            </div>

            {/*Title*/}
            <div id="Profile" className="grid grid-cols-6 tablet:grid-cols-1">
                <div className="tablet:hidden"></div>
                <div className="col-span-4">
                    <div
                        className="flex-col relative w-4/5 mx-auto z-10 gap-8 tablet:mx-auto tablet:w-full  ">
                        <div className="w-[200px] h-[200px]  -mt-[40px] tablet:-mt-[20px] mx-auto">
                            <Avatar src={agency.profile_avatar_url}/>
                        </div>
                        <div className="flex-col space-y-4 mt-4 mx-auto">
                            <h1 className="archivo capitalize text-4xl tablet:mx-4 text-center">{agency.name}</h1>
                            <h2 className="text-xl mx-16 text-center">{agency.heading}</h2>
                        </div>
                    </div>

                    {/* Scroll Navbar */}
                    <div className="flex justify-between overflow-x-auto mx-16 my-12 tablet:mx-8 min-h-[auto] border-b-2">
                                <ScrollHighlightNavbbar
                                    activeClass={"text-2xl text-black pb-4 min-h-[44px]"}
                                    normalclass={"text-2xl text-[#808080] hover:border-b-2 min-h-[44px] hover:border-black pb-4"}
                                    handleScroll={handleScroll} navHeader={navHeader}/>
                    </div>

                    <div id="Profile" className="mx-16 my-16 tablet:mx-8  border-b-2">
                        <AgencyAbout agency={agency} projects={projects} awards={awards} edit={edit}/>
                    </div>
                    <div id="Work" className="mx-16 my-16 tablet:mx-8  border-b-2">
                        <Projects agency={agency} projects={projects} awards={awards} edit={edit}/>
                    </div>
                    {awards && awards.length > 0 && <div id="Awards" className="mx-16 my-16 tablet:mx-8  border-b-2">
                        <Awards awards={awards} edit={edit}/>
                    </div>}
                    <div id="Experience" className="mx-16 py-16 tablet:mx-8  border-b-2">
                        <Experience agency={agency} edit={edit}/>
                    </div>
                </div>
                <div className="tablet:hidden"></div>

            </div>
            <button onClick={clickHandler} id="fixed-btn" className='fixed bottom-[50px] right-[5%] w-[112px] h-[112px]'>
                <img src={topBtn} alt="scroll"/>
            </button>
        </div>



    )


}


export default PublicAgencyProfile;