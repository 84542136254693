import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { CheckLogin } from "../component/service/commonapi"
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Session from 'supertokens-web-js/recipe/session';
import common from "./common";
import DynamicLoader from "./dynamicLoader";
function GuardedRoute(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const talentStore = useSelector(state => state.talentStore);
    const agencyStore = useSelector(state => state.agencyStore);
    const commonStore = useSelector(state => state.commonStore);
    const talentProfileStore = useSelector(state => state.talentProfileStore);
    const agencyProfileStore = useSelector(state => state.agencyProfileStore);
    const dispatch = useDispatch();
    const [platform, setPlatform] = useState(props.auth)
    const locSearch = new URLSearchParams(location.search);
    useEffect(() => {
        if (Session.doesSessionExist()) {
            if (!props.isPlatform) {
                try {
                    CheckLogin(dispatch, talentStore, agencyStore, commonStore, talentProfileStore, agencyProfileStore).then((response) => {
                        if (response && response.status && response.platform) {
                            if (response.platform === props.platform) {
                                setPlatform(true);
                                if (locSearch.size && location.search && location.search !== "") {
                                    const searchParam = location.search
                                    navigate("/" + props.platformPath + searchParam)
                                } else {
                                    if (props.platformPath === "talent-project-upload" || props.platformPath === "talent-project") {
                                        navigate("/talent-profile?" + common.encryptdecrypt("encrypt", response.talent_id));
                                    } else if (props.platformPath === "agency-project-upload" || props.platformPath === "agency-project") {
                                        navigate("/agency-profile?" + common.encryptdecrypt("encrypt", response.agency_id));
                                    } else {
                                        navigate("/" + props.platformPath);
                                    }
                                }
                            } else {
                                if (props.isProfile) {
                                    setPlatform(true);
                                    if (locSearch.size && location.search && location.search !== "") {
                                        const searchParam = location.search
                                        navigate("/" + props.platformPath + searchParam)
                                    }
                                }
                                else if (response.platform === 'talent' && (props.platformPath === "talent-project-upload" || props.platformPath === "talent-project")) {
                                    navigate("/talent-profile?" + common.encryptdecrypt("encrypt", response.talent_id));
                                } else if (response.platform === 'agency' && (props.platformPath === "agency-project-upload" || props.platformPath === "agency-project")) {
                                    navigate("/agency-profile?" + common.encryptdecrypt("encrypt", response.agency_id));
                                } else if (response.platform === 'agency' && (props.platformPath === "talent-project-upload" || props.platformPath === "talent-project")) {
                                    navigate("/agency-profile?" + common.encryptdecrypt("encrypt", response.agency_id));
                                } else if (response.platform === 'talent' && (props.platformPath === "agency-project-upload" || props.platformPath === "agency-project")) {
                                    navigate("/talent-profile?" + common.encryptdecrypt("encrypt", response.talent_id));
                                } else if (props.platformPath === 'project-teams') {
                                    var platform = location.pathname.split('/')[2]
                                    navigate("/" + props.platformPath + '/' + platform);
                                }
                                else {
                                    navigate("/login");
                                    setPlatform(false)
                                }
                            }
                        } else {
                            navigate("/login");
                        }
                    }).catch((err) => {
                        navigate("/login");
                        console.log(err)
                    })
                } catch (err) {
                    console.log('er', err)
                }
            }
        } else {
            navigate("/login");
        }
    }, []);
    return platform === true ? props.children ? props.children : <Navigate to={props.platformPath} replace /> : <DynamicLoader name="Login"></DynamicLoader>;
}
export default GuardedRoute;