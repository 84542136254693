import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DynamicLoader from "../../../utils/dynamicLoader"
import { InitialPage } from '../project-setup/initial';
import { StartingPoint } from '../project-setup/startingpoint';
import { Skills } from '../project-setup/skills';
import { Pinpoints } from '../project-setup/pinpoints';
import { Summary } from '../project-setup/summary';
import { GetBrands, GetDiversity, GetIndustry, GetSkills } from '../../service/commonapi';
import { useDispatch, useSelector } from 'react-redux';
import { GetTone } from '../../service/talent-onboardig-api';
import { GetProjectByID, ProjectUpdate } from '../../service/project-setup-api';
import common from '../../../utils/common';
import Rendertoast from '../../../utils/rendertoast';
import minilogo from "../../../assets/img/logo-mini-color.svg";
import mlogo from "../../../assets/img/logo-mini-color.svg";
import logo from "../../../assets/img/initial-logo.svg";
import darkminilogo from "../../../assets/img/dark-logo-mini.svg";
import darklogo from "../../../assets/img/dark-initial-logo.svg";
import {WhyIcon} from "../common/icons/why-icon";
import {Tooltip as ReactTooltip} from "react-tooltip";
import layer from "../../../assets/img/layer.png";
import darklayer from "../../../assets/img/LayerDark.png";
import {ProjectSetupTemplate} from "../common/project-setup-template";
import orangeIcon from "../../../assets/img/arrow-orange-icon.svg";
import {LockIcon} from "../common/icons/lock-icon";
import {BrowseTalentPopup} from "../project-setup/browsetalent-popup";
import {RequestDemoPopup} from "../project-setup/requestDemo-popup";
import {ProjectSetupFooter} from "../common/project-setup-footer";
import {saveValue} from "../../../store/project-edit-reducer";
import FormSearchIcon from "../../../utils/form-search-icon";
import FormValidationIcon from "../../../utils/form-validation-icon";

export function ProjectName(props) {
    const selectStore = useSelector(state => state.selectStore);
    const commonStore = useSelector(state => state.commonStore);
    const [showBrowseTalent,setShowBrowseTalent] = useState(false);
    const [showGuideTour,setShowGuideTour] = useState(false);
    const [openMobileView,setMobileView] = useState(false);
    const projectSetupStore = useSelector(state => state.projectSetupStore);
    const navigate = useNavigate();
    const [steps, setsteps] = useState(0);
    const [startOption,setStartOption] = useState("");
    const [showloader, setshowloader]= useState(true);
    const isMounted = useRef(true);
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParam = new URLSearchParams(location.search);
    const id = searchParam.size && location.search && location.search !== "" ? common.encryptdecrypt("decrypt",location.search.slice(1)) : null;

    useEffect(() => {
        if (isMounted.current) {
            setshowloader(false)
            // doesSessionExist();
            callAllSelect();
        }
    }, []);

    useEffect(()=>{
        if(document.querySelector(".page-section")) {
            document.querySelector(".page-section").classList.add("skillpoint");
        }
        return () => {
            if(document.querySelector(".page-section")) {
                document.querySelector(".page-section").classList.remove("skillpoint");
            }
        }
    },[])

    useEffect(() => {
        resizeListener()
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }

    },[]);
    
    // async function doesSessionExist() {
    //     if (await Session.doesSessionExist()) {
    //       setshowloader(false)
    //     } else {
    //         navigate("/login");
    //     }
    // }

    useEffect(() => {
        var _ids = id ? id : null;
        getProjectDetails(_ids)
    }, [projectSetupStore.project_id])

    function getProjectDetails(decrypted_id) {
        if(decrypted_id) {
            GetProjectByID(dispatch, decrypted_id, projectSetupStore).then((res) => {
                if(res) {
                }
            })
        }
    } 

    useEffect(() => {
        if (commonStore.theme) {
            if (commonStore.theme === 'light') {
                document.body.classList.remove('dark')
            } else {
                document.body.classList.add(commonStore.theme);
            }
        }
    }, [commonStore.theme])

    function nextStep() {
      setsteps(x => x + 1 )
    }
    function prevStep(page) {
        console.log("prevStep " + page)
        if(page === "startingpoint"){
            setsteps(0)
        }else if(page === "skills"){
            setsteps(1)
        }else{
            setsteps(x => x - 1)
        }
    }

    function nextPage(val){
        if(val && val !== ""){
            setStartOption(val);
            nextStep();
        }
    }
    function saveData(datas){
        var _datas = JSON.parse(JSON.stringify(datas));
        if(id && id !== ""){
            var _id = id ? id : null;
            _datas["project_id"] = _id;
        } else {
            Rendertoast.showToast({ "type": "e", "msg": "Project id is missing, please try again" });
            dispatch({ type: 'project_setup/reset'});
            navigate(`/home`);
        }
        ProjectUpdate({"input":_datas}).then((res) => {
        })
    }

    function callAllSelect(){ 
        try {
            // CheckLogin(dispatch, talentStore, agencyStore, commonStore, talentProfileStore, agencyProfileStore).then((response) => {
            //     if (response.platform === "agency") {
            //         // navigate("/agency-profile?" + common.encryptdecrypt("encrypt", response.agency_id));
            //         dispatch({ type: 'common_details/insertValue', payload: { "id": { "id": response.agency_id, "platform": "agency" }, "name": "activeProfile" } });
            //     } else {
            //         // navigate(`/talent-profile?${common.encryptdecrypt("encrypt", response.talent_id)}`)
            //         dispatch({ type: 'common_details/insertValue', payload: { "id": { "id": response.talent_id, "platform": "talent" }, "name": "activeProfile" } });
            //     }
            //  })
            GetDiversity(dispatch,selectStore)
            GetSkills(dispatch,selectStore)
            GetIndustry(dispatch,selectStore)
            GetBrands(dispatch, selectStore)
            GetTone(dispatch,selectStore)
        } catch (error) {
            setshowloader(false);console.log(error)
        }
    }
    if(showloader){return (<DynamicLoader name="Login"></DynamicLoader>)}

    function browseTalent(){
        setShowBrowseTalent(true);
        // submitHandler('browse_talent');
    }

    function guideTour(){
        setShowGuideTour(true);
        // submitHandler('guided_tour');
    }



    function resizeListener() {
        if(window.innerWidth < 768) {
            setMobileView(true);
        } else {
            setMobileView(false);
            setShowBrowseTalent(false);
            setShowGuideTour(false);
        }
    }

    function submitHandler(value){
        var _data = JSON.parse(JSON.stringify(projectSetupStore.page1));
        switch (value){
            case 'find_team':
                navigate('/project/skills')
                dispatch({type:"project-edit/update",payload: {"key":"start_point","value":"find_team"}})
                break;
            case 'add_talent':
                if(value){
                    _data["starting_point"] = value;
                    dispatch({ type:"project_setup/insertValue", payload: {"id":_data,"name":"page1"}})
                    dispatch({ type:"project_setup/insertValue", payload: {"id":'talent',"name":"teams_platform"}})
                    props.saveData({ "starting_point" : value})
                    props.nextPage(value);
                }
                break;
            case 'browse_talent':
                if(value){
                    _data["starting_point"] = value;
                    dispatch({ type:"project_setup/insertValue", payload: {"id":_data,"name":"page1"}})
                    props.saveData({ "starting_point" : value});
                    props.nextPage(value);
                }
                break;
            case 'guided_tour':
                if(value){
                    _data["starting_point"] = value;
                    dispatch({ type:"project_setup/insertValue", payload: {"id":_data,"name":"page1"}})
                    props.saveData({ "starting_point" : value})
                    props.nextPage(value);
                }
                break;
            default:
                break;
        }

    }

    function NamePageText() {
        return (
            <div className="flex flex-col justify-between bg-[#F5F5F5] dark:bg-[#161616] footer-clr-change">
                <div className="flex flex-col gap-[88px] mobile:gap-[48px] tablet:gap-[48px]">
                    <div className="flex flex-row gap-[16px] mobile:h-[64px] tablet:py-[5px] tablet:px-[24px] tablet:bg-white tablet:dark:bg-[#0E0E0E]">
                        <img alt="mlogo" className="tablet:p-[10px] [@media(min-width:768px)]:hidden dark:hidden" src={minilogo} width="48px" /><img alt="mlogo" className="tablet:p-[10px] mobile:hidden dark:hidden" src={mlogo} width="56px" height="50px" /><img alt="logo" className="mobile:hidden dark:hidden" src={logo} width="182px" />
                        <img alt="mlogo" className="tablet:p-[10px] hidden mobile:dark:block" src={darkminilogo} width="48px" /><img alt="mlogo" className="tablet:p-[10px] mobile:dark:hidden dark:block hidden" src={darkminilogo} width="56px" height="50px" /><img alt="logo" className="mobile:hidden dark:block hidden" src={darklogo} width="182px" />
                    </div>
                    <div className="flex flex-col gap-[24px] text-[#1D1D1D] tablet:px-[24px] dark:text-[#fff]">
                        <span className="flex flex-col  relative mobile:justify-between mobile:text-[14px] mobile:tracking-[-0.42px] ">
                            <span className="text-[14px] w-fit font-semibold tracking-[-0.54px] text-[#0e0e0e] dark:text-[#fff] hover:!text-[#F05831]">Start</span>
                            <hr className="border-solid mt-[16px] w-full border-[#d9d9d9] dark:border-[#A3A3A3]"></hr>
                            <div className="hidden mobile:block" data-tooltip-id='whyask'><WhyIcon/></div>
                            <ReactTooltip content='Aim high! What skills and expertise do you need (Remember, it is rocket science)' className='tooltip-top' id='whyask' variant='light' place="top"/>
                        </span>
                        <p className="[@media(width:1920px)]:w-[624px] archivo text-[40px] font-extralight leading-[120%] tracking-[-1.2px] mobile:text-[24px] mobile:leading-[120%] mobile:tracking-[-0.72px] dark:text-[#fff] height-ctrl2:text-[24px] height-ctrl2:tracking-[-0.72px]">Let's give your project a name</p>
                    </div>
                </div>
                <div className="w-full flex-col justify-start items-start gap-[24px] flex tablet:hidden">
                    <div className="w-full flex-col justify-start items-start gap-[24px] flex height-ctrl:hidden">
                        <div className="relative dark:hidden"><img alt="." src={layer} height="168px" width="236px" /></div>
                        <div className="relative hidden dark:block"><img alt="." src={darklayer} height="168px" width="236px" /></div>
                        <div className="self-stretch border-t-[1px] border-solid border-[#d9d9d9] dark:border-[#A3A3A3]"></div>
                    </div>
                    {/*<div className="flex-col justify-start items-start gap-4 flex w-[448px]">*/}
                    {/*    <div className="w-[341px] h-[18px] text-stone-900 text-[18px] font-bold uppercase leading-[100%] tracking-[0.9px] dark:text-[#fff]">Why we ask</div>*/}
                    {/*    <div className="self-stretch text-black text-[20px] font-normal leading-[130%] dark:text-[#fff]">Make us understand what makes you and your brand tick. The nuances. The tone. The texture. The practicalities and realities. And we’ll do the rest.</div>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }

    return (
        <div className="page-section">
            <ProjectSetupTemplate>
                <NamePageText></NamePageText>
                <div className="h-[calc(100%-101px)] flex-col justify-between flex-wrap gap-[50.71px] tablet:gap-[48px] tablet:h-[auto]">
                    <div className="w-full h-full flex flex-col justify-between gap-8">
                        <div className="basis-5/6 bg-white flex flex-col justify-center">
                            <div className='flex justify mt-[30px]'>
                                <div className="field-group w-[100%] ">
                                <input  type="text" name="fullname" placeholder=" " className="input-fld"/>
                                <label htmlFor="fullname" className="absolute text-[16px] top-0 text-[#a3a3a3] -z-1 duration-300 origin-0">Full Name</label>
                            </div>
                        </div>
                            </div>
                        <div className="basis-1/6 w-full flex gap-[12px] bg-[#fff]">
                            <button  type="button" className="w-full flex group items-center bg-[#fff] p-[24px] mt-[12px] dark:bg-[#0E0E0E]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="back-arrow relative transition-all group-hover:-translate-x-[12px]">
                                    <rect width="24" height="24" transform="matrix(-1 0 0 1 24 0)" fill="transparent"/>
                                    <path d="M15.4898 7.18733L9.44658 11.9992C9.44658 11.9992 7.11559 10.1432 9.57661 8.18361C10.3379 7.57744 13.4377 5.10925 15.552 3.42578L15.4898 7.18733Z" fill="#0E0E0E"/>
                                    <path d="M15.4896 16.8118L9.44634 12C9.44634 12 7.11536 13.856 9.57637 15.8156C10.3377 16.4217 13.4375 18.8899 15.5518 20.5734L15.4896 16.8118Z" fill="#0E0E0E"/>
                                </svg>
                                <span className="mobile:hidden  text-[24px] leading-[100%]  tracking-[-0.72px] dark:text-[#fff]">BACK</span>
                            </button>

                            <button  type="button" className="w-full flex group items-center justify-end bg-[#fff] p-[24px] mt-[12px] dark:bg-[#0E0E0E]">
                                <span className="mobile:hidden text-[24px] leading-[100%]  tracking-[-0.72px]  dark:text-[#fff]">NEXT</span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" fill="none"/>
                                    <path d="M8.50994 7.18733L14.5532 11.9992C14.5532 11.9992 16.8842 10.1432 14.4232 8.18361C13.6619 7.57744 10.562 5.10925 8.44775 3.42578L8.50994 7.18733Z" fill="#0E0E0E"/>
                                    <path d="M8.50994 16.8118L14.5532 12C14.5532 12 16.8842 13.856 14.4231 15.8156C13.6619 16.4217 10.562 18.8899 8.44775 20.5734L8.50994 16.8118Z" fill="#0E0E0E"/>
                                </svg>
                            </button>
                        </div>
                    </div>

                    <ProjectSetupFooter activepage="Project Name" activepersentage="-5%" />
                </div>
            </ProjectSetupTemplate>
            {showBrowseTalent && <BrowseTalentPopup close={() => {setShowBrowseTalent(false); document.body.classList.remove("overflow-hidden")}} saveData={props.saveData} nextStep={(e) => {submitHandler('browse_talent')}}/>}
            {showGuideTour && <RequestDemoPopup close={() => {setShowGuideTour(false); document.body.classList.remove("overflow-hidden")}} saveData={props.saveData} nextStep={(e) => {submitHandler('guided_tour')}}/>}
        </div>
    )


}

