

export default function Projects({projects}) {
    console.log("projects",projects)

    function findMp4(proj) {
       return  proj.creatives.findIndex((obj) => obj.url.endsWith('mp4'))
    }

    function findImage(proj) {
        return  proj.creatives.findIndex((obj) => obj.url.endsWith('png') || obj.url.endsWith('jpeg') || obj.url.endsWith('jpg') || obj.url.endsWith('webm'))
    }

    function findPoster(proj) {
        return  proj.creatives.findIndex((obj) => obj.url.endsWith('jpg') || obj.url.endsWith('jpeg') || obj.url.endsWith('webm'))
    }

    return (<>
        <div className="text-4xl font-semibold">
            Projects
        </div>
        {console.log("projects",projects)}
        {projects && projects.filter((proj) => proj.creatives && proj.creatives.length>0 && (findMp4(proj)>=0 ||findImage(proj)>=0)).map((proj, i) => {
            return (<div  key={i} className="py-4">
                    {findMp4(proj)>=0 ? <video className="text-3xl font-semibold" controls>
                         <source src={proj.creatives[findMp4(proj)].url} type="video/mp4"/>
                     </video>: (findImage(proj)>=0 && <img src={proj.creatives[findImage(proj)].url}/>)}
                     <div className="text-3xl font-semibold pt-2">{proj.name}</div>
                     <div className="text-2xl">{proj.company && proj.company.label + "| " + proj.year}</div>
            </div>)
        })}
    </>)
}