import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from './app';
import { store } from './store/store'
import { Provider } from 'react-redux';
import "./index.css"
import SuperTokens from "supertokens-web-js";
import Session from "supertokens-web-js/recipe/session";
import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";

SuperTokens.init({
    appInfo: {
        appName: "Moonshoot",
        apiDomain: process.env.REACT_APP_BASE_URL,
        websiteDomain: window.location.origin,
        apiBasePath: "/auth",
        websiteBasePath: "/login"
    },
    recipeList: [
        Session.init(),
        ThirdPartyEmailPassword.init(),
    ]
});

const root = ReactDOMClient.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);


