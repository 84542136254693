import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';
import {data} from "vis-network";



export const ProjectEditSlice = createSlice({
    name: "project-edit",
    initialState: {},
    reducers: {
        launch: (state,action) => {

            return {project_id: uuidv4(), name: action.payload.name}
        },
        update: (state, action) => {
            state[action.payload.key] = action.payload.value;
            return state
        },
        appendUniq: (state, action) => {
            var arr = state[action.payload.key]
            if (!arr) {
                arr = [];
            }

            if (!arr.includes(action.payload.value)) {
                arr.push(action.payload.value);
            }

            state[action.payload.key] = arr;
            return state
        },
        append: (state, action) => {
            var arr = state[action.payload.key]
            if (!arr) {
                arr = [];
            }
            arr.push(action.payload.value);
            state[action.payload.key] = arr;
            return state
        },

        remove: (state, action) => {
            var arr = state[action.payload.key]
            if (!arr) {
                return state
            }
            state[action.payload.key] = arr.filter(item => item !== action.payload.value)

            return state
        },


        reset: () => {
            return {}
        },


        addBrand: (state, action) => {
            var arr = state["brands"]
            if (!arr) {
                arr = [];
            }
            var existing = arr.find(item => item.company_id == action.payload.value.company_id)
            if (!existing) {
                arr.push(action.payload.value)
            }
            state["brands"] = arr

            return state
        },

        removeBrand: (state, action) => {
            var arr = state["brands"]
            if (!arr) {
                arr = [];
            }

            state["brands"] = arr.filter(item => item.company_id !== action.payload.value.company_id)

            return state
        },

        addLocation: (state, action) => {
            var arr = state["location"]
            if (!arr) {
                arr = [];
            }
            var existing = arr.find(item => item.location_id == action.payload.value.location_id)
            if (!existing) {
                arr.push(action.payload.value)
            }
            state["location"] = arr

            return state
        },

        removeLocation: (state, action) => {
            var arr = state["location"]
            if (!arr) {
                arr = [];
            }

            state["location"] = arr.filter(item => item.location_id !== action.payload.value.location_id)

            return state
        },
    }
})

export default ProjectEditSlice.reducer