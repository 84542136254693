import { apiService } from "../../utils/service";
//Api to update/save the agency onboarding form details
export const FormDataUpdateAgency = (data) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "agency/update",
            data: data,
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}
//Api to upload the profile image
export const ProfileUploadApi = (file) => {
    try {
        return apiService.get({
            url: process.env.REACT_APP_BASE_URL + "agency/avatar/upload?type=" + file.type.replace("image/", ""),
            data: "",
        })
            .then((res) => {
                if (res && res.data && res.data.status && (res.data.status === "ok" || res.data.status === "success")) {
                    return res;
                } else {
                    return "";
                }
            })
            .catch((err) => {
                // return res.data;
            })
    } catch (error) {

    }
}
//Api to get company size drop-down options
export const GetCompanySize = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.companysize.length === 0) {
            return apiService.get({
                url: process.env.REACT_APP_BASE_URL + "company/size/get",
                data: "",
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        dispatch({ type: 'drop_down_option/insertValue', payload: { "value": res.data.data, "name": "companysize" } });
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    return false
                })
        } else {
            return true
        }
    } catch (error) {

    }
}
//function to get year options for drop-down
export const GetYear = (dispatch, selectStore) => {
    try {
        if (selectStore && selectStore.yearstarted.length === 0) {
            var today = new Date(), beforeYears = 150, thisyear = today.getFullYear(), yearList = [];
            for (var year = thisyear - beforeYears, index = 0;
                index <= beforeYears;
                index++, year++) {
                yearList.push({ "label": year, "value": year })
            }
            let yrList = yearList.reverse();
            dispatch({ type: 'drop_down_option/insertValue', payload: { "value": yrList, "name": "yearstarted" } });
        }
    } catch (error) {

    }
}
//Api to upload work
export const uploadWorkApi = (file) => {
    try {
        return apiService.get({
            url: process.env.REACT_APP_BASE_URL + "agency/work/upload?type=" + file.type.split("/")[1],
            data: "",
        })
            .then((res) => {
                if (res && res.data && res.data.status && (res.data.status === "ok" || res.data.status === "success")) {
                    return res;
                } else {
                    return "";
                }
            })
            .catch((err) => {
                // return res.data;
            })
    } catch (error) {

    }
}
//Api to get the detailed agency work
export const getAgencyWork = (dispatch, id) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "work/get",
            data: {
                "filter": {
                    "agency_id": [
                        id
                    ]
                }
            }
        })
            .then((res) => {
                if (res && res.data && res.data.status && (res.data.status === "ok" || res.data.status === "success")) {
                    const filteredItems = res.data.works.filter(item => item.creatives && item.creatives.length)
                    if (filteredItems && filteredItems.length) {
                        dispatch({ type: 'agency_profile/insertValue', payload: { "id": { work: filteredItems }, "name": "tab5" } });
                        var awards = filteredItems.filter(item => 'awards' in item).map((awrds) => awrds.awards).flat();
                        var workWithAwd = filteredItems.filter(item => 'awards' in item).map((awrds) => awrds);
                        var modifiedObj = {}
                        workWithAwd.forEach((data, i) => {
                            data.awards.forEach(awd => {
                                modifiedObj[awd.award_id] = []
                            })
                        })
                        Object.entries(modifiedObj).forEach(ad => {
                            workWithAwd.forEach((data, i) => {
                                data.awards.forEach(awd => {
                                    if (ad[0] === awd.award_id) {
                                        ad[1].push(Object.assign({ client_id: data.client_id, work_id: data.work_id, company: data.company }, awd))
                                    }
                                })
                            })
                        })
                        dispatch({ type: 'agency_profile/insertValue', payload: { "id": { awardList: modifiedObj }, "name": "tab7" } });
                        dispatch({ type: 'agency_profile/insertValue', payload: { "id": { awards: awards }, "name": "tab3" } });
                    } else {
                        dispatch({ type: 'agency_profile/insertValue', payload: { "id": { work: [] }, "name": "tab5" } });
                        dispatch({ type: 'agency_profile/insertValue', payload: { "id": { awards: [] }, "name": "tab3" } });
                    }

                }
                return res;
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}
//Api to get perticular work using work id
export const getAgencyWorkById = (id, work_id) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "work/get",
            data: {
                "filter": {
                    "agency_id": [
                        id
                    ],
                    "work_id": [work_id]
                }
            }
        })
            .then((res) => {
                if (res && res.data && res.data.status && (res.data.status === "ok" || res.data.status === "success")) {
                    return res.data.works
                }
                return null;
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}
//Api to reset the agency onboarding when we want to edit it after completion process
export const AgencyUpdateReset = (body) => {
    try {
        return apiService.post({
            url: process.env.REACT_APP_BASE_URL + "agency/update/reset",
            data: body,
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {

            })
    } catch (error) {

    }
}