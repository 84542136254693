import { useEffect, useRef, useState } from 'react';
import FormValidationIcon from '../../../../utils/form-validation-icon';
import { useDispatch, useSelector } from 'react-redux';
import { SelectFormFieldError } from '../../../../utils/error-message';
import { Dropdown } from '../../common/icons/dropdown';
import common from '../../../../utils/common';
import { ProCancel } from '../../common/icons/skills-cancel';
import { useGetIndustryQuery} from "../../../../store/api/api";
import MultiSelectTree from "../../common/multiselect-tree";
import classNames from "classnames";
import {ArrowSmall} from "../../common/icons/arrow-small";


var items = [
    {label:"You",id:"1",subItems:[{label:"Are",id:"3"}, {label:"Amazing",id:"3"}]},
    {label:"Are",id:"2",subItems:[{label:"Are",id:"3"}, {label:"Amazing",id:"3"}]},
    {label:"Simply",id:"3",subItems:[{label:"Are",id:"3"}, {label:"Amazing",id:"3"}]},
    {label:"The",id:"4",subItems:[{label:"Are",id:"3"}, {label:"Amazing",id:"3"}]},
    {label:"Best",id:"5",subItems:[{label:"Are",id:"3"}, {label:"Amazing",id:"3"}]},
]

export const IndustryPopup = (props) => {
   const [industry, setIndustry] = useState([]);
  const industryParentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { data: industryLookupData, error: industryLookupError, isLoading: industryLookupIsLoading } = useGetIndustryQuery();
    const [isCollapsed, setIsCollapsed] = useState(false);


    useEffect(()=> {
        if(!props.open){
            const popup = document.querySelector(".industry-popup")
            if(popup){
                popup.classList.add("slide-right")
            }
            setTimeout(() => {
                setIsOpen(false);
            },100)
        }else{
            setIsOpen(true)
        }
    },[props.open])


  useEffect(() => {
    window.addEventListener("scroll", resetDropdownPosition);
    return () => {
      window.removeEventListener("scroll", resetDropdownPosition);
  };
  }, [])

  /**Recalculate Position on scroll*/
  function resetDropdownPosition() {
    var indusParent = industryParentRef.current;
    if (indusParent) {
      indusParent = indusParent.getBoundingClientRect();
      if (document.querySelector('.inds-primary.demoFixed') && indusParent.top) {
        document.querySelector('.inds-primary.demoFixed').style.top = indusParent.top + 30 + 'px';
      }
    }
  }



  if(isOpen){
  return (
    <div className="relative z-10 mobile:w-full project-setup-industry" onClick={(event) => {event.preventDefault();if (isCollapsed) {setIsCollapsed(false)}}} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 overlay-background bg-opacity-75 backdrop-blur-sm transition-opacity mobile:hidden"></div>
      <div className={`${props.open ? "slide-left" : ""} industry-popup fixed inset-0 z-10 overflow-y-auto h-full mobile:relative mobile:h-auto mobile:w-full`}>
        <div className='absolute h-full p-[32px] top-0 right-0 bg-[#ffffff] w-[485px] shadow-md mobile:relative mobile:shadow-none mobile:w-full mobile:p-0 mobile:h-auto dark:bg-[#0E0E0E]'>
          <div className="w-full flex flex-wrap">
            <div className='flex mobile:hidden'>
              <div className='flex flex-wrap gap-1 text-left'>
                <p className='text-[#07091b] text-[20px] leading-[130%] tracking-[-0.6px] dark:text-[#fff]'>02. Industry</p>
                <p className='text-[#a3a3a3] text-[14px] leading-[130%] tracking-[-0.42px] dark:text-[#808080]'>What industry experience are most relevant to your project?</p>
              </div>
            </div>
                  <div>
                    <label className="text-[14px] top-0 text-[#a3a3a3] primary z-0 duration-300 origin-0 mt-[-26px] select-label">Industries</label>
                 </div>
              <div className="w-[421px]">

               <div className="flex flex-col items-end mt-8">
                   <div className={classNames({'w-10 pr-4': true})}>
                       <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" className="dropdown-icon"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.70711 6.24422C5.31658 6.63474 4.68342 6.63474 4.29289 6.24422L0.292892 2.24422C-0.0976325 1.85369 -0.0976325 1.22053 0.292892 0.830002C0.683416 0.439478 1.31658 0.439478 1.70711 0.830002L5 4.1229L8.29289 0.830002C8.68342 0.439478 9.31658 0.439478 9.70711 0.830002C10.0976 1.22053 10.0976 1.85369 9.70711 2.24422L5.70711 6.24422Z" fill="#4C4C4C"></path></svg>
                  </div>

                   <input readOnly onClick={() => setIsCollapsed(!isCollapsed)} className="border-b-2 border-black w-full" type="text"/>

               </div>
                  {isCollapsed && <MultiSelectTree items={items}/>}
                  <div className="flex gap-4">
                    <div className="border-2 mt-2 border-black rounded-3xl flex justify-between py-2 font-bond">
                      <div className="px-3">Consumer Goods</div>
                      <div role="button" className="px-3">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.72184 15.2738C4.82196 15.3739 4.938 15.4399 5.06997 15.4717C5.20648 15.5036 5.33845 15.5036 5.46587 15.4717C5.59784 15.4399 5.7116 15.3762 5.80717 15.2806L10.0051 11.0827L14.1962 15.2806C14.2918 15.3762 14.4056 15.4399 14.5375 15.4717C14.6695 15.5036 14.8015 15.5036 14.9334 15.4717C15.0654 15.4399 15.1815 15.3739 15.2816 15.2738C15.3771 15.1782 15.4408 15.0644 15.4727 14.9325C15.5046 14.8005 15.5046 14.6708 15.4727 14.5434C15.4408 14.4114 15.3771 14.2954 15.2816 14.1953L11.0904 9.99733L15.2816 5.8062C15.3771 5.70609 15.4408 5.59232 15.4727 5.4649C15.5091 5.33294 15.5091 5.20097 15.4727 5.069C15.4408 4.93703 15.3771 4.82327 15.2816 4.7277C15.1769 4.62759 15.0586 4.5616 14.9266 4.52975C14.7992 4.4979 14.6695 4.4979 14.5375 4.52975C14.4056 4.55705 14.2918 4.62076 14.1962 4.72088L10.0051 8.91883L5.80717 4.72088C5.7116 4.62531 5.59784 4.5616 5.46587 4.52975C5.3339 4.4979 5.20193 4.4979 5.06997 4.52975C4.938 4.5616 4.82196 4.62759 4.72184 4.7277C4.62628 4.82327 4.56257 4.93703 4.53072 5.069C4.49886 5.20097 4.49886 5.33294 4.53072 5.4649C4.56257 5.59687 4.62628 5.70836 4.72184 5.79937L8.91297 9.99733L4.72184 14.2021C4.62628 14.2931 4.5603 14.4046 4.52389 14.5366C4.49204 14.6685 4.49204 14.8005 4.52389 14.9325C4.55575 15.0644 4.62173 15.1782 4.72184 15.2738Z" className="dark:fill-white" fill="#1D1D1D"></path></svg>
                      </div>
                  </div>
              </div>
              </div>
          </div>
          <div className='absolute bottom-[32px] mobile:mt-[24px] mobile:relative mobile:bottom-auto'>
            <button disabled={!industry.length}  className='bg-c-black disabled:opacity-[0.3] text-white w-[421px] h-[45px] font-bold mobile:text-[12px] mobile:tracking-[-0.36px] text-[16px] leading-[130%] tracking-[-0.48px] rounded-full px-[24px] py-[12px] mobile:w-full dark:!bg-[#fff] dark:text-[#0E0E0E]'>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}
}